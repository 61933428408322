// constants
import { LOADING_START, LOADING_STOP } from 'store/types';

type LoadingReducerType = { isLoading: boolean };

const initialState = {
  isLoading: false,
};

export const loading = (state = initialState, action): LoadingReducerType => {
  switch (action.type) {
    case LOADING_START:
      return { ...state, isLoading: true };

    case LOADING_STOP:
      return { ...state, isLoading: false };

    default:
      return { ...state };
  }
};
