import styled from '@emotion/styled';
import STRENGTH from './password-strength.constants';
import { BaseThemeType } from 'theme/baseTheme';

export const PasswordStrengthMeterWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  margin-top: ${props => props.theme.spaces.space_mini};
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spaces.space_m};
  min-height: ${props => props.theme.spaces.space_s};
`;

const colorMapper = props => {
  let state = '';

  if (!props.isActive) {
    return props.theme.colors.neutral300;
  }

  if (!props.passwordLength) {
    return props.theme.colors.neutral300;
  }

  switch (props.state) {
    case STRENGTH.WEAK:
    case STRENGTH.VERY_WEAK:
      state = props.theme.colors.brightRed;
      break;
    case STRENGTH.NEUTRAL:
      state = props.theme.colors.brightOrange;
      break;
    case STRENGTH.STRONG:
    case STRENGTH.VERY_STRONG:
      state = props.theme.colors.brightGreen;
      break;
  }
  return state;
};

export const PasswordStrengthMeterBlockStyled = styled.div<{
  theme?: BaseThemeType;
  isActive?: any;
  state?: string;
  passwordLength?: number;
}>`
  height: ${props => props.theme.spaces.space_mini};
  width: 20%;
  border-radius: 1000px;
  background: ${colorMapper};
  @supports (not (gap: ${props => props.theme.spaces.space_mini})) {
    margin-right: ${props => props.theme.spaces.space_mini};
  }
`;

export const PasswordStrengthMeterLabelStyled = styled.div<{ theme?: BaseThemeType }>`
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.xxxSmall};
  line-height: ${props => props.theme.spaces.space_s};
  color: ${props => colorMapper(props)};
  white-space: nowrap;
`;

export const PasswordStrengthBlockWrapper = styled.div<{ theme?: BaseThemeType }>`
  display: flex;
  width: 80%;
  gap: ${props => props.theme.spaces.space_mini};
`;

export const PasswordStrengthLabelStyled = styled.div<{ theme?: BaseThemeType }>`
  margin-top: ${props => props.theme.spaces.space_xs};
  font-size: ${props => props.theme.fontSizes.xxxSmall};
  line-height: ${props => props.theme.spaces.space_s};
  color: ${props => props.theme.colors.neutral700};
  text-align: left;
`;
