import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';

// components
import { MpInput, MpDropdownSelect, MpMessage, Label } from 'UI';
import { Cents } from './cents';

// styles
import { InputWithDropdownWrapperStyled } from './input-currency-with-dropdown.styled';

// constants
import { ERROR } from 'constants/message-types';
import { creditNoteAmount } from 'constants/form-data';
import { FALLBACK_CURRENCY_ITEM } from 'constants/currency';

// utils
import { formatter } from 'utils';
import { useAppSelector } from 'store';

type Props = {
  handleChange: (data: any) => any;
  options: Array<{ value: string; label: string; name: string }>;
  label: string;
  name: string;
  isRequired: boolean;
  handleNameSymbolChange?: (data: any) => any;
  /** Error Message */
  errorMessage?: string;
  showCents?: boolean;
  value: number | string;
  isCurrencyDropdownDisabled?: boolean;
  defaultCurrencyCode?: string;
};

export const InputCurrencyWithDropdown = ({
  handleChange,
  handleNameSymbolChange,
  label,
  name,
  isRequired,
  errorMessage,
  showCents = false,
  value,
  isCurrencyDropdownDisabled = false,
  defaultCurrencyCode = '',
}: Props) => {
  const isRefund = name === creditNoteAmount.name;
  const { currencyList } = useAppSelector(state => state.settings);

  const currencyCode = defaultCurrencyCode;
  const initialCurrencyOption =
    currencyCode && currencyList.find(({ label }) => label === currencyCode)
      ? currencyList.find(({ label }) => label === currencyCode)
      : currencyList[0] || FALLBACK_CURRENCY_ITEM;

  const [selectedOption, setSymbol] = useState(initialCurrencyOption);
  const [isFocused, setIsFocused] = useState(false);
  const [amount, cents = ''] = String(value).split('.');

  const onChange = selectedOption => {
    setSymbol(selectedOption);
    handleNameSymbolChange?.({
      currency_symbol: selectedOption.value,
      currency_code: selectedOption.label,
      name: selectedOption.name,
    });
  };

  const onPriceChange = e => {
    const event = { target: { name, label, value: `${e.target.value}.${cents}` } };
    handleChange(event);
  };

  const onCentChange = e => {
    const event = { target: { name, label, value: `${amount}.${e.target.value}` } };
    handleChange(event);
  };

  const handlePaste = event => {
    const lang = localStorage.getItem('sellerLang') || 'en';
    const separator = lang === 'en' ? '.' : ',';
    // @ts-ignore
    const copiedAmount = (event.clipboardData || window.clipboardData).getData('text');

    const [integer, cents] = copiedAmount.split(separator);
    if (integer && cents) {
      // set timeout needs to overwrite the value after pasting
      setTimeout(() => {
        handleChange({ target: { name, label, value: `${integer}.${cents}` } });
      }, 0);
    }
  };

  return (
    <Grid container alignItems="middle">
      {label && (
        <Grid item xs={12}>
          <Label htmlFor={name} isRequired={isRequired}>
            {label}
          </Label>
        </Grid>
      )}
      <InputWithDropdownWrapperStyled showCents={showCents}>
        <Grid item xs={showCents ? 7 : 9}>
          <MpInput
            leftLabel={selectedOption?.value}
            onChange={onPriceChange}
            className="currency-amount"
            name={name}
            type={isFocused ? 'number' : 'text'}
            // @ts-ignore
            inputmode="numeric"
            value={
              isFocused ? amount : amount && formatter(amount, { precision: showCents ? 0 : 2 })
            }
            onKeyDown={event => {
              if (event.key === '.' || event.key === ',') {
                event.preventDefault();
              }
            }}
            placeholder="1.000"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onPaste={handlePaste}
          />
        </Grid>
        {showCents && (
          <Grid item xs={2}>
            <Cents handleChange={onCentChange} defaultValue={cents} />
          </Grid>
        )}
        <Grid item xs={3}>
          <MpDropdownSelect
            name="currency-select"
            isDisabled={isRefund || isCurrencyDropdownDisabled}
            onChange={onChange}
            options={currencyList}
            defaultValue={selectedOption}
          />
        </Grid>
      </InputWithDropdownWrapperStyled>
      {errorMessage && (
        <Box pt={1}>
          <Grid item xs={12}>
            <MpMessage type={ERROR} message={errorMessage} />
          </Grid>
        </Box>
      )}
    </Grid>
  );
};
