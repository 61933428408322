// types
import {
  UPDATE_BUYER_LIST,
  UPDATE_BUYERS_PAGE_DETAILS,
  UPDATE_SINGLE_BUYER_BASIC_DETAILS,
  RESET_SINGLE_BUYER_BASIC_DETAILS,
  RESET,
  CHANGE_ADD_BUYER_FORM,
  RESET_BUYER_FORM,
} from 'store/types';

import {
  Address,
  BuyerDetails,
  CreditQualification,
  NewBuyerForm,
  UserType,
  BuyerStatus,
} from '../types/buyers';
import { termOrder } from 'constants/enum.constants';

export type Buyer = {
  address: Address;
  business_name: string;
  buyer: string;
  buyer_users: Array<UserType>;
  credit_qualification: CreditQualification;
  credit_score?: unknown;
  due_invoice: number;
  email: string;
  id: number;
  legal_form: string;
  merchant_buyer_id: string;
  paid_inovice: number;
  phone: string;
  registration_number: string;
  status: keyof typeof BuyerStatus;
  steuernummer?: string;
  total_invoice: number;
  vat_id: string;
  _uuid: string;
  business_established_year?: number;
};

type BuyerReducerType = {
  newBuyerForm: NewBuyerForm;
  singleBuyerTransactionListCount: number;
  page: Record<string, unknown>;
  totalBuyers: number;
  buyersList: Array<Buyer>;
  contactList: Array<UserType>;
  issuedBy: string | null;
  buyersSearchList: Array<unknown>;
  buyerDetails: BuyerDetails;
};

const initialBuyerForm: NewBuyerForm = {
  step: 0,
  business_name: '',
  registration_number: '',
  merchant_buyer_id: '',
  accountsPayableEmail: '',
  first_name: '',
  last_name: '',
  language: 'en',
  role: 'ADMIN',
  phone: '',
  email: '',
  address_line1: '',
  address_line2: '',
  zip_code: '',
  city: '',
  country: '',
  isBillingAddressSameAsBilling: false,
  shipping_address_line1: '',
  shipping_postal_code: '',
  shipping_city: '',
  shipping_country: '',
};

const initialBuyerDetails: BuyerDetails = {
  isVerified: true,
  name: '',
  status: 'UNDER REVIEW',
  credit_qualification: {
    company_credit_score: '',
    probability_of_default: '',
    bond_equivalent_rating: '',
    rec_credit_limit: '',
    est_insured_limit: '',
    insurable: '',
    currency: { code: 'EUR', name: 'EURO', symbol: '€' },
    eligible_payment_terms: [],
  },
  address: {} as Address,
  payment_term: {
    created_at: '',
    credit_limit: '',
    currency_code: '',
    currency_name: '',
    currency_symbol: '',
    payment_terms: '',
  },
  companyDetails: {},
  people: [
    {
      email: '',
      first_name: '',
      id: 0,
      last_name: '',
      user_designation: '',
      user_type: '',
      role: 'MEMBER',
    },
  ],
  language: 'en',
  update_requests: [],
  business_name: '',
  buyer: '',
  buyer_users: [] as Array<UserType>,
  credit_score: null,
  due_invoice: 0,
  email: '',
  id: 0,
  legal_form: '',
  merchant_buyer_id: '',
  paid_inovice: 0,
  phone: '',
  registration_number: '',
  total_invoice: 0,
  vat_id: '',
  _uuid: '',
  business_established_year: 0,
};

const initialState: BuyerReducerType = {
  newBuyerForm: initialBuyerForm,
  singleBuyerTransactionListCount: 0,
  page: {},
  totalBuyers: 0,
  buyersList: [],
  contactList: [],
  issuedBy: null,
  buyersSearchList: [],
  buyerDetails: initialBuyerDetails,
};

export const buyer = (state = initialState, action): BuyerReducerType => {
  switch (action.type) {
    case RESET_BUYER_FORM:
      return { ...state, newBuyerForm: initialBuyerForm };

    case CHANGE_ADD_BUYER_FORM:
      return { ...state, newBuyerForm: { ...state.newBuyerForm, ...action.payload } };

    case UPDATE_BUYER_LIST:
      return { ...state, buyersList: action.payload, totalBuyers: action.payload.length };

    case UPDATE_BUYERS_PAGE_DETAILS:
      return { ...state, page: action.payload };

    case UPDATE_SINGLE_BUYER_BASIC_DETAILS: {
      const { buyer_users } = action.payload;
      const {
        credit_qualification: { eligible_payment_terms = [], ...restCreditQualificationDetails },
        ...buyerDetails
      } = action.payload || {};

      const isEligiblePaymentTermsValidArray =
        Array.isArray(eligible_payment_terms) && eligible_payment_terms?.length > 0;

      const eligiblePaymentTerms = isEligiblePaymentTermsValidArray
        ? eligible_payment_terms.sort((a, b) => termOrder[a] - termOrder[b])
        : [];

      return {
        ...state,
        buyerDetails: {
          ...buyerDetails,
          credit_qualification: {
            ...restCreditQualificationDetails,
            eligible_payment_terms: eligiblePaymentTerms,
          },
        },
        contactList: buyer_users,
        issuedBy: buyer_users.find(({ email_otp_validated }) => email_otp_validated)?.email || null,
      };
    }

    case RESET_SINGLE_BUYER_BASIC_DETAILS:
      return { ...state, buyerDetails: initialBuyerDetails, contactList: [], issuedBy: null };

    case RESET: {
      return initialState;
    }

    default:
      return { ...state };
  }
};
