// vendor
import React, { useState, useEffect, useRef } from 'react';
import { Tooltip, Typography, AccordionDetails } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useAppDispatch } from 'store';
import { formatInTimeZone } from 'date-fns-tz';
import { ExpandMore, Timeline as TimelineIcon } from '@mui/icons-material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  timelineItemClasses,
} from '@mui/lab';

// components
import { FormattedMessage } from 'UI';

// styles
import {
  HistoryTableTitleStyled,
  HistoryTableDateStyled,
  HistoryTableTimeStyled,
  TimelineCustomAccordionSummary,
  TimelineCustomAccordion,
  CustomStatusBox,
} from './mp-timeline.styled';

// constants
import { INVOICE_DETAILS_TIMELINE_HEADER_KEYS } from 'constants/invoice-details.constant';
import { black, grey, WHITE } from 'theme/colors';
import fontSizes from 'constants/font-sizes';
import spaces from 'constants/spacing';

// utils
import { renderTag, formatter } from 'utils';

// assets
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// utils
import { copyToClipboard } from 'utils';
import { changeTableConfig } from 'store/actions/tables';
import { CurrencySymbol } from 'constants/currency';

type Props = {
  title?: string;
  data: Array<{
    date: string;
    label: string;
  }>;
};

const extractDateTime = (date: string) => {
  const newDate = new Date(date);
  return [
    formatInTimeZone(newDate, 'Europe/Paris', 'dd/MM/yyyy'),
    formatInTimeZone(newDate, 'Europe/Paris', 'HH:mm'),
  ];
};

const mapHistoryData = (list, handleClickToCopy) => {
  return list.map((row, index) => {
    const {
      recorded_at,
      event,
      currency_symbol,
      amount = 0,
      status,
      declined_reason = '',
    } = row || {};

    const eventName = String(event || '')
      .split('_')
      .join(' ')
      .toLowerCase();

    const [extractedDate, extractedTime] = extractDateTime(recorded_at);
    return {
      [INVOICE_DETAILS_TIMELINE_HEADER_KEYS.date]: (
        <>
          <HistoryTableDateStyled>{extractedDate}</HistoryTableDateStyled> -
          <HistoryTableTimeStyled>{extractedTime}</HistoryTableTimeStyled>
        </>
      ),
      [INVOICE_DETAILS_TIMELINE_HEADER_KEYS.event]: (
        <>
          <FormattedMessage id={`timeline.${eventName}`} />
          {row.id && (
            <Tooltip title={<FormattedMessage id="CLICK_TO_COPY" />} arrow placement="top">
              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => handleClickToCopy(row.id)}
                component="span"
              >
                {' '}
                <FormattedMessage id="ID" />: {row.id}
              </Typography>
            </Tooltip>
          )}
        </>
      ),
      [INVOICE_DETAILS_TIMELINE_HEADER_KEYS.amount]: currency_symbol + formatter(Number(amount)),
      [INVOICE_DETAILS_TIMELINE_HEADER_KEYS.status]: (
        <CustomStatusBox>
          {renderTag(status)}
          {declined_reason && (
            <Tooltip
              title={declined_reason}
              placement="right"
              sx={{ marginLeft: spaces.space_xxxs }}
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    color: WHITE,
                    backgroundColor: grey[900],
                    fontWeight: 600,
                    fontSize: fontSizes.xxxSmall,
                    lineHeight: spaces.space_s,
                    padding: spaces.space_xs,
                    maxWidth: '200px',
                  },
                },
                arrow: {
                  sx: {
                    color: grey[900],
                  },
                },
              }}
            >
              <InfoOutlinedIcon id={'declined-reason-icon-' + index} />
            </Tooltip>
          )}
        </CustomStatusBox>
      ),
    };
  });
};

export interface CustomTimelineContentProps {
  date: React.ReactNode | string;
  event: React.ReactNode | string;
  amount: React.ReactNode | string;
  status: React.ReactNode | string;
  fileUrl: React.ReactNode | string;
  recorded_at: Date;
  declined_reason: null | string;
  currency_symbol: CurrencySymbol;
}

const CustomTimelineContent = ({ ...item }: CustomTimelineContentProps) => {
  return (
    <TimelineContent display="flex" gap={2}>
      <div>{item.date}</div>|<div>{item.event}</div>|<div>{item.amount}</div>|
      <div>{item.status}</div>
      {item.fileUrl}
    </TimelineContent>
  );
};

export const MpTimeline = (props: Props) => {
  const { data } = props;
  const [expanded, setIsExpanded] = useState(true);
  const [tableData, setTableData] = useState<CustomTimelineContentProps[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const divRef = useRef();

  const handleClickToCopy = text => {
    copyToClipboard(text);
    enqueueSnackbar(`${text} copied`);
  };

  useEffect(() => {
    dispatch(changeTableConfig('isDensePadding', false));
    setTableData(mapHistoryData(data, handleClickToCopy));
  }, [data]);

  return (
    <TimelineCustomAccordion
      // @ts-ignore
      ref={divRef}
      isExpanded={expanded}
      defaultExpanded
    >
      <TimelineCustomAccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel3a-content"
        id="panel3a-header"
        onClick={() => {
          setIsExpanded(prevValue => !prevValue);
          setTimeout(() => {
            // @ts-ignore
            divRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
          }, 300);
        }}
      >
        <HistoryTableTitleStyled>
          <TimelineIcon style={{ fill: 'url(#with-icon-gradient)' }} />
          <FormattedMessage id="HISTORY_TABLE_HEADER_TITLE" />
        </HistoryTableTitleStyled>
      </TimelineCustomAccordionSummary>
      <AccordionDetails
        sx={{
          paddingBottom: 0,
        }}
      >
        <Timeline
          sx={{
            padding: 0,
            margin: 0,
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {tableData.map((record, index) => {
            return (
              <TimelineItem sx={{ margin: 0 }} key={`timwline-record-${index}`}>
                <TimelineSeparator>
                  <TimelineDot sx={{ background: black[300], color: black[300], borderWidth: 0 }} />
                  {tableData.length - 1 !== index && (
                    <TimelineConnector sx={{ background: black[300], color: black[300] }} />
                  )}
                </TimelineSeparator>
                <CustomTimelineContent {...record} />
              </TimelineItem>
            );
          })}
        </Timeline>
      </AccordionDetails>
    </TimelineCustomAccordion>
  );
};
