export default {
  PAID: 'PAID',
  AUTHORIZED: 'AUTHORIZED',
  OPEN: 'OPEN',
  OVERDUE: 'OVERDUE',
  DECLINED: 'DECLINED',
  CANCELED: 'CANCELED',
  APPLIED: 'APPLIED',
  REFUNDED: 'REFUNDED',
  CAPTURED: 'CAPTURED',
};
