// vendor
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';

// assets
import SearchIcon from 'assets/icons/search.svg';

// components
import { Label } from 'UI';

// styles
import {
  MpDropdownSearchWithInputStyled,
  MpDropDownSearchInputWrapperStyled,
  MpDropDownSearchInputIconStyled,
  MpDropdownCreatableSearchWithInputStyled,
} from './mp-dropdown-search-input.styled';

type Props = {
  options: Array<{
    label: string;
    value: string;
  }>;
  handleInputChange: (val?: any) => any;
  handleChange: (val?: any) => any;
  /** cross icon to clear the content*/
  isClearable: boolean;
  /** name of the field */
  name: string;
  defaultValue?: {
    label: string;
    value: string;
  };
  /** to disable the input dropdown */
  isDisabled: boolean;
  /** formatCreateLabel Custom dropdown message option */
  formatCreateLabel?: () => any;
  selectType?: string;
  isCreatable?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  label?: string | React.ReactNode;
  isRequired?: boolean;
  hideNoOptionMessage?: boolean;
  /** Translator Function */
  t: (d: any) => string;
};

const customStyles = {
  control: base => {
    return {
      ...base,
      'border': 0,
      // This line disable the blue border
      'boxShadow': 'none',
      '&:hover': {
        borderColor: 'none',
      },
    };
  },
};

const MpDropdownSearchWithInput = (props: Props) => {
  const { label, isRequired, hideNoOptionMessage, t } = props;
  useEffect(() => {
    props.handleChange(props.defaultValue || {});
  }, []);

  return (
    <>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <MpDropDownSearchInputWrapperStyled data-sdet={props.name}>
        <MpDropDownSearchInputIconStyled src={SearchIcon} />
        {props.isCreatable ? (
          <MpDropdownCreatableSearchWithInputStyled
            placeholder={t(props.placeholder)}
            defaultValue={props.defaultValue}
            isDisabled={props.isDisabled}
            isClearable={props.isClearable}
            onChange={props.handleChange}
            onInputChange={props.handleInputChange}
            options={props.options}
            // @ts-ignore
            classNamePrefix
            onCreateOption={props.handleChange}
            name={props.name}
            formatCreateLabel={props.formatCreateLabel}
            isLoading={props.isLoading}
            styles={customStyles}
          />
        ) : (
          <MpDropdownSearchWithInputStyled
            {...props}
            defaultValue={props.defaultValue}
            placeholder={t(props.placeholder)}
            isDisabled={props.isDisabled}
            isClearable={props.isClearable}
            onChange={props.handleChange}
            onInputChange={props.handleInputChange}
            options={props.options}
            // @ts-ignore
            classNamePrefix
            name={props.name}
            isLoading={props.isLoading}
            hideNoOptionMessage={hideNoOptionMessage}
            styles={customStyles}
          />
        )}
      </MpDropDownSearchInputWrapperStyled>
    </>
  );
};

MpDropdownSearchWithInput.defaultProps = {
  isClearable: true,
  isDisabled: false,
  isLoading: false,
  placeholder: 'Select',
  isCreatable: false,
  hideNoOptionMessage: false,
};

export default withTranslation('translations')(MpDropdownSearchWithInput);
