import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/react';
import * as FullStory from '@fullstory/browser';
import zipy from 'zipyai';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';

import './index.css';
import 'react-phone-input-2/lib/style.css';
import reportWebVitals from './reportWebVitals';
import { store } from 'store';
import App from './app';
import { MuiThemeProviderExtended as ThemeProvider } from 'theme/mui-theme';
// translations
import i18n from 'i18next';
import i18nextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { packageJson } from 'package.alias';

if (process.env.REACT_APP_CURRENT_ENV !== 'local') {
  Sentry.init({
    dsn: 'https://df333808b60e4eefb8c6a489cb44a343@o651071.ingest.sentry.io/5916403',
    // @ts-ignore (failed in the pipeline only)
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay({ maskAllText: false })],
    tracesSampleRate: 1.0,
    release: packageJson.version,
    environment: process.env.REACT_APP_CURRENT_ENV,
    ignoreErrors: [
      'Request failed with status code 401',
      'No active account found with the given credentials',
      'Token is invalid or expired',
      'Bad Request',
      'User not found',
      'Failed to fetch visitor data on mount',
      'FPJSAgentError: Failed to load the JS script of the agent',
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
  });
}

if (process.env.REACT_APP_CURRENT_ENV === 'prod') {
  FullStory.init({ orgId: 'o-1ESJKB-na1' });
  zipy.init('f04b928b', { releaseVer: packageJson.version });
}

i18n
  .use(i18nextHttpBackend)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: localStorage.getItem('uiLang') || 'en',
      //debug: false,
      ns: ['translations'],
      backend: {
        loadPath: '/i18n/{{lng}}.json' + '?ts=' + new Date().getTime(),
        crossDomain: true,
        requestOptions: {
          cache: 'no-store',
        },
      },
    },
    function () {
      const container = document.getElementById('root') as Element;
      const root = createRoot(container);
      root.render(
        <ThemeProvider>
          <Provider store={store}>
            <Router>
              <React.StrictMode>
                <SnackbarProvider maxSnack={3}>
                  <FpjsProvider
                    loadOptions={{
                      apiKey: 'VLQShOCoxtQifKWEZS4O',
                      region: 'eu',
                    }}
                  >
                    <App />
                  </FpjsProvider>
                </SnackbarProvider>
              </React.StrictMode>
            </Router>
          </Provider>
        </ThemeProvider>
      );
    }
  );
// @ts-ignore
reportWebVitals();
