// vendor
import React from 'react';
import { withRouter } from 'react-router-dom';

// styles
import { MpSidebarMenuLabelStyled } from './mp-sidebar-menu.styled';

type Props = {
  location: {
    pathname: string;
  };
  onMenuLinkClicked: (path: string) => any;
  onSubMenuClick: () => any;
  menu: Array<{
    label: string;
    path: string;
    dataSdet?: string;
  }>;
};

function MpSideBarMenu(props: Props) {
  const { onMenuLinkClicked, onSubMenuClick, menu } = props;
  return (
    <div className="sidebar-menu">
      {menu.map(({ label, path, dataSdet = '' }, index) => {
        if (label) {
          const length = menu.length;
          const isActive = props.location.pathname.includes(path);
          return (
            <MpSidebarMenuLabelStyled
              key={`mp-sidebar-menu-${length}-${index}`}
              isActive={isActive}
              data-sdet={`sidebar-menu-${dataSdet}`}
              onClick={() => {
                onMenuLinkClicked(path), onSubMenuClick();
              }}
            >
              {label}
            </MpSidebarMenuLabelStyled>
          );
        }
      })}
    </div>
  );
}

export default withRouter(MpSideBarMenu);
