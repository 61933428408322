export const sort = (tableData, label) => {
  const data = [...tableData].sort((a, b) => {
    if (a[label] < b[label]) {
      return -1;
    }
    if (a[label] > b[label]) {
      return 1;
    }
    return 0;
  });
  return data;
};
