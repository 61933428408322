// vendor
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Tooltip, Grid } from '@mui/material';
import {
  Help,
  CancelRounded,
  UploadFileOutlined,
  InsertDriveFileOutlined,
} from '@mui/icons-material';

// styles
import { MpDropzonePlaceholderStyled, MpDropzoneWrapperStyled } from './mp-dropzone.styled';

// components
import { Label, MpMessage, FormattedMessage } from 'UI';

// constants
import { ERROR } from 'constants/message-types';
import { grey } from 'theme/colors';

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

type Props = {
  label?: string;
  tooltip?: string;
  placeholder?: string;
  name: string;
  onChange: (files?: any) => any;
  accept?: string;
  /** to disable the dropzone */
  isDisabled?: boolean;
  /** Is Required flag on the label */
  isRequired?: boolean;
  /** Error message */
  errorMessage?: string;
  /** Default value */
  defaultValue?: string;
  /** Delete file */
  onDelete?: () => any;
};

function DropzoneComponent(props: Props) {
  const [files, setFiles] = useState([]);

  const onDrop = acceptedFiles => {
    setFiles(acceptedFiles);
    props.onChange(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: props.accept,
    disabled: props.isDisabled,
  });

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  // clean up
  useEffect(
    () => () => {
      // @ts-ignore
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const handleDelete = e => {
    e.stopPropagation();
    setFiles([]);
    props.onDelete?.();
  };

  return (
    <section>
      {props.label && (
        <Grid container>
          <Label isRequired={props.isRequired}>{props.label}</Label>
          {props.tooltip && (
            <Tooltip placement="top" arrow title={<FormattedMessage id={props.tooltip} />}>
              <Help
                sx={{
                  cursor: 'pointer',
                  width: '12px',
                  color: grey[500],
                  transform: 'translate(1px, -3px)',
                }}
              />
            </Tooltip>
          )}
        </Grid>
      )}
      <MpDropzoneWrapperStyled {...getRootProps({ style })}>
        <input {...getInputProps()} name={props.name} />
        {/*@ts-ignore*/}
        {files[0]?.name || props.defaultValue ? (
          <MpDropzonePlaceholderStyled isDisabled={props.isDisabled}>
            <InsertDriveFileOutlined sx={{ mr: 2 }} />
            {/*@ts-ignore*/}
            {files[0]?.name || props.defaultValue}
            {props.isDisabled ? (
              <></>
            ) : (
              (!props.defaultValue || props.onDelete) && (
                <CancelRounded
                  sx={{ cursor: 'pointer', ml: 'auto', fontSize: '1.2rem' }}
                  onClick={handleDelete}
                />
              )
            )}
          </MpDropzonePlaceholderStyled>
        ) : (
          <MpDropzonePlaceholderStyled justifyCenter>
            <UploadFileOutlined sx={{ mr: 2 }} />
            <FormattedMessage id={props.placeholder || ''} />
          </MpDropzonePlaceholderStyled>
        )}
      </MpDropzoneWrapperStyled>

      {props.errorMessage && (
        <MpMessage type={ERROR} message={props.errorMessage} isPaddingTopRequired={true} />
      )}
    </section>
  );
}

export default DropzoneComponent;

DropzoneComponent.defaultProps = {
  accept: 'application/pdf, ,pdf',
  isDisabled: false,
};
