// vendor
import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';

// components
import { MpIcon, MpButton, MpLink, FormattedMessage } from 'UI';

// constants
import { GHOST } from 'constants/button-types';

// styles
import {
  EmailVerificationWrapperStyled,
  EmailVerificationDescriptionStyled,
  EmailVerificationEmailSuccessTitleStyled,
  EmailVerificationButtonWrapperStyled,
  AdditionalLinkEmailStyled,
} from './styled';

// utils
import { useAppDispatch } from 'store';
import { errorMessage, snackbarConfig } from 'utils';

// actions
import { stopLoading, startLoading, resendEmailVerificationLink } from 'store/actions';

type Props = {
  /** email id */
  email: string;
};

export const EmailVerification = ({ email = '' }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const sendEmailVerificationMail = () => {
    dispatch(startLoading);
    resendEmailVerificationLink(email)
      .then(() => {
        enqueueSnackbar(<FormattedMessage id="RESEND_EMAIL_LINK_SUCCESS" />, snackbarConfig());
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };

  useEffect(() => {
    if (email) {
      sendEmailVerificationMail();
    }
  }, [email]);

  return (
    <EmailVerificationWrapperStyled>
      <MpIcon name="email-green" />
      <EmailVerificationEmailSuccessTitleStyled>
        <FormattedMessage id="VERIFY_EMAIL_SUCCESS_TITLE" />
      </EmailVerificationEmailSuccessTitleStyled>
      <EmailVerificationDescriptionStyled>
        <FormattedMessage id="VERIFY_EMAIL_SUCCESS_DERSRIPTION" />
        <MpLink to={`mailto:${email}`} additionalStyles={AdditionalLinkEmailStyled}>
          {email}
        </MpLink>
      </EmailVerificationDescriptionStyled>
      <EmailVerificationDescriptionStyled>
        <FormattedMessage id="VERIFY_EMAIL_SUCCESS_DERSRIPTION2" />
      </EmailVerificationDescriptionStyled>
      <EmailVerificationButtonWrapperStyled>
        <MpButton onClick={() => sendEmailVerificationMail()} type={GHOST}>
          <FormattedMessage id="SEND_AGAIN" />
        </MpButton>
      </EmailVerificationButtonWrapperStyled>
    </EmailVerificationWrapperStyled>
  );
};
