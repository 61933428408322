// vendor
import styled from '@emotion/styled';

import { BaseThemeType } from 'theme/baseTheme';
import { grey, WHITE } from 'theme/colors';

export const MpHeaderWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  width: 220px;
  padding: 24px;
  background: ${grey[900]};
  height: 100vh;
  overflow: auto;
  transition: 0.3s;
  .mob-menu-icons {
    display: none;
  }
  @media only screen and (max-width: 900px) {
    position: absolute;
    z-index: 2;
    overflow: visible;
    left: 0;

    &.mob-menu-closed {
      left: -220px;
    }

    .mob-menu-icons {
      display: block;
      background: ${grey[900]};
      position: absolute;
      right: -36px;
      border-radius: 4px;
      svg {
        font-size: 40px;
        padding: 3px;
        border-radius: 10px;
        color: ${WHITE};
      }
    }
  }
`;

export const MpHeaderLinkStyled = styled.div<{
  theme?: BaseThemeType;
  isActive?: boolean;
}>`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: ${props => (props.isActive ? '600' : '500')};
  color: ${WHITE};
  background: ${props => (props.isActive ? '#242151' : 'none')};
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  gap: 6px;
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const MpHeaderLeftSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;
