// constants
import { ACCESS_TOKEN, REFRESH_TOKEN, ORIGINAL_ACCESS_TOKEN } from 'constants/storage.constants';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

Storage.prototype.setObject = function (key, value) {
  this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
  return JSON.parse(this.getItem(key) as string);
};

const getObject = key => {
  return sessionStorage.getObject(key);
};

const setObject = (key, value) => {
  return sessionStorage.setObject(key, value);
};

const getItem = key => {
  return sessionStorage.getItem(key);
};

const setItem = (key, value) => {
  return sessionStorage.setItem(key, value);
};

const setAccessToken = token => {
  const current = new Date();
  const nextYear = new Date();
  nextYear.setDate(current.getDate() + 30);
  cookies.set(ACCESS_TOKEN, token, { path: '/', expires: nextYear });
};

const setToken = tokenObj => {
  const current = new Date();
  const nextYear = new Date();

  nextYear.setDate(current.getDate() + 30);

  cookies.set(ACCESS_TOKEN, tokenObj[ACCESS_TOKEN], { path: '/', expires: nextYear });
  cookies.set(REFRESH_TOKEN, tokenObj[REFRESH_TOKEN], { path: '/', expires: nextYear });
};

const getAccessToken = () => {
  return cookies.get(ACCESS_TOKEN);
};

const getRefreshToken = () => {
  return cookies.get(REFRESH_TOKEN);
};

const getCookieValue = key => cookies.get(key);

const clearToken = () => {
  cookies.remove(ACCESS_TOKEN);
  cookies.remove(REFRESH_TOKEN);
  cookies.remove(ORIGINAL_ACCESS_TOKEN);
  //localStorage.clear();
  sessionStorage.clear();
};

export default {
  setToken,
  setAccessToken,
  getAccessToken,
  getRefreshToken,
  clearToken,
  getItem,
  setItem,
  getObject,
  setObject,
  getCookieValue,
};
