import React from 'react';
import { Box, Typography } from '@mui/material';

// components
import { FormattedMessage } from 'UI';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

export const PendingRequest = () => {
  return (
    <Box sx={{ padding: '32px 0 80px' }}>
      <Box
        sx={{
          background: '#F5F8FF',
          borderRadius: '50%',
          padding: '20px',
          width: '64px',
          height: '64px',
          margin: 'auto',
          overflow: 'hidden',
        }}
      >
        <TimerOutlinedIcon sx={{ fill: 'url(#with-icon-gradient)' }} />
      </Box>
      <Box
        sx={{
          marginTop: '24px',
        }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '32px',
            textAlign: 'center',
            color: '#000339',
          }}
        >
          <FormattedMessage id="BUYER.PENDING_REVIEW" />
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: '24px',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '20px',
            textAlign: 'center',
            color: '#000339',
          }}
        >
          <FormattedMessage id="BUYER.PENDING_REVIEW_MESSAGE" />
        </Typography>
      </Box>
    </Box>
  );
};
