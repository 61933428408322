import React from 'react';

// actions
import { startLoading, stopLoading } from 'store/actions';

// api
import { postData } from 'api';

// constants
import { INVOICE } from 'constants/api-endpoints.constants';
import { FETCH_ALL_INVOICES, FETCH_ALL_ORDERS, FETCH_ALL_PENDING_ORDERS } from 'store/types';
import { FormattedMessage } from 'UI';

// utils
import { snackbarConfig, errorMessage } from 'utils';

export const getAllInvoicesActionCreator = (buyerId = '') => ({
  type: FETCH_ALL_INVOICES,
  payload: { buyerId },
});

export const fetchOrdersActionCreator = (buyerId = '') => ({
  type: FETCH_ALL_ORDERS,
  payload: { buyerId },
});

export const fetchPendingOrdersActionCreator = (buyerId = '') => ({
  type: FETCH_ALL_PENDING_ORDERS,
  payload: { buyerId },
});

export const addInvoice = (formData, enqueueSnackbar, fn) => {
  return dispatch => {
    dispatch(startLoading);
    return postData(INVOICE, formData, {
      'Content-Type': 'multipart/form-data',
    })
      .then(() => {
        enqueueSnackbar(<FormattedMessage id="INVOICE_ADDED_SUCCESSFULLY" />, snackbarConfig());
        if (fn) {
          fn();
        }
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };
};
