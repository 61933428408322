// Flags: https://flagpedia.net/download/api

export const MAP_LANG_TO_FLAG = {
  English: 'gb', // legacy
  en: 'gb',
  es: 'es',
  nl: 'nl',
  de: 'de',
  fr: 'fr',
  pl: 'pl',
};

export const UI_LANGUAGES = ['en', 'es'];
