import * as TYPES from '../types';
import * as USE_CASES from 'UI/components/table/constants';

type TablesReducerType = {
  useCase:
    | typeof USE_CASES.TABLE_USE_CASE_INVOICES
    | typeof USE_CASES.TABLE_USE_CASE_ORDERS
    | typeof USE_CASES.TABLE_USE_CASE_SETTLEMENTS
    | typeof USE_CASES.TABLE_USE_CASE_BUYERS
    | typeof USE_CASES.TABLE_USE_CASE_PENDING_ORDERS
    | '';
  isDensePadding: boolean;
  search: string;
  filterBy: Record<string, unknown>;
  sortBy: string;
  isLoading: boolean;
};

const initialState: TablesReducerType = {
  // config
  useCase: '',
  isDensePadding: localStorage.getItem('isDensePadding') === 'true',
  // settings
  search: '',
  filterBy: {},
  sortBy: '',
  isLoading: false,
};

export const table = (state = initialState, action): TablesReducerType => {
  switch (action.type) {
    case TYPES.RESET_TABLE_SETTINGS: {
      const { isDensePadding } = state;
      return {
        ...state,
        search: '',
        filterBy: {},
        sortBy: '',
        isDensePadding,
      };
    }

    case TYPES.CHANGE_TABLE_CONFIG: {
      const { key, value } = action.payload;

      localStorage.setItem(key, JSON.stringify(value));
      return { ...state, [key]: value };
    }

    case TYPES.SET_TABLE_IS_LOADING: {
      return { ...state, isLoading: action.payload };
    }

    case TYPES.CHANGE_TABLE_SETTINGS: {
      const { key, value } = action.payload;
      // reset page param from the url when filter or search applied
      if (key === 'search' || key === 'filterBy') {
        location.hash = location.hash.replace(/page=.*&/, 'page=1&');
      }
      const newSettings = { [key]: value };
      return { ...state, ...newSettings };
    }

    default:
      return { ...state };
  }
};
