// vendor
import React from 'react';
import { Box, Dialog, Breakpoint } from '@mui/material';

// components
import { FormattedMessage } from 'UI';
import { Title } from './title';

const MIN_MODAL_MAX_WIDTH = '500px';

type Props = {
  maxWidth?: Breakpoint;
  title?: string | React.ReactNode;
  titleIcon?: React.ReactNode;
  children: React.ReactNode;
  modalOpen: boolean;
  handleClose: () => any;
  boxPosition?: 'relative' | 'initial' | 'absolute' | 'fixed' | 'sticky' | 'static' | 'inherit';
};

export const MpModal = (props: Props) => {
  const { title, titleIcon, children, modalOpen, handleClose, maxWidth, boxPosition } = props;

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          maxWidth: MIN_MODAL_MAX_WIDTH,
        },
      }}
      fullWidth={true}
      open={modalOpen}
      maxWidth={maxWidth}
      onClose={handleClose}
    >
      <Box position={boxPosition || 'initial'}>
        <Title onClose={handleClose}>
          {titleIcon}
          <FormattedMessage id={title as string} />
        </Title>
        <Box padding="0 32px">{children}</Box>
      </Box>
    </Dialog>
  );
};
