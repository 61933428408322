import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store';

// constants
import { grey } from 'theme/colors';
import { FALLBACK_CURRENCY_ITEM } from 'constants/currency';
import { APPROVE_BUYER, REJECT_BUYER } from 'constants/api-endpoints.constants';
import { CREDIT_DECISION_OPTIONS } from 'constants/form-data';

// icons
import { CheckRounded, CancelRounded } from '@mui/icons-material';

// components
import {
  MpModal,
  MpDropdownSelect,
  FormattedMessage,
  SectionLoader,
  InputCurrencyWithDropdown,
  MpButton,
} from 'UI';

// utils
import { postData } from 'api';
// actions
import { getBuyerBasicProfileDetails } from 'store/actions';
import { errorMessage } from 'utils';

interface AddPersonProps {
  openModal: boolean;
  closeModal: () => void;
}

const EXPECTED_CREDIT_DECISION_STATUS = 'APPROVED';

export const ApproveRejectBuyer = ({ openModal, closeModal }: AddPersonProps) => {
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const {
    credit_qualification: {
      credit_limit,
      available_credit_limit,
      currency: { symbol, code },
    },
    _uuid,
  } = useAppSelector(state => state.buyer.buyerDetails);
  const { sellerId } = useAppSelector(state => state.login);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');
  const [state, setState] = useState({
    status: '',
    credit_limit: '',
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (openModal) {
      setDetailsSubmitted(false);
    }
  }, [openModal]);

  const updateBuyer = () => {
    setLoader(true);
    const API = state.status === EXPECTED_CREDIT_DECISION_STATUS ? APPROVE_BUYER : REJECT_BUYER;
    postData(
      API(sellerId, _uuid),
      state.status === EXPECTED_CREDIT_DECISION_STATUS
        ? {
            credit_limit: state.credit_limit,
          }
        : null
    )
      .then(() => {
        dispatch(getBuyerBasicProfileDetails(_uuid, sellerId));
      })
      .catch(e => {
        setError(errorMessage(e));
      })
      .finally(() => {
        setDetailsSubmitted(true);
        setLoader(false);
      });
  };

  const isDisabled = () => {
    const { status, credit_limit } = state;

    if (status === '') {
      return true;
    }

    if (status === EXPECTED_CREDIT_DECISION_STATUS && !credit_limit.split('.').join('')) {
      return true;
    }
    return false;
  };

  const usedCreditLimit =
    parseFloat(credit_limit || '0') - parseFloat(available_credit_limit || '0');

  const showCreditLimitError = parseFloat(state.credit_limit) < usedCreditLimit;

  const currentEnv = process.env.REACT_APP_CURRENT_ENV || '';

  return (
    <MpModal
      title=""
      modalOpen={openModal}
      handleClose={() => {
        setState({
          status: '',
          credit_limit: '',
        });
        setError('');
        closeModal();
      }}
      maxWidth="sm"
    >
      {loader ? (
        <SectionLoader />
      ) : detailsSubmitted ? (
        <Box sx={{ padding: '32px 0 80px' }}>
          <Box
            sx={{
              background: '#F5F8FF',
              borderRadius: '50%',
              padding: '20px',
              width: '64px',
              height: '64px',
              margin: 'auto',
              overflow: 'hidden',
            }}
          >
            {error ? (
              <CancelRounded sx={{ fill: 'url(#with-icon-gradient)' }} />
            ) : (
              <CheckRounded sx={{ fill: 'url(#with-icon-gradient)' }} />
            )}
          </Box>
          <Box
            sx={{
              marginTop: '24px',
            }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '32px',
                textAlign: 'center',
                color: '#000339',
              }}
            >
              <FormattedMessage id={error || 'BUYER.APPROVE_REJECT_BUYER.SUCCESS'} />
            </Typography>
          </Box>
        </Box>
      ) : (
        <section>
          <Typography
            variant="h3"
            sx={{
              mt: 4,
              color: grey[900],
              fontWeight: '700',
              lineHeight: '32px',
            }}
          >
            <FormattedMessage
              id="BUYER.APPROVE_REJECT_BUYER.TITLE"
              context={{ env: currentEnv.charAt(0).toUpperCase() + currentEnv.slice(1) }}
            />
          </Typography>

          <Typography sx={{ mt: 3, fontSize: '1rem', color: grey[900] }}>
            <FormattedMessage
              id="BUYER.APPROVE_REJECT_BUYER.SUB_TITLE"
              context={{ env: currentEnv }}
            />
          </Typography>

          <Box mt={3}>
            <MpDropdownSelect
              {...{
                name: 'netTerms',
                label: 'LABELS.APPROVE_REJECT_CREDIT_DECISION_BUYER_STATUS',
                isSearchable: true,
                isRequired: true,
                options: CREDIT_DECISION_OPTIONS,
              }}
              onChange={selectedOption => {
                setState({
                  ...state,
                  status: selectedOption.value,
                });
              }}
            />
          </Box>
          {state.status === EXPECTED_CREDIT_DECISION_STATUS && (
            <>
              <Box mt={3}>
                <InputCurrencyWithDropdown
                  {...{
                    showCents: true,
                    options: [FALLBACK_CURRENCY_ITEM],
                    name: 'creditLimit',
                    currency_name: 'currencyName',
                    currency_code: 'code',
                    currency_symbol: 'symbol',
                    label: 'LABELS.NEW_CREDIT_LIMIT',
                    isRequired: state.status === EXPECTED_CREDIT_DECISION_STATUS ? true : false,
                    placeholder: 'PLACEHOLDERS.REQUIRED',
                    defaultCurrencyCode: code || 'EUR',
                  }}
                  handleChange={e => {
                    setState({
                      ...state,
                      credit_limit: e.target.value,
                    });
                  }}
                  value={state.credit_limit}
                />
              </Box>
              {showCreditLimitError ? (
                <Box mt={2}>
                  <Typography sx={{ fontSize: '14px', color: 'red' }}>
                    <FormattedMessage
                      id="BUYER.APPROVE_REJECT_BUYER.CREDIT_LIMIT_ERROR"
                      context={{
                        usedCreditLimit,
                        symbol,
                      }}
                    />
                  </Typography>
                </Box>
              ) : (
                ''
              )}
            </>
          )}
          <Box mt={3} mb={4} textAlign="end">
            <MpButton onClick={updateBuyer} disabled={isDisabled()}>
              <FormattedMessage id="SAVE" />
            </MpButton>
          </Box>
        </section>
      )}
    </MpModal>
  );
};
