import styled from '@emotion/styled';
import { grey } from 'theme/colors';
import { BaseThemeType } from 'theme/baseTheme';

export const LabelStyled = styled.label<{
  theme?: BaseThemeType;
  isRequired?: boolean;
  labelBold?: boolean;
  subTextAlignRightToLabel?: boolean;
}>`
  display: ${props => (props.subTextAlignRightToLabel ? 'flex' : 'inline-block')};
  align-items: center;
  font-weight: ${props => (props.labelBold ? 'bold' : '500')};
  font-size: ${props => props.theme.fontSizes.xxSmall};
  line-height: ${props => props.theme.spaces.space_22};
  color: ${grey[500]};

  margin-bottom: ${props => props.theme.spaces.space_xxxs};

  ${props =>
    props.isRequired &&
    `
    ::after {
      content: '*';
      color: ${grey[500]};
    }
  `}
`;
