// vendor
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// styles
import { SubMenuLabelStyled } from './mp-sidebar-menu.styled';

type SubMenuProps = {
  withoutMargin?: boolean;
  menu: Array<{
    label: React.ReactNode;
    path: string;
    dataSdet?: string;
  }>;
};

export const SubMenu = (props: SubMenuProps) => {
  const history = useHistory();
  const { menu, withoutMargin = false } = props;
  const { pathname } = useLocation();

  return (
    <div>
      {menu.map(({ label, path, dataSdet = '' }) => {
        const isActive = pathname.includes(path);
        return (
          <SubMenuLabelStyled
            key={`mp-sidebar-menu-${path}`}
            isActive={isActive}
            withoutMargin={withoutMargin}
            data-sdet={`sidebar-menu-${dataSdet}`}
            onClick={() => history.push(path)}
          >
            {label}
          </SubMenuLabelStyled>
        );
      })}
    </div>
  );
};
