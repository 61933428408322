import fontSizes from 'constants/font-sizes';
import spaces from 'constants/spacing';
import {
  WHITE,
  PRIMARY100,
  BACKGROUND_SECONDARY,
  BLACK,
  grey,
  blue,
  red,
  green,
  yellow,
} from './colors';

const bodyFont = '"Inter", sans-serif';

const colors = {
  lightBackground: WHITE,
  dark: BLACK,
  shadowA: grey[50],
  backgroundSecondary: BACKGROUND_SECONDARY,
  brightYellow: yellow[300],
  primary100: PRIMARY100,
  primary200: blue[400],
  brightGreen: green[500],
  brightRed: red[500],
  neutral100: grey[100],
  neutral200: grey[200],
  neutral300: grey[300],
  neutral400: grey[400],
  neutral500: grey[500],
  neutral600: grey[600],
  neutral700: grey[700],
  neutral800: grey[800],
  neutral900: grey[900],
  brightOrange: yellow[500],
};

export type BaseThemeType = {
  colors: Record<string, string>;
  bodyFont: string;
  spaces: Record<string, string>;
  fontSizes: Record<string, string>;
};

const baseTheme: BaseThemeType = {
  colors,
  bodyFont,
  spaces,
  fontSizes,
};

export default baseTheme;
