// vendor
import styled from '@emotion/styled';
import { BaseThemeType } from 'theme/baseTheme';

export const InvoiceTableHeaderStyled = styled.div<{ theme?: BaseThemeType }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.spaces.space_xl};
  min-height: 48px;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    text-align: center;
  }

  .add-new {
    white-space: nowrap;
  }
`;

export const InvoiceTableLabelInputStyled = styled.div<{ theme?: BaseThemeType }>`
  display: flex;
  gap: ${props => props.theme.spaces.space_m};
`;

export const InvoiceTableSearchInputStyled = styled.div<{ theme?: BaseThemeType }>`
  @supports (not (gap: ${props => props.theme.spaces.space_giga})) {
    margin-left: ${props => props.theme.spaces.space_giga};
  }
  min-width: 320px;
`;
