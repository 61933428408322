import styled from '@emotion/styled';

import { BaseThemeType } from 'theme/baseTheme';

export const MpBuyersDashboardWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  display: flex;
  flex-direction: column;
`;

export const MpBuyerDashboardContentStyled = styled.div<{ theme?: BaseThemeType }>`
  width: 100%;
`;

export const MpBuyerHeaderTitleStyled = styled.span<{ theme?: BaseThemeType }>`
  font-weight: 600;
  font-size: ${props => props.theme.fontSizes.large};
  line-height: ${props => props.theme.spaces.space_xl};
  color: ${props => props.theme.colors.neutral700};
`;
