// vendor
import React, { useState, useEffect } from 'react';

// styles
import { MpSwitchStyled, MpSwitchSpanStyled, MpSwitchInputStyled } from './mp-switch.styled';

type Props = {
  state: boolean;
  onChange: (val?: any) => any;
  name?: string;
  /** to disable the switch */
  isDisabled?: boolean;
};

const MpSwitch = (props: Props) => {
  const [isChecked, setIsChecked] = useState(props.state || false);

  useEffect(() => {
    setIsChecked(props.state);
  }, [props.state]);

  const onChange = event => {
    const value = event.currentTarget.checked;
    setIsChecked(value);
    props.onChange?.(value);
  };

  return (
    <MpSwitchStyled>
      <MpSwitchInputStyled
        type="checkbox"
        onChange={onChange}
        checked={isChecked}
        name={props.name}
        disabled={props.isDisabled}
      />
      <MpSwitchSpanStyled
        checked={isChecked}
        className="slider round"
        disabled={props.isDisabled}
      />
    </MpSwitchStyled>
  );
};

MpSwitch.defaultProps = {
  state: false,
};

export default MpSwitch;
