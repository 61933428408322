// vendor
import React, { useCallback } from 'react';

import { useTranslation } from 'i18n/formatted-message';

// components
import { MpMessage, Label } from 'UI';

// styles
import { TextAreaStyled } from './styled';

// constants
import { ERROR } from 'constants/message-types';

type Props = {
  value?: string;
  /** Handler called when input value is changed */
  onChange?: (v: any) => any;
  /** Error Message for input */
  errorMessage?: React.ReactNode;
  /** Flag for show the mandatory asterisk symbol */
  isRequired?: boolean;
  /** Label for input */
  label?: React.ReactNode;
  /** Property to make label bold */
  labelBold?: boolean;
  /**Name of the input element*/
  name?: string;
  /** Placeholder */
  placeholder?: string;
  /** to diable the input field */
  isDisabled?: boolean;
  onFocus?: () => void;
  onBlur?: (e) => void;
  onKeyDown?: (e: any) => void;
  className?: string;
};

export const TextArea = ({
  label,
  errorMessage,
  name,
  isRequired,
  onChange,
  placeholder,
  value,
  labelBold = false,
  isDisabled = false,
  onFocus = () => undefined,
  onBlur = () => undefined,
  onKeyDown = () => undefined,
  className = '',
}: Props) => {
  const onHandleChange = useCallback(event => {
    onChange?.(event);
  }, []);

  return (
    <>
      {label && (
        <Label htmlFor={name} isRequired={isRequired} labelBold={labelBold}>
          {label}
        </Label>
      )}
      <TextAreaStyled
        disabled={isDisabled}
        name={name}
        onChange={onHandleChange}
        placeholder={useTranslation(placeholder || '')}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        className={className}
        rows={1}
      />
      {errorMessage && (
        <MpMessage type={ERROR} message={errorMessage} isPaddingTopRequired={true} />
      )}
    </>
  );
};
