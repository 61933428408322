// vendor
import styled from '@emotion/styled';

import type from 'constants/tag-constants';
import { blue, green, red, grey, yellow } from 'theme/colors';
import { BaseThemeType } from 'theme/baseTheme';
import spaces from 'constants/spacing';

export const MpTagStyled = styled.span<{ theme?: BaseThemeType; type?: any }>`
  border-radius: ${spaces.space_micro};
  font-size: ${props => props.theme.fontSizes.xxxSmall};
  font-weight: 600;
  line-height: ${spaces.space_22};
  padding: ${spaces.space_5} ${spaces.space_xxxs};
  ${props =>
    props.type === type.SUCCESS &&
    `
      background: ${grey[100]};
      color: ${blue[400]};
    `}
  ${props =>
    props.type === type.WARNING &&
    `
      background: ${green[100]};
      color: #046F6B;
    `};
  ${props =>
    props.type === type.DANGER &&
    `
      background: ${red[100]};
      color: ${red[500]};
    `};

  ${props =>
    props.type === type.PRIMARY &&
    `
      background: ${blue[50]};
      color: ${blue[400]};
    `};

  ${props =>
    props.type === type.DISABLED &&
    `
      background: ${grey[100]};
      color: ${grey[700]};
    `};

  ${props =>
    props.type === type.HIGHLIGHT &&
    `
      border-radius: 20px;
      font-size: 12px;
      background: ${yellow[100]};
      color: ${yellow[500]};
    `}
`;
