// vendor
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import * as reducers from './reducers';

import { rootSaga } from 'store/sagas';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const rootReducer = {
  login: reducers.login,
  user: reducers.user,
  buyer: reducers.buyer,
  transaction: reducers.invoice,
  popup: reducers.popup,
  loading: reducers.loading,
  table: reducers.table,
  settlements: reducers.settlements,
  settings: reducers.settings,
  invoice: reducers.addInvoice,
  order: reducers.order,
  kyc: reducers.kyc,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
  devTools: process.env.REACT_APP_CURRENT_ENV !== 'prod',
});

// then run the saga
sagaMiddleware.run(rootSaga);

type AppDispatch = typeof store.dispatch;

type RootReducer = ReturnType<typeof store.getState>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootReducer> = useSelector;
