import React from 'react';
import { Box, Typography } from '@mui/material';
import { DATE_FORMAT } from 'constants/date';
import { format } from 'date-fns';
import { FormattedMessage } from 'i18n/formatted-message';
import { TransactionInfoIconWrapperStyled } from '../add-invoice/styled';
import DoneIcon from '@mui/icons-material/Done';

type SettlementDateUpdateSuccessProps = {
  fee: string;
  settlementDate: Date;
};

export const SettlementDateUpdateSuccess = ({
  fee,
  settlementDate,
}: SettlementDateUpdateSuccessProps) => {
  const data = [
    {
      label: 'DYNAMIC_SETTLEMENTS_POPUP.UPDATE_SETTLEMENT_DATE.SUCCESS.SETTLEMENT_DATE',
      value: format(new Date(settlementDate), DATE_FORMAT),
    },
    {
      label: 'DYNAMIC_SETTLEMENTS_POPUP.UPDATE_SETTLEMENT_DATE.SUCCESS.SETTLEMENT_FEE',
      value: fee,
    },
  ];

  return (
    <Box>
      <TransactionInfoIconWrapperStyled>
        <DoneIcon style={{ fill: 'url(#with-icon-gradient)' }} />
      </TransactionInfoIconWrapperStyled>
      <Box textAlign="center" mb={3.5}>
        <Typography lineHeight="32px" fontSize="20px" fontWeight="700" mb={2}>
          <FormattedMessage id="DYNAMIC_SETTLEMENTS_POPUP.UPDATE_SETTLEMENT_DATE.SUCCESS.TITLE" />
        </Typography>
        <Typography lineHeight="24px" fontSize="16px" fontWeight="400">
          <FormattedMessage id="DYNAMIC_SETTLEMENTS_POPUP.UPDATE_SETTLEMENT_DATE.SUCCESS.DESCRIPTION" />
        </Typography>
      </Box>
      <Box pb={4}>
        {data.map((item, index) =>
          item ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              key={index}
              mb={3}
            >
              <Typography lineHeight="24px" fontSize="16px" fontWeight="400">
                <FormattedMessage id={item.label} />
              </Typography>
              <Typography lineHeight="24px" fontSize="16px" fontWeight="600">
                {item.value}
              </Typography>
            </Box>
          ) : (
            ''
          )
        )}
      </Box>
    </Box>
  );
};
