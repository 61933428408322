import React from 'react';
import { Box } from '@mui/material';

// components
import { FormattedMessage } from 'UI';
import DoneIcon from '@mui/icons-material/Done';

function UpdateRequestSuccess() {
  return (
    <Box sx={{ padding: '32px 0 52px' }}>
      <Box
        sx={{
          background: '#F5F8FF',
          borderRadius: '50%',
          padding: '20px',
          width: '64px',
          height: '64px',
          margin: 'auto',
          overflow: 'hidden',
        }}
      >
        <DoneIcon sx={{ fill: 'url(#with-icon-gradient)' }} />
      </Box>
      <Box
        sx={{
          marginTop: '24px',
          fontSize: '24px',
          fontWeight: '700',
          lineHeight: '32px',
          textAlign: 'center',
          color: '#000339',
        }}
      >
        <FormattedMessage id="BUYER.UPDATE_CREDIT_LIMIT_MODAL.SUBMIT_TITLE" />
      </Box>
      <Box
        sx={{
          marginTop: '24px',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '20px',
          textAlign: 'center',
          color: '#000339',
        }}
      >
        <>
          <div>
            <FormattedMessage id="BUYER.UPDATE_CREDIT_LIMIT_MODAL.SUBMIT_TITLE_HELPER" />
          </div>
          <div>
            <FormattedMessage id="BUYER.UPDATE_CREDIT_LIMIT_MODAL.SUBMIT_TITLE_HELPER_SECOND_LINE" />
          </div>
        </>
      </Box>
    </Box>
  );
}

export { UpdateRequestSuccess };
