import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

// @ts-ignore
import { ReactComponent as EmptyState } from 'assets/icons/empty_state.svg';
// @ts-ignore
import { ReactComponent as FirstInvoiceEmptyState } from 'assets/icons/add_invoice_empty_state.svg';
import { useAppSelector } from 'store';
import * as USE_CASES from './constants';

// components
import { FormattedMessage } from 'UI';

const NoDataStyled = styled.div`
  text-align: center;
  padding: 10% 5% 12%;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #a7b7ec;
`;

const checkForNoData = (filters: object, search: string) => {
  if (Object.entries(filters).length !== 0) {
    return true;
  }
  if (search) {
    return true;
  }
  return false;
};

export const NoData = ({ useCase }: { useCase: string }) => {
  const { filterBy, search } = useAppSelector(state => state.table);

  const emptyStateMessage = () => {
    if (useCase === USE_CASES.TABLE_USE_CASE_BUYERS) {
      return 'ADD_YOUR_FIRST_BUYER';
    }

    if (useCase === USE_CASES.TABLE_USE_CASE_INVOICES) {
      return 'ADD_YOUR_FIRST_INVOICE';
    }

    if (useCase === USE_CASES.TABLE_USE_CASE_ORDERS) {
      return 'ADD_YOUR_FIRST_ORDER';
    }

    if (useCase === USE_CASES.TABLE_USE_CASE_PENDING_ORDERS) {
      return 'NO_PENDING_ORDERS';
    }

    if (useCase === USE_CASES.TABLE_USE_CASE_SETTLEMENTS) {
      return 'NO_SETTLEMENTS_FOUND';
    }

    return '';
  };

  return (
    <NoDataStyled>
      {checkForNoData(filterBy, search) ? (
        <>
          <EmptyState />
          <Box mt={5}>
            <FormattedMessage
              id={
                useCase === USE_CASES.TABLE_USE_CASE_SETTLEMENTS
                  ? 'NO_SETTLEMENTS_FOUND_FOR_SELECTED_CRITERIA'
                  : 'NO_DATA_TRY_SEARCH'
              }
            />
          </Box>
        </>
      ) : (
        <>
          <FirstInvoiceEmptyState />
          <Box mt={5}>
            <FormattedMessage id={emptyStateMessage()} />
          </Box>
        </>
      )}
    </NoDataStyled>
  );
};
