// vendor
import React from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { useAppSelector } from 'store';

// constants
import { EMAIL_TOKEN_VERIFY, PASSWORD_RESET, LOGIN, SIGNUP } from 'constants/routes.constants';

// styles
import { HomeWrapperStyled } from './home.styled';
import { EmailVerify, Login, SignUp, PasswordReset } from 'UI';
import { Dashboard } from 'pages/dashborad';
import { SELLER_ID } from 'constants/storage.constants';

const ProtectedComponent = () => {
  const { isLoggedIn } = useAppSelector(state => state.login);
  const { pathname } = useLocation();
  const isPasswordReset = pathname.includes(PASSWORD_RESET);
  const isEmailVerify = pathname.includes(EMAIL_TOKEN_VERIFY);
  const isLoginPage = pathname.includes(LOGIN);
  const sellerId = localStorage.getItem(SELLER_ID);

  if ((!isLoggedIn || !sellerId) && !isLoginPage && !isPasswordReset && !isEmailVerify) {
    return <Redirect to="/login" replace />;
  }

  return <Dashboard />;
};

export const AuthRoutes = () => {
  return (
    <HomeWrapperStyled>
      <Switch>
        {/* save backward compatibility */}
        <Redirect from="/overview" to="/" />
        <Route exact path={LOGIN} component={Login} />
        <Route exact path={SIGNUP} component={SignUp} />
        <Route exact path={EMAIL_TOKEN_VERIFY} component={EmailVerify} />
        <Route exact path={PASSWORD_RESET} component={PasswordReset} />
        {/* default */}
        <Route path="/" component={ProtectedComponent} />
      </Switch>
    </HomeWrapperStyled>
  );
};
