import React from 'react';
// icons
import { EuroSymbol, AttachMoney, CurrencyPound, AccountBalance } from '@mui/icons-material';

export type CurrencySymbol = '€' | '£' | '$';
export type CurrencyCode = 'EUR' | 'GBP' | 'USD';

export type CurrencyItem = {
  value: CurrencySymbol;
  label: CurrencyCode;
  name: string;
};

export const getCurrencyIcon = code => {
  switch (code) {
    case 'EUR':
      return <EuroSymbol sx={{ fill: 'url(#with-icon-gradient)' }} />;
    case 'USD':
      return <AttachMoney sx={{ fill: 'url(#with-icon-gradient)' }} />;
    case 'GBP':
      return <CurrencyPound sx={{ fill: 'url(#with-icon-gradient)' }} />;
    default:
      return <AccountBalance sx={{ fill: 'url(#with-icon-gradient)' }} />;
  }
};

export const FALLBACK_CURRENCY_ITEM = { value: '€', label: 'EUR', name: 'EURO' };
