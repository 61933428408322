// vendor
import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';

// constants
import { UI_LANGUAGES } from 'constants/lang';
import { UPDATE_COUNTRY_LABEL_BASED_ON_LANG } from 'store/types';

// components
import { MpDropdownMenu } from 'UI';
import { CountryFlag } from 'UI/components/country-flag';

// utils
import { FormattedMessage } from 'i18n/formatted-message';

// helpers
import { useAppDispatch } from 'store';

export const UiLangSwitcher = () => {
  const uiLang = localStorage.getItem('uiLang') || 'en';
  const [lang, setLang] = useState(uiLang);
  const dispatch = useAppDispatch();

  return (
    <MpDropdownMenu
      withDropdownIcon
      onMenuItemClick={(_, lang = 'en') => {
        // @ts-ignore
        window.changeLanguage(lang);
        dispatch({ type: UPDATE_COUNTRY_LABEL_BASED_ON_LANG });
        setLang(lang);
      }}
      dataSdet="lang-dropdown"
      menuList={UI_LANGUAGES.map(language => ({
        label: (
          <Stack spacing={1} direction="row" alignItems="center">
            <Box ml="10px">
              <CountryFlag language={language} />
            </Box>
            <FormattedMessage id={`lang.${language}`} />
          </Stack>
        ),
        action: language,
      }))}
      label={<CountryFlag language={lang} />}
    />
  );
};
