import styled from '@emotion/styled';
import { hexToRGBA } from 'utils';
import { BaseThemeType } from 'theme/baseTheme';
import spaces from 'constants/spacing';
import { grey, WHITE } from 'theme/colors';
import { Typography, Paper, MenuItem } from '@mui/material';

export const AddBuyerPopupButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: ${spaces.space_xxxl};
  margin-bottom: ${spaces.space_xl};
`;

export const AddBuyerFieldWrapperStyled = styled.div`
  margin-top: ${spaces.space_m};
`;

export const CompanyDetailsStyled = styled.div<{ theme?: BaseThemeType }>`
  padding: ${spaces.space_xs};
  min-height: 108px;
  background: ${props => props.theme.colors.lightBackground};
  border: ${spaces.space_nano} solid ${props => props.theme.colors.primary200};
  box-sizing: border-box;
  box-shadow: 0 ${spaces.space_mini} ${spaces.space_xxxs}
    ${props => hexToRGBA(props.theme.colors.neutral300, 0.5)} ${spaces.space_xxxs};
  border-radius: ${spaces.space_mini};
  margin: ${spaces.space_m} 0;
  position: relative;
`;

export const CompanyDetailsTitleStyled = styled.div<{ theme?: BaseThemeType }>`
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.xSmall};
  line-height: ${spaces.space_m};
  color: ${props => props.theme.colors.primary200};
`;

export const CompanyDetailsCloseIconStyled = styled.div`
  position: absolute;
  top: ${spaces.space_xs};
  right: ${spaces.space_xs};
  cursor: pointer;
`;

export const CompanyAddressDetailsStyled = styled.div<{ theme?: BaseThemeType }>`
  margin-top: ${spaces.space_xxxs};
`;

export const CompanyAddressDetailsInfoStyled = styled.div<{
  theme?: BaseThemeType;
}>`
  font-size: ${props => props.theme.fontSizes.xxSmall};
  line-height: ${spaces.space_22};
  color: ${props => props.theme.colors.neutral600};
`;

export const CustomAddBuyerLabelStyled = styled.div<{ theme?: BaseThemeType }>`
  font-size: ${props => props.theme.fontSizes.xSmall};
  line-height: ${spaces.space_m};
  color: ${grey[900]};
`;

export const AddBuyerModalHelperText = styled(Typography)<{ theme?: BaseThemeType }>`
  font-size: ${props => props.theme.fontSizes.xSmall};
  line-height: ${spaces.space_m};
  color: ${grey[900]};
  font-weight: 400;
  margin-top: ${spaces.space_xs};
`;

export const AddBuyerOptionStyled = styled(Paper)<{ theme?: BaseThemeType }>`
  padding: ${spaces.space_xxxs};
  isolation: isolate;
  background: ${WHITE};
  box-shadow: 0 ${spaces.space_mini} ${spaces.space_xxxs} rgba(229, 234, 237, 0.5);
  border-radius: ${spaces.space_mini};
  width: 100%;
  margin-top: ${spaces.space_xxxs};
`;

export const AddBuyerMenuText = styled(Typography)<{
  theme?: BaseThemeType;
  isBold?: boolean;
  isMarginPresent?: boolean;
}>`
  font-weight: ${props => (props.isBold ? 600 : 400)};
  marginbottom: ${props => (props.isMarginPresent ? spaces.space_xxxs : 0)};
  font-size: 14px;
  line-height: ${spaces.space_22};
  color: ${grey[900]};
`;

export const MenuItemStyled = styled(MenuItem)<{
  theme?: BaseThemeType;
  showCursor?: boolean;
  isLast?: boolean;
}>`
  align-items: start;
  padding: ${spaces.space_xxxs};
  border-radius: ${spaces.space_mini};
  cursor: ${props => (props.showCursor ? 'pointer' : 'unset')};
  border-bottom: ${props => (props.isLast ? '1px solid #DCE4FF' : 'none')};
`;
