import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'store';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import styled from '@emotion/styled';
import { Download } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import {
  BuyerDetailsCreditAssessmentLabelStyled,
  BuyerDetailsCreditAssessmentSectionStyled,
  BuyerACLTableDataRowStyled,
  IdSectionStyled,
} from './styled';

import { getData } from 'api';
import { formatter, MAP_TYPE_TO_COLOR } from 'utils';
import { FormattedMessage, MpTag, SectionLoader } from 'UI';
import { ORDERS } from 'constants/api-endpoints.constants';
import { CurrencySymbol } from 'constants/currency';
import { grey } from 'theme/colors';
import { adaptOrdersAndInvoicesToACL } from './helpers';
import { getCurrencySymbol } from 'utils/currencies';

export type AclListItem = {
  itemType: 'invoice' | 'order' | 'creditNote';
  id: string;
  status: string;
  fxRate: string;
  fxCurrencySymbol: CurrencySymbol;
  fxAmount: string;
  amount: string;
  downloadLink?: string;
  detailsPageLink?: string;
};

const BuyerDataRowStyled = styled.div`
  width: 45%;
  padding: 10px 0;
`;

const fetchBuyerOrders = (buyerId, onSuccess, onError, sellerId) => {
  getData(ORDERS(sellerId) + `?buyer_id=${buyerId}&status=AUTHORIZED&page_size=50`)
    .then(({ data: { data: orders } }) => {
      const updatedOrders = orders.map(order => ({
        ...order,
        currency_symbol: getCurrencySymbol(order.currency_code),
        invoices: order.invoices.map(invoice => ({
          ...invoice,
          currency_symbol: getCurrencySymbol(invoice.currency_code),
        })),
      }));

      onSuccess(updatedOrders);
    })
    .catch(({ message }) => {
      onError(`fetchBuyerOrders failed: ${message}`);
    });
};

export const AvailableCredit = ({ buyerId }: { buyerId: string }) => {
  const [aclItems, setAclItems] = useState<Array<AclListItem>>([]);
  const [ordersAmount, setOrdersAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    credit_qualification: {
      credit_limit,
      available_credit_limit,
      currency: { symbol },
    },
  } = useAppSelector(state => state.buyer.buyerDetails);
  const { sellerId } = useAppSelector(state => state.login);

  const isFetched = useRef(false);

  useEffect(() => {
    if (!isFetched.current) {
      isFetched.current = true;
      fetchBuyerOrders(
        buyerId,
        data => {
          setAclItems(adaptOrdersAndInvoicesToACL(data));
          setOrdersAmount(data.length);
          setIsLoading(false);
        },
        // eslint-disable-next-line no-console
        setError,
        sellerId
      );
    }
  }, [buyerId]);

  return (
    <BuyerDetailsCreditAssessmentSectionStyled bottomPadding>
      <BuyerDetailsCreditAssessmentLabelStyled>
        <FormattedMessage id="BUYER.AVAILABLE_CREDIT_LIMIT" />
      </BuyerDetailsCreditAssessmentLabelStyled>
      {/*loader*/}

      <TableContainer sx={{ width: ['100%', '100%', '100%', '90%'] }}>
        <Table sx={{ minWidth: 'fit-content' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderBottom: '0', padding: '12px 0 0' }}>
                <BuyerACLTableDataRowStyled>
                  <FormattedMessage id="BUYER.CREDIT_LIMIT" />
                </BuyerACLTableDataRowStyled>
              </TableCell>
              <TableCell sx={{ borderBottom: '0', padding: '12px 0 0' }}>
                <span>{(symbol || '') + formatter(Number(credit_limit))}</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={2}>
                  <SectionLoader />
                </TableCell>
              </TableRow>
            )}
            {error ? (
              <TableRow>
                <TableCell colSpan={2}>{error}</TableCell>
              </TableRow>
            ) : (
              aclItems.map(
                (
                  {
                    itemType,
                    id,
                    status,
                    amount,
                    downloadLink,
                    fxRate,
                    fxCurrencySymbol,
                    fxAmount,
                    detailsPageLink,
                  },
                  index
                ) => {
                  const itemTypeTranslation =
                    itemType === 'order'
                      ? 'ORDER'
                      : itemType === 'invoice'
                      ? 'INVOICE'
                      : 'timeline.credit note';

                  const showFxRate = Number(fxRate) !== 1;
                  const isNotLastRow = index !== aclItems.length - 1;

                  return (
                    <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell
                        sx={{
                          borderBottom: isNotLastRow
                            ? '0'
                            : '1px solid rgba(224, 224, 224, 1) !important',
                          padding: '12px 0',
                        }}
                        scope="row"
                      >
                        <BuyerACLTableDataRowStyled>
                          <Box display="flex" alignItems="center">
                            <IdSectionStyled>
                              {detailsPageLink ? (
                                <Link to={detailsPageLink}>
                                  <FormattedMessage id={itemTypeTranslation} /> {id}
                                </Link>
                              ) : (
                                <>
                                  <FormattedMessage id={itemTypeTranslation} /> {id}
                                </>
                              )}
                            </IdSectionStyled>
                            {isMobile && (
                              <div style={{ minWidth: '85px', display: 'inline-flex' }}>
                                <MpTag
                                  type={MAP_TYPE_TO_COLOR[status]}
                                  label={<FormattedMessage id={`status.${status}`} />}
                                />
                              </div>
                            )}
                          </Box>
                          {!isMobile && (
                            <div style={{ minWidth: '85px', display: 'inline-flex' }}>
                              <MpTag
                                type={MAP_TYPE_TO_COLOR[status]}
                                label={<FormattedMessage id={`status.${status}`} />}
                              />
                            </div>
                          )}
                          {showFxRate && (
                            <Typography component="span" sx={{ marginLeft: [0, '20px'] }}>
                              <FormattedMessage id="BUYER.FOREX_RATE" />: {fxRate}
                              <span style={{ marginLeft: '10px' }}>{fxCurrencySymbol}</span>
                              {formatter(Number(fxAmount))}
                            </Typography>
                          )}
                        </BuyerACLTableDataRowStyled>
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: isNotLastRow
                            ? '0'
                            : '1px solid rgba(224, 224, 224, 1) !important',
                          padding: '12px 0',
                        }}
                      >
                        <Box sx={{ minWidth: [0, 0, 0, '84px'], display: 'inline-flex' }}>
                          {itemType === 'creditNote' ? '+' : '-'}
                          {(symbol || '') + formatter(Number(amount))}
                        </Box>
                        {downloadLink && (
                          <Download
                            sx={{ cursor: 'pointer', color: grey[500], margin: ['0 0 -8px 10px'] }}
                            onClick={() => window.open(downloadLink, 'blank')}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              )
            )}

            {/*TODO: backend can't expose more than 50 items as page_size  */}
            {ordersAmount === 50 && (
              <TableRow>
                <TableCell sx={{ borderBottom: '0', padding: '12px 0' }}>
                  <BuyerDataRowStyled>...</BuyerDataRowStyled>
                </TableCell>
                <TableCell sx={{ borderBottom: '0', padding: '12px 0' }}>
                  <BuyerDataRowStyled>...</BuyerDataRowStyled>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell sx={{ borderBottom: '0', padding: '12px 0' }}>
                <BuyerACLTableDataRowStyled>
                  <strong>
                    <FormattedMessage id="BUYER.AVAILABLE_CREDIT_LIMIT" />
                  </strong>
                </BuyerACLTableDataRowStyled>
              </TableCell>
              <TableCell sx={{ borderBottom: '0', padding: '12px 0' }}>
                <BuyerACLTableDataRowStyled>
                  <strong>{(symbol || '') + formatter(Number(available_credit_limit))}</strong>
                </BuyerACLTableDataRowStyled>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </BuyerDetailsCreditAssessmentSectionStyled>
  );
};
