// vendor
import React from 'react';
import DOMPurify from 'dompurify';
import i18n from 'i18next';
import { useTranslation as i18useTranslation } from 'react-i18next';

const changeLanguage = value => {
  if (!value) return;
  localStorage.setItem('uiLang', value);
  i18n.changeLanguage(value);
};
// @ts-ignore
window.changeLanguage = changeLanguage;

interface FormattedMessageProps {
  id: string;
  context?: Record<string, any>;
}

export const FormattedMessage = ({ id, context }: FormattedMessageProps) => {
  const { t, ready } = i18useTranslation('translations');

  if (!ready) return <>loading translations...</>;

  const mySafeHTML = DOMPurify.sanitize(t(id, { ...context }));
  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: mySafeHTML.replace(/href/g, "target='_blank' href") }}
    />
  );
};

export const useTranslation = (id: string, sanitizeOptions?: any): string => {
  const { t, ready } = i18useTranslation('translations');

  if (!ready) return 'loading translations...';

  if (sanitizeOptions?.returnObjects) {
    return t(id, { ...sanitizeOptions });
  }
  return DOMPurify.sanitize(t(id, { ...sanitizeOptions }));
};
