// vendor
import styled from '@emotion/styled';

// utils
import { hexToRGBA } from 'utils';
import { BaseThemeType } from 'theme/baseTheme';
import { grey, WHITE } from 'theme/colors';
import spaces from 'constants/spacing';

export const MpLoginWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  width: 480px;
  margin: auto;
  background: ${WHITE};
  box-shadow: 0 ${spaces.space_mini} ${spaces.space_xxxs} ${hexToRGBA(grey[300], 0.5)};
  border: ${spaces.space_nano} solid ${grey[200]};
  border-radius: ${spaces.space_xxxs};
  box-sizing: border-box;
  padding: ${spaces.space_giga} ${spaces.space_xl} ${spaces.space_xl} ${spaces.space_xl};
  position: relative;
  z-index: 10;
  max-height: 90%;
  overflow: scroll;
  @media only screen and (max-width: 900px) {
    width: 94%;
    padding: 3%;
  }
`;

export const MpLoginInputWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  padding-bottom: ${spaces.space_m};
`;

export const MpLoginSecondaryButtonStyled = styled.div<{ theme?: BaseThemeType }>`
  margin-top: ${spaces.space_xxs};
`;

export const AccountItemStyled = styled.div<{ isSelected: boolean }>`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid ${grey[300]};

  &:hover {
    cursor: ${props => (props.isSelected ? 'not-allowed' : 'pointer')};
    background-color: ${grey[300]};
    box-shadow: 0 4px 8px rgba(229, 234, 237, 0.5);
  }
`;
