import React from 'react';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { debounce } from 'lodash';
import { useAppSelector, useAppDispatch } from 'store';
import { Add, ArrowUpward } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import { TABLE_SEARCH_DEBOUNCE } from 'UI/components/table/constants';

// components
import { MpInput, FormattedMessage, MpButton } from 'UI';
import { SubMenu } from 'UI/components/mp-sidebar-menu/sub-menu';

// constants
import { BUYER_DETAILS_MENU } from 'constants/buyer.constants';

// styles
import {
  InvoiceTableHeaderStyled,
  InvoiceTableLabelInputStyled,
  InvoiceTableSearchInputStyled,
} from './styled';
import { changeTableSetting } from 'store/actions/tables';

import { BuyerDetailsHeaderNameSection } from '../buyer-detail-dashboard/header';

import { BuyerStatus } from 'store/types/buyers';
import { OPEN_ADD_INVOICE_POPUP } from 'store/types';
import { AddInvoice } from '../add-invoice';

interface HeaderProps {
  buyerId: string;
  status: string;
  name: string;
}

export const Header = ({ buyerId, status, name }: HeaderProps) => {
  const dispatch = useAppDispatch();
  const {
    order: { buyerId: ordersBuyerID = '' },
    user: {
      userProfile: { status: sellerStatus },
    },
    user: { isStaff },
    settings: {
      sellerConfig: { is_pending_order_enabled },
    },
  } = useAppSelector(state => state);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const requestSearch = debounce(e => {
    dispatch(changeTableSetting('search', e.target.value.toLowerCase()));
  }, TABLE_SEARCH_DEBOUNCE);

  return (
    <>
      <InvoiceTableHeaderStyled>
        {buyerId ? (
          <BuyerDetailsHeaderNameSection name={name || ''} />
        ) : (
          <Typography
            variant="h1"
            component="h1"
            sx={{ fontWeight: '700', fontSize: '24px', lineHeight: '32px' }}
          >
            <FormattedMessage id="MENU.TRANSACTIONS_V2" />
          </Typography>
        )}
        <Box
          display="flex"
          gap="24px"
          marginTop={isMobile ? '10px' : 0}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <InvoiceTableLabelInputStyled>
            <InvoiceTableSearchInputStyled>
              <MpInput
                onChange={requestSearch}
                leftIcon="search"
                placeholder={
                  buyerId
                    ? 'SEARCH_INVOICE_PLACEHOLDER_ON_BUYER_PAGE'
                    : 'SEARCH_INVOICE_PLACEHOLDER_LABEL_V2'
                }
              />
            </InvoiceTableSearchInputStyled>
          </InvoiceTableLabelInputStyled>

          {process.env.REACT_APP_CURRENT_ENV !== 'prod' &&
          process.env.REACT_APP_CURRENT_ENV !== 'staging' &&
          isStaff ? (
            <Box
              display="flex"
              alignItems="center"
              sx={{ background: '#ECEDF8', borderRadius: '4px' }}
              p={1}
            >
              <Link
                to={{
                  pathname: '/mark-as-paid',
                  search: '?page=1&rowsPerPage=25',
                  hash: '#',
                }}
              >
                <ArrowUpward sx={{ fontSize: '20px' }} />
              </Link>
            </Box>
          ) : (
            <></>
          )}
          <MpButton
            disabled={!!((buyerId && status !== BuyerStatus.ACTIVE) || sellerStatus !== 'ACTIVE')}
            onClick={() => {
              dispatch({ type: OPEN_ADD_INVOICE_POPUP });
            }}
            variant="contained"
            className="add-new"
          >
            <Add style={{ marginRight: '10px' }} />
            <FormattedMessage id="ADD_INVOICE_BUTTON_LABEL" />
          </MpButton>
        </Box>

        <AddInvoice buyerId={buyerId || ordersBuyerID} />
      </InvoiceTableHeaderStyled>
      {buyerId ? <SubMenu menu={BUYER_DETAILS_MENU(is_pending_order_enabled, buyerId)} /> : null}
    </>
  );
};
