import React from 'react';
import { Tooltip } from '@mui/material';

// icons
import { EditOutlined } from '@mui/icons-material';

// store
import { store } from 'store';

// constants
import { PaymentTerms } from 'constants/enum.constants';
import { INVOICE_TABLE_HEADER_KEYS } from 'constants/invoice.constants';
import TYPE from 'constants/tag-constants';
import fontSizes from 'constants/font-sizes';
import { WHITE } from 'theme/colors';
import spaces from 'constants/spacing';
import { OPEN_SETTLEMENT_DATE_POPUP, SET_DATA_FOR_EDIT_SETTLEMENT_DATE } from 'store/types';
import PaymentStatus from 'constants/payment-status.constants';

// components
import { ElipsesTextWithTooltip, MpTag } from 'UI';
import { FormattedMessage } from 'i18n/formatted-message';

// utils
import { isInvoiceOpenOrOverdue, renderTag, formatter, formatTimezone } from 'utils';

export const PiaLabel = () => {
  return (
    <Tooltip
      title={<FormattedMessage id="TRANSACTION_PIA_TOOLTIP" />}
      placement="bottom"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            color: WHITE,
            backgroundColor: '#5164A3',
            fontWeight: 600,
            fontSize: fontSizes.xxxSmall,
            lineHeight: spaces.space_s,
            padding: spaces.space_xs,
            maxWidth: '200px',
          },
        },
      }}
    >
      <span style={{ marginLeft: '10px' }}>
        <MpTag type={TYPE.HIGHLIGHT} label="PIA" />
      </span>
    </Tooltip>
  );
};

export const createInvoiceTable = list => {
  const extendedListWithInvoices = list;

  return extendedListWithInvoices.map(row => {
    const {
      buyer_name,
      currency_symbol: currencySymbol,
      due_date,
      settlement_date,
      invoice_status,
      merchant_invoice_id,
      merchant_order_id,
      net_amount,
      transaction_status,
      _uuid,
      payment_terms,
      date: merchantInvoiceDate,
    } = row || {};

    const isOpenOrOverdue = isInvoiceOpenOrOverdue(invoice_status);

    return {
      routingId: row.routingId || _uuid,
      id: _uuid,
      merchant_order_id: (
        <ElipsesTextWithTooltip
          maxWidth="150px"
          title={<FormattedMessage id="SPRINQUE_ORDER_ID" context={{ id: merchant_order_id }} />}
        >
          {merchant_order_id}
        </ElipsesTextWithTooltip>
      ),
      merchant_invoice_id: (
        <ElipsesTextWithTooltip
          maxWidth="150px"
          title={<FormattedMessage id="SPRINQUE_ID" context={{ id: merchant_invoice_id }} />}
        >
          <span data-sdet={merchant_invoice_id} data-sdet-sprinque-id={_uuid}>
            {merchant_invoice_id}
          </span>
        </ElipsesTextWithTooltip>
      ),
      buyerStatus: renderTag(invoice_status),
      merchantStatus: renderTag(transaction_status),
      [INVOICE_TABLE_HEADER_KEYS.Buyer]: (
        <ElipsesTextWithTooltip title={buyer_name} maxWidth="90px">
          {buyer_name}
        </ElipsesTextWithTooltip>
      ),
      [INVOICE_TABLE_HEADER_KEYS.Value]: (
        <span
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {currencySymbol + formatter(+net_amount)}
        </span>
      ),
      [INVOICE_TABLE_HEADER_KEYS.SettlementDate]: (
        <span>
          {settlement_date ? (
            <>
              {formatTimezone(settlement_date)}
              {transaction_status !== 'SETTLED' &&
              transaction_status !== 'REVERSED' &&
              invoice_status === PaymentStatus.OPEN ? (
                <EditOutlined
                  sx={{ fontSize: '12px', marginLeft: '15px' }}
                  onClick={e => {
                    e.stopPropagation();
                    store.dispatch({ type: OPEN_SETTLEMENT_DATE_POPUP });
                    store.dispatch({
                      type: SET_DATA_FOR_EDIT_SETTLEMENT_DATE,
                      payload: {
                        merchantInvoiceDate,
                        netTerms: payment_terms,
                        settlementDate: new Date(settlement_date).setUTCHours(0, 0, 0, 0),
                        invoiceId: _uuid,
                      },
                    });
                  }}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <span style={{ minWidth: '70px', display: 'inline-block' }}>-</span>
          )}
        </span>
      ),
      [INVOICE_TABLE_HEADER_KEYS.DueDate]: (
        <>
          <span>
            {due_date && isOpenOrOverdue ? (
              formatTimezone(due_date)
            ) : (
              <span style={{ minWidth: '70px', display: 'inline-block' }}>-</span>
            )}
          </span>
          {payment_terms === PaymentTerms.PAY_IN_ADVANCE && <PiaLabel />}
        </>
      ),
    };
  });
};
