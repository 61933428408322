import {
  SET_ORDER_ID_FOR_VOID,
  SET_BUYER_ID_FOR_ORDER,
  CLEANUP_ORDER,
  UPDATE_ORDER_STEP_DATA,
} from '../types';

export const setOrderIdForVoid = payload => ({ type: SET_ORDER_ID_FOR_VOID, payload });

export const setOrderDetails = (buyerId, orderCurrency, orderId, authTransactions) => ({
  type: SET_BUYER_ID_FOR_ORDER,
  payload: { buyerId, orderCurrency, orderId, authTransactions },
});

export const cleanupOrder = () => ({ type: CLEANUP_ORDER });

export const updateOrderStepData = data => ({
  type: UPDATE_ORDER_STEP_DATA,
  payload: { data },
});
