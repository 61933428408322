import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector, useAppDispatch } from 'store';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

// components
import { MpModal, CreateForm, FormattedMessage, SectionLoader } from 'UI';
import { UpdateRequestSuccess } from './update-request-success';
import { PendingRequest } from './pending-request';

// form
import {
  updatePaymentTernsFormFields,
  initialValuesUpdatePaymentTernsFormFields,
  valuesToValidateOnUpdatePaymentTernsFormFields,
} from 'constants/form-data';

// constants
import { grey } from '@mui/material/colors';
import { snackbarConfig } from 'utils';
import {
  GET_PENDING_PAYMENT_TERM_REQUEST,
  UPDATE_PAYMENT_TERMS,
} from 'constants/api-endpoints.constants';

// utils
import { getData, postData } from 'api';

// actions
import { startLoading, stopLoading } from 'store/actions';

interface UpdatePaymentTermsProps {
  openModal: boolean;
  closeModal: () => void;
}

const UpdatePaymentTerms = ({ openModal, closeModal }: UpdatePaymentTermsProps) => {
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const {
    credit_qualification: { eligible_payment_terms },
    _uuid,
  } = useAppSelector(state => state.buyer.buyerDetails);
  const { sellerId } = useAppSelector(state => state.login);

  const [pendingReview, setPendingReview] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const getPreviousPaymentTermRequest = () => {
    setLoader(true);
    getData(GET_PENDING_PAYMENT_TERM_REQUEST(_uuid || '', sellerId))
      .then(({ data }) => {
        if (data.has_pending_payment_terms_update) {
          setPendingReview(true);
        }
      })
      .catch(e => {
        enqueueSnackbar(e.message, snackbarConfig({ type: 'error' }));
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (openModal) {
      getPreviousPaymentTermRequest();
      setDetailsSubmitted(false);
    }
  }, [openModal]);

  const onSubmit = values => {
    dispatch(startLoading);
    postData(UPDATE_PAYMENT_TERMS(_uuid || '', sellerId), {
      new_eligible_payment_terms: values.netTerms.map?.(term => term.value),
      merchant_reason: values.reason,
    })
      .then(() => {
        setDetailsSubmitted(true);
      })
      .catch(e => {
        setError(e.message);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };

  return (
    <MpModal
      title={pendingReview || detailsSubmitted ? '' : 'BUYER.UPDATE_PAYMENT_TERMS_MODAL.TITLE'}
      titleIcon={
        pendingReview || detailsSubmitted ? (
          ''
        ) : (
          <CalendarTodayOutlined
            sx={{
              fill: 'url(#with-icon-gradient)',
              transform: 'translate(-5px, 1px)',
              fontSize: '1.2rem',
            }}
          />
        )
      }
      modalOpen={openModal}
      handleClose={() => {
        setError('');
        closeModal();
      }}
      maxWidth="sm"
    >
      {loader ? (
        <SectionLoader />
      ) : pendingReview ? (
        <PendingRequest />
      ) : detailsSubmitted ? (
        <UpdateRequestSuccess />
      ) : (
        (error && (
          <Typography sx={{ mt: 2, mb: 4, fontSize: '1rem', color: grey[900] }}>{error}</Typography>
        )) || (
          <>
            <Typography sx={{ mt: 2, fontSize: '1rem', color: grey[900] }}>
              <FormattedMessage id="BUYER.UPDATE_PAYMENT_TERMS_MODAL.SUB_TITLE" />
            </Typography>

            {eligible_payment_terms.length ? (
              <Box>
                <Typography sx={{ mt: 3, fontSize: '1rem', color: grey[400] }}>
                  <FormattedMessage id="BUYER.UPDATE_PAYMENT_TERMS_MODAL.PAYMENT_TERM_LABEL" />
                </Typography>
                <Typography sx={{ mt: '4px', fontSize: '1rem', color: grey[900] }}>
                  {eligible_payment_terms.join(', ')}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
            <CreateForm
              primaryButtonConfig={{
                label: <FormattedMessage id="SUBMIT" />,
                handleSubmit: onSubmit,
                alignToLeft: true,
              }}
              formFields={updatePaymentTernsFormFields}
              valuestoValidate={valuesToValidateOnUpdatePaymentTernsFormFields}
              initialValue={initialValuesUpdatePaymentTernsFormFields({
                netTerms: eligible_payment_terms,
              })}
            />
          </>
        )
      )}
    </MpModal>
  );
};

export { UpdatePaymentTerms };
