// vendor
import styled from '@emotion/styled';

// constants
import { BaseThemeType } from 'theme/baseTheme';
import spaces from 'constants/spacing';
import { grey, WHITE } from 'theme/colors';
import fontSizes from 'constants/font-sizes';

export const SettingsPaymentCollectionWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  min-width: 700px;
  background: ${WHITE};
  border: ${spaces.space_nano} solid ${grey[300]};
  box-sizing: border-box;
  border-radius: ${spaces.space_xxxs};
  padding: 32px;
  margin-bottom: 16px;
  @media only screen and (max-width: 900px) {
    min-width: initial;
    width: 100%;
    padding: 3%;
  }
`;

export const SettingBlockTitleStyled = styled.h3<{ theme?: BaseThemeType }>`
  font-family: ${props => props.theme.bodyFont};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: ${spaces.space_xl};
  margin: 0;
  display: flex;
  align-items: center;
  gap: ${spaces.space_s};
`;

export const SettingSubtitleTitleStyled = styled.h4<{ theme?: BaseThemeType }>`
  font-family: ${props => props.theme.bodyFont};
  font-size: ${fontSizes.xSmall};
  font-weight: 400;
  line-height: ${spaces.space_m};
  margin: ${spaces.space_22} 0 0;
  color: ${grey[900]};
`;
