import React from 'react';
import { FormattedMessage } from 'i18n/formatted-message';
import {
  USER_PROFILE_ROUTE,
  COMPANY_ROUTE,
  TEAM_ROUTE,
  API_KEY_ROUTE,
  NOTIFICATION_ROUTE,
  PAYMENT_COLLECTION_ROUTE,
  FEES_ROUTE,
  BILLING_ROUTE,
  KYC_PROCESS,
} from 'constants/routes.constants';

export const SettingsSidebarMenu = isSellerInActive => [
  {
    label: <FormattedMessage id="MENU.SETTING.USER_PROFILE" />,
    path: USER_PROFILE_ROUTE,
    dataSdet: 'User profile',
  },
  {
    label: <FormattedMessage id="MENU.SETTING.COMPANY" />,
    path: COMPANY_ROUTE,
    dataSdet: 'Company',
  },
  {
    label: <FormattedMessage id="MENU.SETTING.TEAM" />,
    path: TEAM_ROUTE,
    dataSdet: 'Team',
  },
  {
    label: <FormattedMessage id="MENU.SETTING.API_KEYS" />,
    path: API_KEY_ROUTE,
    dataSdet: 'API keys and urls',
  },
  isSellerInActive && {
    label: <FormattedMessage id="MENU.SETTING.KYC" />,
    path: KYC_PROCESS,
    dataSdet: 'Kyc process',
  },
  {
    label: <FormattedMessage id="MENU.SETTING.NOTIFICATIONS" />,
    path: NOTIFICATION_ROUTE,
    dataSdet: 'Notifications',
  },
  {
    label: <FormattedMessage id="MENU.SETTING.PAYMENT_COLLECTION" />,
    path: PAYMENT_COLLECTION_ROUTE,
    dataSdet: 'payment_collection',
  },
  {
    label: <FormattedMessage id="MENU.SETTING.FEES" />,
    path: FEES_ROUTE,
    dataSdet: 'fees',
  },
  {
    label: <FormattedMessage id="MENU.SETTING.BILLING" />,
    path: BILLING_ROUTE,
    dataSdet: 'billing',
  },
];

export const IMAGE_UPLOAD_LIMIT = 2500000;

export const IMAGE_PICKER_ACCEPTED_FORMAT = 'image/png, image/x-png, image/jpeg, image/jpg';
