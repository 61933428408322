// vendor
import styled from '@emotion/styled';
import { BaseThemeType } from 'theme/baseTheme';

export const CreateFormStyled = styled.div<{ theme?: BaseThemeType }>`
  margin-bottom: ${props => props.theme.spaces.space_xxxl};
`;

export const CreateFormInputStyled = styled.div<{
  theme?: BaseThemeType;
  width?: string;
  fieldMarginTop?: string;
}>`
  width: ${props => (props.width === '25' ? '25%' : props.width === '50' ? '50%' : '100%')};
  margin-top: ${props => props.fieldMarginTop || props.theme.spaces['space_m']};

  ${props =>
    props.width === '25' &&
    `
      label {
        width: 100%;
      }

      .MuiFormControl-root.MuiTextField-root {
        width: 50%;
      }
    `}
  @media only screen and (max-width: 900px) {
    width: 100%;
    label {
      width: 100%;
    }

    .MuiFormControl-root.MuiTextField-root {
      width: 100%;
    }
  }
`;

export const CreateFormInputWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  display: flex;
  gap: ${props => props.theme.spaces.space_xs};
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }

  @supports (not (gap: ${props => props.theme.spaces.space_xs})) {
    > * {
      margin-right: ${props => props.theme.spaces.space_xs};
    }
  }
`;

export const CreateFormGroupTitleStyled = styled.div<{
  theme?: BaseThemeType;
  marginTop?: string;
  fontWeight?: string;
  fontSize?: string;
  lineHeight?: string;
}>`
  font-weight: ${props => props.fontWeight || 600};
  font-size: ${props => props.fontSize || props.theme.fontSizes.xSmall};
  line-height: ${props => props.lineHeight || props.theme.spaces.space_m};
  color: ${props => props.theme.colors.neutral700};
  margin-top: ${props => props.marginTop || props.theme.spaces.space_xxxl};
`;

export const CreateFormButtonWrapperStyled = styled.div<{
  theme?: BaseThemeType;
  isSecondaryButtonPresent?: any;
  alignPrimaryButtonToLeft?: boolean;
}>`
  margin: ${props => props.theme.spaces.space_xl} 0;
  display: flex;
  flex-direction: ${props => (props.alignPrimaryButtonToLeft ? 'row' : 'row-reverse')};
  ${props =>
    props.isSecondaryButtonPresent
      ? `
          justify-content: space-between;
        `
      : `
          text-align: right;
        `}
`;
