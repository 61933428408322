import React from 'react';
import { Typography, Tooltip } from '@mui/material';

interface ElipsesTextWithTooltipProps {
  title?: NonNullable<React.ReactNode>;
  maxWidth?: string;
  children?: React.ReactNode;
  arrow?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const ElipsesTextWithTooltip = ({
  children,
  title = '',
  maxWidth = '250px',
  arrow = true,
}: ElipsesTextWithTooltipProps) => {
  return (
    <Tooltip title={title} placement="top" arrow={arrow}>
      <Typography
        sx={{
          cursor: 'pointer',
          maxWidth: maxWidth,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};
