// vendor
import styled from '@emotion/styled';

// utils
import { BaseThemeType } from 'theme/baseTheme';
import { grey } from 'theme/colors';
import fontSizes from 'constants/font-sizes';
import spaces from 'constants/spacing';

export const UsefulResourcesCardStyled = styled.div<{ theme?: BaseThemeType }>`
  background: ${props => props.theme.colors.lightBackground};
  border: ${spaces.space_nano} solid ${props => props.theme.colors.neutral300};
  box-sizing: border-box;
  padding: ${spaces.space_m};
  height: 100%;
`;

export const UsefulResourcesCardStyledLabelIconStyled = styled.div<{ theme?: BaseThemeType }>`
  display: flex;
  gap: ${spaces.space_s};
`;

export const UsefulResourcesCardStyledLabelStyled = styled.div<{ theme?: BaseThemeType }>`
  font-size: ${fontSizes.xxxSmall};
  line-height: ${spaces.space_s};
  color: ${grey[900]};
  font-weight: 600;
`;

export const UsefulResourcesCardDataStyled = styled.div<{ theme?: BaseThemeType }>`
  margin-top: ${spaces.space_m};
`;

export const UsefulResourcesCardDataRowStyled = styled.div<{
  theme?: BaseThemeType;
  isLast?: boolean;
}>`
  padding: 16px 0;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid #e6e7fe;
`;

export const UsefulResourcesCardDataRowLabelStyled = styled.div<{ theme?: BaseThemeType }>`
  font-size: ${fontSizes.xxSmall};
  line-height: ${spaces.space_22};
  color: ${props => props.theme.colors.primary200};
`;
