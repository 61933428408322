export default {
  space_nano: '1px',
  space_micro: '2px',
  space_3: '3px',
  space_mini: '4px',
  space_5: '5px',
  space_6: '6px',
  space_7: '7px',
  space_xxxs: '8px',
  space_9: '9px',
  space_10: '10px',
  space_xxs: '12px',
  space_14: '14px',
  space_15: '15px',
  space_xs: '16px',
  space_18: '18px',
  space_s: '20px',
  space_22: '22px',
  space_m: '24px',
  space_25: '25px',
  space_l: '28px',
  space_30: '30px',
  space_xl: '32px',
  space_xxl: '36px',
  space_xxxl: '40px',
  space_mega: '44px',
  space_giga: '48px',
  space_tera: '56px',
  space_peta: '64px',
  space_70: '70px',
  space_exa: '80px',
  space_84: '84px',
  space_90: '90px',
  space_zetta: '96px',
  space_100: '100px',
};
