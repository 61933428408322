// vendor
import React from 'react';
import { Box, Typography } from '@mui/material';

// components
import { MpDropdownMenu, FormattedMessage } from 'UI';
import { CountryFlag } from 'UI/components/country-flag';

// icons
import {
  MailOutlined,
  MarkEmailReadOutlined,
  LocalPhoneOutlined,
  CreateOutlined,
} from '@mui/icons-material';

// Constants
import { UserDesginationMappingConstants } from 'constants/enum.constants';
import { grey } from 'theme/colors';
import { UserType } from 'store/types/buyers';
import { TABLE_USE_CASE_BUYERS } from 'UI/components/table/constants';

// styles
import {
  ContactPersonDataStyled,
  ContactPersonDataContentLabelStyled,
  ContactPersonAutorisedPayerStyled,
  ContactPersonContentStyled,
} from './contact-person.styled';

type Props = {
  data: UserType;
  useCase: string;
  dropDownConfig: {
    onMenuItemClick: () => any;
    dropdownMenuList: Array<{
      label: string;
      action: string;
      payload: Record<string, unknown>;
    }>;
    label: string;
  };
  editableHandler: (data: any) => any;
  size?: 'xs' | 'sm';
  isContactSingle?: boolean;
};

const ContactPerson = (props: Props) => {
  const { data, dropDownConfig, editableHandler, isContactSingle, useCase } = props;

  const linkClicked = (type, value) => {
    if (type === 'mobile') {
      window.open(`tel:${value}`);
    }
    if (type === 'email') {
      window.open(`mailto:${value}`);
    }
  };

  return (
    <ContactPersonDataStyled>
      <div>
        <ContactPersonAutorisedPayerStyled>
          {data.first_name + ' ' + data.last_name}
          {data.language && (
            <Box ml="10px">
              <CountryFlag language={data.language} />
            </Box>
          )}
        </ContactPersonAutorisedPayerStyled>

        <ContactPersonDataContentLabelStyled>
          {useCase === TABLE_USE_CASE_BUYERS && (
            <>
              <FormattedMessage id="LABELS.ACCOUNTABILITY" />{' '}
              <FormattedMessage id="AUTHORISED_PAYER" />
            </>
          )}
        </ContactPersonDataContentLabelStyled>

        <ContactPersonDataContentLabelStyled>
          <FormattedMessage id="LABELS.ROLE" />:{' '}
          {UserDesginationMappingConstants[data.user_designation || data.role] ||
            data.user_designation}
        </ContactPersonDataContentLabelStyled>

        <div>
          {data.phone && (
            <Typography
              sx={ContactPersonContentStyled}
              onClick={() => linkClicked('mobile', data.phone)}
              component="span"
            >
              <LocalPhoneOutlined sx={{ fontSize: 20, marginRight: 1 }} />
              {data.phone}
            </Typography>
          )}

          <Typography
            sx={ContactPersonContentStyled}
            onClick={() => linkClicked('email', data.email)}
            component="span"
          >
            {data.email_otp_validated ? (
              <MarkEmailReadOutlined sx={{ fontSize: 20, marginRight: 1 }} />
            ) : (
              <MailOutlined sx={{ fontSize: 20, marginRight: 1 }} />
            )}
            {data.email}
          </Typography>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <div
          data-sdet={`${data.first_name}-${data.last_name}`}
          style={{ position: 'absolute', right: 0, top: 0 }}
        >
          {dropDownConfig && (
            <MpDropdownMenu
              onMenuItemClick={dropDownConfig.onMenuItemClick}
              menuList={dropDownConfig.dropdownMenuList}
              label={dropDownConfig.label}
              data={data}
            />
          )}

          {isContactSingle && (
            <div onClick={() => editableHandler(data)}>
              <CreateOutlined sx={{ fontSize: '18px', cursor: 'pointer', color: grey[700] }} />
            </div>
          )}
        </div>
      </div>
    </ContactPersonDataStyled>
  );
};

export default ContactPerson;
