import styled from '@emotion/styled';
import { BaseThemeType } from 'theme/baseTheme';

export const MpDropzonePlaceholderStyled = styled.div<{
  theme?: BaseThemeType;
  justifyCenter?: any;
  isDisabled?: boolean;
}>`
  font-size: 12px;
  font-weight: 400;
  line-height: ${props => props.theme.spaces.space_22};
  width: 100%;
  display: flex;
  align-items: center;
  ${props => props.justifyCenter && `justify-content: center;`}
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
`;

export const MpDropzoneLabelImageStyled = styled.img<{ theme?: BaseThemeType }>`
  height: 22px;
  width: 18px;
  margin-right: ${props => props.theme.spaces.space_15};
`;

export const MpDropzoneWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  padding: ${props => props.theme.spaces.space_s};
  background: ${props => props.theme.colors.neutral100};
  border: ${props => props.theme.spaces.space_nano} dashed ${props => props.theme.colors.neutral400};
  box-sizing: border-box;
  border-radius: ${props => props.theme.spaces.space_mini};
  color: ${props => props.theme.colors.neutral500};
`;
