// vendor
import styled from '@emotion/styled';

import { BaseThemeType } from 'theme/baseTheme';

export const MpDashboardWrapperStyled = styled.div`
  min-height: 100vh;
`;

export const MpDashboardContentStyled = styled.div<{ theme?: BaseThemeType }>`
  padding: 10px 30px;
  overflow: auto;
  height: 100vh;
  width: calc(100% - 220px);
  [data-sdet='profile-dropdown'] {
    background: none;
    @media only screen and (max-width: 900px) {
      margin-top: 10px;
    }
  }
  @media only screen and (max-width: 900px) {
    width: 94%;
    padding: 3%;
  }
`;

export const MpDashboardContentWrapperStyled = styled.div`
  display: flex;
`;

export const MpDashboardHeaderDropdownTitleStyled = styled.div<{ theme?: BaseThemeType }>`
  font-weight: 400;
  font-size: ${props => props.theme.fontSizes.xSmall};
  line-height: ${props => props.theme.spaces.space_m};
  color: ${props => props.theme.colors.neutral700};
`;
export const MpDashboardHeaderDropdownCSubtitleStyled = styled.div<{ theme?: BaseThemeType }>`
  font-weight: 300;
  font-size: ${props => props.theme.fontSizes.xxxSmall};
  line-height: ${props => props.theme.spaces.space_s};
  color: ${props => props.theme.colors.neutral700};
`;

export const MpDashboardHeaderDropdownAvatarStyled = styled.img<{ theme?: BaseThemeType }>`
  margin-right: ${props => props.theme.spaces.space_xxs};
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;
