import React from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { Grid, TablePagination, Switch, FormControlLabel } from '@mui/material';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

import { TablePaginationActions } from './pagination-actions';
import { changeTableConfig, changeTableSetting } from 'store/actions/tables';
import { useTranslation } from 'i18n/formatted-message';
import { DEFAULT_ROWS_PER_PAGE, PAGES } from 'constants/table-filter-options';

type PaginationProps = {
  totalItems: number;
  useCase: string;
  defaultPageSize?: number;
};

export const Pagination = ({
  totalItems = 99,
  useCase,
  defaultPageSize = DEFAULT_ROWS_PER_PAGE,
}: PaginationProps) => {
  const { isDensePadding } = useAppSelector(state => state.table);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { page = 1, rowsPerPage = defaultPageSize } = qs.parse(location.hash.split('?')[1]);

  const navigateTo = param => {
    const newSearchParams = {
      page,
      rowsPerPage,
      ...param,
    };

    history.push({
      search: qs.stringify(newSearchParams),
    });
    dispatch(changeTableSetting('useCase', useCase));
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={4}>
        <FormControlLabel
          style={{ marginBottom: 0 }}
          checked={isDensePadding}
          onChange={() => dispatch(changeTableConfig('isDensePadding', !isDensePadding))}
          control={<Switch />}
          label={useTranslation('DENSE_PADDING')}
          labelPlacement="start"
        />
      </Grid>
      <Grid item xs={8}>
        <TablePagination
          rowsPerPageOptions={PAGES}
          count={totalItems}
          rowsPerPage={Number(rowsPerPage)}
          page={Number(page || 1) - 1}
          labelRowsPerPage={useTranslation('ROWS_PER_PAGE')}
          onPageChange={(_, page) => navigateTo({ page: page + 1 })}
          onRowsPerPageChange={event => navigateTo({ rowsPerPage: event.target.value, page: 1 })}
          // @ts-ignore
          ActionsComponent={TablePaginationActions}
        />
      </Grid>
    </Grid>
  );
};
