// vendors
import React from 'react';
import { Box } from '@mui/material';

// @ts-ignore
import { ReactComponent as BlueClose } from 'assets/icons/blue-close.svg';

import { SettlementsEmailBoxStyled } from './styled';

interface DisplayEmailInterface {
  emails: Array<string>;
  deleteEmail: (index: number) => void;
}

export const DisplayEmails = ({ emails, deleteEmail }: DisplayEmailInterface) => {
  return Array.isArray(emails) ? (
    <Box sx={{ display: 'flex', gap: '9px', flexWrap: 'wrap' }}>
      {emails.map((email, index) => {
        return (
          <SettlementsEmailBoxStyled key={`${email}-${index}`}>
            <span>{email}</span>
            {emails.length > 1 ? (
              <BlueClose
                data-sdet={`cross-icon-${email}`}
                style={{ cursor: 'pointer' }}
                onClick={() => deleteEmail(index)}
              />
            ) : (
              ''
            )}
          </SettlementsEmailBoxStyled>
        );
      })}
    </Box>
  ) : (
    <></>
  );
};
