// constants
import {
  SET_USER_DETAILS,
  DELETE_USER_DETAILS,
  SELLERS_USER_PROFILE,
  SELLERS_COMPANY_PROFILE,
  UPDATE_SELLER_USERS,
  RESET,
  SET_FINGERPRINT_INFO,
} from 'store/types';
import { UserType, Address } from '../types/buyers';

type UserProfileType = {
  _uuid: string;
  is_staff: boolean;
  first_name: string;
  last_name: string;
  email: string;
  language: string;
  business_name: string;
  profile_picture_url: string;
  status: string;
};

type UserReducerType = {
  userProfile: UserProfileType;
  isStaff: boolean;
  name: string;
  company: string;
  avatar: string;
  team: Array<UserType>;
  _uuid?: string;
  email?: string;
  fingerprint?: Record<string, unknown>;
  companyProfile: {
    business_name: string;
    registration_number: string;
    vat_id: string;
    email: string;
    phone: string;
    business_type: string;
    iban: string;
    business_logo: string;
    address: Address;
    _uuid: string;
    eligible_payment_terms: Array<string>;
    is_default_guarantee_enabled: boolean;
  };
};

const initialState: UserReducerType = {
  userProfile: {} as UserProfileType,
  isStaff: false,
  fingerprint: undefined,
  name: '',
  company: '',
  avatar: '',
  team: [],
  companyProfile: {} as UserReducerType['companyProfile'],
};

export const user = (state = initialState, action): UserReducerType => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return { ...state, name: action.payload.name, company: action.payload.company };

    case DELETE_USER_DETAILS:
      return { ...state, name: '', company: '' };

    case SELLERS_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
        isStaff: action.payload.is_staff,
      };

    case SELLERS_COMPANY_PROFILE:
      const address = action.payload.address || {};
      return { ...state, companyProfile: { ...action.payload, address } };

    case UPDATE_SELLER_USERS:
      return { ...state, team: action.payload };

    case SET_FINGERPRINT_INFO:
      return { ...state, fingerprint: action.payload };

    case RESET: {
      return initialState;
    }

    default:
      return { ...state };
  }
};
