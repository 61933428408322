// vendor
import React from 'react';
import { Box, Grid } from '@mui/material';

// components
import { MpDropdownMenu, FormattedMessage } from 'UI';

// icons
import {
  MailOutlined,
  LocalPhoneOutlined,
  CreateOutlined,
  Language,
  MarkEmailReadOutlined,
} from '@mui/icons-material';

// Constants
import { grey } from 'theme/colors';

// styles
import {
  ContactPersonDataStyled,
  ContactPersonDataContentLabelStyled,
  ContactPersonAutorisedPayerStyled,
} from './styled';
import { UserType } from 'store/types/buyers';

type Props = {
  data: UserType;
  dropDownConfig: {
    onMenuItemClick: () => any;
    dropdownMenuList: Array<{
      label: string;
      action: string;
      payload: Record<string, unknown>;
    }>;
    label: string;
  };
  editableHandler: (data: any) => any;
  size?: 'xs' | 'sm';
  isContactSingle?: boolean;
};

export const Contact = (props: Props) => {
  const { data, dropDownConfig, editableHandler, isContactSingle } = props;

  const linkClicked = (type, value) => {
    if (type === 'mobile') {
      window.open(`tel:${value}`);
    }
    if (type === 'email') {
      window.open(`mailto:${value}`);
    }
  };

  return (
    <>
      <ContactPersonDataStyled>
        <div>
          <ContactPersonAutorisedPayerStyled>
            {data.first_name + ' ' + data.last_name}
          </ContactPersonAutorisedPayerStyled>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ width: ['100%', '100%', '250px'] }}
              mt={[2]}
            >
              <Box sx={ContactPersonDataContentLabelStyled}>
                <Language sx={{ marginRight: 1, fill: '#000339', width: '16px' }} />
                <FormattedMessage id="BUYER.CONTACT_PERSON.PREFERRED_LANGUAGE" />{' '}
                <FormattedMessage id={`lang.${[data.language]}`} />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ width: ['100%', '100%', '250px'] }}
              mt={[2]}
            >
              <Box sx={ContactPersonDataContentLabelStyled}>
                <LocalPhoneOutlined sx={{ marginRight: 1, fill: '#000339', width: '16px' }} />
                <FormattedMessage
                  id="BUYER.CONTACT_PERSON.PHONE"
                  context={{ phone: data.phone || '-' }}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ width: ['100%', '100%', '250px'] }}
              mt={[2]}
            >
              <Box
                onClick={() => linkClicked('email', data.email)}
                sx={ContactPersonDataContentLabelStyled}
              >
                {data.email_otp_validated ? (
                  <MarkEmailReadOutlined sx={{ marginRight: 1, fill: '#000339', width: '16px' }} />
                ) : (
                  <MailOutlined sx={{ marginRight: 1, fill: '#000339', width: '16px' }} />
                )}
                <FormattedMessage id="BUYER.EMAIL" />: {data.email || '-'}
              </Box>
            </Grid>
          </Grid>
        </div>
        <div style={{ position: 'relative' }}>
          <div
            data-sdet={`${data.first_name}-${data.last_name}`}
            style={{ position: 'absolute', right: 0, top: 0 }}
          >
            {dropDownConfig && (
              <MpDropdownMenu
                onMenuItemClick={dropDownConfig.onMenuItemClick}
                menuList={dropDownConfig.dropdownMenuList}
                label={dropDownConfig.label}
                data={data}
              />
            )}

            {isContactSingle && (
              <div onClick={() => editableHandler(data)}>
                <CreateOutlined sx={{ fontSize: '18px', cursor: 'pointer', color: grey[700] }} />
              </div>
            )}
          </div>
        </div>
      </ContactPersonDataStyled>
    </>
  );
};
