// vendor
import React, { useLayoutEffect, useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { Switch, Route, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';

// components
import { InvoiceTable, FormattedMessage } from 'UI';
import { AddContact, ApproveRejectBuyer, UpdateCreditLimit, UpdatePaymentTerms } from './modals';
import { BuyerDetailsHeader } from './header';
import { SubMenu } from 'UI/components/mp-sidebar-menu/sub-menu';

// constants
import {
  BUYERS_INVOICE_ROUTE,
  BUYER_INVOICES_SUB_ROUTE,
  BUYER_ORDERS_SUB_ROUTE,
  BUYERS_ORDERS_ROUTE,
  BUYER_PROCESSED_ORDER_SUB_ROUTE,
  BUYER_PENDING_ORDER_SUB_ROUTE,
} from 'constants/routes.constants';
import {
  OPEN_UPDATE_CREDIT_LIMIT_MODAL,
  OPEN_UPDATE_PAYMENT_TERMS_MODAL,
  RESET_SINGLE_BUYER_BASIC_DETAILS,
  OPEN_APPROVE_REJECT_BUYER_MODAL,
} from 'store/types';
import { BUYER_DETAILS_MENU } from 'constants/buyer.constants';

// routes
import { BuyersDetailsRoute, BuyersContactsRoute, BuyersRedirectCheckoutRoute } from 'routes';

// styles
import { MpBuyersDashboardWrapperStyled, MpBuyerDashboardContentStyled } from './styled';

// actions
import {
  getBuyerBasicProfileDetails,
  closeAddPersonPopup,
  addBuyerContact,
  updateBuyerContact,
} from 'store/actions';
import { snackbarConfig, isTestingOrSandboxEnv } from 'utils';
import { OrdersDashboard } from '../orders-table';

const CONTACT_LABEL = 'contacts';

export const BuyerDetailDashboard = () => {
  const { pathname } = useLocation();
  const { id: buyerId } = useParams();

  const isContactsPage = pathname.includes(CONTACT_LABEL);
  const isInvoicePage = pathname.endsWith(BUYER_INVOICES_SUB_ROUTE);
  const isOrdersPage =
    pathname.endsWith(BUYER_ORDERS_SUB_ROUTE) ||
    pathname.endsWith(BUYER_PROCESSED_ORDER_SUB_ROUTE) ||
    pathname.endsWith(BUYER_PENDING_ORDER_SUB_ROUTE);

  const { enqueueSnackbar } = useSnackbar();
  const {
    buyer: {
      buyerDetails: { business_name: name, status },
      contactList,
    },
    popup: { addBuyerContactTeamPersonPopup, initialValuesOnBuyersDashboardAddPersonPopup },
    login: { sellerId },
    settings: {
      sellerConfig: { is_pending_order_enabled: is_pending_order },
    },
  } = useAppSelector(state => state);

  const [state, setState] = useState({
    openUpdateCreditLimitModal: false,
    openUpdatePaymentTermsModal: false,
    openApproveRejectBuyerModal: false,
  });

  const dispatch = useAppDispatch();

  const onClick = action => {
    if (action) {
      if (action === OPEN_UPDATE_CREDIT_LIMIT_MODAL) {
        setState({ ...state, openUpdateCreditLimitModal: true });
        return;
      } else if (action === OPEN_UPDATE_PAYMENT_TERMS_MODAL) {
        setState({ ...state, openUpdatePaymentTermsModal: true });
        return;
      } else if (action === OPEN_APPROVE_REJECT_BUYER_MODAL) {
        setState({ ...state, openApproveRejectBuyerModal: true });
        return;
      } else {
        dispatch({ type: action });
      }
    }
  };

  const closePersonMemberModalPopup = () => {
    dispatch(closeAddPersonPopup);
  };

  const checkEmailAlreadyExistedForOtherBuyerUser = values => {
    let emailMatchFound = false;
    let existedContactName = '';
    contactList.forEach(contact => {
      if ((!values._uuid || values._uuid !== contact._uuid) && values.email === contact.email) {
        emailMatchFound = true;
        existedContactName = `${contact.first_name} ${contact.last_name}`;
      }
    });

    return { emailMatchFound, existedContactName };
  };

  const onSubmit = values => {
    const newValues = {
      ...values,
      phone: values?.phone
        ? values?.phone.startsWith('+')
          ? values.phone
          : `+${values.phone}`
        : null,
      email: values?.email ? values.email.toLowerCase() : null,
      business: buyerId,
    };

    const initialValue = initialValuesOnBuyersDashboardAddPersonPopup;

    const { emailMatchFound, existedContactName } =
      checkEmailAlreadyExistedForOtherBuyerUser(newValues);

    if (emailMatchFound) {
      enqueueSnackbar(
        <FormattedMessage id="BUYER.ALREADY_EXISTED_CONTACT" context={{ existedContactName }} />,
        snackbarConfig({ type: 'error' })
      );
    } else {
      if (Object.keys(initialValue).length > 0 && initialValue.first_name) {
        dispatch(updateBuyerContact(newValues, enqueueSnackbar, sellerId));
      } else {
        dispatch(addBuyerContact(newValues, enqueueSnackbar, sellerId));
      }
    }
  };

  useLayoutEffect(() => {
    dispatch(getBuyerBasicProfileDetails(buyerId, sellerId));
    return () => {
      dispatch({ type: RESET_SINGLE_BUYER_BASIC_DETAILS });
    };
  }, []);

  useEffect(() => {
    if (pathname === `/buyers/${buyerId}/details`) {
      dispatch(getBuyerBasicProfileDetails(buyerId, sellerId));
    }
  }, [pathname]);

  return (
    <Box>
      <MpBuyersDashboardWrapperStyled>
        <BuyerDetailsHeader
          onClick={onClick}
          isContactsPage={isContactsPage}
          isInvoicePage={isInvoicePage || isOrdersPage}
        />

        {!isInvoicePage && !isOrdersPage && (
          <SubMenu menu={BUYER_DETAILS_MENU(is_pending_order, buyerId)} />
        )}

        <MpBuyerDashboardContentStyled>
          <Switch>
            <Route exact {...BuyersDetailsRoute} />
            <Route exact {...BuyersContactsRoute} />
            <Route exact {...BuyersRedirectCheckoutRoute} />
            <Route
              exact
              path={BUYERS_INVOICE_ROUTE}
              render={() => <InvoiceTable buyerId={buyerId} status={status} name={name} />}
            />
            <Route
              exact={is_pending_order ? false : true}
              path={BUYERS_ORDERS_ROUTE}
              render={() => <OrdersDashboard buyerId={buyerId} status={status} name={name} />}
            />
          </Switch>
        </MpBuyerDashboardContentStyled>
      </MpBuyersDashboardWrapperStyled>
      <AddContact
        openModal={addBuyerContactTeamPersonPopup}
        initialValues={initialValuesOnBuyersDashboardAddPersonPopup}
        closeModal={closePersonMemberModalPopup}
        onSubmit={onSubmit}
      />
      <UpdateCreditLimit
        openModal={state.openUpdateCreditLimitModal}
        closeModal={() => {
          setState({ ...state, openUpdateCreditLimitModal: false });
        }}
      />

      <UpdatePaymentTerms
        openModal={state.openUpdatePaymentTermsModal}
        closeModal={() => {
          setState({ ...state, openUpdatePaymentTermsModal: false });
        }}
      />

      {isTestingOrSandboxEnv() && (
        <ApproveRejectBuyer
          openModal={state.openApproveRejectBuyerModal}
          closeModal={() => {
            setState({ ...state, openApproveRejectBuyerModal: false });
          }}
        />
      )}
    </Box>
  );
};
