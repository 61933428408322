// constants
import * as TYPES from 'store/types';
import * as KYC_TYPES from 'store/types/kyc';

const initialState: KYC_TYPES.KycReducerType = {
  status: null,
  type: undefined,
  currentActiveStep: null,
  correctionSteps: [],
  company_details: {
    [KYC_TYPES.FormKeys.registered_name]: '',
    [KYC_TYPES.FormKeys.local_registration_number]: '',
    [KYC_TYPES.FormKeys.license_status]: '', // if true then fill description_of_activities "free text"
    [KYC_TYPES.FormKeys.license_description]: '',
    [KYC_TYPES.FormKeys.description_of_activities]: '',
    [KYC_TYPES.FormKeys.trade_outside_europe_status]: '',
    [KYC_TYPES.FormKeys.trade_outside_europe_countries]: '',
    [KYC_TYPES.FormKeys.jurisdiction_of_incorporation]: '',
    address: {
      [KYC_TYPES.KycAddressKeys.address_line_1]: '',
      [KYC_TYPES.KycAddressKeys.postal_code]: '',
      [KYC_TYPES.KycAddressKeys.city]: '',
      [KYC_TYPES.KycAddressKeys.country]: '',
    },
  },
  bank_account: [] as Array<KYC_TYPES.BankAccountType>,
  is_bank_account_details_filled: false,
  legal_rep: [] as Array<
    KYC_TYPES.LegalRepType & KYC_TYPES.KycAddressType & KYC_TYPES.LegalRepGovtIdType
  >,
  shareholder_type: undefined,
  shareholder_file: {} as KYC_TYPES.file,
  shareholders: [] as Array<KYC_TYPES.ShareholderType>,
  ubo: [] as Array<KYC_TYPES.UBO_TYPE>,
  ownership_details: {
    [KYC_TYPES.LegalRepKeys.firstName]: '',
    [KYC_TYPES.LegalRepKeys.lastName]: '',
    [KYC_TYPES.LegalRepKeys.dob]: '',
    [KYC_TYPES.LegalRepKeys.gender]: '',
    [KYC_TYPES.KycAddressKeys.address_line_1]: '',
    [KYC_TYPES.KycAddressKeys.postal_code]: '',
    [KYC_TYPES.KycAddressKeys.city]: '',
    [KYC_TYPES.KycAddressKeys.country]: '',
    [KYC_TYPES.LegalRepIdKeys.document]: '',
    [KYC_TYPES.LegalRepIdKeys.number]: '',
    [KYC_TYPES.LegalRepIdKeys.issued_date]: '',
    [KYC_TYPES.LegalRepIdKeys.placeOfIssue]: '',
    [KYC_TYPES.LegalRepIdKeys.copyOfId]: {} as KYC_TYPES.file,
  } as KYC_TYPES.LegalRepType & KYC_TYPES.KycAddressType & KYC_TYPES.LegalRepGovtIdType,
  documents: {
    [KYC_TYPES.DocumentNameEnum.additionalDocument]: {} as KYC_TYPES.file,
    [KYC_TYPES.DocumentNameEnum.bankStatement]: {} as KYC_TYPES.file,
    [KYC_TYPES.DocumentNameEnum.localRegistration]: {} as KYC_TYPES.file,
  },
};

export const kyc = (state = initialState, action): KYC_TYPES.KycReducerType => {
  switch (action.type) {
    case TYPES.SET_KYC_STATUS: {
      return {
        ...state,
        type: action.payload.type || undefined,
        status: action.payload.status,
        currentActiveStep: action.payload.last_active_step,
        correctionSteps: action.payload.correction_steps,
      };
    }

    case TYPES.SET_KYC_DATA: {
      return {
        ...state,
        ...action.payload,
        is_bank_account_details_filled: action.payload.bank_account.length > 0,
      };
    }

    case TYPES.SET_INITIAL_KYC_FLOW_TYPE: {
      return { ...state, type: action.payload };
    }

    case TYPES.CHANGE_CURRENT_ACTIVE_STEP: {
      return { ...state, currentActiveStep: action.payload };
    }

    case TYPES.SET_KYC_COMPANY_DETAILS: {
      return { ...state, company_details: action.payload };
    }

    case TYPES.ADD_EDIT_BANK_ACCOUNT_DETAILS: {
      // Check if the bank account already exists
      const existingBankAccount = state.bank_account.find(
        account => account.id === action.payload.id
      );

      if (existingBankAccount) {
        // If the bank account already exists, edit it
        return {
          ...state,
          bank_account: state.bank_account.map(account => {
            if (account.id === action.payload.id) {
              return { ...account, ...action.payload };
            } else {
              return account;
            }
          }),
        };
      } else {
        // If the bank account doesn't exist, add it
        return {
          ...state,
          bank_account: [
            ...state.bank_account,
            {
              ...action.payload,
              id: new Date().getTime(),
            },
          ],
          is_bank_account_details_filled: true,
        };
      }
    }

    case TYPES.DELETE_BANK_ACCOUNT_DETAILS: {
      // Filter out the bank account with the specified ID
      const updatedBankAccounts = state.bank_account.filter(
        account => account.id !== action.payload.id
      );

      return {
        ...state,
        bank_account: updatedBankAccounts,
        is_bank_account_details_filled: updatedBankAccounts.length > 0,
      };
    }

    case TYPES.ADD_EDIT_LEGAL_REP_DETAILS: {
      // Check if the legal rep already exists
      const existingLegalRep = state.legal_rep.find(account => account.id === action.payload.id);

      if (existingLegalRep) {
        // If the legal rep already exists, edit it
        return {
          ...state,
          legal_rep: state.legal_rep.map(account => {
            if (account.id === action.payload.id) {
              return { ...account, ...action.payload };
            } else {
              return account;
            }
          }),
        };
      } else {
        // If the legal rep doesn't exist, add it
        const { dob, issued_date } = action.payload;

        return {
          ...state,
          legal_rep: [
            ...state.legal_rep,
            {
              ...action.payload,
              id: new Date().getTime(),
              issued_date: issued_date,
              dob: dob,
            },
          ],
        };
      }
    }

    case TYPES.DELETE_LEGAL_REP_DETAILS: {
      // Filter out the legal rep with the specified ID
      const updatedLegReps = state.legal_rep.filter(account => account.id !== action.payload.id);

      return {
        ...state,
        legal_rep: updatedLegReps,
      };
    }

    case TYPES.SAVE_SHAREHOLDER_DETAILS: {
      const existingShareholder = state.shareholders.find(
        details => details.id === action.payload.id
      );

      if (existingShareholder) {
        // If the shareholder already exists, edit it
        return {
          ...state,
          shareholder_type: KYC_TYPES.SHAREHOLDER_TYPE_ENUM.MANUAL_ENTRY,
          shareholders: state.shareholders.map(details => {
            if (details.id === action.payload.id) {
              return { ...details, ...action.payload };
            } else {
              return details;
            }
          }),
        };
      } else {
        // If the shareholder doesn't exist, add it
        const { comment, percent, name } = action.payload;

        return {
          ...state,
          shareholder_type: KYC_TYPES.SHAREHOLDER_TYPE_ENUM.MANUAL_ENTRY,
          shareholders: [
            ...state.shareholders,
            {
              id: new Date().getTime(),
              name,
              comment,
              percent,
            },
          ],
        };
      }
    }

    case TYPES.DELETE_SHAREHOLDER_DETAILS: {
      // Filter out the shareholder with the specified ID
      const updatedShareholders = state.shareholders.filter(
        details => details.id !== action.payload.id
      );

      return {
        ...state,
        shareholders: updatedShareholders,
      };
    }

    case TYPES.SAVE_SHAREHOLDER_FILE: {
      return {
        ...state,
        shareholder_type: KYC_TYPES.SHAREHOLDER_TYPE_ENUM.FILE,
        shareholder_file: action.payload,
      };
    }

    // SAVE UBO DETAILS
    case TYPES.SAVE_UBO_DETAILS: {
      const existingUBO = state.ubo.find(details => details.id === action.payload.id);

      if (existingUBO) {
        // If the UBO already exists, edit it
        return {
          ...state,
          ubo: state.ubo.map(details => {
            if (details.id === action.payload.id) {
              return { ...details, ...action.payload };
            } else {
              return details;
            }
          }),
        };
      } else {
        return {
          ...state,
          ubo: [
            ...state.ubo,
            {
              ...action.payload,
              id: new Date().getTime(),
            },
          ],
        };
      }
    }

    case TYPES.DELETE_UBO_DETAILS: {
      // Filter out the UBO with the specified ID
      const updatedUBOs = state.ubo.filter(details => details.id !== action.payload.id);
      return {
        ...state,
        ubo: updatedUBOs,
      };
    }

    case TYPES.CHANGE_STATUS_TO_UNDER_REVIEW: {
      return {
        ...state,
        status: KYC_TYPES.STATUS_TYPE.UNDER_REVIEW,
      };
    }

    case TYPES.SAVE_OWNERSHIP_DETAILS: {
      return {
        ...state,
        ownership_details: { ...state.ownership_details, ...action.payload },
      };
    }

    case TYPES.SAVE_DOCUMENTS: {
      return {
        ...state,
        documents: action.payload,
      };
    }
    case TYPES.RESET: {
      return { ...initialState };
    }
    default:
      return { ...state };
  }
};
