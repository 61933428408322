// vendor
import React, { useCallback, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { withTranslation } from 'react-i18next';

// components
import { MpMessage, MpIcon, PasswordStrengthMeter, ForgotPassword, Label } from 'UI';

// styles
import { MpInputStyled } from './mp-input.styled';

// constants
import { ERROR } from 'constants/message-types';

type Props = {
  value?: string;
  /** Handler called when input value is changed */
  onChange?: (v: any) => any;
  /** Error Message for input */
  errorMessage?: React.ReactNode;
  /** Flag for show the mandatory asterisk symbol */
  isRequired?: boolean;
  /** Label for input */
  label?: React.ReactNode;
  /** Property to make label bold */
  labelBold?: boolean;
  /**Name of the input element*/
  name?: string;
  /** Placeholder */
  placeholder?: string;
  /** Type of input field */
  type?: string;
  /** left Icon */
  leftIcon?: string;
  /** right Icon */
  rightIcon?: string | React.ReactNode;
  /** Left Label */
  leftLabel?: string;
  /** to diable the input field */
  isDisabled?: boolean;
  /** Flag to add password strength checker */
  enablePasswordStrengthChecker?: boolean;
  onFocus?: () => void;
  onBlur?: (e) => void;
  onPaste?: (event?: any) => void;
  onKeyDown?: (event: any) => void;
  className?: string;
  onIconClicked?: () => void;
  /** Translator Function */
  t: (d: any) => string;
  /** React Children */
  showForgotPasswordLink?: boolean;
  helper?: string | React.ReactNode;
};

function MpInput(props: Props) {
  const {
    label,
    errorMessage,
    name,
    isRequired,
    onChange,
    placeholder,
    type,
    leftIcon = '',
    rightIcon = '',
    value,
    leftLabel,
    enablePasswordStrengthChecker,
    labelBold = false,
    isDisabled = false,
    onFocus = () => undefined,
    onBlur = () => undefined,
    onKeyDown = () => undefined,
    onPaste = () => undefined,
    className = '',
    t,
    showForgotPasswordLink,
  } = props;

  // @ts-ignore
  const onHandleChange = useCallback(event => {
    onChange?.(event);
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const renderIcon = () => {
    if (leftIcon) {
      return {
        startAdornment: (
          <InputAdornment position="start">
            <MpIcon name={leftIcon} />
          </InputAdornment>
        ),
      };
    } else if (rightIcon) {
      return {
        endAdornment: (
          <InputAdornment position="end" onClick={props.onIconClicked}>
            {rightIcon}
          </InputAdornment>
        ),
      };
    } else if (leftLabel) {
      return {
        startAdornment: <InputAdornment position="start">{leftLabel}</InputAdornment>,
      };
    }
  };

  return (
    <>
      {label && (
        <Label htmlFor={name} isRequired={isRequired} labelBold={labelBold} helper={props.helper}>
          {label}
        </Label>
      )}
      {type === 'password' ? (
        <>
          <MpInputStyled
            disabled={isDisabled}
            className="sentry-mask"
            // @ts-ignore
            shrink="false"
            // @ts-ignore
            onChange={onHandleChange}
            variant="outlined"
            // @ts-ignore
            placeholder={t(placeholder)}
            name={name}
            type={showPassword ? 'text' : 'password'}
            value={value}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onBlur={onBlur}
            // @ts-ignore
            onWheel={() => document.activeElement.blur()}
          />
          {enablePasswordStrengthChecker && <PasswordStrengthMeter password={value} />}
          {errorMessage && (
            <MpMessage type={ERROR} message={errorMessage} isPaddingTopRequired={true} />
          )}
          {showForgotPasswordLink && (
            <div style={{ marginTop: '8px', textAlign: 'left' }}>
              {/*@ts-ignore*/}
              <ForgotPassword withLogout />
            </div>
          )}
        </>
      ) : (
        <>
          <MpInputStyled
            disabled={isDisabled}
            name={name}
            // @ts-ignore
            onChange={onHandleChange}
            variant="outlined"
            placeholder={t(placeholder)}
            type={type}
            value={value}
            // @ts-ignore
            InputProps={renderIcon()}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
            className={className}
            // @ts-ignore
            onWheel={() => document.activeElement.blur()}
          />
          {errorMessage && (
            <MpMessage type={ERROR} message={errorMessage} isPaddingTopRequired={true} />
          )}
        </>
      )}
    </>
  );
}

export default withTranslation('translations')(MpInput);
