import React from 'react';
import {
  TransactionInfoBoxWrapperStyled,
  TransactionInfoBuyerIdStyled,
  TransactionInfoIconWrapperStyled,
  TransactionInfoTitleStyled,
} from './styled';
import DoneIcon from '@mui/icons-material/Done';

import { FormattedMessage } from 'UI';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'store';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'constants/date';

type OrderCreatedProps = {
  id: string;
};

export const Step3Captured = ({ id }: OrderCreatedProps) => {
  return (
    <TransactionInfoBoxWrapperStyled>
      <TransactionInfoIconWrapperStyled isMarginTopPresent>
        <DoneIcon style={{ fill: 'url(#with-icon-gradient)' }} />
      </TransactionInfoIconWrapperStyled>
      <TransactionInfoTitleStyled>
        <FormattedMessage id={'ORDERS.AUTHORIZED'} />
      </TransactionInfoTitleStyled>
      <TransactionInfoBuyerIdStyled isMarginPresent>
        <FormattedMessage id="ORDERS.SPRINQUE_ORDER_ID" />
      </TransactionInfoBuyerIdStyled>
      <TransactionInfoBuyerIdStyled isBiggerFont>{id}</TransactionInfoBuyerIdStyled>
    </TransactionInfoBoxWrapperStyled>
  );
};

type InvoiceCapturedProps = {
  invoice_id: string;
  amount: string;
  due_date: string;
  currency: string;
  settlement_date?: string;
};

export const InvoiceCaptured = ({
  invoice_id,
  amount,
  currency,
  due_date,
  settlement_date,
}: InvoiceCapturedProps) => {
  const { currencyList } = useAppSelector(state => state.settings);
  const currencySymbol = currencyList.find(item => item.label === currency)?.value || '';
  const data = [
    { label: 'TRANSACTIONS.SPRINQUE_INVOICE_ID', value: invoice_id },
    { label: 'LABELS.INVOICE_AMOUNT', value: currencySymbol + amount },
    { label: 'TXN_DETAILS.DUE_DATE', value: format(new Date(due_date), DATE_FORMAT) },
    settlement_date && {
      label: 'LABELS.SETTLEMENT_DATE',
      value: format(new Date(settlement_date), DATE_FORMAT),
    },
  ];

  return (
    <Box>
      <TransactionInfoIconWrapperStyled>
        <DoneIcon style={{ fill: 'url(#with-icon-gradient)' }} />
      </TransactionInfoIconWrapperStyled>
      <Box textAlign="center" mb={3.5}>
        <Typography lineHeight="32px" fontSize="20px" fontWeight="700" mb={2}>
          <FormattedMessage id="INVOICE_CREATION_MODAL_STEP.TITLE" />
        </Typography>
        <Typography lineHeight="24px" fontSize="16px" fontWeight="400">
          <FormattedMessage id="INVOICE_CREATION_MODAL_STEP.SUCCESS_MESSAGE" />
        </Typography>
      </Box>
      <Box pb={4}>
        {data.map((item, index) =>
          item ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              key={index}
              mb={3}
            >
              <Typography lineHeight="24px" fontSize="16px" fontWeight="400">
                <FormattedMessage id={item.label} />
              </Typography>
              <Typography lineHeight="24px" fontSize="16px" fontWeight="600">
                {item.value}
              </Typography>
            </Box>
          ) : (
            ''
          )
        )}
      </Box>
    </Box>
  );
};
