import styled from '@emotion/styled';

export const IconStyled = styled.span<{
  leftMargin?: string;
  rightMargin?: string;
  pointer?: () => any;
}>`
  margin-left: ${props => props.leftMargin};
  margin-right: ${props => props.rightMargin};
  display: inherit;
  cursor: ${props => props.pointer && 'pointer'};
`;
