export const ACCESS_TOKEN = 'access';
export const REFRESH_TOKEN = 'refresh';
export const TOKEN_EXPIRED = 'token_not_valid';
export const ORIGINAL_ACCESS_TOKEN = 'ori';
export const IS_LOGGED_IN_AS = 'isLoginAs';
export const SELLER_ID = 'sellerId';
export const ORIGINAL_SELLER_ID = 'originalSellerId';
export const USER_ID = 'userId';
export const SELLERS = 'mcc-sel';
export const ORG = 'mcc-org';
