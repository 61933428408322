import styled from '@emotion/styled';

import { grey } from 'theme/colors';
import { BaseThemeType } from 'theme/baseTheme';

export const CentsStyled = styled.div<{ theme?: BaseThemeType }>`
  border: 1px solid ${grey[300]};
  border-radius: 0 4px 4px 0;
  padding: ${props =>
    `${props.theme.spaces.space_5} ${props.theme.spaces.space_xxs} ${props.theme.spaces.space_mini}`};
  margin-right: ${props => props.theme.spaces.space_10};
  input {
    font-size: ${props => props.theme.fontSizes.xxSmall};
  }
`;
