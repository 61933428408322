// vendor
import React, { useState, useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { useHistory } from 'react-router-dom';

// utils
import { sort } from 'utils';
import { createInvoiceTable } from './adapter';

// actions
import { getAllInvoicesActionCreator } from 'store/actions';

// constants
import { INVOICE_DETAILS } from 'constants/routes.constants';
import { INVOICE_TABLE_HEADER, INVOICES_PER_BUYER_HEADERS } from 'constants/invoice.constants';
import { TABLE_USE_CASE_INVOICES } from 'UI/components/table/constants';

// components
import { MpTable } from 'UI';
import { Header } from './header';
import { UpdateSettlementDate } from './update-settlement-date';

type InvoiceTableProps = {
  buyerId: string;
  /** Buyer status */
  status?: string;
  /** Buyer name */
  name?: string;
};

export const InvoiceTable = ({ buyerId = '', name, status }: InvoiceTableProps) => {
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  const { list, totalItems, isDynamicSettlementsEnabled } = useAppSelector(
    ({
      transaction: {
        invoices,
        page: { count },
      },
      settings: {
        sellerConfig: { is_dynamic_settlements_enabled },
      },
      popup: { settlementDate: settlementDatePopup },
    }) => ({
      list: invoices,
      totalItems: count,
      isDynamicSettlementsEnabled: is_dynamic_settlements_enabled,
      settlementDatePopup,
    })
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    setTableData(createInvoiceTable(list));
  }, [list]);

  const unsubscribed = useRef(false);

  useEffect(() => {
    if (!unsubscribed.current) {
      unsubscribed.current = true;
      dispatch(getAllInvoicesActionCreator(buyerId));
    }
  }, []);

  const requestSort = value => {
    // @ts-ignore
    setTableData(sort(tableData, value));
  };

  const onRowClick = e => {
    history.push(`${INVOICE_DETAILS}/${e.routingId}`);
  };

  const HEADER = buyerId ? INVOICES_PER_BUYER_HEADERS : INVOICE_TABLE_HEADER;
  let updatedHeader = [] as any;
  if (isDynamicSettlementsEnabled) {
    updatedHeader = HEADER.filter(header => header.value !== 'Due Date');
  } else {
    updatedHeader = HEADER.filter(header => header.value !== 'Settlement Date');
  }

  return (
    <div style={{ position: 'relative' }}>
      <Header buyerId={buyerId} status={status || ''} name={name || ''} />
      <MpTable
        data={tableData}
        // @ts-ignore
        headerData={updatedHeader}
        requestSort={requestSort}
        onRowClick={onRowClick}
        // @ts-ignore
        totalItems={totalItems}
        useCase={TABLE_USE_CASE_INVOICES}
        withMinHeight
      />
      <UpdateSettlementDate callback={() => dispatch(getAllInvoicesActionCreator(buyerId))} />
    </div>
  );
};
