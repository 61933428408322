import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import {
  Table,
  TableBody,
  TableHead,
  TableSortLabel,
  Paper,
  CircularProgress,
  Box,
  TableCellProps,
} from '@mui/material';

// styles
import {
  MpTableContainerStyled,
  TableCellStyled,
  TabletRowStyled,
  TabletHeaderRowStyled,
} from './styled';
import { NoData } from 'UI/components/table/no-data';
import { Pagination } from './pagination';
import { changeTableSetting, resetTableSettings, changeTableConfig } from 'store/actions/tables';

type Props = {
  /** Header Label Data */
  headerData: Array<{
    value?: any;
    label: string | React.ReactNode;
    sort?: boolean;
    sortBy?: string;
    minWidth?: string;
    align?: Pick<TableCellProps, 'align'>;
  }>;
  /** Table Data */
  data: Array<Record<string, unknown>>;
  /** Align */
  align?: 'left' | 'center' | 'right';
  /** Request Sort */
  requestSort?: (value?: any) => any;
  //** On Row Click*/
  onRowClick?: (row?: any) => any;
  totalItems?: number;
  useCase: string;
  withMinHeight?: boolean;
  defaultPageSize?: number;
  /** To override existing styles */
  additionalStyles?: string | object;
};

export const MpTable = ({
  headerData,
  onRowClick,
  data,
  align = 'left',
  requestSort,
  totalItems,
  useCase,
  withMinHeight,
  defaultPageSize,
  additionalStyles,
}: Props) => {
  const { isDensePadding, sortBy = '', isLoading } = useAppSelector(state => state.table);
  const dispatch = useAppDispatch();

  const handleSort = value => {
    const nextSort = sortBy === value ? `-${value}` : sortBy === `-${value}` ? value : value;
    dispatch(changeTableSetting('sortBy', nextSort));
    if (requestSort) requestSort(nextSort);
  };

  useEffect(() => {
    if (useCase) {
      dispatch(resetTableSettings());
      dispatch(changeTableConfig('useCase', useCase));
    }
  }, [useCase]);

  return (
    <>
      <MpTableContainerStyled
        component={Paper}
        withMinHeight={withMinHeight}
        additionalStyles={additionalStyles}
      >
        <Table
          aria-label="simple table"
          style={{ opacity: isLoading ? '0.6' : '1' }}
          size={isDensePadding ? 'small' : 'medium'}
        >
          <TableHead>
            <TabletHeaderRowStyled>
              {headerData.map((header, index) => (
                <TableCellStyled align={align} key={index} minWidth={header.minWidth}>
                  {header.sort ? (
                    <TableSortLabel
                      active={sortBy.includes(header.sortBy as string)}
                      direction={sortBy.startsWith('-') ? 'desc' : 'asc'}
                      onClick={() => handleSort(header.sortBy)}
                    >
                      {header.label}
                    </TableSortLabel>
                  ) : (
                    header.label
                  )}
                </TableCellStyled>
              ))}
            </TabletHeaderRowStyled>
          </TableHead>

          <TableBody>
            {data.map((row, index) => (
              <TabletRowStyled
                key={`Mp-Table-Row-${index}`}
                isCursorPointer={!!onRowClick}
                style={{ opacity: row.inactive ? '0.6' : '1' }}
                // @ts-ignore
                type={row.type || useCase}
              >
                {headerData.map((header, key) => (
                  <TableCellStyled
                    key={`Mp-Table-Row-${index}-Cell-${key}`}
                    onClick={() => onRowClick?.(row)}
                    // @ts-ignore
                    align={header.align}
                  >
                    {/*@ts-ignore*/}
                    {row[header.value] || '-'}
                  </TableCellStyled>
                ))}
              </TabletRowStyled>
            ))}
          </TableBody>
        </Table>
        {isLoading ? (
          <Box
            my={2}
            style={{
              textAlign: 'center',
              position: 'absolute',
              top: '90px',
              left: 'calc(50% - 20px)',
            }}
          >
            <CircularProgress />
          </Box>
        ) : !data.length ? (
          <NoData useCase={useCase} />
        ) : (
          ''
        )}
      </MpTableContainerStyled>

      {totalItems ? (
        <Pagination totalItems={totalItems} useCase={useCase} defaultPageSize={defaultPageSize} />
      ) : (
        ''
      )}
    </>
  );
};
