// vendor
import styled from '@emotion/styled';
import { Button } from '@mui/material';

// constants
import { GHOST, DANGER } from 'constants/button-types';
import { blue } from 'theme/colors';
import { BaseThemeType } from 'theme/baseTheme';

export const MpButtonStyled = styled(Button, {
  shouldForwardProp: prop => prop !== 'isFullWidth' && prop !== 'additionalStyles',
})<{ theme?: BaseThemeType; isFullWidth?: boolean; additionalStyles?: any }>`
  padding: ${props => props.theme.spaces.space_9} ${props => props.theme.spaces.space_xs};
  width: ${props => (props.isFullWidth ? '100%' : 'auto')};
  height: 40px;
  border: 0;
  box-shadow: none;
  background: ${blue[400]};
  color: ${props => props.theme.colors.lightBackground};
  text-transform: none;
  :hover {
    background: ${blue[500]};
  }

  ${props =>
    props.disabled &&
    `
      background: #E8EDFF !important;
      color:#fff !important;
  `}

  ${props =>
    // @ts-ignore
    props.type === GHOST &&
    `
      background: ${props.theme.colors.lightBackground};
      border: ${props.theme.spaces.space_micro} solid ${blue[400]};
      color: ${blue[400]};
      box-shadow: none;
      height: 42px;
      :hover {
        background: ${props.theme.colors.lightBackground};
        box-shadow: none;
        color: ${blue[500]};
        border: ${props.theme.spaces.space_micro} solid ${blue[500]};
      }

      ${
        props.disabled &&
        `
          border: 2px solid #E8EDFF !important;
          background: #fff !important;
          color:#E8EDFF !important;
      `
      }
    `}

  ${props =>
    // @ts-ignore
    props.type === DANGER &&
    `
      background: ${props.theme.colors.brightRed};
      border: ${props.theme.spaces.space_micro} solid
        ${props.theme.colors.neutral300};
      color: ${props.theme.colors.lightBackground};
      box-shadow: none;
      height: 42px;
      :hover {
        background: ${props.theme.colors.brightRed};
        box-shadow: none;
      }
    `}

  span {
    text-transform: none;
    font-weight: 600;
    font-size: ${props => props.theme.fontSizes.xxSmall};
    line-height: ${props => props.theme.spaces.space_22};
  }

  ${props => props.additionalStyles}
`;
