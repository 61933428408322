// vendor
import React from 'react';
import { Box } from '@mui/material';
import { VisibilityOutlined, EmailOutlined } from '@mui/icons-material';

// components
import { FormattedMessage } from 'i18n/formatted-message';
import { MpButton } from 'UI/components/mp-button';

// utils
import { formatter, formatTimezone } from 'utils';
import { getCurrencySymbol } from 'utils/currencies';

// Sprinque invoice header
export const SPRINQUE_INVOICE_TABLE_HEADER = [
  {
    value: 'invoice_date',
    label: <FormattedMessage id="DATE" />,
    sort: true,
    sortBy: 'invoice_date',
    minWidth: '150px',
  },
  {
    value: 'invoice_number',
    label: <FormattedMessage id="SETTINGS.BILLING.TABLE_HEADER.INVOICE_NUMBER" />,
    sort: false,
    minWidth: '150px',
  },
  {
    value: 'invoice_total',
    label: <FormattedMessage id="SETTINGS.BILLING.TABLE_HEADER.INVOICE_TOTAL" />,
    sort: false,
  },
  {
    value: 'actions',
    label: <FormattedMessage id="ACTIONS" />,
    sort: false,
    align: 'left',
    minWidth: '130px',
  },
];

// Sprinque invoice table config
export const createInvoiceTable = (list, openEmailPopup) => {
  return list.map(row => {
    const { invoice_date, file, invoice_total, _uuid, filename, currency_code, invoice_number } =
      row || {};

    return {
      routingId: _uuid,
      id: _uuid,
      invoice_number,
      invoice_date: formatTimezone(invoice_date),
      filename: filename.split('/').pop(),
      invoice_total: getCurrencySymbol(currency_code) + formatter(+invoice_total),
      actions: (
        <Box display="flex" gap="16px">
          <MpButton
            additionalStyles={{
              'background': '#ECEDF8',
              'padding': '6px 12px',
              'color': '#000',
              'minWidth': 'unset',
              ':hover': {
                background: '#ECEDF8',
              },
            }}
            onClick={() => {
              window.open(file, '_blank');
            }}
          >
            <VisibilityOutlined sx={{ fontSize: '16px' }} />
          </MpButton>
          <MpButton
            additionalStyles={{
              'background': '#ECEDF8',
              'padding': '6px 12px',
              'color': '#000',
              'minWidth': 'unset',
              ':hover': {
                background: '#ECEDF8',
              },
            }}
            onClick={() => {
              openEmailPopup(row);
            }}
          >
            <EmailOutlined sx={{ fontSize: '16px' }} />
          </MpButton>
        </Box>
      ),
    };
  });
};
