import React from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'i18n/formatted-message';
import { TransactionInfoIconWrapperStyled } from '../add-invoice/styled';
import CloseIcon from '@mui/icons-material/Close';

export const SettlementDateUpdateFailure = ({ errorMessage }: { errorMessage?: string }) => {
  return (
    <Box mb={5}>
      <TransactionInfoIconWrapperStyled>
        <CloseIcon style={{ fill: 'url(#with-icon-gradient)' }} />
      </TransactionInfoIconWrapperStyled>
      <Box textAlign="center" mb={3.5} mt={5}>
        <Typography lineHeight="32px" fontSize="20px" fontWeight="700" mb={2}>
          <FormattedMessage id="DYNAMIC_SETTLEMENTS_POPUP.UPDATE_SETTLEMENT_DATE.FAILURE.TITLE" />
        </Typography>
        <Typography lineHeight="24px" fontSize="16px" fontWeight="400">
          {errorMessage || (
            <FormattedMessage id="DYNAMIC_SETTLEMENTS_POPUP.UPDATE_SETTLEMENT_DATE.FAILURE.DESCRIPTION" />
          )}
        </Typography>
      </Box>
    </Box>
  );
};
