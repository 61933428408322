// vendor
import React from 'react';

// styles
import { MpTagStyled } from './mp-tag.styled';

type Props = {
  label: string | React.ReactNode;
  type: string;
};

function MpTag(props: Props) {
  return <MpTagStyled type={props.type}>{props.label}</MpTagStyled>;
}

export default MpTag;
