// vendor
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useAppDispatch, useAppSelector } from 'store';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

// actions
import { stopLoading, startLoading, setIsLoginAs } from 'store/actions';
import { fetchCountry, getData, postData } from 'api';

// components
import { MpButton, MpInput, SignUp, MpModal, FormattedMessage, EmailVerification } from 'UI';
import { SelectMccAccount } from './select-mcc-account';

// constants
import { GHOST, PRIMARY } from 'constants/button-types';
import {
  valuesToValidateOnLoginPage,
  initialValuesLoginPage,
  loginPageFields,
} from 'constants/form-data';
import { GET_LIST_MERCHANTS, LOGIN_ENDPOINT } from 'constants/api-endpoints.constants';
import { LOGGED_IN, SET_SELLER_ID, SET_SELLER_USERS } from 'store/types';
import { HOME } from 'constants/routes.constants';
import { ORG } from 'constants/storage.constants';

// utils
import { validators, storageService, snackbarConfig, errorMessage } from 'utils';

// styles
import {
  MpLoginWrapperStyled,
  MpLoginInputWrapperStyled,
  MpLoginSecondaryButtonStyled,
} from './styled';

export const Login = () => {
  const [state, setState] = useState({
    openEmailVerificationModal: false,
    openSignUpModal: false,
    email: '',
  });
  const [isAccountSelector, setIsAccountSelector] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const { isLoggedIn } = useAppSelector(({ login }) => login);

  const handleSignUpModalState = state => {
    setState(prevState => ({
      ...prevState,
      openSignUpModal: state,
    }));
  };

  const onLoginSuccess = () => {
    dispatch({ type: LOGGED_IN });
    dispatch(fetchCountry());
    history.push(HOME);
    enqueueSnackbar(<FormattedMessage id="LOGGEDIN_SUCCESSFULLY" />, snackbarConfig());
  };

  const checkIfMultipleAccounts = () => {
    getData(GET_LIST_MERCHANTS)
      .then(({ data }) => {
        const organization = data[0];
        const sellers = organization?.sellers || [];
        localStorage.setObject(ORG, organization);
        if (sellers.length === 1) {
          onLoginSuccess();
          dispatch({
            type: SET_SELLER_ID,
            payload: sellers[0]._uuid,
          });
        } else if (sellers.length > 1) {
          setIsAccountSelector(true);
          dispatch({ type: SET_SELLER_USERS, payload: organization.sellers });
        } else {
          // eslint-disable-next-line no-console
          console.error('Get list merchant should return data[0].sellers');
        }
      })
      .catch(error => {
        errorMessage(error, enqueueSnackbar);
      });
  };

  const setAccessTokenOnSubmit = values => {
    dispatch(startLoading);
    postData(LOGIN_ENDPOINT, values)
      .then(res => {
        if (res.data.is_email_verified === false) {
          setState((prevState: any) => ({
            ...prevState,
            openEmailVerificationModal: true,
          }));
        } else {
          storageService.setToken(res.data);
          checkIfMultipleAccounts();
        }
      })
      .catch(error => {
        errorMessage(error, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };

  const onSubmit = (values, { setSubmitting }) => {
    setState((prevState: any) => ({
      ...prevState,
      email: values.email,
    }));
    setSubmitting(false);
    setAccessTokenOnSubmit(values);
  };

  useEffect(() => {
    dispatch(setIsLoginAs(false));
  }, []);

  const showRegistrationButton =
    search.includes('allowRegister=1') || process.env.REACT_APP_CURRENT_ENV !== 'prod';

  const userIsLoggedIn = isLoggedIn && !search.includes('isLogout=1');

  return (
    <>
      <MpLoginWrapperStyled>
        {isAccountSelector || userIsLoggedIn ? (
          <SelectMccAccount
            onSelect={onLoginSuccess}
            handleSecondaryButtonClick={() => {
              if (showRegistrationButton) {
                handleSignUpModalState(true);
              }
            }}
            setIsAccountSelector={setIsAccountSelector}
          />
        ) : (
          <Formik
            initialValues={{ ...initialValuesLoginPage }}
            validate={values => {
              const errors = validators(values, valuesToValidateOnLoginPage);
              if (!values.password) {
                errors.password = 'VALIDATOR.PASSWORD_REQUIRED';
              }
              return errors;
            }}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form>
                <Box mb={3}>
                  <Typography variant="h3">
                    <FormattedMessage id="LOGIN_TITLE" />
                  </Typography>
                </Box>
                {loginPageFields.map((inputElement, key) => (
                  <MpLoginInputWrapperStyled key={`Mp-Login-Details-Page-${key}`}>
                    <MpInput
                      {...inputElement}
                      enablePasswordStrengthChecker={false}
                      onChange={handleChange}
                      // @ts-ignore
                      onBlur={handleBlur}
                      value={values[inputElement.name]}
                      errorMessage={
                        errors[inputElement.name] &&
                        touched[inputElement.name] &&
                        errors[inputElement.name]
                      }
                    />
                  </MpLoginInputWrapperStyled>
                ))}
                <MpButton
                  type={PRIMARY}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  isFullWidth={true}
                >
                  <FormattedMessage id="LOGIN" />
                </MpButton>
                {showRegistrationButton && (
                  <MpLoginSecondaryButtonStyled>
                    <MpButton
                      type={GHOST}
                      onClick={() => handleSignUpModalState(true)}
                      disabled={isSubmitting}
                      isFullWidth={true}
                    >
                      <FormattedMessage id="CREATE_AN_ACCOUNT" />
                    </MpButton>
                  </MpLoginSecondaryButtonStyled>
                )}
              </form>
            )}
          </Formik>
        )}
      </MpLoginWrapperStyled>

      {/* Sign up modal */}
      <MpModal
        title={state.openEmailVerificationModal ? '' : 'MODAL_TITLES.CREATE_YOUR_ACCOUNT'}
        modalOpen={state.openSignUpModal}
        handleClose={() => handleSignUpModalState(false)}
        maxWidth="sm"
      >
        <SignUp handleClose={() => handleSignUpModalState(false)} />
      </MpModal>

      {/* Email verification modal */}
      <MpModal
        title=""
        modalOpen={state.openEmailVerificationModal}
        handleClose={() => setState({ ...state, openEmailVerificationModal: false })}
        maxWidth="sm"
      >
        <EmailVerification email={state.email} />
      </MpModal>
    </>
  );
};
