export const USEFULL_RESOURCE = [
  { label: 'OVERVIEW.RESOURCES_DATA.ADDING_BUYER', redirectionLink: '/buyers' },
  {
    label: 'OVERVIEW.RESOURCES_DATA.ADDING_AN_INVOICE',
    redirectionLink: '/invoices',
  },
  {
    label: 'OVERVIEW.RESOURCES_DATA.MANAGE_YOUR_TEAM',
    redirectionLink: '/settings/team',
  },
  {
    label: 'OVERVIEW.RESOURCES_DATA.API_KEYS',
    redirectionLink: '/settings/api-keys-and-urls',
  },
  {
    label: 'OVERVIEW.RESOURCES_DATA.NOTIFICATIONS',
    redirectionLink: '/settings/notifications',
  },
];
