import React from 'react';

// components
import { DynamicSettlements } from './dynamic-settlements';

// store
import { useAppSelector } from 'store';
import { FeesWithoutDynamicSettlements } from './fees-without-dynamic-settlement';

export const Fees = () => {
  const {
    settings: {
      sellerConfig: { is_dynamic_settlements_enabled },
    },
  } = useAppSelector(state => state);

  return is_dynamic_settlements_enabled ? (
    <DynamicSettlements />
  ) : (
    <FeesWithoutDynamicSettlements />
  );
};
