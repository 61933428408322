export enum BankAccountKeys {
  bank_account_country = 'country',
  currency = 'currency',
  IBAN = 'IBAN',
  BIC = 'BIC',
  bank_code = 'bank_code',
  routing_number = 'routing_number',
  bank_name = 'bank_name',
  account_name = 'account_name',
  account_number = 'account_number',
  sort_code = 'sort_code',
}

export enum LegalRepKeys {
  firstName = 'firstName',
  lastName = 'lastName',
  dob = 'dob',
  gender = 'gender',
}

export enum KycAddressKeys {
  address_line_1 = 'address_line_1',
  postal_code = 'postal_code',
  city = 'city',
  country = 'country',
}

export enum FormKeys {
  registered_name = 'registered_name',
  local_registration_number = 'local_registration_number',
  license_status = 'license_required_for_activities_status',
  license_description = 'license_required_for_activities_status_description_of_activities',
  description_of_activities = 'description_of_activities',
  trade_outside_europe_status = 'trade_with_customers_outside_europe_status',
  trade_outside_europe_countries = 'trade_with_customers_outside_europe_countries',
  jurisdiction_of_incorporation = 'jurisdiction_of_incorporation',
}

export enum KYC_STEPPER_ENUM {
  COMPANY_DETAILS = 'COMPANY DETAILS',
  SHAREHOLDERS = 'SHAREHOLDERS',
  LEGAL_REP = 'LEGAL REP',
  UBO = 'UBO',
  DOCUMENTS = 'DOCUMENTS',
  OWNER_DETAILS = 'OWNERSHIP',
}

export enum KYC_FLOW_TYPE_ENUM {
  SOLE_PROPRIETORSHIP = 'SOLE PROPRIETORSHIP',
  PRIVATE_COMPANY = 'PRIVATE COMPANY',
  PUBLIC_LIMITED_COMPANY = 'PUBLIC LIMITED COMPANY',
  LIMITED_PARTNERSHIP = 'LIMITED PARTNERSHIP',
  GENERAL_PARTNERSHIP = 'GENERAL PARTNERSHIP',
  FOUNDATION = 'Foundation',
  ASSOCIATION = 'Association',
  OTHER = 'Other',
}

export enum LegalRepIdKeys {
  document = 'identification_document',
  number = 'id_number',
  issued_date = 'issued_date',
  placeOfIssue = 'place_of_issue',
  copyOfId = 'copy_of_id',
  securityNumber = 'security_number',
}

export type file = {
  path: string;
  size: number;
  type: string;
  name: string;
  file_id?: string;
};

export type LegalRepGovtIdType = {
  [LegalRepIdKeys.document]: string;
  [LegalRepIdKeys.number]: string;
  [LegalRepIdKeys.issued_date]: string;
  [LegalRepIdKeys.placeOfIssue]: string;
  [LegalRepIdKeys.copyOfId]: file;
  [LegalRepIdKeys.securityNumber]: string;
};

export type LegalRepType = {
  id: string | number;
  firstName: string;
  lastName: string;
  dob: string | undefined;
  gender: string;
};

export type BankAccountType = {
  id: number;
  [BankAccountKeys.bank_account_country]: string;
  [BankAccountKeys.currency]: string;
  [BankAccountKeys.IBAN]: string;
  [BankAccountKeys.BIC]: string;
  [BankAccountKeys.bank_code]: string;
  [BankAccountKeys.routing_number]: string;
  [BankAccountKeys.bank_name]: string;
  [BankAccountKeys.account_name]: string;
  [BankAccountKeys.account_number]: string;
  [BankAccountKeys.sort_code]: string;
};

export type KycAddressType = {
  [KycAddressKeys.address_line_1]: string;
  [KycAddressKeys.postal_code]: string;
  [KycAddressKeys.city]: string;
  [KycAddressKeys.country]: string;
};

export enum ShareholderKeys {
  name = 'name',
  percent = 'percent',
  comment = 'comment',
}

export type ShareholderType = {
  id: number;
  [ShareholderKeys.name]: string;
  [ShareholderKeys.percent]: number;
  [ShareholderKeys.comment]: string;
};

export enum SHAREHOLDER_TYPE_ENUM {
  FILE = 'FILE',
  MANUAL_ENTRY = 'MANUAL_ENTRY',
}

export enum UBO_KEYS {
  type = 'type',
  firstName = 'first_name',
  lastName = 'last_name',
  dob = 'dob',
  gender = 'gender',
}

export type UBO_TYPE = {
  id: string;
  [UBO_KEYS.firstName]: string;
  [UBO_KEYS.lastName]: string;
  [UBO_KEYS.dob]: string;
  [UBO_KEYS.gender]: string;
  [UBO_KEYS.type]: string;
};

export enum DocumentNameEnum {
  localRegistration = 'local_registration_document',
  bankStatement = 'bank_statement',
  additionalDocument = 'additional_document',
}

export enum STATUS_TYPE {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN PROGRESS',
  UNDER_REVIEW = 'UNDER REVIEW',
  RETURNED_FOR_CORRECTION = 'RETURNED FOR CORRECTION',
  VERIFIED = 'VERIFIED',
}

export type KycReducerType = {
  status: STATUS_TYPE | null;
  type: KYC_FLOW_TYPE_ENUM | undefined;
  currentActiveStep: KYC_STEPPER_ENUM | null;
  correctionSteps: Array<KYC_STEPPER_ENUM>;
  company_details: {
    [FormKeys.registered_name]: string;
    [FormKeys.local_registration_number]: string;
    [FormKeys.license_status]: string; // if true then fill description_of_activities "free text"
    [FormKeys.license_description]: string;
    [FormKeys.description_of_activities]: string;
    [FormKeys.trade_outside_europe_status]: string;
    [FormKeys.trade_outside_europe_countries]: string;
    [FormKeys.jurisdiction_of_incorporation]: string; // if NL then SBI code is mandatory
    address: KycAddressType;
  };
  bank_account: Array<BankAccountType>;
  is_bank_account_details_filled: boolean;
  legal_rep: Array<LegalRepType & KycAddressType & LegalRepGovtIdType>;
  shareholder_type: SHAREHOLDER_TYPE_ENUM.FILE | SHAREHOLDER_TYPE_ENUM.MANUAL_ENTRY | undefined;
  shareholder_file: file;
  shareholders: Array<ShareholderType>;
  ubo: Array<UBO_TYPE>;
  ownership_details: LegalRepType & KycAddressType & LegalRepGovtIdType;
  documents: {
    [DocumentNameEnum.additionalDocument]: file;
    [DocumentNameEnum.bankStatement]: file;
    [DocumentNameEnum.localRegistration]: file;
  };
};
