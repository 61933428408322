import React from 'react';

import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { Box, Card, Grid, Typography, Divider } from '@mui/material';
import { FormattedMessage } from 'UI';
import { CreditNote } from 'store/types/invoices';
import { formatter } from 'utils';

interface Props {
  outstanding_amount: string;
  credit_notes: Array<CreditNote>;
  total_amount: string;
  currency_symbol: string;
  late_payment_fees_amount: string;
}

const DisplayInvoiceLabelValue = ({ label, value }: { label: string; value: string | number }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
    <Typography
      sx={{
        fontSize: '16px',
        color: '#898DC8',
      }}
    >
      <FormattedMessage id={label} />
    </Typography>
    <Typography fontSize="16px" color="#000039">
      {value}
    </Typography>
  </Box>
);
export const InvoiceCalculation = ({
  outstanding_amount,
  credit_notes,
  total_amount,
  currency_symbol,
  late_payment_fees_amount,
}: Props) => {
  let totalCreditNotes = 0;

  totalCreditNotes = credit_notes?.reduce?.((acc, item) => acc + item.amount, 0);

  return (
    <Grid item xs={12}>
      <Card sx={{ p: 3, boxShadow: 'none', pb: 0, borderRadius: 2 }}>
        <Grid container alignItems="center" mb={4}>
          <CalculateOutlinedIcon sx={{ fill: 'url(#with-icon-gradient)', mr: 1 }} />
          <Typography fontSize="20px" fontWeight="700" lineHeight="32px" color="#000039">
            <FormattedMessage id="TXN_DETAILS.INVOICE_CALCULATION_TITLE" />
          </Typography>
        </Grid>
        <Box width="75%">
          <DisplayInvoiceLabelValue
            label="TXN_DETAILS.INVOICE_AMOUNT"
            value={currency_symbol + formatter(+total_amount)}
          />
          <DisplayInvoiceLabelValue
            label="TXN_DETAILS.CREDIT_NOTE_AMOUNT"
            value={'-' + currency_symbol + formatter(+totalCreditNotes)}
          />
          {+late_payment_fees_amount ? (
            <DisplayInvoiceLabelValue
              label="TXN_DETAILS.LATE_PAYMENT_FEE"
              value={currency_symbol + formatter(+late_payment_fees_amount)}
            />
          ) : null}
          <Divider
            sx={{
              borderColor: '#ECEDF8',
              marginBottom: 3,
            }}
          />
          <DisplayInvoiceLabelValue
            label="TXN_DETAILS.OUTSTANDING"
            value={currency_symbol + formatter(+outstanding_amount)}
          />
        </Box>
      </Card>
    </Grid>
  );
};
