// vendor
import React from 'react';
import { useAppSelector } from 'store';
import { format } from 'date-fns';
import { Typography } from '@mui/material';

// components
import { FormattedMessage } from 'UI';
import { AvailableCredit } from './available-credit';
import { TimelineUpdate } from './timeline-update';

// constants
import { DATE_FORMAT } from 'constants/date';
import { grey } from 'theme/colors';
import fontSizes from 'constants/font-sizes';
import spaces from 'constants/spacing';

// utils
import { formatter } from 'utils';
import {
  getBondEquivalentRatingColor,
  getCreditDecisionColor,
  getCreditRiskScoreColor,
  getCreditRiskTextKey,
  getDefaultProbabilityColor,
} from './helpers';

// styles
import {
  BuyerDetailsCreditAssessmentSectionStyled,
  BuyerDetailsCreditAssessmentLabelStyled,
  BuyerDetailsDataStyled,
  BuyerDetailsCreditAssessmentDataRowValueStyled,
  BuyerDataRowStyled,
} from './styled';

type BuyersCompanyDetailBlockProp = {
  label: React.ReactNode;
  data: Array<{
    label: React.ReactNode;
    value: React.ReactNode;
    color?: string;
    alignValue?: 'right' | 'left';
    alignLabel?: 'right' | 'left';
  }>;
  buyerAddedFlow?: boolean;
};

export const BuyersCompanyDetailsBlock = (props: BuyersCompanyDetailBlockProp) => {
  const { data, label, buyerAddedFlow } = props;

  return (
    <BuyerDetailsCreditAssessmentSectionStyled className={buyerAddedFlow ? 'buyer-added-flow' : ''}>
      <BuyerDetailsCreditAssessmentLabelStyled>
        <FormattedMessage id={String(label)} />
      </BuyerDetailsCreditAssessmentLabelStyled>
      <BuyerDetailsDataStyled>
        {data.map((row, index) => (
          <BuyerDataRowStyled className="data-item" key={`BuyerDataRowStyled-${index}`}>
            <Typography
              fontSize={fontSizes.xSmall}
              lineHeight={spaces.space_m}
              color={grey[500]}
              textAlign={row.alignLabel || 'initial'}
            >
              <FormattedMessage id={String(row.label)} />
            </Typography>
            <BuyerDetailsCreditAssessmentDataRowValueStyled
              type={(row.value && row.color) || ''}
              isBlocked={row.value === 'BLOCKED'}
              alignValue={row.alignValue || 'initial'}
            >
              {row.value || '-'}
            </BuyerDetailsCreditAssessmentDataRowValueStyled>
          </BuyerDataRowStyled>
        ))}
      </BuyerDetailsDataStyled>
    </BuyerDetailsCreditAssessmentSectionStyled>
  );
};

export const BuyerCompanyDetails = () => {
  const {
    buyerDetails: { credit_qualification, ...companyDetails },
  } = useAppSelector(state => state.buyer);

  const {
    company_credit_score,
    probability_of_default,
    bond_equivalent_rating,
    credit_decision,
    available_credit_limit,
    credit_limit,
    eligible_payment_terms,
  } = credit_qualification;

  const {
    business_name,
    vat_id,
    registration_number,
    business_established_year,
    payment_term,
    address,
    email,
    status,
    _uuid,
    merchant_buyer_id,
  } = companyDetails || {};

  const { created_at, currency_symbol } = payment_term || {};
  const {
    address_line1,
    address_line2 = '',
    zip_code,
    city,
    country = { name: '' },
  } = address || {};

  const date =
    created_at &&
    new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(new Date(created_at));

  return (
    <div style={{ opacity: status === 'INACTIVE' ? 0.6 : 1 }}>
      <BuyersCompanyDetailsBlock
        label="BUYER.PAYMENT_TERMS"
        data={[
          {
            label: 'BUYER.PAYMENT_TERMS',
            value: eligible_payment_terms?.length
              ? eligible_payment_terms.map((res, index) => {
                  const isLast = eligible_payment_terms.length - 1 === index;
                  return (
                    <>
                      <FormattedMessage
                        key={`eligible-payment-terms-${res}-${index}`}
                        id={`LABELS.TERMS.${res}`}
                      />
                      {isLast ? '' : ', '}
                    </>
                  );
                })
              : null,
          },
          {
            label: 'BUYER.CREATED_ON',
            value: date ? format(new Date(date), DATE_FORMAT) : '-',
          },
          {
            label: 'BUYER.CREDIT_LIMIT',
            value: credit_limit ? currency_symbol + formatter(Number(credit_limit)) : '',
          },
          {
            label: 'BUYER.AVAILABLE_CREDIT',
            value: available_credit_limit
              ? currency_symbol + formatter(Number(available_credit_limit))
              : '',
          },
        ]}
      />
      {_uuid && <AvailableCredit buyerId={_uuid} />}

      <BuyersCompanyDetailsBlock
        label="BUYER.CREDIT_ASSESSMENT_TITLE"
        data={[
          {
            label: 'BUYER.CREDIT_SCORE',
            value: company_credit_score ? (
              <FormattedMessage id={`BUYER.${getCreditRiskTextKey(company_credit_score)}`} />
            ) : (
              '-'
            ),
            color: company_credit_score ? getCreditRiskScoreColor(company_credit_score) : '',
          },
          {
            label: 'BUYER.DEFAULT_PROBABILITY',
            value: probability_of_default ? `${Number(+probability_of_default).toFixed(2)}%` : '-',
            color: probability_of_default
              ? getDefaultProbabilityColor(+probability_of_default)
              : '',
          },
          {
            label: 'BUYER.BOND_EQUIVALENT_RATING',
            value: bond_equivalent_rating,
            color: getBondEquivalentRatingColor(bond_equivalent_rating),
          },
          {
            label: 'BUYER.CREDIT_DECISION',
            value: credit_decision,
            color: getCreditDecisionColor(credit_decision),
          },
        ]}
      />
      <BuyersCompanyDetailsBlock
        label="BUYER.COMPANY_DETAILS_TITLE"
        data={[
          { label: 'BUYER.COMPANY_NAME', value: business_name },
          { label: 'BUYER.EMAIL', value: email },
          { label: 'BUYER.REGISTRATION_NUMBER', value: registration_number },
          { label: 'BUYER.VAT_NO', value: vat_id },
          {
            label: 'BUYER.ADDRESS',
            value: [address_line1, address_line2, city, zip_code].filter(value => value).join(', '),
          },
          { label: 'BUYER.COUNTRY', value: country?.name },
          { label: 'LABELS.MERCHANT_BUYER_ID', value: merchant_buyer_id },
          { label: 'BUYER.ACTIVE_SINCE', value: business_established_year },
        ]}
      />
      <TimelineUpdate />
    </div>
  );
};
