import styled from '@emotion/styled';
import { BaseThemeType } from 'theme/baseTheme';

export const InputWithDropdownWrapperStyled = styled.div<{
  theme?: BaseThemeType;
  showCents?: boolean;
}>`
  display: flex;
  width: 100%;

  .MuiTypography-root,
  div.mp-select__single-value {
    font-weight: 500;
    color: ${props => props.theme.colors.neutral600};
    text-overflow: initial;
    overflow: visible;
  }

  .currency-amount input {
    text-align: ${props => (props.showCents ? 'right' : 'left')};
  }

  div.MuiFormControl-root.MuiTextField-root {
    .MuiInputAdornment-positionStart {
      margin: 0 ${props => props.theme.spaces.space_xxs};
    }

    fieldset {
      border-right: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
  div.mp-dropdown {
    div.mp-select__control {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    div.mp-select__indicator.mp-select__dropdown-indicator,
    div.mp-select__value-container.mp-select__value-container--has-value {
      padding: 6px;
      overflow: visible;
    }
  }
`;
