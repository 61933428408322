import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { RequestQuoteOutlined } from '@mui/icons-material';

// components
import { FormattedMessage, MpTable } from 'UI';

// constants
import { TABLE_USE_CASE_FEES } from 'UI/components/table/constants';
import baseTheme from 'theme/baseTheme';

// store
import { useAppSelector } from 'store';

// styles
import { SettingsPaymentCollectionWrapperStyled } from 'UI/containers/dashboard/settings/payment-collection/styled';
import { FeesStyled, fontStyles } from '../styled';

// utils
import { useGetPrice } from './useGetPrice';

export const SellerFee = () => {
  const {
    settings: {
      sellerFee: { settlement_frequency, ...prices },
    },
  } = useAppSelector(state => state);
  const { tableHeader, tableData } = useGetPrice(prices);

  return (
    <FeesStyled>
      <SettingsPaymentCollectionWrapperStyled>
        <Typography variant="h3" sx={fontStyles.title}>
          <RequestQuoteOutlined sx={{ fill: 'url(#with-icon-gradient)' }} />
          <FormattedMessage id="SETTINGS.FEES.SELLER.TITLE" />
        </Typography>

        <Typography variant="h6" sx={fontStyles.description} mt={3}>
          <FormattedMessage id="SETTINGS.FEES.SELLER.DESCRIPTION" />
        </Typography>

        <Typography
          variant="h6"
          sx={{
            ...fontStyles.value,
            fontFamily: baseTheme.bodyFont,
            whiteSpace: 'pre',
            display: 'inline-block',
          }}
          mt={3}
        >
          <FormattedMessage
            id="SETTINGS.FEES.SELLER.DEFAULT_SETTLEMENT_FREQUENCY"
            context={{ settlement_frequency }}
          />
          <Divider sx={{ borderColor: '#D9DBF2', marginTop: '12px' }} />
        </Typography>

        <Typography variant="h6" sx={fontStyles.description} mt={3}>
          <FormattedMessage id="SETTINGS.FEES.SELLER.HIGHLIGHT_TEXT" />
        </Typography>

        <Box mt={3}>
          <MpTable data={tableData} headerData={tableHeader} useCase={TABLE_USE_CASE_FEES} />
        </Box>
      </SettingsPaymentCollectionWrapperStyled>
    </FeesStyled>
  );
};
