import { AUTH_TRANSACTION } from 'store/types/ordersItem';
import {
  SET_ORDER_ID_FOR_VOID,
  SET_BUYER_ID_FOR_ORDER,
  CLEANUP_ORDER,
  UPDATE_ORDER_STEP_DATA,
  SET_AUTH_TRANSACTIONS,
} from '../types';
import { VOID_FOR } from 'UI/containers/dashboard/orders-table/order-table-actions';

type OrderReducerType = {
  orderIdForVoid?: string;
  voidFor: VOID_FOR.PENDING_ORDER | VOID_FOR.PROCESSED_ORDERS | undefined;
  buyerId?: string;
  orderCurrency?: string;
  orderId: '';
  authTransactions: Array<AUTH_TRANSACTION>;
  orderPopupValues: {
    merchantOrderId: string;
    orderAmount: string;
  };
};

const initialState: OrderReducerType = {
  orderIdForVoid: undefined,
  voidFor: undefined,
  buyerId: '',
  orderCurrency: '',
  orderId: '',
  authTransactions: [],
  orderPopupValues: {
    merchantOrderId: '',
    orderAmount: '',
  },
};

export const order = (state = initialState, action): OrderReducerType => {
  switch (action.type) {
    case SET_ORDER_ID_FOR_VOID: {
      return { ...state, ...action.payload };
    }

    case SET_AUTH_TRANSACTIONS: {
      const { authTransactions, orderCurrency } = action.payload;
      return { ...state, authTransactions, orderCurrency };
    }

    case SET_BUYER_ID_FOR_ORDER: {
      const { buyerId, orderCurrency, orderId, authTransactions } = action.payload;
      return { ...state, buyerId, orderCurrency, orderId, authTransactions };
    }

    case UPDATE_ORDER_STEP_DATA: {
      const { data } = action.payload;
      return { ...state, orderPopupValues: data };
    }

    case CLEANUP_ORDER: {
      return initialState;
    }

    default:
      return { ...state };
  }
};
