// vendors
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Card, Grid, Typography } from '@mui/material';

// assets
import { FactCheck } from '@mui/icons-material';

// components
import { FormattedMessage, MpButton, MpInput, Label } from 'UI';
import { DisplayEmails } from './display-emails';
import { BuyerSpecificNotifications } from './buyer-specific';

// constants
import { EMAIL_REGEX_CASE_INSENSITIVE } from 'constants/regex';
import { EMAIL_SETTINGS } from 'constants/api-endpoints.constants';

// styles
import { fontStyle } from '../index';
import { putData } from 'api';
import { errorMessage, snackbarConfig } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { SET_EMAIL_CONFIG } from 'store/types';

export const updateEmailConfig = (sellerId, values, onSuccess, onFailure) => {
  putData(`${EMAIL_SETTINGS(sellerId)}`, values)
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch(onFailure);
};

export const arrayIncludesItem = (emails: Array<string> = [], emailToCheck) => {
  return emails.find(email => email === emailToCheck);
};

export const EmailsManagement = () => {
  const [newSettlementsEmailError, setNewSettlementsEmailError] = useState<React.ReactNode>('');
  const { enqueueSnackbar } = useSnackbar();
  const [newSettlementEmail, setNewSettlementEmail] = useState<string>('');
  const {
    login: { sellerId },
    settings: { notifications },
  } = useAppSelector(state => state);
  const dispatch = useAppDispatch();

  const { settlements_email: settlementsEmail = [] } = notifications;

  const onUpdateEmailSuccess = (
    data,
    useCase: React.ReactNode = <FormattedMessage id="SETTLEMENTS.SETTLEMENTS" />
  ) => {
    dispatch({ type: SET_EMAIL_CONFIG, payload: data });
    setNewSettlementEmail('');
    enqueueSnackbar(
      <span>
        {useCase} <FormattedMessage id="SETTINGS.NOTIFICATIONS.EMAIL_SETTINGS_UPDATED" />
      </span>,
      snackbarConfig()
    );
  };

  const onUpdateEmailFailure = error => {
    errorMessage(error, enqueueSnackbar);
  };

  const onSubmitSettlements = () => {
    if (!EMAIL_REGEX_CASE_INSENSITIVE.test(newSettlementEmail)) {
      setNewSettlementsEmailError(<FormattedMessage id="VALIDATOR.EMAIL" />);
      return;
    }
    setNewSettlementsEmailError('');

    if (!arrayIncludesItem(settlementsEmail || [], newSettlementEmail)) {
      const emails = [...(settlementsEmail || []), newSettlementEmail];

      updateEmailConfig(
        sellerId,
        {
          ...notifications,
          settlements_email: emails,
        },
        onUpdateEmailSuccess,
        onUpdateEmailFailure
      );
    } else {
      enqueueSnackbar(
        <FormattedMessage id="ERROR_MESSAGES.EMAIL_ALREADY_EXIST" />,
        snackbarConfig({ type: 'error' })
      );
    }
  };

  const onSettlementsEmailChange = event => {
    setNewSettlementEmail(event.target.value);
    setNewSettlementsEmailError('');
  };

  const deleteSettlementEmail = index => {
    const filterEmail = settlementsEmail.filter((_, i) => index !== i);

    updateEmailConfig(
      sellerId,
      {
        ...notifications,
        settlements_email: filterEmail,
      },
      onUpdateEmailSuccess,
      onUpdateEmailFailure
    );
  };

  return (
    <>
      <Typography mb={3} sx={{ ...fontStyle }}>
        <FormattedMessage id="SETTINGS.NOTIFICATIONS.TITLE" />
      </Typography>
      <Box>
        <Card>
          <Box p={4} sx={{ maxWidth: '80%' }}>
            <Typography variant="h3">
              <FactCheck sx={{ fill: 'url(#with-icon-gradient)' }} />
              <FormattedMessage id="SETTLEMENTS.SETTLEMENTS" />
            </Typography>

            <Grid container columnSpacing={2} alignItems="end">
              <Grid item xs={12}>
                <Box pt={3} pb={2}>
                  <Label>SETTINGS.NOTIFICATIONS.SEND_SETTLEMENTS_TO</Label>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box pb={3}>
                  {<DisplayEmails emails={settlementsEmail} deleteEmail={deleteSettlementEmail} />}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box pb={2} width="50%">
                  <MpInput
                    label="PLACEHOLDERS.EMAIL"
                    labelBold
                    placeholder="settlements@company.com"
                    onChange={onSettlementsEmailChange}
                    value={newSettlementEmail}
                    errorMessage={newSettlementsEmailError}
                    name="settlements-email"
                  />
                </Box>
              </Grid>

              <Grid item container xs={12}>
                <Box>
                  <MpButton
                    disabled={Boolean(!newSettlementEmail) || Boolean(newSettlementsEmailError)}
                    onClick={onSubmitSettlements}
                  >
                    <FormattedMessage id="SAVE" />
                  </MpButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
        <BuyerSpecificNotifications
          emailConfig={notifications}
          onUpdateEmailSuccess={onUpdateEmailSuccess}
          onUpdateEmailFailure={onUpdateEmailFailure}
        />
      </Box>
    </>
  );
};
