import * as TYPE from '../types';

type PopupReducerType = {
  addInvoicePopup: boolean;
  addBuyerPopup: boolean;
  addOrderPopup: boolean;
  addBuyerContactTeamPersonPopup: boolean;
  addInvoiceDefaultValues: Record<string, unknown>;
  initialValuesOnBuyersDashboardAddPersonPopup: Record<string, unknown>;
  settlementDate: boolean;
};

const initialState: PopupReducerType = {
  addInvoicePopup: false,
  addOrderPopup: false,
  addBuyerPopup: false,
  addBuyerContactTeamPersonPopup: false,
  addInvoiceDefaultValues: {},
  initialValuesOnBuyersDashboardAddPersonPopup: {},
  settlementDate: false,
};

export const popup = (state = initialState, action): PopupReducerType => {
  switch (action.type) {
    case TYPE.OPEN_ADD_INVOICE_POPUP:
      return {
        ...state,
        addInvoicePopup: true,
      };

    case TYPE.CLOSE_ADD_INVOICE_POPUP:
      return {
        ...state,
        addInvoicePopup: false,
      };

    case TYPE.OPEN_ADD_ORDER_POPUP:
      return {
        ...state,
        addOrderPopup: true,
      };

    case TYPE.CLOSE_ADD_ORDER_POPUP:
      return {
        ...state,
        addOrderPopup: false,
      };

    case TYPE.OPEN_ADD_BUYER_POPUP:
      return { ...state, addBuyerPopup: true };

    case TYPE.CLOSE_ADD_BUYER_POPUP:
      return { ...state, addBuyerPopup: false };

    case TYPE.OPEN_ADD_BUYER_TEAM_PERSON_POPUP:
      return {
        ...state,
        addBuyerContactTeamPersonPopup: true,
        initialValuesOnBuyersDashboardAddPersonPopup: {
          ...action.payload,
          first_name: action.payload?.first_name || '',
          last_name: action.payload?.last_name || '',
          phone: action.payload?.phone || '',
          email: action.payload?.email || '',
        },
      };

    case TYPE.CLOSE_ADD_BUYER_TEAM_PERSON_POPUP:
      return {
        ...state,
        addBuyerContactTeamPersonPopup: false,
        initialValuesOnBuyersDashboardAddPersonPopup: {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          isPrimaryContact: false,
        },
      };

    case TYPE.OPEN_SETTLEMENT_DATE_POPUP: {
      return {
        ...state,
        settlementDate: true,
      };
    }

    case TYPE.CLOSE_SETTLEMENT_DATE_POPUP: {
      return {
        ...state,
        settlementDate: false,
      };
    }

    case TYPE.RESET: {
      return initialState;
    }

    default:
      return { ...state };
  }
};
