// api
import { postData, getData, putData } from 'api';

// constants
import {
  FORGOT_PASSWORD_ENDPOINT,
  VERIFY_RESET_PASSWORD_TOKEN,
  RESEND_EMAIL_VERIFICATION_LINK,
} from 'constants/api-endpoints.constants';

import { LOGGED_OUT, LOGGED_IN } from 'store/types';

// constants
import { EMAIL_VERIFY_LINK } from 'constants/api-endpoints.constants';

export const loginAction = { type: LOGGED_IN };
export const logoutAction = { type: LOGGED_OUT };

export const sendForgotPasswordEmailLink = values => {
  return postData(FORGOT_PASSWORD_ENDPOINT, values);
};

export const verifyPasswordToken = token => {
  return getData(`${VERIFY_RESET_PASSWORD_TOKEN}?token=${token}`);
};

export const putPasswordNewPassword = (token, values) => {
  return putData(`${VERIFY_RESET_PASSWORD_TOKEN}?token=${token}`, values);
};

export const VerifyEmail = token => {
  return getData(`${EMAIL_VERIFY_LINK}?token=${token}`);
};

export const resendEmailVerificationLink = email => {
  return postData(RESEND_EMAIL_VERIFICATION_LINK, {
    email_id: email,
  });
};
