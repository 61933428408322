import React from 'react';
import {
  DashboardOutlined,
  InsertDriveFileOutlined,
  WorkOutlineOutlined,
  CheckOutlined,
  SettingsOutlined,
  ArrowDropDown,
  ListAlt,
  PaymentsOutlined,
} from '@mui/icons-material/';
import { SettingsSidebarMenu } from 'constants/settings-constants';
import { FormattedMessage } from 'i18n/formatted-message';

import {
  OVERVIEW,
  INVOICES,
  BUYERS,
  SETTINGS,
  SETTLEMENTS,
  ORDERS,
  PAYABLES,
  ORDERS_DETAILS_PROCESSED,
} from 'constants/routes.constants';

export const HeaderData = (is_pending_order_enabled, isSellerInActive) => [
  {
    label: <FormattedMessage id="MENU.DASHBOARD" />,
    path: OVERVIEW,
    icon: <DashboardOutlined />,
    route: true,
  },
  {
    label: <FormattedMessage id="MENU.TRANSACTIONS_V2" />,
    path: INVOICES,
    icon: <InsertDriveFileOutlined />,
    route: true,
  },
  {
    label: <FormattedMessage id="MENU.ORDERS" />,
    path: is_pending_order_enabled ? ORDERS_DETAILS_PROCESSED : ORDERS,
    icon: <ListAlt />,
    route: true,
  },
  {
    label: <FormattedMessage id="MENU.BUYERS" />,
    path: BUYERS,
    icon: <WorkOutlineOutlined />,
    route: true,
  },
  {
    label: <FormattedMessage id="MENU.SETTLEMENTS" />,
    path: SETTLEMENTS,
    icon: <CheckOutlined />,
    route: true,
  },
  {
    label: <FormattedMessage id="MENU.PAYABLES" />,
    path: PAYABLES,
    icon: <PaymentsOutlined />,
    route: true,
  },
  {
    label: <FormattedMessage id="MENU.SETTINGS" />,
    path: SETTINGS,
    subMenu: SettingsSidebarMenu(isSellerInActive),
    icon: <SettingsOutlined />,
    rightIcon: <ArrowDropDown sx={{ marginLeft: 'auto' }} />,
    route: false, // Don't route to the page, only show expand the menu
  },
];

export const DASHBOARD_HEADER_DROPDOWN_MENU_LIST = {
  MY_ACCOUNT: 'MY_ACCOUNT',
  LOGOUT: 'LOGOUT',
  SWITCH_ACCOUNT: 'SWITCH_ACCOUNT',
  SWITCH_BACK: 'SWITCH_BACK',
  GOD_MODE: 'GOD_MODE',
};

export const ENV_SWITCH = {
  local: {
    leftLabel: <FormattedMessage id="env.testing" />,
    rightLabel: <FormattedMessage id="env.staging" />,
    isChecked: false,
  },
  testing: {
    leftLabel: <FormattedMessage id="env.testing" />,
    rightLabel: <FormattedMessage id="env.staging" />,
    isChecked: false,
  },
  staging: {
    leftLabel: <FormattedMessage id="env.testing" />,
    rightLabel: <FormattedMessage id="env.staging" />,
    isChecked: true,
  },
  sandbox: {
    leftLabel: <FormattedMessage id="env.sandbox" />,
    rightLabel: <FormattedMessage id="env.production" />,
    isChecked: false,
  },
  prod: {
    leftLabel: <FormattedMessage id="env.sandbox" />,
    rightLabel: <FormattedMessage id="env.production" />,
    isChecked: true,
  },
};
