// vendor
import styled from '@emotion/styled';
import { grey, WHITE } from 'theme/colors';

// utils
import spaces from 'constants/spacing';
import fontSizes from 'constants/font-sizes';

export const ContactsWrapperStyled = styled.div`
  min-width: 700px;
  padding: 32px;
  background: #ffffff;
  border: 1px solid #d9dbf2;
  border-radius: 4px;
  @media only screen and (max-width: 900px) {
    min-width: initial;
    width: 100%;
  }
`;

export const ContactPersonDataStyled = styled.div`
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  background: ${WHITE};
  box-sizing: border-box;
  border-radius: ${spaces.space_xxxs};
  @media only screen and (max-width: 900px) {
    overflow-x: scroll;
  }
`;

export const ContactPersonDataContentLabelStyled = {
  fontSize: fontSizes.xSmall,
  lineHeight: spaces.space_m,
  color: grey[700],
  alignItems: 'center',
  display: 'flex',
  whiteSpace: 'pre',
};

export const ContactPersonAutorisedPayerStyled = styled.div`
  color: ${grey[900]};
  font-weight: 600;
  font-size: ${fontSizes.xSmall};
  line-height: ${spaces.space_m};
  margin-bottom: ${spaces.space_xs};
`;
