export const getCurrencySymbol = (currency: string) => {
  return currency
    ? new Intl.NumberFormat('eu-ES', {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
      })
        .formatToParts(1)
        .find(x => x.type === 'currency')?.value
    : '';
};

export const getCountryName = new Intl.DisplayNames(['en'], { type: 'region' });
