import styled from '@emotion/styled';
import { BaseThemeType } from 'theme/baseTheme';

export const MpSwitchStyled = styled.label<{ theme?: BaseThemeType }>`
  margin: 0;
  position: relative;
  display: inline-block;
  height: 28px;
  width: 52px;
  border-radius: ${props => props.theme.spaces.space_100};

  input:checked + .slider:before {
    transform: translateX(${props => props.theme.spaces.space_m});
  }
`;

export const MpSwitchSpanStyled = styled.span<{
  theme?: BaseThemeType;
  checked?: boolean;
  disabled?: boolean;
}>`
  position: absolute;
  cursor: ${props => !props.disabled && 'pointer'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props =>
    props.disabled
      ? 'lightgrey'
      : props.checked
      ? props.theme.colors.primary200
      : props.theme.colors.neutral300};
  transition: 0.4s;
  border-radius: ${props => props.theme.spaces.space_100};

  :before {
    border-radius: 50%;
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: ${props => props.theme.spaces.space_3};
    top: calc(50% - ${props => props.theme.spaces.space_22} / 2);
    background: ${props => props.theme.colors.lightBackground};
    transition: 0.4s;
  }
`;

export const MpSwitchInputStyled = styled.input<{ theme?: BaseThemeType }>`
  opacity: 0;
  width: 0;
  height: 0;
  background-color: ${props => props.checked && props.theme.colors.primary200};
`;
