import React from 'react';
import { Grid, Box } from '@mui/material';

import { FilterMenu } from 'UI/components/table/filter-menu';
import { FormattedMessage } from 'i18n/formatted-message';
import { ORDERS_FILTER_STATUS } from './table-filter-options';
import {
  ORDERS_DETAILS_PENDING,
  ORDERS_DETAILS_PROCESSED,
  BUYER_PROCESSED_ORDER_SUB_ROUTE,
  BUYER_PENDING_ORDER_SUB_ROUTE,
} from './routes.constants';

export const INVOICE_TABLE_HEADER = [
  {
    value: 'merchant_invoice_id',
    label: <FormattedMessage id="TRANSACTIONS.INVOICE_ID" />,
    sort: false,
    sortBy: '_uuid',
    minWidth: '150px',
  },
  {
    value: 'merchant_order_id',
    label: <FormattedMessage id="TRANSACTIONS.ORDER_ID" />,
    sort: false,
    sortBy: 'merchant_order_id',
    minWidth: '150px',
  },
  {
    value: 'Buyers',
    label: <FormattedMessage id="TRANSACTIONS.BUYER" />,
    sort: false,
    sortBy: 'buyer_id',
  },
  {
    value: 'Value',
    label: <FormattedMessage id="TXN_DETAILS.OPEN_AMOUNT" />,
    sort: false,
    align: 'left',
    minWidth: '130px',
  },
  {
    value: 'merchantStatus',
    label: (
      <Box display="flex" alignItems="center" gap={2} data-sdet="merchant-status">
        <FormattedMessage id="TRANSACTIONS.MERCHANT_STATUS" />
        <FilterMenu filterByField="transaction_status" />
      </Box>
    ),
    sort: false,
    sortBy: 'status',
    minWidth: '190px',
  },
  {
    value: 'buyerStatus',
    label: (
      <Box display="flex" alignItems="center" gap={2}>
        <FormattedMessage id="TRANSACTIONS.BUYER_STATUS" />
        <FilterMenu filterByField="invoice_status" />
      </Box>
    ),
    sort: false,
    sortBy: 'invoice__status',
    minWidth: '180px',
  },
  {
    value: 'Settlement Date',
    label: (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <FormattedMessage id="LABELS.SETTLEMENT_DATE" />
        </Grid>
      </Grid>
    ),
    sort: true,
    sortBy: 'settlement_date',
    minWidth: '180px',
  },
  {
    value: 'Due Date',
    label: (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <FormattedMessage id="TXN_DETAILS.DUE_DATE" />
        </Grid>
      </Grid>
    ),
    sort: true,
    sortBy: 'due_date',
    minWidth: '180px',
  },
];

export const INVOICES_PER_BUYER_HEADERS = INVOICE_TABLE_HEADER.filter(
  ({ value }) => value !== 'Buyers'
);

export const INVOICE_TABLE_HEADER_KEYS = {
  'Invoice#': 'Invoice #',
  'Transaction': 'Transactions',
  'MerchantId': 'MerchantId',
  'Buyer': 'Buyers',
  'Value': 'Value',
  'DueDate': 'Due Date',
  'SettlementDate': 'Settlement Date',
  'Term': 'Term',
  'Insured': 'Insured',
  'Type': 'Type',
};

export const ORDERS_TABLE_HEADER = [
  {
    value: 'merchant_order_id',
    label: <FormattedMessage id="ORDERS.TABLE_HEADER.MERCHANT_ORDER_ID" />,
    sortBy: 'merchant_order_id',
    minWidth: '100px',
  },
  {
    value: 'buyerName',
    label: <FormattedMessage id="ORDERS.TABLE_HEADER.BUYER" />,
  },
  {
    value: 'order_amount',
    label: <FormattedMessage id="ORDERS.TABLE_HEADER.AUTH_AMOUNT" />,
    align: 'left',
    minWidth: '130px',
  },
  {
    value: 'created_at',
    label: <FormattedMessage id="ORDERS.TABLE_HEADER.DATE" />,
    minWidth: '170px',
  },
  {
    value: 'order_status',
    label: (
      <Box display="flex" alignItems="center" gap={2}>
        <FormattedMessage id="ORDERS.TABLE_HEADER.STATUS" />
        <FilterMenu filterByField={ORDERS_FILTER_STATUS} />
      </Box>
    ),
    minWidth: '200px',
  },
  {
    value: 'actions',
    label: <FormattedMessage id="ORDERS.TABLE_HEADER.ACTIONS" />,
  },
];

export const BUYER_ORDERS_TABLE_HEADER = ORDERS_TABLE_HEADER.filter(
  ({ value }) => value !== 'buyerName'
);

export const MARK_INVOICE_AS_PAID_HEADER = [
  {
    value: 'merchant_invoice_id',
    label: <FormattedMessage id="TRANSACTIONS.INVOICE_ID" />,
    sort: false,
    minWidth: '150px',
  },
  {
    value: 'merchant_order_id',
    label: <FormattedMessage id="TRANSACTIONS.ORDER_ID" />,
    sort: false,
    minWidth: '150px',
  },
  {
    value: 'Buyers',
    label: <FormattedMessage id="TRANSACTIONS.BUYER" />,
    sort: false,
  },
  {
    value: 'Value',
    label: <FormattedMessage id="TXN_DETAILS.OPEN_AMOUNT" />,
    sort: false,
    align: 'left',
    minWidth: '130px',
  },
  {
    value: 'merchantStatus',
    label: <FormattedMessage id="TRANSACTIONS.MERCHANT_STATUS" />,
    sort: false,
    minWidth: '190px',
  },
  {
    value: 'buyerStatus',
    label: <FormattedMessage id="TRANSACTIONS.BUYER_STATUS" />,
    sort: false,
    minWidth: '180px',
  },
  {
    value: 'Due Date',
    label: (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <FormattedMessage id="TXN_DETAILS.DUE_DATE" />
        </Grid>
      </Grid>
    ),
    sort: false,
    minWidth: '180px',
  },
];

export const PAYABLES_TABLE_HEADER_KEYS = {
  BusinessName: 'Business name',
  InvoiceId: 'Invoice id',
  InvoiceDate: 'Invoice date',
  OpenAmount: 'Open amount',
  DueDate: 'Due date',
  Status: 'Status',
  FILE_URL: 'File url',
};

export const PAYABLES_TABLE_HEADER = [
  {
    value: PAYABLES_TABLE_HEADER_KEYS.BusinessName,
    label: <FormattedMessage id="PAYABLES_TABLE.BUSINESS_NAME" />,
    sort: true,
    sortBy: 'seller_name',
    minWidth: '150px',
  },
  {
    value: PAYABLES_TABLE_HEADER_KEYS.InvoiceId,
    label: <FormattedMessage id="PAYABLES_TABLE.INVOICE_ID" />,
    minWidth: '150px',
  },
  {
    value: PAYABLES_TABLE_HEADER_KEYS.InvoiceDate,
    label: <FormattedMessage id="PAYABLES_TABLE.INVOICE_DATE" />,
    sort: true,
    sortBy: 'invoice_date',
  },
  {
    value: PAYABLES_TABLE_HEADER_KEYS.OpenAmount,
    label: <FormattedMessage id="PAYABLES_TABLE.OPEN_AMOUNT" />,
    align: 'left',
    minWidth: '130px',
  },
  {
    value: PAYABLES_TABLE_HEADER_KEYS.DueDate,
    label: <FormattedMessage id="PAYABLES_TABLE.DUE_DATE" />,
    sort: true,
    sortBy: 'due_date',
    minWidth: '190px',
  },
  {
    value: PAYABLES_TABLE_HEADER_KEYS.Status,
    label: (
      <Box display="flex" alignItems="center" gap={2}>
        <FormattedMessage id="PAYABLES_TABLE.INVOICE_STATUS" />
        <FilterMenu filterByField="invoice_status" />
      </Box>
    ),
    sort: false,
    sortBy: 'invoice__status',
    minWidth: '180px',
  },
  {
    value: PAYABLES_TABLE_HEADER_KEYS.FILE_URL,
    label: '',
    minWidth: '20px',
  },
];

export const PENDING_ORDERS_TABLE_HEADER = [
  {
    value: 'merchant_order_id',
    label: <FormattedMessage id="ORDERS.TABLE_HEADER.MERCHANT_ORDER_ID" />,
    sortBy: 'merchant_order_id',
    sort: true,
    minWidth: '100px',
  },
  {
    value: 'buyerName',
    label: <FormattedMessage id="ORDERS.TABLE_HEADER.BUYER" />,
  },
  {
    value: 'auth_amount',
    label: <FormattedMessage id="ORDERS.TABLE_HEADER.AUTH_AMOUNT" />,
    align: 'left',
    sortBy: 'auth_amount',
    sort: true,
  },
  {
    value: 'date',
    label: <FormattedMessage id="ORDERS.TABLE_HEADER.DATE" />,
    sortBy: 'date',
    sort: true,
  },
  {
    value: 'payment_terms',
    label: <FormattedMessage id="ORDERS.TABLE_HEADER.NET_TERMS" />,
    sortBy: 'payment_terms',
    sort: true,
  },
  {
    value: 'status',
    label: <FormattedMessage id="ORDERS.TABLE_HEADER.STATUS" />,
  },
  {
    value: 'actions',
    label: <FormattedMessage id="ORDERS.TABLE_HEADER.ACTIONS" />,
  },
];

export const BUYER_PENDING_ORDERS_TABLE_HEADER = PENDING_ORDERS_TABLE_HEADER.filter(
  ({ value }) => value !== 'buyerName'
);

export const ORDER_SUB_MENU = buyerId => [
  {
    label: <FormattedMessage id="ORDERS.PROCESSED" />,
    path: buyerId
      ? `/buyers/${buyerId}/buyer-order/${BUYER_PROCESSED_ORDER_SUB_ROUTE}`
      : ORDERS_DETAILS_PROCESSED,
    dataSdet: 'PROCESSED',
  },
  {
    label: <FormattedMessage id="ORDERS.PENDING" />,
    path: buyerId
      ? `/buyers/${buyerId}/buyer-order/${BUYER_PENDING_ORDER_SUB_ROUTE}`
      : ORDERS_DETAILS_PENDING,
    dataSdet: 'PENDING',
  },
];

export const AUTHORIZED = 'AUTHORIZED';
