import React from 'react';

// components
import { MpModal, CreateForm, FormattedMessage } from 'UI';

import {
  buyersDashboardAddPersonFormFields,
  valuesToValidateOnBuyersDashboardAddPersonPopup,
} from 'constants/form-data';
interface AddPersonProps {
  openModal: boolean;
  initialValues: object;
  closeModal: () => void;
  onSubmit: (values) => void;
}

export const AddContact = ({ openModal, initialValues, closeModal, onSubmit }: AddPersonProps) => {
  return (
    <MpModal
      title="MODAL_TITLES.ADD_CONTACT"
      modalOpen={openModal}
      handleClose={closeModal}
      maxWidth="sm"
    >
      <CreateForm
        secondaryButtonConfig={{
          label: <FormattedMessage id="CANCEL" />,
          handleCancel: closeModal,
        }}
        primaryButtonConfig={{ label: <FormattedMessage id="SAVE" />, handleSubmit: onSubmit }}
        formFields={buyersDashboardAddPersonFormFields}
        valuestoValidate={valuesToValidateOnBuyersDashboardAddPersonPopup}
        initialValue={initialValues}
      />
    </MpModal>
  );
};
