export const transformUserDetails = values => {
  return {
    first_name: values.first_name,
    business: {
      business_name: values.business_name || values.business,
      registration_number: values.registration_number,
      status: values.business_status,
      vat_id: values.vat_id,
      registration_date: null,
      address: {
        address_line1: values.address_line1,
        address_line2: values.address_line2,
        zip_code: values.zip_code,
        city: values.city,
        country: {
          code: values.country,
        },
      },
    },
    last_name: values.last_name,
    email: values.email,
    password: values.password,
    confirm_password: values.confirm_password,
    language: values.language || values.preferred_language,
  };
};

export const transformCompanyDetails = (country, selectedCompany, rest) => {
  return {
    ...rest,
    ...selectedCompany,
    ...selectedCompany.address,
    business: selectedCompany.business_name,
    address: `${selectedCompany.address.address_line1}, ${
      selectedCompany.address.address_line2 ? `${selectedCompany.address.address_line2}, ` : ''
    }${selectedCompany.address.city}, ${selectedCompany.address.zip_code}`,
    country,
  };
};
