// vendor
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';

// components
import { MpMessage, Label } from 'UI';

// constants
import { ERROR } from 'constants/message-types';

// styles
import { MpDropdownSelectStyled, DropdownErrorMessageStyled } from './mp-dropdown-select.styled';

type Props = {
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  name?: string;
  /** Option to be displayed */
  options: Array<{
    value: string;
    label: string;
  }>;
  onChange: (option?: any) => any;
  /** Label for select */
  label?: React.ReactNode;
  /** Default Value */
  defaultValue?:
    | string
    | {
        value: string;
        label: string;
      }
    | null;
  /** Error Message*/
  errorMessage?: string;
  /** isRequired */
  isRequired?: boolean;
  /** Translator Function */
  t: (d: any) => string;
  /** is Multi select */
  isMulti?: boolean;
  /** Placeholder */
  placeholder?: string;
};

class MpDropdownSelect extends Component<Props> {
  state = {
    defaultValue: undefined,
    options: [],
  };

  static getDerivedStateFromProps(nextProps, state) {
    const { defaultValue, t } = nextProps;
    let options = nextProps.options || [];

    if (options.length > 0) {
      options = options
        .filter(item => item && item.label && item.value)
        .map(item => {
          if (item.isLabelReactNode) {
            return {
              ...item,
              label: item?.label || '',
            };
          }
          return {
            ...item,
            label: t(item?.label || ''),
          };
        });
    }

    if (defaultValue && typeof defaultValue !== 'object') {
      const value = options.find(option => {
        return option.value === defaultValue;
      });
      return { ...state, defaultValue: value, options };
    }

    if (typeof defaultValue === 'object' && !(defaultValue instanceof Array)) {
      const { label, value } = defaultValue || {};
      return { ...state, defaultValue: { label, value }, options };
    }

    return { ...state, defaultValue, options };
  }

  render() {
    const {
      isClearable = false,
      isSearchable = false,
      isDisabled = false,
      isLoading = false,
      label,
      onChange,
      name,
      errorMessage,
      isRequired,
      isMulti = false,
      placeholder,
    } = this.props;

    const customStyles = {
      control: base => {
        return {
          ...base,
          'border': 0,
          // This line disable the blue border
          'boxShadow': 'none',
          '&:hover': {
            borderColor: 'none',
          },
        };
      },
    };

    return (
      <Fragment>
        {label && (
          <Label htmlFor="dropdown" isRequired={isRequired}>
            {label}
          </Label>
        )}
        <div data-sdet={name}>
          <MpDropdownSelectStyled
            placeholder={this.props.t(placeholder || 'PLACEHOLDERS.SELECT')}
            className="mp-dropdown"
            classNamePrefix="true"
            value={this.state.defaultValue}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            name={`dropdown-select ${name}`}
            options={this.state.options}
            menuShouldScrollIntoView={true}
            onChange={onChange}
            styles={customStyles}
            isMulti={isMulti}
          />
        </div>
        {errorMessage && (
          <DropdownErrorMessageStyled>
            <MpMessage type={ERROR} message={errorMessage} />
          </DropdownErrorMessageStyled>
        )}
      </Fragment>
    );
  }
}

export default withTranslation('translations')(MpDropdownSelect);
