import React, { useState } from 'react';
import { Box } from '@mui/material';
import { FormattedMessage, MpButton, MpDropdownSearchInput } from 'UI';
import { debounce } from 'lodash';
import { updateSingleBuyer } from 'store/actions/buyerActions';

// constants
import { GHOST } from 'constants/button-types';
import { PaymentTerms } from 'constants/enum.constants';

// helpers
import { BuyerInfoBox, NextButtonComponent, fetchBuyers, getRejectedBuyerTitle } from './helpers';
import { useAppSelector, useAppDispatch } from 'store';

import { useSnackbar } from 'notistack';

import { onBuyerCompanySelect, setFilteredBuyers } from 'store/actions/invoices';
import { errorMessage, formatAddress } from 'utils';
import { stopLoading } from 'store/actions';
import { BuyerStatus } from 'store/types/buyers';

interface SearchCompanyProps {
  closeModal?: () => void;
  changeStepperState: (step: number) => void;
}

export const SearchCompany = ({ closeModal, changeStepperState }: SearchCompanyProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const {
    settings: {
      sellerConfig: {
        is_pay_in_advance_enabled: isPayInAdvanceAllowed = false,
        is_pending_order_enabled,
      },
    },
    invoice: { step, filteredBuyers, selectedCompany },
    login: { sellerId },
  } = useAppSelector(state => state);

  const [showBuyerInfoBox, setShowBuyerInfoBox] = useState(false);
  const [titleForBuyerInfoBox, setTitleForBuyerInfoBox] = useState<React.ReactNode>('');
  const [descriptionForBuyerInfoBox, setDescriptionForBuyerInfoBox] = useState<React.ReactNode>('');
  const [companyButtonDisabledState, setCompanyButtonDisabledState] = useState(true);

  const getBuyerListForAddInvoice = (name = '') => {
    fetchBuyers(name, enqueueSnackbar, sellerId)
      .then(buyers => {
        setCompanyButtonDisabledState(true);
        dispatch(setFilteredBuyers(buyers));
        dispatch(onBuyerCompanySelect(undefined));

        const buyersNotFound = buyers.length === 0;
        setShowBuyerInfoBox(buyersNotFound);

        if (buyersNotFound) {
          setTitleForBuyerInfoBox(<FormattedMessage id="COMPANY.NOT_FOUND" />);
          setDescriptionForBuyerInfoBox(<FormattedMessage id="COMPANY.ADD_FROM_BUYER_PAGE" />);
        }
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };

  const onCancel = () => {
    setShowBuyerInfoBox(false);
    setCompanyButtonDisabledState(true);
    closeModal?.();
  };

  const refreshCompanyListObject = debounce(value => {
    if (value && typeof value === 'string') {
      getBuyerListForAddInvoice(value);
    }
  }, 1000);

  const handleCompanyChange = buyer => {
    if (buyer && Object.keys(buyer).length > 0) {
      dispatch(updateSingleBuyer(buyer));
      const isBuyerInactive = buyer.status !== BuyerStatus.ACTIVE;

      const isPayInAdvance =
        buyer.credit_qualification.payment_terms === PaymentTerms.PAY_IN_ADVANCE &&
        !isPayInAdvanceAllowed;

      const isBuyerQualificationApproved =
        buyer.credit_qualification.credit_decision === 'APPROVED' || isPayInAdvanceAllowed;
      // buyer IS NOT approved for NET terms

      if (
        isBuyerInactive &&
        !is_pending_order_enabled &&
        (!isBuyerQualificationApproved || isPayInAdvance)
      ) {
        setShowBuyerInfoBox(true);
        setTitleForBuyerInfoBox(
          getRejectedBuyerTitle(buyer, isBuyerInactive, isBuyerQualificationApproved)
        );
        setDescriptionForBuyerInfoBox(
          <FormattedMessage id="TRANSACTIONS.CREDIT_DECISION_AND_BUYER_REQUIREMENT" />
        );
      } else {
        // buyer approved for NET terms
        const {
          address_line1 = '',
          address_line2 = '',
          city = '',
          zip_code = '',
          country: { name = '' } = {},
        } = buyer.address || {};

        setShowBuyerInfoBox(true);
        setTitleForBuyerInfoBox(buyer.buyer);
        setDescriptionForBuyerInfoBox(
          formatAddress([address_line1, address_line2, city, zip_code, name], ', ')
        );
        setCompanyButtonDisabledState(false);
        dispatch(onBuyerCompanySelect(buyer));
      }
    } else {
      // company was not selected or reset
      setShowBuyerInfoBox(false);
      setCompanyButtonDisabledState(true);
      dispatch(onBuyerCompanySelect(undefined));
    }
  };
  return (
    <div>
      <div style={{ minHeight: '300px', marginTop: '28px' }}>
        <MpDropdownSearchInput
          label="LABELS.BUSINESS_NAME"
          isRequired
          options={filteredBuyers}
          placeholder="PLACEHOLDERS.SEARCH_COMPANIES"
          name="company"
          defaultValue={selectedCompany}
          handleInputChange={refreshCompanyListObject}
          handleChange={handleCompanyChange}
          hideNoOptionMessage={showBuyerInfoBox}
        />
        {showBuyerInfoBox && (
          <BuyerInfoBox
            title={titleForBuyerInfoBox}
            description={descriptionForBuyerInfoBox as string}
          />
        )}
      </div>

      <Box
        display="flex"
        justifyContent={closeModal ? 'space-between' : 'right'}
        mt={5}
        mb={4}
        textAlign="right"
      >
        {closeModal ? (
          <MpButton type={GHOST} onClick={onCancel}>
            <FormattedMessage id="CANCEL" />
          </MpButton>
        ) : (
          <></>
        )}
        <MpButton
          disabled={companyButtonDisabledState}
          onClick={() => changeStepperState(step + 1)}
        >
          <NextButtonComponent />
        </MpButton>
      </Box>
    </div>
  );
};
