import React from 'react';
import { ArrowForwardRounded } from '@mui/icons-material';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

// components
import { MpButton } from 'UI';
import { FormattedMessage } from 'i18n/formatted-message';
import { NoEmailFound } from './no-email-found';

// styles
import { fontStyles } from './styled';

// store
import { useAppSelector } from 'store';

// api
import { postData } from 'api';

// constants
import { SPRINQUE_INVOICES } from 'constants/api-endpoints.constants';

// utils
import { errorMessage, snackbarConfig } from 'utils';

export const EmailPopup = ({
  invoiceNumber,
  invoiceId,
  handleClose,
}: {
  invoiceNumber: string;
  invoiceId: string;
  handleClose: () => void;
}) => {
  const {
    login: { sellerId },
    settings: {
      notifications: { settlements_email },
    },
  } = useAppSelector(state => state);
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = React.useState(() => {
    if (!settlements_email) return {};
    return settlements_email.reduce((acc, email) => {
      acc[email] = false;
      return acc;
    }, {});
  });

  // Only get the email addresses that are checked
  const getSelectedEmails = () => {
    return (
      Object.entries(state)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, value]) => value)
        .map(([key]) => key)
    );
  };

  const sendEmail = () => {
    postData(`${SPRINQUE_INVOICES(sellerId)}/send`, {
      invoice_id: invoiceId,
      to_emails: getSelectedEmails(),
    })
      .then(() => {
        enqueueSnackbar(
          <FormattedMessage id="SETTINGS.BILLING.EMAIL_POPUP.SUCCESS" />,
          snackbarConfig()
        );
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      });
    handleClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Box mb={4}>
      {Boolean(settlements_email?.length) ? (
        <>
          <Typography mt={4} sx={{ ...fontStyles.title, fontFamily: 'Space Grotesk' }}>
            <FormattedMessage
              id="SETTINGS.BILLING.EMAIL_POPUP.TITLE"
              context={{ invoice_number: invoiceNumber }}
            />
          </Typography>
          <Typography mt={2} sx={fontStyles.description}>
            <FormattedMessage
              id="SETTINGS.BILLING.EMAIL_POPUP.DESCRIPTION"
              context={{ email: settlements_email?.join(' ') }}
            />
          </Typography>
          <Box my={4}>
            <FormControl required component="fieldset" variant="standard">
              <FormGroup>
                {settlements_email?.map((email, index) => {
                  const isLast = index === settlements_email.length - 1;
                  return (
                    <FormControlLabel
                      key={index}
                      sx={{
                        'margin': 0,
                        'marginBottom': isLast ? 0 : '16px',
                        '& .MuiCheckbox-root': {
                          padding: 0,
                        },
                      }}
                      control={
                        <Checkbox
                          checked={state[index]}
                          onChange={handleChange}
                          name={email}
                          sx={{
                            '& svg': {
                              fill: '#000',
                              marginRight: '12px',
                            },
                          }}
                        />
                      }
                      label={email}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </Box>
          <Box textAlign="right">
            <MpButton
              onClick={() => sendEmail()}
              disabled={!Boolean(Object.values(state).some(value => value))}
            >
              <FormattedMessage id="SEND" />
              <ArrowForwardRounded
                sx={{
                  marginLeft: '8px',
                  fontSize: '20px',
                }}
              />
            </MpButton>
          </Box>
        </>
      ) : (
        <NoEmailFound handleClose={handleClose} invoiceNumber={invoiceNumber} />
      )}
    </Box>
  );
};
