// vendor
import React from 'react';

// styles
import { LabelStyled } from './index.styled';

// components
import { FormattedMessage } from 'UI';

type Props = {
  children: string | React.ReactNode;
  isRequired?: boolean;
  htmlFor?: string;
  labelBold?: boolean;
  subTextAlignRightToLabel?: boolean;
  helper?: string | React.ReactNode;
};

function Label(props: Props) {
  const {
    isRequired,
    children,
    htmlFor = '',
    labelBold = false,
    subTextAlignRightToLabel = true,
    helper,
  } = props;
  return (
    <LabelStyled
      isRequired={isRequired}
      htmlFor={htmlFor}
      labelBold={labelBold}
      subTextAlignRightToLabel={subTextAlignRightToLabel}
    >
      {/* @ts-ignore */}
      <FormattedMessage id={children} />
      {helper}
    </LabelStyled>
  );
}

export { Label };
