// vendor
import React from 'react';
import PhoneInput from 'react-phone-input-2';

// styles
import { PhoneInputWrapper } from './index.styled';

// components
import { Label } from 'UI';
import { useTranslation } from 'i18n/formatted-message';

type Props = {
  /** Default value */
  value: string;
  /** handle change value */
  handleChange: (phone: string) => void;
  /** Label for input */
  label?: string;
  /** Flag for show the mandatory asterisk symbol */
  isRequired?: boolean;
  /** Property to make label bold */
  labelBold?: boolean;
  /** Name of the input element*/
  name?: string;
  /** Placeholder for input */
  placeholder?: string;
};

function PhoneNumberInput(props: Props) {
  const { label, isRequired, labelBold } = props;

  return (
    <PhoneInputWrapper>
      {label && (
        <Label htmlFor={props.name} isRequired={isRequired} labelBold={labelBold}>
          {label}
        </Label>
      )}
      <PhoneInput
        {...props}
        placeholder={useTranslation(props.placeholder || '')}
        value={props.value}
        onChange={props.handleChange}
        containerClass="phone-input-container-class"
        inputClass="phone-number-input-class"
        buttonClass="phone-number-dropdown-class"
      />
    </PhoneInputWrapper>
  );
}

export { PhoneNumberInput };
