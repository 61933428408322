import React from 'react';
import { CreditNote, TimelineItem } from 'store/types/invoices';

interface ExtendedTimelineDataType {
  fileUrl: string;
  id: string | React.ReactNode;
}

interface ExtendedTimelineType {
  credit_notes: Array<CreditNote>;
  url: string;
  timeline: Array<TimelineItem>;
}

export const extendTimelineWithFileUrls = ({
  timeline,
  credit_notes,
  url,
}: ExtendedTimelineType) => {
  const reversedCreditNotes = credit_notes?.reverse?.();
  let orderOfCreditNote = 0;
  return timeline.map(item => {
    const data: ExtendedTimelineDataType = { fileUrl: '', id: '' };

    switch (item.event) {
      case 'CREDIT_NOTE': {
        if (item.status === 'OPEN') {
          const credit_note = reversedCreditNotes[orderOfCreditNote];
          data.id = credit_note._uuid;
          data.fileUrl = credit_note.credit_note_file;
          orderOfCreditNote += 1;
        }
        break;
      }
      default: {
        data.fileUrl = url;
        break;
      }
    }
    return {
      ...item,
      ...data,
    };
  });
};
