// vendor
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useAppSelector, useAppDispatch } from 'store';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { convertFingerprintDataToSprinquePayload } from 'b2b-sprinque-tools';

// actions
import {
  getAllInvoicesActionCreator,
  startLoading,
  stopLoading,
  getBuyerBasicProfileDetails,
} from 'store/actions';
import { changeStepAction, cleanupInvoice, updateInvoiceStepData } from 'store/actions/invoices';
import { cleanupOrder } from 'store/actions/orders';
import { fetchOrdersActionCreator, fetchPendingOrdersActionCreator } from 'store/actions/invoice';

// components
import { FormattedMessage, MpModal, CreateForm } from 'UI';
import { InvoiceCaptured } from './step-3-captured';
import { SearchCompany } from './search-company';
import { NextButtonComponent, BackButtonComponent, ModalStepTitle } from './helpers';
import { SettlementCalendar } from './settlement-calendar';

// constants
import { CAPTURE, ORDERS as ADD_ORDERS_API_ENDPOINT } from 'constants/api-endpoints.constants';
import {
  addInvoiceFormField,
  valuesToValidateOnAddInvoiceForm,
  addressDetailsFormFields,
  valuesToValidateOnAddInvoiceShippingForm,
} from 'constants/form-data';
import { AUTH } from 'constants/api-endpoints.constants';
import {
  RESET_SINGLE_BUYER_BASIC_DETAILS,
  CLOSE_ADD_INVOICE_POPUP,
  SET_AUTH_TRANSACTIONS,
  UPDATE_MERCHANT_ORDER_ID,
} from 'store/types';
import { INVOICES, ORDERS } from 'constants/routes.constants';
import { DATE_FORMAT_FOR_API } from 'constants/date';
import { AUTHORIZED } from 'constants/invoice.constants';

// apis and helper functions
import { getData, postData } from 'api';
import { errorMessage, isInvoiceDue } from 'utils';
import { mapUiValuesToAuthPayload, mapUiValuesToCapturePayload } from './adapters';
import { uploadFile } from 'utils/upload-file';

type Props = {
  buyerId: string;
};

let invoiceFile = '';
let additionalFile = '';

export const AddInvoice = ({ buyerId = '' }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const {
    user: { fingerprint },
    settings: {
      currencyList,
      sellerConfig: { is_dynamic_settlements_enabled, is_pending_order_enabled },
    },
    buyer: {
      buyerDetails: {
        _uuid,
        address,
        credit_qualification: { payment_terms = '', eligible_payment_terms },
      },
      issuedBy,
    },
    invoice: {
      step,
      step1Values: { ...invoiceStep1Values },
      step2Values,
    },
    order: {
      orderCurrency,
      // orderId: existingOrderId,
      authTransactions,
    },
    popup: { addInvoicePopup },
    login: { sellerId },
  } = useAppSelector(state => state);

  // locale state

  const [invoiceData, setInvoiceData] = useState({
    invoice_id: '',
    amount: '',
    currency: '',
    due_date: '',
  });
  const step1Values = { ...invoiceStep1Values, merchantInvoiceFile: invoiceFile };
  const isOrdersInvoicePage = pathname.includes(INVOICES) || pathname.includes(ORDERS);

  const changeStepperState = nextStep => {
    dispatch(changeStepAction(nextStep));
  };

  useEffect(() => {
    // on buyers page start from invoice details
    if (addInvoicePopup) {
      changeStepperState(buyerId ? 1 : 0);
      if (buyerId) {
        dispatch(getBuyerBasicProfileDetails(buyerId, sellerId));
      }
    }
  }, [buyerId, addInvoicePopup]);

  useEffect(() => {
    if (address.address_line1) {
      dispatch(updateInvoiceStepData(2, { ...address, country: address.country.code }));
    }
  }, [address.address_line1]);

  const closeModal = () => {
    dispatch(cleanupInvoice());
    dispatch(cleanupOrder());
    dispatch({ type: CLOSE_ADD_INVOICE_POPUP });
  };

  const captureCall = (orderId, data) => {
    postData(CAPTURE(sellerId, orderId), data)
      .then(res => {
        // reset buyer info from the invoices page
        if (!buyerId || isOrdersInvoicePage) {
          dispatch({ type: RESET_SINGLE_BUYER_BASIC_DETAILS });
        }
        changeStepperState(step + 1);
        setInvoiceData(res.data.invoice);
        dispatch(getAllInvoicesActionCreator(isOrdersInvoicePage ? '' : buyerId));
        dispatch(fetchOrdersActionCreator(isOrdersInvoicePage ? '' : buyerId));
        if (is_pending_order_enabled) {
          dispatch(fetchPendingOrdersActionCreator(isOrdersInvoicePage ? '' : buyerId));
        }
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };

  const authCall = (buyerUuid, authData) => {
    return postData(AUTH(sellerId, buyerUuid), authData);
  };

  const onFinalSubmit = async values => {
    const metadata = fingerprint
      ? { merchant: convertFingerprintDataToSprinquePayload(fingerprint) }
      : {};
    const currencyLabel = orderCurrency || currencyList?.[0].label;
    const combinedValues = { ...step1Values, ...values, issuedBy, metadata };
    const authData = mapUiValuesToAuthPayload(combinedValues, currencyLabel);
    dispatch(startLoading);
    try {
      // Upload the files
      const fileUploadPromises = [
        combinedValues.merchantInvoiceFile || invoiceFile,
        additionalFile,
      ].map((file, index) => {
        if (file) {
          return uploadFile(file, index);
        }
        return null;
      });

      // Wait for all the files to be uploaded
      const uploadedFiles = await Promise.all(fileUploadPromises);

      // Create an array of file locations
      const uploadedFileLocations: Array<string> = [];

      for (const uploadedFile of uploadedFiles) {
        if (uploadedFile) {
          const { index, fileLocation } = uploadedFile;
          uploadedFileLocations[index] = fileLocation;
        }
      }

      const invoiceDue = isInvoiceDue(combinedValues.merchantInvoiceDate, combinedValues.netTerms);

      const settlement_date = !invoiceDue
        ? is_dynamic_settlements_enabled
          ? format(new Date(invoiceStep1Values.settlementDate), DATE_FORMAT_FOR_API)
          : null
        : null;

      // Send the array of file locations to another API
      const captData = mapUiValuesToCapturePayload(
        { ...combinedValues, settlement_date },
        uploadedFileLocations[0],
        uploadedFileLocations[1],
        currencyLabel
      );

      if (step1Values.transaction_id) {
        captureCall(step1Values.transaction_id, captData);
      } else {
        authCall(_uuid, authData)
          .then(
            ({
              data: {
                // order_id = '', to be corrected when backend API is fixed
                transaction_id = '',
              },
            }) => {
              captureCall(transaction_id, captData);
            }
          )
          .catch(err => {
            dispatch(stopLoading);
            errorMessage(err, enqueueSnackbar);
          });
      }
    } catch (error) {
      dispatch(stopLoading);
      errorMessage(error, enqueueSnackbar);
    }
  };

  const submitInvoiceDetails = values => {
    const { merchantInvoiceFile, additionalFileUrl, ...rst } = values;
    invoiceFile = merchantInvoiceFile;
    additionalFile = additionalFileUrl;
    changeStepperState(step + 1);
    dispatch(updateInvoiceStepData(1, { ...rst }));
  };

  const submitShippingAddress = values => {
    dispatch(updateInvoiceStepData(2, { ...values }));
    onFinalSubmit(values);
  };

  const getOrderDetails = async e => {
    if (!e.target.value) {
      dispatch({ type: UPDATE_MERCHANT_ORDER_ID, payload: { merchantOrderId: '' } });
      dispatch({
        type: SET_AUTH_TRANSACTIONS,
        payload: { authTransactions: [], orderCurrency: '' },
      });
      return;
    }

    dispatch(startLoading);
    try {
      dispatch({ type: UPDATE_MERCHANT_ORDER_ID, payload: { merchantOrderId: e.target.value } });
      const queryParams = `?merchant_order_id=${e.target.value}&buyer_id=${buyerId || _uuid}`;
      const res = await getData(ADD_ORDERS_API_ENDPOINT(sellerId) + queryParams);

      const authTransactions = res.data.data[0].transactions.filter(
        transaction => transaction.status === AUTHORIZED
      );
      dispatch({
        type: SET_AUTH_TRANSACTIONS,
        payload: { authTransactions, orderCurrency: authTransactions?.[0]?.currency_code },
      });
    } catch {
      dispatch({
        type: SET_AUTH_TRANSACTIONS,
        payload: { authTransactions: [], orderCurrency: '' },
      });
    } finally {
      dispatch(stopLoading);
    }
  };

  const MerchantForm = () => {
    return (
      <CreateForm
        secondaryButtonConfig={{
          label: buyerId ? <FormattedMessage id="CANCEL" /> : <BackButtonComponent />,
          handleCancel: () => (buyerId ? closeModal() : changeStepperState(step - 1)),
        }}
        primaryButtonConfig={{
          hidePrimaryButtonDefaultTickIcon: true,
          label: <NextButtonComponent />,
          handleSubmit: submitInvoiceDetails,
        }}
        formFields={addInvoiceFormField(
          eligible_payment_terms || [],
          step1Values.disableFields,
          orderCurrency,
          authTransactions,
          getOrderDetails
        )}
        valuestoValidate={{
          ...valuesToValidateOnAddInvoiceForm,
          transaction_id: authTransactions.length > 1 ? 'transaction_id' : '',
        }}
        initialValue={{
          ...step1Values,
          netTerms: invoiceStep1Values.netTerms || payment_terms,
          transaction_id: authTransactions.length > 1 ? '' : authTransactions?.[0]?._uuid,
        }}
      />
    );
  };

  const AddressComponent = () => {
    return (
      <CreateForm
        secondaryButtonConfig={{
          label: <BackButtonComponent />,
          handleCancel: () => changeStepperState(step - 1),
        }}
        primaryButtonConfig={{
          hidePrimaryButtonDefaultTickIcon: true,
          label: <NextButtonComponent />,
          handleSubmit: submitShippingAddress,
        }}
        formFields={addressDetailsFormFields}
        valuestoValidate={valuesToValidateOnAddInvoiceShippingForm}
        initialValue={{
          ...step2Values,
        }}
      />
    );
  };

  return (
    <MpModal title="" modalOpen={addInvoicePopup} handleClose={closeModal} maxWidth="sm">
      {is_dynamic_settlements_enabled ? (
        <>
          {/* Search business */}
          {step === 0 && (
            <>
              <ModalStepTitle title="ADD_TRANSACTION_POPUP_STEP_INITIAL_TITLE" step="1/4" />
              <SearchCompany changeStepperState={changeStepperState} />
            </>
          )}

          {/* Invoice info */}
          {step === 1 && (
            <>
              <ModalStepTitle title="ADD_TRANSACTION_STEP_1_TITLE" step={buyerId ? '1/3' : '2/4'} />
              <MerchantForm />
            </>
          )}
          {/* Settlement calendar */}
          {step === 2 && (
            <>
              <ModalStepTitle title="ADD_TRANSACTION_STEP_2_TITLE" step={buyerId ? '2/3' : '3/4'} />
              <SettlementCalendar onClick={changeStepperState} />
            </>
          )}

          {/* Shipping to another address */}
          {step === 3 && (
            <>
              <ModalStepTitle title="ADD_TRANSACTION_STEP_3_TITLE" step={buyerId ? '3/3' : '4/4'} />
              <AddressComponent />
            </>
          )}

          {/* Success screen */}
          {step === 4 && <InvoiceCaptured {...invoiceData} />}
        </>
      ) : (
        <>
          {/* Search business */}
          {step === 0 && (
            <>
              <ModalStepTitle title="ADD_TRANSACTION_POPUP_STEP_INITIAL_TITLE" step="1/3" />
              <SearchCompany changeStepperState={changeStepperState} />
            </>
          )}

          {/* Invoice info */}
          {step === 1 && (
            <>
              <ModalStepTitle title="ADD_TRANSACTION_STEP_1_TITLE" step={buyerId ? '1/2' : '2/3'} />
              <MerchantForm />
            </>
          )}

          {/* Shipping to another address */}
          {step === 2 && (
            <>
              <ModalStepTitle title="ADD_TRANSACTION_STEP_3_TITLE" step={buyerId ? '2/2' : '3/3'} />
              <AddressComponent />
            </>
          )}
          {/* Success screen */}
          {step === 3 && <InvoiceCaptured {...invoiceData} />}
        </>
      )}
    </MpModal>
  );
};
