import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { Box, Typography } from '@mui/material';

import { setOrderIdForVoid } from 'store/actions/orders';
import { postData } from 'api';
import { ORDER, VOID } from 'constants/api-endpoints.constants';
import { GHOST } from 'constants/button-types';
import { errorMessage, snackbarConfig } from 'utils';
import { useSnackbar } from 'notistack';
import { FormattedMessage, MpButton, MpModal } from 'UI';
import { fetchOrdersActionCreator, fetchPendingOrdersActionCreator } from 'store/actions/invoice';

const PENDING_ORDER = 'PENDING_ORDER';
export const ConfirmOrderVoid = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    order: { orderIdForVoid, voidFor },
    login: { sellerId },
    transaction: { orders, pending_orders },
    buyer: {
      buyerDetails: { _uuid: buyerId = '' },
    },
  } = useAppSelector(state => state);

  const [open, setOpen] = React.useState(false);
  const currentOrder = (voidFor === PENDING_ORDER ? pending_orders : orders).find(
    ({ order_id }) => order_id === orderIdForVoid
  );

  const handleClose = () => {
    dispatch(setOrderIdForVoid({ orderIdForVoid: '', voidFor: '' }));
  };

  const handleVoid = () => {
    postData(
      voidFor === PENDING_ORDER
        ? // @ts-ignore
          VOID(sellerId, currentOrder?.transaction_id)
        : `${ORDER(sellerId)}void/${orderIdForVoid}/`,
      null
    )
      .then(() => {
        enqueueSnackbar(
          <FormattedMessage id="ORDERS.ORDER_VOIDED" />,
          snackbarConfig({ type: 'success' })
        );
        voidFor === PENDING_ORDER
          ? dispatch(fetchPendingOrdersActionCreator(buyerId))
          : dispatch(fetchOrdersActionCreator(buyerId));
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        handleClose();
      });
  };

  useEffect(() => {
    setOpen(Boolean(orderIdForVoid));
  }, [orderIdForVoid]);

  if (!currentOrder || !orderIdForVoid) return null;

  return (
    <MpModal
      title=""
      handleClose={handleClose}
      modalOpen={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box id="alert-dialog-title" sx={{ margin: '100px 0 50px', textAlign: 'center' }}>
        <Typography variant="h3">
          <FormattedMessage
            id="ORDERS.CONFIRM_VOID"
            context={{ orderId: currentOrder.merchant_order_id }}
          />
        </Typography>
        <Typography sx={{ marginTop: '30px' }} variant="body2" color="text.secondary">
          <FormattedMessage id="ORDERS.VOID_DESCRIPTION" />
        </Typography>
      </Box>
      <Box
        sx={{
          margin: '0 0 50px',
          display: 'flex',
          justifyContent: 'center',
          gap: '70px',
        }}
      >
        <MpButton onClick={handleClose} type={GHOST}>
          <FormattedMessage id="CANCEL" />
        </MpButton>
        <MpButton onClick={handleVoid}>
          <FormattedMessage id="VOID" />
        </MpButton>
      </Box>
    </MpModal>
  );
};
