import React, { useEffect, useState } from 'react';
import { Box, Card, FormControlLabel, Grid, Typography, FormControl, Radio } from '@mui/material';
import { useSnackbar } from 'notistack';
import ShoppingBasket from '@mui/icons-material/ShoppingBasketOutlined';
import { useAppSelector } from 'store';

// components
import { DisplayEmails } from './display-emails';
import { FormattedMessage, MpButton, Label, MpInput } from 'UI';
import { updateEmailConfig, arrayIncludesItem } from './index';

// styles
import { RadioGroupStyled } from './styled';

// utils
import { snackbarConfig } from 'utils';
import { useTranslation } from 'i18n/formatted-message';

// types
import { EmailConfig } from 'store/types/settings';

type BuyerSpecificNotificationsProps = {
  emailConfig: EmailConfig;
  onUpdateEmailSuccess: (data: EmailConfig, useCase: React.ReactNode) => void;
  onUpdateEmailFailure: (error: string) => void;
};

const CC = 'cc';
const BCC = 'bcc';

export const BuyerSpecificNotifications = ({
  emailConfig,
  onUpdateEmailSuccess,
  onUpdateEmailFailure,
}: BuyerSpecificNotificationsProps) => {
  const [copyState, setCopyState] = useState<string>('');
  const [newSettlementEmail, setNewSettlementEmail] = useState<string>('');
  const [newSettlementsEmailError, setNewSettlementsEmailError] = useState<React.ReactNode>('');
  const { enqueueSnackbar } = useSnackbar();
  const { sellerId } = useAppSelector(({ login }) => login);

  useEffect(() => {
    setCopyState(emailConfig?.receive_copy?.copy || '');
  }, [emailConfig?.receive_copy?.copy]);

  const onSubmitEmails = () => {
    const defaultEmails = emailConfig.receive_copy?.to_seller_users || [];
    if (!arrayIncludesItem(defaultEmails || [], newSettlementEmail)) {
      const emails = [...(defaultEmails || []), newSettlementEmail].filter(email => email);

      updateEmailConfig(
        sellerId,
        {
          ...emailConfig,
          receive_copy: {
            copy: copyState || null,
            to_seller_users: emails,
          },
        },
        data => {
          setNewSettlementEmail('');
          onUpdateEmailSuccess(
            data,
            <FormattedMessage id="SETTINGS.NOTIFICATIONS.BUYER_SPECIFIC" />
          );
        },
        onUpdateEmailFailure
      );
    } else {
      enqueueSnackbar(
        <FormattedMessage id="ERROR_MESSAGES.EMAIL_ALREADY_EXIST" />,
        snackbarConfig({ type: 'error' })
      );
    }
  };

  const deleteSettlementEmail = index => {
    const filterEmail = emailConfig.receive_copy?.to_seller_users.filter((_, i) => index !== i);
    updateEmailConfig(
      sellerId,
      {
        ...emailConfig,
        receive_copy: {
          copy: copyState || null,
          to_seller_users: filterEmail,
        },
      },
      data =>
        onUpdateEmailSuccess(data, <FormattedMessage id="SETTINGS.NOTIFICATIONS.BUYER_SPECIFIC" />),
      onUpdateEmailFailure
    );
  };

  const onSettlementsEmailChange = e => {
    setNewSettlementEmail(e.target.value);
    setNewSettlementsEmailError('');
  };

  return (
    <Box mt={4}>
      <Card>
        <Box p={4} sx={{ maxWidth: '80%' }}>
          <Typography variant="h3">
            <ShoppingBasket sx={{ fill: 'url(#with-icon-gradient)' }} />
            <FormattedMessage id="SETTINGS.NOTIFICATIONS.BUYER_SPECIFIC_EMAILS" />
          </Typography>

          <Grid container columnSpacing={2} alignItems="end">
            <Grid item xs={12}>
              <Box pt={3}>
                <Typography pb={1}>
                  <Label>SETTINGS.NOTIFICATIONS.BUYER_MAIL_CONFIG_HELPER</Label>
                </Typography>
                <Box>
                  <FormControl
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      setCopyState(event.target.value);
                    }}
                  >
                    <RadioGroupStyled
                      className="buyer-settlement-radio-box"
                      value={copyState}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value={CC}
                        control={<Radio />}
                        data-sdet="buyer-cc-email"
                        label={useTranslation('SETTINGS.NOTIFICATIONS.RECIEVE_COPY_AS_CC')}
                      />
                      <FormControlLabel
                        value={BCC}
                        control={<Radio />}
                        data-sdet="buyer-bcc-email"
                        label={useTranslation('SETTINGS.NOTIFICATIONS.RECIEVE_COPY_AS_BCC')}
                      />
                      <FormControlLabel
                        value=""
                        data-sdet="buyer-none-selected-email"
                        control={<Radio />}
                        label={useTranslation('SETTINGS.NOTIFICATIONS.DONT_RECEIVE_COPY')}
                      />
                    </RadioGroupStyled>
                  </FormControl>
                </Box>
              </Box>
            </Grid>

            {copyState && (
              <>
                <Grid item xs={12}>
                  <Box pb={2}>
                    <Label>SETTINGS.NOTIFICATIONS.BUYER_COPY_AS</Label>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pb={3}>
                    <DisplayEmails
                      emails={emailConfig.receive_copy?.to_seller_users}
                      deleteEmail={deleteSettlementEmail}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pb={2} width="50%">
                    <MpInput
                      label="PLACEHOLDERS.NEW_EMAIL"
                      labelBold
                      onChange={onSettlementsEmailChange}
                      value={newSettlementEmail}
                      errorMessage={newSettlementsEmailError}
                      name="buyer-settlements-email"
                    />
                  </Box>
                </Grid>
              </>
            )}
            <Grid item container xs={12}>
              <Box pt={2} data-sdet="buyer-notification-save">
                <MpButton onClick={onSubmitEmails}>
                  <FormattedMessage id="SAVE" />
                </MpButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};
