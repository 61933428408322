// vendor
import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { useSnackbar } from 'notistack';

// components
import { CreateForm, MpIcon, AddCompanyForm, FormattedMessage } from 'UI';

// constants
import {
  addBuyerFormFields,
  valuesToValidateOnAddBuyerFormFields,
  addressDetailsOnAddBuyerPopupFormFields,
  valuesToValidateOnAddressDetails,
  businessInformationFieldOnAddBuyer,
  valuesToValidateOnAddBuyerBusinessInformationField,
  shippingAddressOnBuyerOnboardingFlow,
  valuesToValidateOnShippingAddressDetails,
} from 'constants/form-data';
import { MAX_STEP_COUNTER, INC_STEPPER, DEC_STEPPER, SOURCE } from 'constants/buyer.constants';
import { ORIGINAL_ACCESS_TOKEN } from 'constants/storage.constants';
import { registerationNumber } from 'constants/form-data';

// actions
import { addBuyer } from 'store/actions';
import { changeBuyerFormValue, fetchAllBuyers, resetBuyerForm } from 'store/actions/buyerActions';

import { Country } from 'store/types/settings';
import { Step4 } from './step4';

// utils
import { storageService } from 'utils';
import { SUPPORTED_LANGUAGES } from 'constants/supported-languages';
import { convertFingerprintDataToSprinquePayload } from 'b2b-sprinque-tools';
import { ModalStepTitle } from '../add-invoice/helpers';

// types
type Props = {
  handleClose: () => any;
};

export const AddBuyerPopup = ({ handleClose }: Props) => {
  const {
    buyer: { newBuyerForm },
    settings: { countries },
    user: { fingerprint },
  } = useAppSelector(state => state);
  const { sellerId } = useAppSelector(state => state.login);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [disableFields, setDisableFields] = useState(true);
  const [buyerId, setBuyerId] = useState('');
  const [fieldsToBeDisabled, setFieldsToBeDisabled] = useState({});
  const { step } = newBuyerForm;

  const stepSwapper = state => {
    const nextStep =
      state === INC_STEPPER && step <= MAX_STEP_COUNTER
        ? step + 1
        : state === DEC_STEPPER && step > 0
        ? step - 1
        : step;

    dispatch(changeBuyerFormValue({ step: nextStep }));
  };

  const saveDetails = values => {
    dispatch(changeBuyerFormValue({ ...values }));
    stepSwapper(INC_STEPPER);
  };

  const addBuyerManually = values => {
    dispatch(resetBuyerForm());
    setDisableFields(false);
    dispatch(changeBuyerFormValue({ ...values }));
    stepSwapper(INC_STEPPER);
  };

  const createBuyer = values => {
    const ori = storageService.getCookieValue(ORIGINAL_ACCESS_TOKEN);
    const source = ori && ori !== 'undefined' ? SOURCE.GOD_MODE : SOURCE.MCC;
    dispatch(changeBuyerFormValue({ ...values }));
    const metadata = fingerprint
      ? { merchant: convertFingerprintDataToSprinquePayload(fingerprint) }
      : {};
    dispatch(
      addBuyer(
        { ...values, source, metadata },
        enqueueSnackbar,
        data => {
          dispatch(fetchAllBuyers());
          stepSwapper(INC_STEPPER);
          setBuyerId(data._uuid);
        },
        sellerId
      )
    );
  };

  const disableFieldsBasedOnAddBuyerManually = formFields => {
    const currentEnv = process.env.REACT_APP_CURRENT_ENV;
    return formFields.map(({ fields, ...rest }) => {
      return {
        ...rest,
        fields: fields.map(subFields =>
          subFields.map(field =>
            field.name
              ? {
                  ...field,
                  isDisabled:
                    (currentEnv === 'sandbox' || currentEnv === 'testing') &&
                    field.name === registerationNumber.name
                      ? false
                      : fieldsToBeDisabled[field.name] &&
                        newBuyerForm[field.name]?.trim() &&
                        disableFields,
                }
              : {}
          )
        ),
      };
    });
  };

  const saveCompanyDetails = (
    values = {
      country: '',
      selectedCompany: {
        address: { country: '' },
      },
    }
  ) => {
    const {
      country,
      selectedCompany: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        address: { country: addressCountry, ...restAddressFields },
        ...rest
      },
    } = values;

    dispatch(resetBuyerForm());
    setDisableFields(true);
    setFieldsToBeDisabled({
      country,
      ...rest,
      ...restAddressFields,
    });
    dispatch(changeBuyerFormValue({ ...values, ...rest, ...restAddressFields, country }));
    stepSwapper(INC_STEPPER);
  };

  const selectedCountry = newBuyerForm.country.toLowerCase();
  const initialLanguage = SUPPORTED_LANGUAGES.map(({ value }) => value).includes(selectedCountry)
    ? selectedCountry
    : 'en';

  const { is_registration_number_required = false } =
    (countries.find(
      ({ code }) => code.toUpperCase() === selectedCountry.toUpperCase()
    ) as Country) || {};

  const BuyerAddTeamMember = () => (
    <>
      <ModalStepTitle title="MODAL_TITLES.CONTACT_DETAILS" />
      <CreateForm
        formFields={addBuyerFormFields(selectedCountry)}
        valuestoValidate={valuesToValidateOnAddBuyerFormFields}
        initialValue={{ ...newBuyerForm }}
        secondaryButtonConfig={{
          label: (
            <>
              <MpIcon name="arrow-left" rightMargin="12px" />
              <FormattedMessage id="BACK" />
            </>
          ),
          handleCancel: () => stepSwapper(DEC_STEPPER),
        }}
        primaryButtonConfig={{
          hidePrimaryButtonDefaultTickIcon: true,
          label: (
            <>
              <FormattedMessage id="NEXT" />
              <MpIcon name="arrow-right" leftMargin="12px" />
            </>
          ),
          handleSubmit: createBuyer,
        }}
      />
    </>
  );

  return (
    <div className="add-buyer-popup">
      {step === 0 && (
        <>
          <ModalStepTitle title="MODAL_TITLES.COMPANY_DETAILS" />
          <AddCompanyForm
            addManually={addBuyerManually}
            handleClose={handleClose}
            onSubmit={saveCompanyDetails}
            addManualButtonLabel="COMPANY.BUYER"
            countries={countries}
            // @ts-ignore
            initialValues={newBuyerForm}
          />
        </>
      )}

      {step === 1 && (
        <>
          <ModalStepTitle title="MODAL_TITLES.BUSINESS_INFO" />
          <CreateForm
            formFields={[
              ...disableFieldsBasedOnAddBuyerManually(
                businessInformationFieldOnAddBuyer(is_registration_number_required)
              ),
            ]}
            valuestoValidate={valuesToValidateOnAddBuyerBusinessInformationField(
              is_registration_number_required
            )}
            initialValue={{ ...newBuyerForm }}
            secondaryButtonConfig={{
              label: (
                <>
                  <MpIcon name="arrow-left" rightMargin="12px" />
                  <FormattedMessage id="BACK" />
                </>
              ),
              handleCancel: () => stepSwapper(DEC_STEPPER),
            }}
            primaryButtonConfig={{
              hidePrimaryButtonDefaultTickIcon: true,
              label: (
                <>
                  <FormattedMessage id="NEXT" />
                  <MpIcon name="arrow-right" leftMargin="12px" />
                </>
              ),
              handleSubmit: saveDetails,
            }}
          />
        </>
      )}

      {step === 2 && (
        <>
          <ModalStepTitle title="MODAL_TITLES.BUSINESS_ADDRESS" />
          <CreateForm
            formFields={[
              ...disableFieldsBasedOnAddBuyerManually(addressDetailsOnAddBuyerPopupFormFields),
            ]}
            valuestoValidate={valuesToValidateOnAddressDetails}
            initialValue={{
              ...newBuyerForm,
              language: initialLanguage,
            }}
            secondaryButtonConfig={{
              label: (
                <>
                  <MpIcon name="arrow-left" rightMargin="12px" />
                  <FormattedMessage id="BACK" />
                </>
              ),
              handleCancel: () => stepSwapper(DEC_STEPPER),
            }}
            primaryButtonConfig={{
              hidePrimaryButtonDefaultTickIcon: true,
              label: (
                <>
                  <FormattedMessage id="NEXT" />
                  <MpIcon name="arrow-right" leftMargin="12px" />
                </>
              ),
              handleSubmit: saveDetails,
            }}
          />
        </>
      )}

      {newBuyerForm.isBillingAddressSameAsBilling ? (
        <>
          {step === 3 && <BuyerAddTeamMember />}

          {step === 4 && <Step4 buyerId={buyerId} />}
        </>
      ) : (
        <>
          {step === 3 && (
            <>
              <ModalStepTitle title="ADD_TRANSACTION_STEP_3_TITLE" />
              <CreateForm
                formFields={shippingAddressOnBuyerOnboardingFlow}
                valuestoValidate={valuesToValidateOnShippingAddressDetails}
                initialValue={{
                  ...newBuyerForm,
                }}
                secondaryButtonConfig={{
                  label: (
                    <>
                      <MpIcon name="arrow-left" rightMargin="12px" />
                      <FormattedMessage id="BACK" />
                    </>
                  ),
                  handleCancel: () => stepSwapper(DEC_STEPPER),
                }}
                primaryButtonConfig={{
                  hidePrimaryButtonDefaultTickIcon: true,
                  label: (
                    <>
                      <FormattedMessage id="NEXT" />
                      <MpIcon name="arrow-right" leftMargin="12px" />
                    </>
                  ),
                  handleSubmit: saveDetails,
                }}
              />
            </>
          )}

          {step === 4 && <BuyerAddTeamMember />}

          {step === 5 && <Step4 buyerId={buyerId} />}
        </>
      )}
    </div>
  );
};
