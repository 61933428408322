import { SETTLEMENTS_DEFUALT_ROWS_PER_PAGE } from 'constants/table-filter-options';
// types
import { SET_SETTLEMENTS } from 'store/types';
import { convertSettlementsToUI } from 'store/helpers';
import { Pagination } from '../types/pagination';

type Settlement = {
  created_at: Date;
  file_generation_datetime: Date;
  file_name: string;
  file_range: string;
  id: string;
  modified_at: Date;
  modified_by: null | string;
  report_file: string;
  seller: { id: number; _uuid: string };
  settlement_amount: string;
  total_fees: string;
  total_refunds: string;
  total_sales: string;
  total_vat: string;
};

type SettlementsReducerType = {
  list: Array<Settlement>;
  pagination: Pagination;
};

const initialState: SettlementsReducerType = {
  list: [],
  pagination: {
    count: 0,
    page: 1,
    page_size: SETTLEMENTS_DEFUALT_ROWS_PER_PAGE,
  },
};

export const settlements = (state = initialState, action): SettlementsReducerType => {
  switch (action.type) {
    case SET_SETTLEMENTS: {
      const { data, pagination } = action.payload;
      return { ...state, list: convertSettlementsToUI(data), pagination };
    }

    default:
      return { ...state };
  }
};
