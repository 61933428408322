// vendor
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useSnackbar } from 'notistack';
import { Grid, Stack, Box, Tooltip, useMediaQuery, Theme } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useParams, useHistory } from 'react-router-dom';

// api
import { getData } from 'api';

// components
import { MpTimeline, FormattedMessage, Refund, ErrorPage } from 'UI';
import { MarkAsPaid } from './mark-as-paid';
import { InvoiceIds } from './invoice-ids';
import { UpdateSettlementDate } from '../invoice-table/update-settlement-date';

// constants
import { INVOICES as INVOICE_ROUTE } from 'constants/routes.constants';
import { INVOICES } from 'constants/api-endpoints.constants';
import { grey } from 'theme/colors';
import spaces from 'constants/spacing';
import fontSizes from 'constants/font-sizes';

// actions
import { stopLoading, startLoading } from 'store/actions';

// utils
import { errorMessage, renderTag } from 'utils';
import { Invoice } from 'store/types/invoices';
import { InvoiceData } from './invoice-data';
import { extendTimelineWithFileUrls } from './extend-timeline-with-file-urls';
import { copyToClipboard } from 'utils';
import { InvoiceCalculation } from './invoice-calculation';
import { getCurrencySymbol } from 'utils/currencies';

const REFUND_BUTTON_MAP = {
  'CAPTURED': 'CAPTURED',
  'SETTLED': 'SETTLED',
  'PARTIALLY REVERSED': 'PARTIALLY REVERSED',
};

export const InvoiceDetails = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [state, setState] = useState({
    timeline: [],
    invoice: {} as Invoice,
    showInvoiceData: false,
    invoiceFetchErrorMessage: '',
  });
  const { sellerId } = useAppSelector(state => state.login);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const fetchInvoiceData = () => {
    dispatch(startLoading);
    getData(INVOICES(sellerId) + id)
      .then(({ data }) => {
        const { timeline, credit_notes, url } = data || {
          timeline: [],
          credit_notes: [],
          url: '',
        };
        setState({
          ...state,
          // @ts-ignore
          timeline: extendTimelineWithFileUrls({ timeline, credit_notes, url }),
          invoice: {
            ...data,
            currency_symbol: getCurrencySymbol(data.currency_code),
          } as Invoice,
          showInvoiceData: true,
        });
      })
      .catch(err => {
        setState({
          ...state,
          invoiceFetchErrorMessage: errorMessage(err),
        });
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };

  useEffect(() => {
    fetchInvoiceData();
  }, []);

  const navigateBack = event => {
    event.preventDefault();
    history.goBack() || history.push(INVOICE_ROUTE);
  };

  const handleClickToCopy = text => {
    copyToClipboard(text);
    enqueueSnackbar(`${text} copied`);
  };

  const { timeline, invoice, showInvoiceData, invoiceFetchErrorMessage } = state;

  const {
    merchant_invoice_id,
    invoice_status,
    merchant_order_id = '',
    transaction_status,
    transaction_id = '',
    currency_code,
    invoice_id,
    ...restInvoiceData
  } = invoice;

  const creditNoteIds = (restInvoiceData.credit_notes || []).map?.(
    cn => cn.merchant_credit_note_id
  );
  return (
    <Box>
      {showInvoiceData ? (
        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                lineHeight: spaces.space_xl,
                fontWeight: 600,
                fontSize: fontSizes.medium,
                color: grey[700],
                margin: '0 0 24px',
              }}
            >
              <ArrowBack
                style={{
                  color: grey[700],
                  cursor: 'pointer',
                }}
                href="#"
                data-sdet="back-link"
                onClick={navigateBack}
              />
              <Stack spacing={2} direction={isMobile ? 'column' : 'row'}>
                <FormattedMessage id="INVOICE" />:
                <Tooltip
                  title={
                    <Box p={1}>
                      <Box mb={1}>
                        <FormattedMessage id="LABELS.MERCHANT_INVOICE_ID" />: {merchant_invoice_id}
                      </Box>
                      <FormattedMessage id="CLICK_TO_COPY" />
                    </Box>
                  }
                  arrow
                  placement="top"
                >
                  <Box
                    component="span"
                    sx={{
                      cursor: 'pointer',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '300px',
                    }}
                    onClick={() => handleClickToCopy(merchant_invoice_id)}
                  >
                    #{merchant_invoice_id}
                  </Box>
                </Tooltip>
                {invoice_status && renderTag(invoice_status)}
              </Stack>
            </Box>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              {REFUND_BUTTON_MAP[transaction_status] && (
                <Refund
                  onSuccess={fetchInvoiceData}
                  invoiceId={transaction_id} // Remove this line after BE fix
                  // invoiceId={invoice_id || transaction_id || ''}
                  currencyCode={currency_code}
                />
              )}

              <MarkAsPaid
                onSuccess={fetchInvoiceData}
                invoice={{ _uuid: invoice_id, invoice_status }}
                transactionStatus={transaction_status}
              />
            </Box>
          </Box>

          <Grid container columnSpacing={2} rowGap={3} sx={{ mb: 3 }} borderRadius={1}>
            <InvoiceData {...invoice} />
            <InvoiceCalculation {...restInvoiceData} />
            <InvoiceIds
              {...{
                transaction_id,
                invoice_id,
                merchant_order_id,
                credit_notes_id: creditNoteIds.length ? creditNoteIds : null,
              }}
              handleCopy={handleClickToCopy}
              isMobile={isMobile}
            />
          </Grid>

          {timeline && <MpTimeline data={timeline} />}
        </div>
      ) : (
        <></>
      )}
      {invoiceFetchErrorMessage ? <ErrorPage message={invoiceFetchErrorMessage} /> : <></>}
      <UpdateSettlementDate callback={fetchInvoiceData} />
    </Box>
  );
};
