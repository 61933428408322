// vendor
import React from 'react';

// styles
import { IconStyled } from './icon.styled';

type Props = {
  name: string;
  leftMargin?: string;
  rightMargin?: string;
  onClick?: () => any;
  width?: string;
  height?: string;
  alt?: string;
  style?: React.CSSProperties;
};

const Icon = ({ name, leftMargin, rightMargin, onClick, ...rest }: Props) => {
  return (
    <IconStyled leftMargin={leftMargin} rightMargin={rightMargin} pointer={onClick}>
      <img src={`icons/${name}.svg`} alt={name} {...rest} onClick={onClick} />
    </IconStyled>
  );
};
export default Icon;
