import styled from '@emotion/styled';

export const FeesStyled = styled.div`
  .MuiTableContainer-root {
    border: none !important;
    box-shadow: none;
  }
  a {
    text-decoration: none;
  }
`;

export const fontStyles = {
  title: {
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '32px',
    color: '#000339',
  },
  description: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#000339',
  },
  label: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '22px',
    color: '#000339',
  },
  value: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '22px',
    color: '#000339',
  },
};
