// vendor
import styled from '@emotion/styled';
import { BaseThemeType } from 'theme/baseTheme';

export const EmailVerificationWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  margin: ${props => props.theme.spaces.space_xl} 0;
  min-height: 220px;
  padding: 0 ${props => props.theme.spaces.space_giga};
  text-align: center;
`;

export const EmailVerificationDescriptionStyled = styled.div<{ theme?: BaseThemeType }>`
  font-size: ${props => props.theme.fontSizes.xSmall};
  line-height: ${props => props.theme.spaces.space_m};
  color: ${props => props.theme.colors.neutral700};
  margin: ${props => props.theme.spaces.space_m} 0px;
`;

export const EmailVerificationEmailSuccessTitleStyled = styled.div<{ theme?: BaseThemeType }>`
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.large};
  line-height: ${props => props.theme.spaces.space_xl};
  text-align: center;
  color: ${props => props.theme.colors.neutral900};
  margin: ${props => props.theme.spaces.space_m} 0px;
  margin: ${props => props.theme.spaces.space_m} 0px;
`;

export const EmailVerificationButtonWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  margin: ${props => props.theme.spaces.space_m} auto ${props => props.theme.spaces.space_xxxl};
`;

export const AdditionalLinkEmailStyled = `
  font-size: ${props => props.theme.fontSizes.xSmall};
  line-height: ${props => props.theme.spaces.space_m};
`;
