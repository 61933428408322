// vendor
import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import { useAppSelector } from 'store';

// components
import {
  MpInput,
  MpDropdownSelect,
  MpButton,
  MpIcon,
  MpMessage,
  PhoneNumberInput,
  RadioButtonsGroup,
  MpDropzone,
  Dates,
  InputCurrencyWithDropdown,
  TextArea,
  FormattedMessage,
} from 'UI';

// styles
import {
  CreateFormStyled,
  CreateFormInputStyled,
  CreateFormInputWrapperStyled,
  CreateFormGroupTitleStyled,
  CreateFormButtonWrapperStyled,
} from './create-form.styled';

// utils
import { validators } from 'utils';

// constants
import { PRIMARY, GHOST } from 'constants/button-types';
import { ERROR } from 'constants/message-types';
import { formTypes } from 'constants/form-data';
import { SETTINGS_IBAN_ID } from 'constants/routes.constants';

// types
type Props = {
  formFields: Array<unknown>;
  initialValue: object;
  valuestoValidate: object;
  primaryButtonConfig: {
    label: string | React.ReactNode;
    handleSubmit: (v: any) => any;
    hidePrimaryButtonDefaultTickIcon?: boolean;
    disabled?: boolean;
    alignToLeft?: boolean;
  };
  secondaryButtonConfig?: {
    label: string | React.ReactNode;
    handleCancel: () => any;
  };
  serverErrorMessage?: string;
  children?: React.ReactNode | string;
  customValidators?: (values, valuesToValidate, customValidationRules?) => void;
  customValidationRules?: Record<string, unknown>;
};

const onChange = (selectedOption, handleChange, name = '') => {
  if (Array.isArray(selectedOption)) {
    const event = { target: { name, value: selectedOption } };
    handleChange(event);
    return;
  }

  const { value = '', label = '' } = selectedOption || {};
  const event = { target: { name, value, label } };
  handleChange(event);
};

const onValueChange = (newValue, handleChange, name) => {
  onChange({ value: newValue, label: newValue }, handleChange, name);
};

interface Column {
  name: string;
  type: string;
  width?: string | undefined;
  fieldMarginTop?: string;
  options: Array<{ value: string; label: string; name: string }>;
  label: string;
  handleBlur?: (e) => void;
}

interface FormFieldProps {
  column: Column;
  handleChange: (e) => void;
  handleBlur: (e) => void;
  values: object;
  errors: object;
  touched: object;
}

const RenderFormFields = ({
  column,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
}: FormFieldProps) => {
  const countries = useAppSelector(state => state.settings.countries);
  if (column.type === formTypes.dropdown || column.type === formTypes.country) {
    return (
      <CreateFormInputStyled width={column.width} fieldMarginTop={column.fieldMarginTop}>
        <MpDropdownSelect
          {...column}
          options={
            column.name === 'country' || column.type === formTypes.country
              ? countries
              : column.options
          }
          onChange={selectedOption => {
            onChange(selectedOption, handleChange, column.name);
          }}
          defaultValue={values[column.name]}
          errorMessage={errors[column.name] && touched[column.name] && errors[column.name]}
        />
      </CreateFormInputStyled>
    );
  } else if (column.type === formTypes.phone) {
    return (
      <CreateFormInputStyled width={column.width} fieldMarginTop={column.fieldMarginTop}>
        <PhoneNumberInput
          {...column}
          handleChange={phone => {
            onValueChange(phone, handleChange, column.name);
          }}
          value={values[column.name]}
        />
      </CreateFormInputStyled>
    );
  } else if (column.type === formTypes.textArea) {
    return (
      <CreateFormInputStyled width={column.width} fieldMarginTop={column.fieldMarginTop}>
        <TextArea
          {...column}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[column.name]}
          errorMessage={errors[column.name] && touched[column.name] && errors[column.name]}
        />
      </CreateFormInputStyled>
    );
  } else if (column.type === formTypes.radio) {
    const value = values[column.name]?.target?.checked || values[column.name];
    return (
      <CreateFormInputStyled width={column.width} fieldMarginTop={column.fieldMarginTop}>
        {/* @ts-ignore */}
        <RadioButtonsGroup
          {...column}
          onChange={event => {
            onChange(
              { label: column.label, value: event.target.checked },
              handleChange,
              column.name
            );
          }}
          value={value}
          error={errors[column.name] && touched[column.name] && errors[column.name]}
        />
      </CreateFormInputStyled>
    );
  } else if (column.type === formTypes.file) {
    return (
      <CreateFormInputStyled width={column.width} fieldMarginTop={column.fieldMarginTop}>
        <MpDropzone
          {...column}
          onChange={file => {
            onChange({ value: file?.[0] || '', label: column.name }, handleChange, column.name);
          }}
          errorMessage={errors[column.name] && touched[column.name] && errors[column.name]}
        />
      </CreateFormInputStyled>
    );
  } else if (column.type === formTypes.date) {
    return (
      <CreateFormInputStyled width={column.width}>
        <Dates
          dateConfigs={[
            // @ts-ignore
            {
              ...column,
              value: values[column.name],
              onChange: handleChange,
              error: errors[column.name] && touched[column.name] && errors[column.name],
            },
          ]}
          elementSpacing={12}
        />
      </CreateFormInputStyled>
    );
  } else if (column.type === formTypes.amount) {
    return (
      <CreateFormInputStyled width={column.width}>
        {/* @ts-ignore */}
        <InputCurrencyWithDropdown
          {...column}
          handleChange={handleChange}
          handleNameSymbolChange={({ name, currency_code, currency_symbol }) => {
            onValueChange(name, handleChange, column['currency_name']);
            onValueChange(currency_code, handleChange, column['currency_code']);
            onValueChange(currency_symbol, handleChange, column['currency_symbol']);
          }}
          value={values[column.name]}
          errorMessage={errors[column.name] && touched[column.name] && errors[column.name]}
        />
      </CreateFormInputStyled>
    );
  } else if (column.type === formTypes.freeText) {
    return values[column.name];
  } else {
    return (
      <CreateFormInputStyled width={column.width} fieldMarginTop={column.fieldMarginTop}>
        <MpInput
          {...column}
          onChange={handleChange}
          onBlur={column.handleBlur || handleBlur}
          value={values[column.name]}
          errorMessage={errors[column.name] && touched[column.name] && errors[column.name]}
        />
      </CreateFormInputStyled>
    );
  }
};

const renderForm = (formData, handleChange, handleBlur, values, errors, touched) => {
  const { label, labelStyleConfig, fields } = formData;
  return (
    <CreateFormStyled>
      {label && (
        <CreateFormGroupTitleStyled {...labelStyleConfig}>
          <FormattedMessage id={label} />
        </CreateFormGroupTitleStyled>
      )}

      {fields.map((row, key) => (
        <CreateFormInputWrapperStyled key={`create-form-column-${key}`}>
          {row.map(
            (column, index) =>
              Object.keys(column).length > 1 && (
                <RenderFormFields
                  key={`create-form-sub-column-fields-${index}`}
                  column={column}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              )
          )}
        </CreateFormInputWrapperStyled>
      ))}
    </CreateFormStyled>
  );
};

class CreateForm extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      disableSubmitButton: true,
      uniqueFormKey: Math.floor(Math.random() * 1000000 + 1),
    };
  }

  componentDidMount() {
    // scroll to the IBAN
    if (window.location.hash.endsWith(SETTINGS_IBAN_ID)) {
      setTimeout(() => {
        document
          .getElementById(SETTINGS_IBAN_ID)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);
    }
  }

  render() {
    const {
      initialValue,
      valuestoValidate,
      primaryButtonConfig = { hidePrimaryButtonDefaultTickIcon: false, alignToLeft: false },
      secondaryButtonConfig,
      formFields,
      serverErrorMessage,
      children,
      customValidators,
      customValidationRules,
    } = this.props;
    // @ts-ignore
    const { uniqueFormKey } = this.state;
    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...initialValue }}
          validate={values => {
            if (customValidators) {
              return customValidators(values, valuestoValidate, customValidationRules);
            }
            return validators(values, valuestoValidate);
          }}
          onSubmit={(values, { setSubmitting }) => {
            // @ts-ignore
            primaryButtonConfig.handleSubmit?.(values);
            setSubmitting(false);
            this.setState({ serverErrorMessage: '' });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
            return (
              <Form>
                {formFields.map((formData, index) => (
                  <React.Fragment key={`form-section-outer-field-${index + uniqueFormKey}`}>
                    {renderForm(formData, handleChange, handleBlur, values, errors, touched)}
                  </React.Fragment>
                ))}
                {serverErrorMessage && <MpMessage type={ERROR} message={serverErrorMessage} />}

                {children}

                <CreateFormButtonWrapperStyled
                  isSecondaryButtonPresent={secondaryButtonConfig}
                  alignPrimaryButtonToLeft={primaryButtonConfig.alignToLeft}
                >
                  {primaryButtonConfig && (
                    <MpButton
                      type={PRIMARY}
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      isFullWidth={false}
                    >
                      {!primaryButtonConfig.hidePrimaryButtonDefaultTickIcon && (
                        <MpIcon name="tick" width="16px" height="16px" rightMargin="12px" />
                      )}
                      {/*@ts-ignore*/}
                      {primaryButtonConfig.label}
                    </MpButton>
                  )}

                  {secondaryButtonConfig && (
                    <MpButton type={GHOST} onClick={secondaryButtonConfig.handleCancel}>
                      {secondaryButtonConfig.label as string}
                    </MpButton>
                  )}
                </CreateFormButtonWrapperStyled>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default CreateForm;
