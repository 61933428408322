import { axiosConfig } from 'routes';

// constants
import { GET_COUNTRY_LIST, SELLER_SETTINGS } from 'constants/api-endpoints.constants';

// utils
import { sort } from 'utils';
import { SELLER_CONFIG, FETCHED_COUNTRIES } from 'store/types';
import { getTranslatedCountry } from 'b2b-sprinque-tools';

export const postData = (urlEndpoint, data, headers?: any) => {
  return axiosConfig.post(urlEndpoint, data, {
    headers: headers,
  });
};

export const getData = urlEndpoint => {
  return axiosConfig.get(urlEndpoint);
};

export const putData = (urlEndpoint, data) => {
  return axiosConfig.put(urlEndpoint, data);
};

export const patchData = (urlEndpoint, data) => {
  return axiosConfig.patch(urlEndpoint, data);
};

export const deleteData = (urlEndpoint, data?: any) => {
  return axiosConfig.delete(urlEndpoint, {
    headers: {},
    data,
  });
};

export const fetchCountry = () => {
  return dispatch => {
    getData(GET_COUNTRY_LIST).then(res => {
      const sortedCountry = sort(res.data, 'name');
      const countries = sortedCountry
        .filter(country => country.status === 'ACTIVE')
        .map(country => ({
          ...country,
          value: country.code,
          label: getTranslatedCountry(
            // @ts-ignore
            localStorage.getItem('uiLang') || 'en',
            country.code.toLowerCase()
          ),
        }));
      dispatch({ type: FETCHED_COUNTRIES, payload: countries });
    });
  };
};

export const fetchSellerSettings = sellerId => {
  return dispatch => {
    getData(`${SELLER_SETTINGS}${sellerId}`).then(res => {
      dispatch({ type: SELLER_CONFIG, payload: res.data });
    });
  };
};
