// vendor
import React from 'react';
import { Typography } from '@mui/material';

// components
import { DynamicFee } from './dynamic-fee';
import { SellerFee } from './seller-fee';
import { BuyerFee } from './buyer-fee';
import { FormattedMessage } from 'i18n/formatted-message';

export const DynamicSettlements = () => {
  return (
    <>
      <SellerFee />
      <DynamicFee />
      <BuyerFee />
      <Typography fontSize="14px" mt={4} mb={5}>
        <FormattedMessage id="SETTINGS.FEES.BOTTOM_TEXT" />
      </Typography>
    </>
  );
};
