import {
  OPEN_ADD_BUYER_POPUP,
  CLOSE_ADD_BUYER_POPUP,
  OPEN_ADD_BUYER_TEAM_PERSON_POPUP,
  CLOSE_ADD_BUYER_TEAM_PERSON_POPUP,
} from 'store/types';

export const openBuyerPopup = { type: OPEN_ADD_BUYER_POPUP };
export const closeBuyerPopup = { type: CLOSE_ADD_BUYER_POPUP };

export const openAddPersonPopup = { type: OPEN_ADD_BUYER_TEAM_PERSON_POPUP };
export const closeAddPersonPopup = { type: CLOSE_ADD_BUYER_TEAM_PERSON_POPUP };
