// vendor
import styled from '@emotion/styled';
import Select from 'react-select';
import { grey, blue, BACKGROUND_SECONDARY, PRIMARY100, WHITE } from 'theme/colors';
import { hexToRGBA } from 'utils';
import { BaseThemeType } from 'theme/baseTheme';

export const MpDropdownSelectStyled = styled(Select)<{ theme?: BaseThemeType }>`
  width: 100%;
  border-radius: ${props => props.theme.spaces.space_mini};

  .true__indicator-separator {
    background-color: ${BACKGROUND_SECONDARY};
  }

  .true__placeholder {
    font-size: ${props => props.theme.fontSizes.xxSmall};
    line-height: ${props => props.theme.spaces.space_22};
    color: ${grey[400]};
  }

  .true__control {
    border: ${props => props.theme.spaces.space_nano} solid
      ${props => props.theme.colors.neutral300};
    border-radius: ${props => props.theme.spaces.space_mini};
    min-height: 40px;
  }

  .true__menu {
    z-index: 2;
    border: 1px solid ${grey[300]};
    box-sizing: border-box;
    box-shadow: 0 ${props => props.theme.spaces.space_mini}
      ${props => props.theme.spaces.space_xxxs} ${hexToRGBA(grey[300], 0.5)};
    border-radius: ${props => props.theme.spaces.space_mini};
  }

  .true__menu-list {
    z-index: 2;
    max-height: 150px;
    padding: ${props => props.theme.spaces.space_xxxs};
  }

  & .true__single-value,
  & .true__option {
    font-size: ${props => props.theme.fontSizes.xxSmall};
    font-weight: 400;
    line-height: ${props => props.theme.spaces.space_22};
    color: ${grey[700]};
  }

  & .true__option {
    border-radius: ${props => props.theme.spaces.space_mini};
    margin: ${props => props.theme.spaces.space_mini} 0;
  }

  .true__loading-indicator * {
    display: none;
  }

  & .true__option--is-focused {
    background-color: ${PRIMARY100};
  }

  & .true__option--is-selected,
  & .true__option--is-selected.true__option--is-focused {
    background-color: ${blue[400]};
    color: ${WHITE};
  }
`;

export const DropdownErrorMessageStyled = styled.div<{ theme?: BaseThemeType }>`
  padding-top: ${props => props.theme.spaces.space_5};
`;
