import React from 'react';
import { FormattedMessage } from 'i18n/formatted-message';

export const convertPricesToTableData = (prices, title) => [
  {
    type: (
      <b>
        <FormattedMessage id={title} />
      </b>
    ),
    ...Object.keys(prices).reduce((acc, item) => {
      if (title.includes('BUYER') && !parseFloat(prices[item])) {
        acc[item] = <FormattedMessage id="SETTINGS.FEES.BUYER.BUYER_FREE_PRICE_TABLE_VALUE" />;
      } else {
        acc[item] = parseFloat(prices[item]) + '%';
      }
      return acc;
    }, {}),
  },
];

export const convertPricesToTableDataForFeesWithoutDynamicSettlement = (
  sellerPrices: Record<string, string>,
  buyerPrices: Record<string, string>
) => [
  {
    type: (
      <b>
        <FormattedMessage id="SETTINGS.FEES.MERCHANT_FEE" />
      </b>
    ),
    ...Object.keys(sellerPrices).reduce((acc, item) => {
      acc[item] = parseFloat(sellerPrices[item]) + '%';
      return acc;
    }, {}),
  },
  {
    type: (
      <b>
        <FormattedMessage id="SETTINGS.FEES.BUYER_FEE" />
      </b>
    ),
    ...Object.keys(buyerPrices).reduce((acc, item) => {
      acc[item] = parseFloat(buyerPrices[item]) + '%';
      return acc;
    }, {}),
  },
];
export type tableOption = {
  label: React.ReactNode;
  value: string;
};
