// vendor
import styled from '@emotion/styled';
import { grey, WHITE } from 'theme/colors';

// utils
import { hexToRGBA } from 'utils';
import spaces from 'constants/spacing';
import fontSizes from 'constants/font-sizes';

export const ContactPersonDataStyled = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${WHITE};
  border: ${spaces.space_nano} solid ${grey[300]};
  box-sizing: border-box;
  box-shadow: 0 ${spaces.space_mini} ${spaces.space_xxxs} ${hexToRGBA(grey[300], 0.5)};
  border-radius: ${spaces.space_xxxs};
  margin-bottom: ${spaces.space_xxxl};
  padding: ${spaces.space_xl} ${spaces.space_xxxl} ${spaces.space_10};
  @media only screen and (max-width: 900px) {
    padding: 5%;
    overflow-x: scroll;
  }
`;

export const ContactPersonDataContentLabelStyled = styled.div`
  font-size: ${fontSizes.xSmall};
  line-height: ${spaces.space_m};
  color: ${grey[700]};
  margin-bottom: 22px;
`;

export const ContactPersonAutorisedPayerStyled = styled.div`
  color: ${grey[900]};
  font-weight: 700;
  font-size: ${fontSizes.large};
  line-height: ${spaces.space_xl};
  margin-bottom: ${spaces.space_22};
`;

export const ContactPersonContentStyled = {
  fontSize: fontSizes.xSmall,
  lineHeight: spaces.space_m,
  color: grey[700],
  marginBottom: '22px',
  cursor: 'pointer',
  marginRight: '24px',
  display: 'inline-flex',
  alignItems: 'center',
};
