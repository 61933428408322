export const URL_PREFIX = 'api/v1';
const URL_PREFIX_V2 = 'api/v2';

// Login api endpoints
export const REFRESH_TOKEN_ENDPOINT = `${URL_PREFIX}/token/refresh/`;
export const LOGIN_ENDPOINT = `${URL_PREFIX}/login/`;
export const SIGNUP_ENDPOINT = `${URL_PREFIX}/signup/`;
export const FORGOT_PASSWORD_ENDPOINT = `${URL_PREFIX}/forgot_password/`;
export const VERIFY_RESET_PASSWORD_TOKEN = `${URL_PREFIX}/reset_password/verify`; // append token to the endpoint - api/v1/reset_password/verify?token=<token>
export const EMAIL_VERIFY_LINK = `${URL_PREFIX}/verify/email`; // apend token at the end api/v1/verify/email?token="";
export const GET_COUNTRY_LIST = `${URL_PREFIX}/countries/`;
export const RESEND_EMAIL_VERIFICATION_LINK = `${URL_PREFIX}/resend_email_verification`;
export const GET_LIST_MERCHANTS = `${URL_PREFIX}/list_merchants`;

// Buyers API Endpoint
export const GET_ALL_BUYERS = sellerId => `${URL_PREFIX}/${sellerId}/buyer`;
export const GET_SINGLE_BUYER = sellerId => `${URL_PREFIX}/${sellerId}/buyer/details`;
export const ADD_BUYER = `${URL_PREFIX}/business_buyer/`;
export const ADD_BUSINESS_BUYER_CONTACT = sellerId =>
  `${URL_PREFIX}/business/${sellerId}/buyer/user`;
export const DELETE_BUYER_CONTACT = sellerId => `${URL_PREFIX}/business/${sellerId}/buyer/user`;
export const UPDATE_BUSINESS_BUYER_CONTACT = sellerId =>
  `${URL_PREFIX}/business/${sellerId}/buyer/user`;
export const APPROVE_BUYER = (sellerId, buyerId) => {
  return `${URL_PREFIX}/credit-decision/${sellerId}/buyer/${buyerId}/approve/`;
};
export const REJECT_BUYER = (sellerId, buyerId) => {
  return `${URL_PREFIX}/credit-decision/${sellerId}/buyer/${buyerId}/reject/`;
};

export const BUYERS_SEARCH = `${URL_PREFIX}/search/company`;

// Update credit limit
export const UPDATE_CREDIT_LIMIT = (buyerId: string, sellerId) =>
  `${GET_ALL_BUYERS(sellerId)}/${buyerId}/update_credit_limit/`;
export const GET_CREDIT_LIMIT = (buyerId: string, sellerId) =>
  `${GET_ALL_BUYERS(sellerId)}/${buyerId}/has_credit_limit_pending/`;

// update payment terms
export const UPDATE_PAYMENT_TERMS = (buyerId: string, sellerId) =>
  `${GET_ALL_BUYERS(sellerId)}/${buyerId}/update_payment_terms/`;
export const GET_PENDING_PAYMENT_TERM_REQUEST = (buyerId: string, sellerId) =>
  `${GET_ALL_BUYERS(sellerId)}/${buyerId}/has_payment_terms_pending/`;

// Invoice Endpoints
export const INVOICE = `${URL_PREFIX}/invoice`;
export const SETTLEMENT_DATE = ({ seller_id, invoice_id }) =>
  `/api/v2/pay-by-invoice/${seller_id}/invoice/settlement_date/${invoice_id}/`;
export const SPRINQUE_INVOICES = sellerId => `${URL_PREFIX}/sprinque-invoice/${sellerId}`;

// Settings Page endpoint
export const GET_SELLERS_USER_PROFILE_DETAILS = sellerId =>
  `${URL_PREFIX}/business_seller/profile/${sellerId}`;
export const GET_SELLERS_USER_PROFILE_DETAILS_FOR_GOD_MODE = (sellerId, userId) =>
  `${URL_PREFIX}/business_seller/profile/${sellerId}/user/${userId}/`;

export const SELLER_USER = sellerId => `${URL_PREFIX}/seller/${sellerId}/users/`;
export const GET_SELLERS_COMPANY_DETAILS = `${URL_PREFIX}/business_seller/`;
export const BUSINESS_SELLER = `${URL_PREFIX}/business/seller/`;
export const WHITELIST_URLS = `${URL_PREFIX}/business_seller/urls/`;
export const PAYMENT_COLLECTION = sellerId =>
  `${URL_PREFIX}/business_seller/payment-collection-accounts/${sellerId}/`;
export const EMAIL_SETTINGS = sellerId => `${URL_PREFIX}/business_seller/email/${sellerId}/`; // <- sellerId Missed in BE
export const SELLER_SETTINGS = `${URL_PREFIX}/business_seller/setting/`;
export const BANK_DETAILS = `${URL_PREFIX}/business_seller/account-details/`;
export const SELLER_PRICING = `${URL_PREFIX}/business_seller/pricing/`;

// Settlements
export const SETTLEMENTS = sellerId => `${URL_PREFIX}/settlements/${sellerId}/settlement/`;
export const FILE_UPLOAD = `${URL_PREFIX}/upload_file/`;

// Auth Call
export const API_KEY_GENERATE_ENDPOINT = sellerId => `${URL_PREFIX}/auth/apikey/${sellerId}`;
// export const AUTH = sellerId => `${URL_PREFIX_V2}/pay-by-invoice/${sellerId}/order/authorize`; // append buyer-id

// Capt Call
// export const CAPTURE = sellerId => `${URL_PREFIX_V2}/pay-by-invoice/${sellerId}/order/capture`; // append order-id

// God mode
export const GOD_MODE_SELLERS = `${URL_PREFIX}/god/sellers/`;

// orders
export const INVOICES = sellerId => `${URL_PREFIX_V2}/${sellerId}/invoices/`;
export const ORDERS = sellerId => `${URL_PREFIX_V2}/pay-by-invoice/${sellerId}/orders/`;
export const ORDER = sellerId => `${URL_PREFIX_V2}/pay-by-invoice/${sellerId}/order/`;
// export const REFUND = sellerId => `${URL_PREFIX_V2}/pay-by-invoice/${sellerId}/order/refund`;
export const PENDING_ORDERS = sellerId =>
  `${URL_PREFIX_V2}/pay-by-invoice/${sellerId}/pending_orders/`;

// will be removed after BE changes
export const AUTH = (sellerId, buyerId) =>
  `${URL_PREFIX}/pay-by-invoice/${sellerId}/transaction/authorize/${buyerId}/`; // append buyer-id
export const CAPTURE = (sellerId, transactionId) =>
  `${URL_PREFIX}/pay-by-invoice/${sellerId}/transaction/capture/${transactionId}/`; // append order-id
export const REFUND = (sellerId, transactionId) =>
  `${URL_PREFIX}/pay-by-invoice/${sellerId}/transaction/refund/${transactionId}/`;
export const VOID = (sellerId, transactionId) =>
  `${URL_PREFIX}/pay-by-invoice/${sellerId}/transaction/void/${transactionId}/`;

// Reporting
const REPORTING = `${URL_PREFIX}/reporting`;
export const YTD_METRICS = `${REPORTING}/ytd_metrics`;
export const TOTAL_VS_PAID_INVOICES = `${REPORTING}/total_vs_paid_invoices_count`;
export const TOP_OVERDUE_INVOICES = `${REPORTING}/top_overdue_invoices`;
export const TOP_DEBTORS = `${REPORTING}/top_debtors`;
export const INVOICE_COLLECTIONS = `${REPORTING}/invoice_collection`;
export const GMV_NMV_TREND = `${REPORTING}/gmv_vs_nmv_trend`;
export const TOTAL_BUYER_COUNT = `${REPORTING}/comparison/total_vs_invoiced_buyers_count`;

// KYC
export const KYC = sellerId => `${URL_PREFIX}/kyc/${sellerId}`;

// Payables
export const PAYABLES_API = buyerId => `${URL_PREFIX}/buyers/${buyerId}/invoices/`;
