export const BuyersUserDesgination = [
  { value: 'ACCOUNTS_PAYABLE', label: 'Accounts Payable' },
  { value: 'FINANCE_AND_ACCOUNTING', label: 'Finance and Accounting' },
  { value: 'GENERAL', label: 'General' },
  { value: 'PURCHASE_AND_PROCUREMENT', label: 'Procurement & purchasing' },
];

export const SellersUserDesgination = [
  { value: 'FINANCE_AND_ACCOUNTING', label: 'Finance and Accounting' },
  { value: 'SALES', label: 'Sales' },
  { value: 'GENERAL', label: 'General' },
];

export const UserDesginationMappingConstants = {
  ACCOUNTS_PAYABLE: 'Accounts Payable',
  ADMIN: 'Admin',
  FINANCE_AND_ACCOUNTING: 'Finance and Accounting',
  GENERAL: 'General',
  MEMBER: 'Member',
  NOT_DEFINE: 'Others',
  PURCHASE_AND_PROCUREMENT: 'Procurement & purchasing',
  SALES: 'Sales',
};

export const mapSetTerm = {
  PAY_IN_ADVANCE: 'Pay in advance',
  NET7: 'NET7',
  NET15: 'NET15',
  NET30: 'NET30',
  NET45: 'NET45',
  NET60: 'NET60',
  NET90: 'NET90',
  PAY_IN_3: 'Pay in 3',
  PAY_IN_6: 'Pay in 6',
  PAY_IN_9: 'Pay in 9',
  PAY_IN_12: 'Pay in 12',
};

export const termOrder = {
  PAY_IN_ADVANCE: 0,
  NET7: 1,
  NET15: 2,
  NET30: 3,
  NET45: 4,
  NET60: 5,
  NET90: 6,
};

export enum PaymentTerms {
  PAY_IN_ADVANCE = 'PAY_IN_ADVANCE',
  NET7 = 'NET7',
  NET15 = 'NET15',
  NET30 = 'NET30',
  NET45 = 'NET45',
  NET60 = 'NET60',
  NET90 = 'NET90',
}

export const NET_TERM_VALUES = {
  NET7: 7,
  NET15: 15,
  NET30: 30,
  NET45: 45,
  NET60: 60,
  NET90: 90,

  // Pay in installments outstanding days
  PAY_IN_3: 60,
  PAY_IN_6: 105,
  PAY_IN_9: 150,
  PAY_IN_12: 195,
};
