// vendor
import React from 'react';
import { useAppSelector } from 'store';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  timelineItemClasses,
} from '@mui/lab';
import { Box } from '@mui/material';
import { TimelineRounded } from '@mui/icons-material';

// constants
import { black } from 'theme/colors';

// components
import { FormattedMessage } from 'i18n/formatted-message';
import { CustomTimelineContent } from './timeline-content';

// styles
import {
  BuyerDetailsCreditAssessmentLabelStyled,
  BuyerDetailsCreditAssessmentSectionStyled,
  BuyerDetailsDataStyled,
} from './styled';

export const TimelineUpdate = () => {
  const {
    update_requests = [],
    credit_qualification: {
      currency: { symbol },
    },
  } = useAppSelector(state => state.buyer.buyerDetails);

  return update_requests.length ? (
    <BuyerDetailsCreditAssessmentSectionStyled bottomPadding>
      <Box display="flex" alignItems="center" gap="10px">
        <TimelineRounded style={{ fill: 'url(#with-icon-gradient)' }} />
        <BuyerDetailsCreditAssessmentLabelStyled>
          <FormattedMessage id="BUYER.UPDATE_REQUEST.UPDATES" />
        </BuyerDetailsCreditAssessmentLabelStyled>
      </Box>
      <BuyerDetailsDataStyled>
        <Timeline
          sx={{
            padding: 0,
            margin: 0,
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {update_requests.map((res, index) => {
            return (
              <TimelineItem sx={{ margin: 0 }} key={`buyer-update-timeline-record-${index}`}>
                <TimelineSeparator>
                  <TimelineDot sx={{ background: black[300], color: black[300], borderWidth: 0 }} />
                  {update_requests.length - 1 !== index && (
                    <TimelineConnector sx={{ background: black[300], color: black[300] }} />
                  )}
                </TimelineSeparator>
                <CustomTimelineContent {...res} currencySymbol={symbol} />
              </TimelineItem>
            );
          })}
        </Timeline>
      </BuyerDetailsDataStyled>
    </BuyerDetailsCreditAssessmentSectionStyled>
  ) : (
    <></>
  );
};
