// vendor
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from 'store';
import { format } from 'date-fns';
import { Done, Refresh } from '@mui/icons-material';

// components
import { MpButton, MpModal, CreateForm, FormattedMessage } from 'UI';

// constants
import {
  refundPopupFormFields,
  valuesToValidateOnRefundPopupForm,
  initialValuesOnRefundPopupFormFields,
} from 'constants/form-data';
import { FILE_UPLOAD, REFUND } from 'constants/api-endpoints.constants';

// helpers
import { postData } from 'api';
import { errorMessage } from 'utils';
import { startLoading, stopLoading } from 'store/actions';

// styles
import {
  RefundCreditNoteCreatedBoxWrapperStyled,
  RefundCreditNoteCreatedIconWrapperStyled,
  RefundCreditNoteCreatedTitleStyled,
  RefundCreditNoteCreatedBuyerIdStyled,
} from './styled';
import { GHOST } from 'constants/button-types';
import { DATE_FORMAT_FOR_API } from 'constants/date';

type Props = {
  invoiceId: string;
  onSuccess: () => any;
  currencyCode: string;
};

export const Refund = ({ invoiceId, onSuccess, currencyCode }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { sellerId } = useAppSelector(state => state.login);
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    openPopup: false,
    currState: 0,
    generatedCreditNoteId: '',
  });

  const changeState = popupState => {
    if (!popupState) {
      setState({
        openPopup: popupState,
        currState: 0,
        generatedCreditNoteId: '',
      });
    } else {
      setState({
        ...state,
        openPopup: popupState,
      });
    }
  };

  const postRefund = (invoiceId, values) => {
    postData(REFUND(sellerId, invoiceId), values)
      .then(res => {
        setState({
          ...state,
          currState: 1,
          generatedCreditNoteId: res.data.credit_note.credit_note_id,
        });
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };

  const onSubmit = ({
    merchantCreditNoteId,
    merchantCreditNoteFile,
    creditNoteCreationDate,
    creditNoteAmount,
  }) => {
    const formData = new FormData();
    formData.append('file', merchantCreditNoteFile);
    dispatch(startLoading);
    postData(FILE_UPLOAD, formData, {
      'Content-Type': 'multipart/form-data',
    })
      .then(res => {
        const values = {
          merchant_credit_note_id: merchantCreditNoteId,
          credit_note_url: res.data.url,
          credit_note_amount: creditNoteAmount,
          credit_note_currency: currencyCode || 'EUR',
          date: format(new Date(creditNoteCreationDate), DATE_FORMAT_FOR_API),
        };
        postRefund(invoiceId, values);
      })
      .catch(err => {
        dispatch(stopLoading);
        errorMessage(err, enqueueSnackbar);
      });
  };

  return (
    <div>
      <MpButton onClick={() => changeState(true)} type={GHOST}>
        <Refresh style={{ transform: 'scaleX(-1)', marginRight: '5px' }} />
        <FormattedMessage id="REFUND_POPUP_BUTTON_LABEL" />
      </MpButton>
      <MpModal
        title={state.currState === 0 ? 'REFUND_POPUP_TITLE' : ''}
        modalOpen={state.openPopup}
        handleClose={() => {
          if (state.currState === 1) {
            onSuccess(); // re-fetch invoice info
          }
          changeState(false);
        }}
        maxWidth="sm"
      >
        {state.currState === 0 ? (
          <CreateForm
            secondaryButtonConfig={{
              label: <FormattedMessage id="CANCEL" />,
              handleCancel: () => changeState(false),
            }}
            primaryButtonConfig={{ label: <FormattedMessage id="SAVE" />, handleSubmit: onSubmit }}
            formFields={refundPopupFormFields(currencyCode)}
            valuestoValidate={valuesToValidateOnRefundPopupForm}
            initialValue={{ ...initialValuesOnRefundPopupFormFields, currencyCode }}
          />
        ) : (
          <RefundCreditNoteCreatedBoxWrapperStyled>
            <RefundCreditNoteCreatedIconWrapperStyled>
              <Done style={{ color: '#10E9D7' }} />
            </RefundCreditNoteCreatedIconWrapperStyled>
            <RefundCreditNoteCreatedTitleStyled>
              <FormattedMessage id="REFUND_REQUEST_SUCCESSFUL" />
            </RefundCreditNoteCreatedTitleStyled>
            <RefundCreditNoteCreatedBuyerIdStyled isMarginPresent>
              <FormattedMessage id="REFUND_REQUEST_SUCCESSFUL_ID_MESSAGE" />
            </RefundCreditNoteCreatedBuyerIdStyled>
            <RefundCreditNoteCreatedBuyerIdStyled isDarkerFont>
              {state.generatedCreditNoteId}
            </RefundCreditNoteCreatedBuyerIdStyled>
          </RefundCreditNoteCreatedBoxWrapperStyled>
        )}
      </MpModal>
    </div>
  );
};
