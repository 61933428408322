import styled from '@emotion/styled';
import ChevronDown from 'assets/icons/chevron-down.svg';
import { grey, blue, PRIMARY100, WHITE } from 'theme/colors';
import { hexToRGBA } from 'utils';
import spaces from 'constants/spacing';
import fontSizes from 'constants/font-sizes';

export const PhoneInputWrapper = styled.div`
  width: 100%;
  .phone-number-input-class {
    width: 100%;
    border: ${spaces.space_nano} solid ${grey[300]};
    border-radius: ${spaces.space_mini};
    font-size: ${fontSizes.xxSmall};
    color: ${grey[700]} !important;
    padding-left: 76px;
    padding-right: 12px;
    height: 40px;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${grey[400]};
      opacity: 1; /* Firefox */
      font-size: ${fontSizes.xxSmall};
      line-height: ${spaces.space_22};
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${grey[400]};
      font-size: ${fontSizes.xxSmall};
      line-height: ${spaces.space_22};
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${grey[400]};
      font-size: ${fontSizes.xxSmall};
      line-height: ${spaces.space_22};
    }
  }

  .phone-number-dropdown-class {
    border-radius: ${spaces.space_mini} 0 0 ${spaces.space_mini};
    border: ${spaces.space_nano} solid ${grey[300]};
    background-color: ${WHITE};
    border-right: 0;

    .selected-flag {
      padding: 0 0 0 ${spaces.space_xxs};
      .arrow {
        border: none;
        background-image: url(${ChevronDown});
        background-repeat: no-repeat;
        background-size: ${spaces.space_15} ${spaces.space_7};
        width: 15px;
        height: 7px;
        margin-top: -${spaces.space_3};
        left: ${spaces.space_xl};
      }
    }
  }

  .country-list {
    padding: ${spaces.space_mini} ${spaces.space_xxxs};
    border: ${spaces.space_nano} solid ${grey[300]};
    box-sizing: border-box;
    box-shadow: 0 ${spaces.space_mini} ${spaces.space_xxxs} ${hexToRGBA(grey[300], 0.5)};
    border-radius: ${spaces.space_mini};

    .country {
      font-size: ${fontSizes.xxSmall};
      font-weight: 400;
      line-height: ${spaces.space_22};
      color: ${grey[700]};
      padding: ${spaces.space_xxxs} 12px;
      margin: ${spaces.space_mini} 0;
      border-radius: ${spaces.space_mini};

      :hover {
        background: ${PRIMARY100};
      }

      + .country.highlight,
      + .country.highlight > .dial-code {
        background-color: ${blue[400]};
        color: ${WHITE};
      }
    }
  }
`;
