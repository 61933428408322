// vendor
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { BaseThemeType } from 'theme/baseTheme';

export const MpLinkStyled = styled(Link)<{ theme?: BaseThemeType }>`
  color: ${props => props.theme.colors.primary200};

  font-size: ${props => props.theme.fontSizes.xxSmall};
  line-height: ${props => props.theme.spaces.space_22};
  font-weight: 400;
  text-decoration: ${props => (props.isTextUnderline ? 'underline' : 'none')};
  :hover {
    text-decoration: underline;
  }
  ${props => props.additionalStyles};
`;
