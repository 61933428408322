import styled from '@emotion/styled';
import Backdrop from '@mui/material/Backdrop';
import { BaseThemeType } from 'theme/baseTheme';

export const LoaderBackdropStyled = styled(Backdrop)<{ theme?: BaseThemeType }>`
  color: ${props => props.theme.colors.lightBackground};
  z-index: 999999999;

  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #333;
    margin: 0 10px;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    animation-delay: -0.32s;
    background: ${props => props.theme.colors.dark};
  }

  .spinner .bounce2 {
    animation-delay: -0.16s;
    background: ${props => props.theme.colors.brightGreen};
  }

  .spinner .bounce3 {
    animation-delay: -0.08s;
    background: ${props => props.theme.colors.brightOrange};
  }

  .spinner .bounce4 {
    background: ${props => props.theme.colors.primary200};
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;
