import styled from '@emotion/styled';
import { RadioGroup } from '@mui/material';
import { blue } from 'theme/colors';
import fontSizes from 'constants/font-sizes';

export const RadioGroupStyled = styled(RadioGroup)`
  label {
    margin: 0 0 24px;
    span.MuiRadio-root {
      padding: 0 8px 0 0;
    }
    span.Mui-checked {
      color: #000;
    }
  }
`;

export const SettlementsEmailBoxStyled = styled.div`
  padding: 3px 6px 3px 10px;
  font-size: ${fontSizes.xxSmall};
  font-weight: 600;
  line-height: 22px;
  gap: 6px;
  border-radius: 100px;
  color: ${blue[400]};
  background: #f5f8ff;
  display: flex;
  align-items: center;
`;
