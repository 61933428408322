// vendor
import React from 'react';

// styles
import { LoaderBackdropStyled } from './loader.styled';
import { useAppSelector } from 'store';
import { Box, CircularProgress } from '@mui/material';

export default function Loader() {
  const isLoading = useAppSelector(state => state.loading.isLoading);
  if (!isLoading) return null;
  return (
    <LoaderBackdropStyled open={isLoading}>
      <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
        <div className="bounce4" />
      </div>
    </LoaderBackdropStyled>
  );
}

export const SectionLoader = () => {
  return (
    <Box minHeight="100px" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress color="info" />
    </Box>
  );
};
