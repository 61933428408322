// vendor
import styled from '@emotion/styled';
import { BaseThemeType } from 'theme/baseTheme';
import spaces from 'constants/spacing';
import { grey } from 'theme/colors';
import { Box } from '@mui/material';

export const ForgotPasswordWrapperStyled = styled.div<{
  theme?: BaseThemeType;
  emailSuccessfullySent?: boolean;
}>`
  margin: ${spaces.space_xl} 0;
  text-align: ${props => props.emailSuccessfullySent && 'center'};
  min-height: 220px;
  position: relative;
`;

export const ForgotPasswordDescriptionStyled = styled(Box)<{ theme?: BaseThemeType }>`
  font-size: ${props => props.theme.fontSizes.xSmall};
  line-height: ${spaces.space_m};
  font-weight: 400;
  color: ${grey[900]};
`;

export const ForgotPasswordEmailSuccessTitleStyled = styled.div<{ theme?: BaseThemeType }>`
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.large};
  line-height: ${spaces.space_xl};
  text-align: center;
  color: ${grey[900]};
  margin: ${spaces.space_s} 0px ${spaces.space_tera};
`;
