import React, { useEffect } from 'react';
import { MoreVert, HighlightOff, AddRounded } from '@mui/icons-material';
import { Grow, Paper, Popper, Grid, ClickAwayListener, Typography } from '@mui/material';
import { useAppDispatch } from 'store';
import { format } from 'date-fns';

import { FormattedMessage } from 'UI';
import { setOrderIdForVoid, setOrderDetails } from 'store/actions/orders';
import { grey } from 'theme/colors';
import { updateInvoiceStepData } from 'store/actions/invoices';
import { OPEN_ADD_INVOICE_POPUP } from 'store/types';
import { DATE_FORMAT_FOR_API } from 'constants/date';
import { AUTH_TRANSACTION } from 'store/types/ordersItem';

export enum VOID_FOR {
  PENDING_ORDER = 'PENDING_ORDER',
  PROCESSED_ORDERS = 'PROCESSED_ORDERS',
}

type OrderTableActionsProps = {
  displayVoid: boolean;
  displayAddInvoice: boolean;
  orderDetails: {
    orderId: string;
    merchantOrderId?: string;
    buyerId?: string;
    orderCurrency?: string;
    authTransactions?: Array<AUTH_TRANSACTION>;
    voidFor?: VOID_FOR.PENDING_ORDER | VOID_FOR.PROCESSED_ORDERS | undefined;
  };
};

export const OrderTableActions = ({
  displayVoid,
  displayAddInvoice,
  orderDetails = {
    orderId: '',
    merchantOrderId: '',
    buyerId: '',
    orderCurrency: '',
    authTransactions: [],
    voidFor: undefined,
  },
}: OrderTableActionsProps) => {
  const { orderId, merchantOrderId, buyerId, orderCurrency, authTransactions, voidFor } =
    orderDetails;
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = e => {
    e.stopPropagation();
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    // @ts-ignore
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      // @ts-ignore
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      {displayVoid || displayAddInvoice ? (
        <MoreVert
          ref={anchorRef}
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          data-sdet={`${merchantOrderId}-more-icon-actions`}
          onClick={handleToggle}
        />
      ) : (
        '-'
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper sx={{ padding: '8px' }}>
              {displayVoid ? (
                <ClickAwayListener onClickAway={handleClose}>
                  <Grid
                    style={{ cursor: 'pointer' }}
                    container
                    gap={1}
                    onClick={() =>
                      dispatch(setOrderIdForVoid({ orderIdForVoid: orderId, voidFor }))
                    }
                    px={1.5}
                    py="9px"
                    minWidth="100px"
                  >
                    <HighlightOff sx={{ fontSize: '20px', transform: 'translate(0px, 1px)' }} />
                    <Typography
                      sx={{
                        fontSize: '14px',
                        lineHeight: '22px',
                        fontWeight: '400',
                        color: grey[900],
                      }}
                    >
                      <FormattedMessage id="ORDERS.VOID" />
                    </Typography>
                  </Grid>
                </ClickAwayListener>
              ) : (
                ''
              )}
              {displayAddInvoice ? (
                <ClickAwayListener onClickAway={handleClose}>
                  <Grid
                    style={{ cursor: 'pointer' }}
                    container
                    gap={1}
                    onClick={() => {
                      dispatch(setOrderDetails(buyerId, orderCurrency, orderId, authTransactions));
                      dispatch(
                        updateInvoiceStepData(1, {
                          merchantOrderId,
                          merchantInvoiceId: '',
                          invoiceAmount: '',
                          merchantInvoiceFile: '',
                          merchantInvoiceDate: format(new Date(), DATE_FORMAT_FOR_API),
                          disableFields: {
                            merchantOrderId: true,
                            isCurrencyDropdownDisabled: true,
                          },
                        })
                      );

                      dispatch({ type: OPEN_ADD_INVOICE_POPUP });
                    }}
                    px={1.5}
                    py="9px"
                    minWidth="100px"
                  >
                    <AddRounded sx={{ fontSize: '20px', transform: 'translate(0px, 1px)' }} />
                    <Typography
                      sx={{
                        fontSize: '14px',
                        lineHeight: '22px',
                        fontWeight: '400',
                        color: grey[900],
                      }}
                    >
                      <FormattedMessage id="ADD_INVOICE_BUTTON_LABEL" />
                    </Typography>
                  </Grid>
                </ClickAwayListener>
              ) : (
                ''
              )}
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
