// vendor
import React, { useEffect, useState } from 'react';
import zxcvbn from 'zxcvbn';

// constants
import STRENGTH from './password-strength.constants';

// styles
import {
  PasswordStrengthMeterWrapperStyled,
  PasswordStrengthMeterBlockStyled,
  PasswordStrengthMeterLabelStyled,
  PasswordStrengthBlockWrapper,
  PasswordStrengthLabelStyled,
} from './password-strength-meter.styled';
import { FormattedMessage } from 'UI';

type Props = {
  password?: string;
};

interface PasswordStrengthBlock {
  strength: number;
  password?: string;
}

const TotalBlocks = 4;

const RenderPasswordStrengthBlock = ({ strength, password = '' }: PasswordStrengthBlock) => {
  const [strengthBlocks, setStrengthBlock] = useState<Array<React.ReactNode | string>>([]);
  const [state, setState] = useState<string>('');

  useEffect(() => {
    const newStrengthBlocks: Array<React.ReactNode | string> = [];

    for (let index = -1; index < TotalBlocks; index++) {
      let newState = '';
      if (strength === 0) {
        newState = STRENGTH.VERY_WEAK;
        setState(STRENGTH.VERY_WEAK);
      }

      if (strength === 1) {
        newState = STRENGTH.WEAK;
        setState(STRENGTH.WEAK);
      }

      if (strength === 2) {
        newState = STRENGTH.NEUTRAL;
        setState(STRENGTH.NEUTRAL);
      }

      if (strength === 3) {
        newState = STRENGTH.STRONG;
        setState(STRENGTH.STRONG);
      }

      if (strength === 4) {
        newState = STRENGTH.VERY_STRONG;
        setState(STRENGTH.VERY_STRONG);
      }

      const isActive = index < strength;

      const createBlock = (
        <PasswordStrengthMeterBlockStyled
          isActive={isActive}
          state={newState}
          passwordLength={password.length}
        />
      );
      newStrengthBlocks.push(createBlock);
      setStrengthBlock(newStrengthBlocks);
    }
  }, [strength, password]);

  return (
    <>
      <PasswordStrengthBlockWrapper>{strengthBlocks}</PasswordStrengthBlockWrapper>
      <PasswordStrengthMeterLabelStyled>{state}</PasswordStrengthMeterLabelStyled>
    </>
  );
};

function PasswordStrengthMeter(props: Props) {
  const { password } = props;
  const [strength, setStrength] = useState(0);

  useEffect(() => {
    if (password) {
      setStrength(zxcvbn(password).score);
    }
  }, [password]);

  return (
    <>
      <PasswordStrengthLabelStyled>
        <FormattedMessage id="PASSWORD_MINIMUM_CHARACTER_MESSAGE" />
      </PasswordStrengthLabelStyled>
      <PasswordStrengthMeterWrapperStyled>
        <RenderPasswordStrengthBlock strength={strength} password={password} />
      </PasswordStrengthMeterWrapperStyled>
    </>
  );
}

export default PasswordStrengthMeter;
