import * as React from 'react';
import { Alert, AlertTitle, Stack } from '@mui/material';

interface Props {
  message: string;
}
export const ErrorPage = ({ message }: Props) => {
  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="error">
        <AlertTitle> {message}</AlertTitle>
      </Alert>
    </Stack>
  );
};
