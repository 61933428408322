import React from 'react';
import { FormattedMessage } from 'i18n/formatted-message';

// hack as we can't use merchant_status twice
export const ORDERS_FILTER_STATUS = 'order_status';

export const FILTER_OPTIONS = {
  payment_terms: [
    {
      label: <FormattedMessage id="SET_TERMS_PAY_ADVANCE" />,
      value: 'PAY_IN_ADVANCE',
    },
  ],
  invoice_status: [
    {
      label: <FormattedMessage id="FILTERS.OPEN" />,
      value: 'OPEN',
    },
    {
      label: <FormattedMessage id="FILTERS.OVERDUE" />,
      value: 'OVERDUE',
    },
    {
      label: <FormattedMessage id="FILTERS.PAID" />,
      value: 'PAID',
    },
    {
      label: <FormattedMessage id="FILTERS.CAPTURE_DECLINE" />,
      value: 'CAPTURE DECLINED',
    },
    {
      label: <FormattedMessage id="FILTERS.RETURNED" />,
      value: 'RETURNED',
    },
    {
      label: <FormattedMessage id="FILTERS.PARTIALLY_RETURNED" />,
      value: 'PARTIALLY RETURNED',
    },
    {
      label: <FormattedMessage id="FILTERS.REFUNDED" />,
      value: 'REFUNDED',
    },
    {
      label: <FormattedMessage id="FILTERS.PARTIALLY_REFUNDED" />,
      value: 'PARTIALLY REFUNDED',
    },
  ],
  transaction_status: [
    {
      label: <FormattedMessage id="FILTERS.CAPTURED" />,
      value: 'CAPTURED',
    },
    {
      label: <FormattedMessage id="FILTERS.PARTIALLY_REVERSED" />,
      value: 'PARTIALLY REVERSED',
    },
    {
      label: <FormattedMessage id="FILTERS.REVERSED" />,
      value: 'REVERSED',
    },
    {
      label: <FormattedMessage id="FILTERS.SETTLED" />,
      value: 'SETTLED',
    },
    {
      label: <FormattedMessage id="FILTERS.CAPTURE_DECLINE" />,
      value: 'CAPTURE DECLINED',
    },
  ],
  [ORDERS_FILTER_STATUS]: [
    {
      label: <FormattedMessage id="FILTERS.AUTHORIZED" />,
      value: 'AUTHORIZED',
    },
    {
      label: <FormattedMessage id="FILTERS.AUTH_DECLINE" />,
      value: 'AUTH DECLINED',
    },
    {
      label: <FormattedMessage id="FILTERS.VOIDED" />,
      value: 'VOIDED',
    },
  ],
};

export const DEFAULT_ROWS_PER_PAGE = 25;
export const SETTLEMENTS_DEFUALT_ROWS_PER_PAGE = 10;

export const PAGES = [10, 25, 50];
