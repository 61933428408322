export default {
  xxxLarge: '48px',
  xxLarge: '34px',
  xLarge: '30px',
  large: '24px',
  medium: '20px',
  small: '18px',
  xSmall: '16px',
  xxSmall: '14px',
  xxxSmall: '12px',
};
