// vendor
import React from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { MoreVert, SupervisorAccountOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Divider } from '@mui/material';

// components
import { Contact } from './contact';
import { FormattedMessage } from 'i18n/formatted-message';
import { MpButton } from 'UI';

// actions
import { deleteBuyersContact, openAddPersonPopup } from 'store/actions';

// utils
import { snackbarConfig } from 'utils';

import { ContactsWrapperStyled } from './styled';

// constants
import { TABLE_USE_CASE_BUYERS } from 'UI/components/table/constants';
import { Box, Typography } from '@mui/material';
import { OPEN_ADD_BUYER_TEAM_PERSON_POPUP } from 'store/types';

const EDIT = 'Edit';
const DELETE = 'Delete';

const dropdownMenuList = [
  { label: <FormattedMessage id="EDIT" />, action: EDIT, payload: { path: '/' } },
  { label: <FormattedMessage id="DELETE" />, action: DELETE, payload: { path: '/' } },
];

export const BuyerContact = () => {
  const contacts = useAppSelector(state => state.buyer.contactList);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { _uuid, id } = useAppSelector(state => state.buyer?.buyerDetails || {});
  const { sellerId } = useAppSelector(state => state.login);

  const onMenuItemClick = (_, action, payload) => {
    if (action === EDIT) {
      dispatch({ ...openAddPersonPopup, payload: payload.data });
    }

    const contactId = payload.data._uuid || payload.data.id;

    if (action === DELETE) {
      if (contacts.length > 1) {
        dispatch(deleteBuyersContact(_uuid || id, contactId, enqueueSnackbar, sellerId));
      } else {
        enqueueSnackbar(
          <FormattedMessage id="SINGLE_BUYER_CONTACT_DELETE_ERROR" />,
          snackbarConfig({ type: 'error' })
        );
      }
    }
  };

  return (
    <>
      <ContactsWrapperStyled>
        <Box display="flex" alignItems="center" gap="18px" mb={2}>
          <SupervisorAccountOutlined style={{ fill: 'url(#with-icon-gradient)' }} />
          <Typography
            variant="h4"
            component="h4"
            sx={{
              fontSize: '20px',
              fontWeight: '700',
              lineHeight: '32px',
            }}
          >
            <FormattedMessage id="BUYER.CONTACT_PERSON.TITLE" />
          </Typography>
        </Box>

        <Typography
          mb={3}
          sx={{
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '20px',
          }}
        >
          <FormattedMessage id="BUYER.CONTACT_PERSON.DESCRIPTION" />
        </Typography>

        {contacts.map((person, index) => {
          const isContactSingle = contacts.length === 1;
          return (
            <>
              <Contact
                data={{ ...person }}
                key={`buyers-contact-person-list-${index}`}
                size="xs"
                useCase={TABLE_USE_CASE_BUYERS}
                // @ts-ignore
                dropDownConfig={
                  !isContactSingle && {
                    onMenuItemClick: onMenuItemClick,
                    dropdownMenuList: dropdownMenuList,
                    label: <MoreVert />,
                  }
                }
                isContactSingle={isContactSingle}
                editableHandler={data => dispatch({ ...openAddPersonPopup, payload: data })}
              />
              <Divider sx={{ border: '1px solid #ECEDF8', my: '16px' }} />
            </>
          );
        })}

        <Box mt={2}>
          <MpButton onClick={() => dispatch({ type: OPEN_ADD_BUYER_TEAM_PERSON_POPUP })}>
            <FormattedMessage id="ADD_NEW" />
          </MpButton>
        </Box>
      </ContactsWrapperStyled>
    </>
  );
};
