// vendor
import React, { Suspense, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// theme
import { grey } from 'theme/colors';

// components
import {
  ApiKeys,
  KycProcess,
  Loader,
  SettingsCompany,
  SettingsTeam,
  SettingsUserProfile,
} from 'UI';
import { EmailsManagement } from './notifications';
import { PaymentCollection } from './payment-collection';
import { Fees } from './fees';
import { Billing } from './billing';

// constants
import {
  SETTINGS_API_KEYS,
  SETTINGS_EMAILS,
  SETTINGS_USER_PROFILE,
  PAYMENT_COLLECTION,
  SETTINGS_COMPANY,
  SETTINGS_TEAM,
  SETTINGS_FEES,
  SETTINGS_BILLING,
  SETTING_KYC_PROCESS,
} from 'constants/routes.constants';
import { EMAIL_SETTINGS } from 'constants/api-endpoints.constants';
import { SET_EMAIL_CONFIG } from 'store/types';

// api
import { getData } from 'api';

// utils
import { errorMessage } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';

export const fontStyle = {
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '32px',
  color: grey[700],
};

const fetchEmailConfig = (sellerId, onSuccess, enqueueSnackbar) => {
  getData(`${EMAIL_SETTINGS(sellerId)}`)
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch(error => {
      errorMessage(error, enqueueSnackbar);
    });
};

const Settings = () => {
  const {
    login: { sellerId },
    user: {
      userProfile: { status: sellerStatus },
    },
  } = useAppSelector(state => state);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const setEmailConfig = data => {
    dispatch({ type: SET_EMAIL_CONFIG, payload: data });
  };

  useEffect(() => {
    fetchEmailConfig(sellerId, setEmailConfig, enqueueSnackbar);
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={SETTINGS_USER_PROFILE} render={() => <SettingsUserProfile />} />
        <Route exact path={SETTINGS_COMPANY} render={() => <SettingsCompany />} />
        <Route exact path={SETTINGS_TEAM} render={() => <SettingsTeam />} />
        <Route exact path={SETTINGS_API_KEYS} render={() => <ApiKeys />} />
        {sellerStatus !== 'ACTIVE' && (
          <Route exact path={SETTING_KYC_PROCESS} render={() => <KycProcess />} />
        )}
        <Route exact path={SETTINGS_EMAILS} component={EmailsManagement} />
        <Route exact path={PAYMENT_COLLECTION} component={PaymentCollection} />
        <Route exact path={SETTINGS_FEES} component={Fees} />
        <Route exact path={SETTINGS_BILLING} component={Billing} />
      </Switch>
    </Suspense>
  );
};

export { Settings };
