// constants
import * as TYPES from 'store/types';
import { CurrencyCode, CurrencyItem } from 'constants/currency';
import { getCurrencySymbol } from 'utils/currencies';
import { SettingsReducerType, SellerConfig } from 'store/types/settings';
import { getTranslatedCountry } from 'b2b-sprinque-tools';

const initialState: SettingsReducerType = {
  countries: [],
  sellerConfig: {} as SellerConfig,
  paymentCollections: [],
  currencyList: [],
  buyerFee: {},
  sellerFee: {},
  dynamicFee: {
    base_fee: 0.0,
    fee_per_day: 0.0,
    minimum_fee: 0.0,
  },
  notifications: {
    receive_copy: {
      copy: 'cc',
      to_seller_users: [],
    },
    settlements_email: [],
  },
};

export const settings = (state = initialState, action): SettingsReducerType => {
  switch (action.type) {
    case TYPES.FETCHED_COUNTRIES:
      return { ...state, countries: action.payload };

    case TYPES.UPDATE_COUNTRY_LABEL_BASED_ON_LANG:
      const countries = state.countries.map(country => {
        return {
          ...country,
          label: getTranslatedCountry(
            // @ts-ignore
            localStorage.getItem('uiLang') || 'en',
            country.code.toLowerCase()
          ),
        };
      });
      return { ...state, countries };

    case TYPES.SELLER_CONFIG:
      localStorage.setItem('sellerLang', action.payload.default_lang || 'en');
      return {
        ...state,
        sellerConfig: action.payload,
      };

    case TYPES.PAYMENT_COLLECTIONS: {
      const currenciesFromApi = action.payload.map(
        item => item.currency_code
      ) as Array<CurrencyCode>;
      const currenciesWithoutDuplicates: Array<CurrencyCode> = [...new Set(currenciesFromApi)];
      const filteredCurrencyList = currenciesWithoutDuplicates.map(
        currencyCode =>
          ({
            value: getCurrencySymbol(currencyCode),
            label: currencyCode,
            name: currencyCode,
          } as CurrencyItem)
      );
      return { ...state, paymentCollections: action.payload, currencyList: filteredCurrencyList };
    }

    case TYPES.SET_MERCHANT_FEES: {
      return { ...state, ...action.payload };
    }

    case TYPES.SET_EMAIL_CONFIG: {
      return { ...state, notifications: action.payload };
    }

    case TYPES.RESET: {
      return { ...initialState, countries: state.countries };
    }
    default:
      return { ...state };
  }
};
