import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useAppSelector, useAppDispatch } from 'store';

// constants
import { grey } from 'theme/colors';

// icons
import { Upgrade } from '@mui/icons-material';

// components
import { MpModal, CreateForm, FormattedMessage, SectionLoader } from 'UI';
import { UpdateRequestSuccess } from './update-request-success';

import {
  updateCreditLimitFormFields,
  valuesToValidateOnUpdateCreditLimitFormFields,
  initialValuesUpdateCreditLimitFormFields,
} from 'constants/form-data';

import {
  FILE_UPLOAD,
  UPDATE_CREDIT_LIMIT,
  GET_CREDIT_LIMIT,
} from 'constants/api-endpoints.constants';
import { getData, postData } from 'api';
import { startLoading, stopLoading } from 'store/actions';
import { errorMessage, formatter, snackbarConfig } from 'utils';
import { PendingRequest } from './pending-request';

interface AddPersonProps {
  openModal: boolean;
  closeModal: () => void;
}

export const UpdateCreditLimit = ({ openModal, closeModal }: AddPersonProps) => {
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const {
    credit_qualification: {
      credit_limit,
      currency: { symbol, code },
    },
    _uuid,
  } = useAppSelector(state => state.buyer.buyerDetails);
  const { sellerId } = useAppSelector(state => state.login);
  const [pendingReview, setPendingReview] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const getPreviousCreditLimitRequest = () => {
    setLoader(true);
    getData(GET_CREDIT_LIMIT(_uuid || '', sellerId))
      .then(({ data }) => {
        if (data.has_pending_credit_limit_update) {
          setPendingReview(true);
        }
      })
      .catch(e => {
        enqueueSnackbar(e.message, snackbarConfig({ type: 'error' }));
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (openModal) {
      getPreviousCreditLimitRequest();
      setDetailsSubmitted(false);
    }
  }, [openModal]);

  const updateCL = data => {
    postData(UPDATE_CREDIT_LIMIT(_uuid || '', sellerId), data)
      .then(() => {
        setDetailsSubmitted(true);
      })
      .catch(e => {
        setError(e.message);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };

  const onSubmit = values => {
    dispatch(startLoading);
    const { invoiceAmount, reason, merchantInvoiceFile } = values;
    if (merchantInvoiceFile) {
      const formData = new FormData();
      formData.append('file', merchantInvoiceFile);
      postData(FILE_UPLOAD, formData, {
        'Content-Type': 'multipart/form-data',
      })
        .then(({ data }) => {
          const fileUrl = data.url;
          updateCL({
            new_credit_limit: invoiceAmount,
            merchant_reason: reason,
            file_url: fileUrl,
          });
        })
        .catch(e => {
          dispatch(stopLoading);
          errorMessage(e, enqueueSnackbar);
        });
    } else {
      updateCL({ new_credit_limit: invoiceAmount, merchant_reason: reason });
    }
  };

  return (
    <MpModal
      title={pendingReview || detailsSubmitted ? '' : 'BUYER.UPDATE_CREDIT_LIMIT_MODAL.TITLE'}
      titleIcon={
        pendingReview || detailsSubmitted ? (
          ''
        ) : (
          <Upgrade sx={{ fill: 'url(#with-icon-gradient)', transform: 'translate(-5px, 5px)' }} />
        )
      }
      modalOpen={openModal}
      handleClose={() => {
        setError('');
        closeModal();
      }}
      maxWidth="sm"
    >
      {loader ? (
        <SectionLoader />
      ) : pendingReview ? (
        <PendingRequest />
      ) : detailsSubmitted ? (
        <UpdateRequestSuccess />
      ) : (
        (error && (
          <Typography sx={{ mt: 2, mb: 4, fontSize: '1rem', color: grey[900] }}>{error}</Typography>
        )) || (
          <section>
            <Typography sx={{ mt: 2, fontSize: '1rem', color: grey[900] }}>
              <FormattedMessage id="BUYER.UPDATE_CREDIT_LIMIT_MODAL.SUB_TITLE" />
            </Typography>

            <Box>
              <Typography sx={{ mt: 3, fontSize: '1rem', color: grey[400] }}>
                <FormattedMessage id="BUYER.UPDATE_CREDIT_LIMIT_MODAL.CREDIT_LIMIT_LABEL" />
              </Typography>
              <Typography sx={{ mt: '4px', fontSize: '1rem', color: grey[900] }}>
                {symbol + formatter(Number(credit_limit))}
              </Typography>
            </Box>

            <CreateForm
              primaryButtonConfig={{
                label: <FormattedMessage id="SUBMIT" />,
                handleSubmit: onSubmit,
                alignToLeft: true,
              }}
              formFields={updateCreditLimitFormFields(code)}
              valuestoValidate={valuesToValidateOnUpdateCreditLimitFormFields}
              initialValue={initialValuesUpdateCreditLimitFormFields}
            />
          </section>
        )
      )}
    </MpModal>
  );
};
