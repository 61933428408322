import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { TableChart } from '@mui/icons-material';

// components
import { FormattedMessage, MpTable } from 'UI';

// styles
import { SettingsPaymentCollectionWrapperStyled } from 'UI/containers/dashboard/settings/payment-collection/styled';
import { FeesStyled, fontStyles } from './styled';

// utils
import { TABLE_USE_CASE_FEES } from 'UI/components/table/constants';
import { useAppSelector } from 'store';
import { tableOption } from './dynamic-settlements/adapters';
import { convertPricesToTableDataForFeesWithoutDynamicSettlement } from './dynamic-settlements/adapters';

export const FeesWithoutDynamicSettlements = () => {
  const [tableHeader, setTableHeader] = useState<Array<tableOption>>([]);
  const [tableData, setTableData] = useState([]);
  const {
    user: {
      companyProfile: { eligible_payment_terms },
    },
    settings: {
      sellerFee: { ...prices },
      buyerFee,
    },
  } = useAppSelector(state => state);

  useEffect(() => {
    // Convert the obj array to lowercase
    const lowercaseObj = eligible_payment_terms?.map?.(key => key.toLowerCase()) || [];

    // Filter the prices object using lowercase keys from obj array
    const filteredPrices = Object.fromEntries(
      Object.entries(prices).filter(([key]) => lowercaseObj.includes(key))
    );

    const netTermsOptions = Object.keys(filteredPrices).map(item => {
      return {
        label: `${item.slice(0, 3).toUpperCase()} ${item.slice(3)}`,
        value: item,
      };
    });

    setTableHeader([
      { value: 'type', label: <FormattedMessage id="SET_TERMS_PAYMENT_LABEL" /> },
      ...netTermsOptions,
    ]);
    setTableData(
      // @ts-ignore
      convertPricesToTableDataForFeesWithoutDynamicSettlement(prices, buyerFee)
    );
  }, [Object.keys(prices).length, eligible_payment_terms]);

  return (
    <FeesStyled>
      <SettingsPaymentCollectionWrapperStyled>
        <Typography variant="h3" sx={fontStyles.title}>
          <TableChart sx={{ fill: 'url(#with-icon-gradient)' }} />
          <FormattedMessage id="SETTINGS.FEES.TITLE" />
        </Typography>

        <Typography variant="h6" sx={fontStyles.description} mt={3}>
          <FormattedMessage id="SETTINGS.FEES.DESCRIPTION" />
        </Typography>

        <Box my={4}>
          <MpTable data={tableData} headerData={tableHeader} useCase={TABLE_USE_CASE_FEES} />
        </Box>
        <div style={{ fontSize: '14px' }}>
          <FormattedMessage id="SETTINGS.FEES.BOTTOM_TEXT" />
        </div>
      </SettingsPaymentCollectionWrapperStyled>
    </FeesStyled>
  );
};
