import React from 'react';
import {
  CompanyAddressDetailsInfoStyled,
  CompanyAddressDetailsStyled,
  CompanyDetailsCloseIconStyled,
  CompanyDetailsStyled,
  CompanyDetailsTitleStyled,
} from './styled';
import { MpIcon } from 'UI/index';
import { SearchedCompany } from 'store/types/buyers';

type CompanyDetailsProps = {
  company: SearchedCompany;
  clearSelectedCompanyDetails: () => any;
};

export const CompanyDetails = ({ company, clearSelectedCompanyDetails }: CompanyDetailsProps) => {
  // @ts-ignore
  const { address_line1, address_line2, city, zip_code, name } = company.address;

  const address = [address_line1, address_line2, city, zip_code, name]
    .filter(word => word && word !== 'null')
    .join(', ');

  return (
    <CompanyDetailsStyled>
      <CompanyDetailsTitleStyled>{company.business_name}</CompanyDetailsTitleStyled>
      <CompanyDetailsCloseIconStyled onClick={clearSelectedCompanyDetails}>
        <MpIcon name="close-circle-grey" />
      </CompanyDetailsCloseIconStyled>
      <CompanyAddressDetailsStyled>
        <CompanyAddressDetailsInfoStyled>{address}</CompanyAddressDetailsInfoStyled>
      </CompanyAddressDetailsStyled>
    </CompanyDetailsStyled>
  );
};
