// vendor
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import { grey } from 'theme/colors';
import { BaseThemeType } from 'theme/baseTheme';
import spaces from 'constants/spacing';

export const MpInputStyled = styled(TextField)<{ theme?: BaseThemeType; disabled?: boolean }>`
  width: 100%;
  border-radius: ${spaces.space_mini};

  & input {
    background: ${props =>
      props.disabled
        ? 'linear-gradient(0deg, #F5F8FF, #F5F8FF), linear-gradient(0deg, #B6B8E2, #B6B8E2);'
        : props.theme.colors.lightBackground};
    height: 22px;
    border-radius: 0 ${spaces.space_mini} ${spaces.space_mini} 0;
    position: relative;
    font-size: ${props => props.theme.fontSizes.xxSmall};
    padding: ${spaces.space_9} ${spaces.space_xxs};
    font-weight: 400;
    line-height: ${spaces.space_22};
    color: ${grey[700]};

    :focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

  & .MuiOutlinedInput-root {
    border-radius: ${spaces.space_mini};
    background: ${props => props.theme.colors.backgroundSecondary};
    padding: 0;
    & fieldset,
    &:hover fieldset,
    &.Mui-focused fieldset {
      border: ${spaces.space_nano} solid ${props => props.theme.colors.neutral300};
    }
  }

  & .MuiInputBase-adornedEnd {
    padding-right: 14px;
  }

  & .MuiInputAdornment-positionStart {
    padding-left: 14px;
  }
`;
