// vendor
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { debounce } from 'lodash';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// vendor
import { SECONDARY } from 'constants/button-types';

// components
import { MpButton, MpTable, MpInput, MpTag, FormattedMessage, ElipsesTextWithTooltip } from 'UI';

// constants
import { BUYERS_DETAILS } from 'constants/routes.constants';
import { BUYERS_HEADER, BUYERS_HEADER_KEYS } from 'constants/buyer.constants';
import { TABLE_SEARCH_DEBOUNCE, TABLE_USE_CASE_BUYERS } from 'UI/components/table/constants';
import { FETCH_BUYERS } from 'store/types';

// utils
import { sort, formatter, MAP_TYPE_TO_COLOR } from 'utils';

// actions
import { openBuyerPopup } from 'store/actions';
import { changeTableSetting, changeTableConfig } from 'store/actions/tables';
import { mapSetTerm } from 'constants/enum.constants';

import { BuyerStatus } from 'store/types/buyers';

type Props = {
  history: object;
};

export const BuyersTable = (props: Props) => {
  const list = useAppSelector(state => state.buyer.buyersList);
  const { count } = useAppSelector(state => state.buyer.page);
  const dispatch = useAppDispatch();
  const [tableData, setTableData] = useState([]);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const newInvoiceData = useMemo(
    () =>
      list.map(row => {
        const { buyer, id, credit_qualification, _uuid, status } = row || {};

        const {
          payment_terms,
          credit_limit,
          available_credit_limit,
          credit_decision,
          currency: { symbol },
        } = credit_qualification || {};

        return {
          id: _uuid || id,
          inactive: status === BuyerStatus.INACTIVE,
          [BUYERS_HEADER_KEYS.Buyers]: (
            <ElipsesTextWithTooltip title={buyer}>{buyer}</ElipsesTextWithTooltip>
          ),
          [BUYERS_HEADER_KEYS['Credit limit']]:
            Number(credit_limit) && `${symbol || ''} ${formatter(Number(credit_limit))}`,
          [BUYERS_HEADER_KEYS['Available credit']]:
            Number(available_credit_limit) &&
            `${symbol || ''} ${formatter(Number(available_credit_limit))}`,
          // @ts-ignore
          [BUYERS_HEADER_KEYS['Net term']]: mapSetTerm[payment_terms] || '-',
          [BUYERS_HEADER_KEYS['Credit decision']]: (
            <MpTag
              type={MAP_TYPE_TO_COLOR[credit_decision || '']}
              label={<FormattedMessage id={`status.${credit_decision}`} />}
            />
          ),
        };
      }),
    [list]
  );

  const requestSearch = debounce(event => {
    dispatch(changeTableSetting('search', event?.target?.value));
  }, TABLE_SEARCH_DEBOUNCE);

  useEffect(() => {
    // @ts-ignore
    setTableData(newInvoiceData);
  }, [newInvoiceData]);

  const unsubscribed = useRef(false);
  useEffect(() => {
    if (!unsubscribed.current) {
      unsubscribed.current = true;
      dispatch({ type: FETCH_BUYERS });
    }
    return () => {
      if (!unsubscribed.current) {
        dispatch(changeTableConfig('search', ''));
      }
    };
  }, []);

  const addBuyer = () => {
    dispatch(openBuyerPopup);
  };

  const requestSort = label => {
    // @ts-ignore
    setTableData(sort(tableData, label));
  };

  const onRowClick = item => {
    // @ts-ignore
    props.history.push(`${BUYERS_DETAILS}/${item.id}/details`);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Typography variant="h1">
          <FormattedMessage id="BUYERS" />
        </Typography>
        <Box
          display="flex"
          gap="24px"
          flexDirection={isMobile ? 'column' : 'row'}
          textAlign={isMobile ? 'center' : 'initial'}
          marginTop={isMobile ? '10px' : 0}
        >
          <Box minWidth="320px">
            <MpInput
              onChange={requestSearch}
              leftIcon="search"
              placeholder="PLACEHOLDERS.SEARCH_BUYERS"
            />
          </Box>
          <div>
            <MpButton
              onClick={addBuyer}
              type={SECONDARY}
              variant="contained"
              additionalStyles={{ minWidth: '140px' }}
            >
              <AddIcon style={{ marginRight: '10px' }} />
              <FormattedMessage id="ADD_BUYER" />
            </MpButton>
          </div>
        </Box>
      </Box>

      <MpTable
        data={tableData}
        headerData={BUYERS_HEADER}
        requestSort={requestSort}
        onRowClick={onRowClick}
        totalItems={count as number}
        useCase={TABLE_USE_CASE_BUYERS}
        withMinHeight
      />
    </>
  );
};
