// vendor
import * as React from 'react';
import { Checkbox, FormGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { withTranslation } from 'react-i18next';

// constants
import { ERROR } from 'constants/message-types';

// assets
import {
  RadioButtonUnchecked,
  CheckCircle,
  CheckBox,
  CheckBoxOutlineBlank,
} from '@mui/icons-material';

// components
import { MpMessage } from 'UI';

type Props = {
  radioButtonData: Array<{
    label: string;
  }>;
  /** name of radio button group */
  name: string;
  onChange: (event) => any;
  /** Legend for radio group */
  legend?: string;
  /** Display in row fashion */
  row?: boolean;
  /** Display in column fashion */
  column?: boolean;
  /** Selected Value */
  value: boolean;
  /** Translator Function */
  t: (d: any) => string;
  /** Is the design squared or rounded */
  isSquared?: boolean;
  /** Align the radio button label and text to same line */
  alignTop?: boolean;
  /** Error message */
  error?: string;
};

function RadioButtonsGroup(props: Props) {
  const {
    legend,
    row,
    column,
    name,
    onChange,
    value,
    radioButtonData,
    t,
    isSquared = false,
    alignTop = false,
  } = props;

  return (
    <>
      <FormControl component="fieldset" fullWidth>
        {legend && <FormLabel component="legend">{t(legend)}</FormLabel>}

        <FormGroup
          row={row}
          /* @ts-ignore */
          column={column}
          aria-label={name}
          onChange={onChange}
          sx={{
            borderRadius: '4px',
            border: '1px solid  #B6B8E2',
            background: '#FFF',
            padding: '13px 16px',
          }}
        >
          {radioButtonData.map((radio, index) => {
            return (
              <FormControlLabel
                slotProps={{
                  typography: {
                    sx: {
                      margin: 0,
                      transform: 'translate(0px, 2px)',
                    },
                  },
                }}
                key={`radion-button-${index}-${name}-${value}`}
                checked={value}
                control={
                  <Checkbox
                    disableRipple
                    classes={{ root: 'custom-checkbox-root' }}
                    icon={isSquared ? <CheckBoxOutlineBlank /> : <RadioButtonUnchecked />}
                    checkedIcon={isSquared ? <CheckBox /> : <CheckCircle />}
                  />
                }
                label={t(radio.label)}
                sx={{
                  'margin': 0,
                  'alignItems': alignTop ? 'flex-start' : 'initial',
                  '& .custom-checkbox-root': {
                    padding: '0 9px 0 0',
                  },
                }}
                name={name}
              />
            );
          })}
        </FormGroup>
      </FormControl>
      {props.error && <MpMessage type={ERROR} message={props.error} isPaddingTopRequired={true} />}
    </>
  );
}

RadioButtonsGroup.defaultProps = {
  row: false,
  column: false,
};

export default withTranslation('translations')(RadioButtonsGroup);
