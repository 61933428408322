// vendor
import styled from '@emotion/styled';

// constants
import tagType from 'constants/tag-constants';
import { grey, red } from 'theme/colors';
import { BaseThemeType } from 'theme/baseTheme';

export const BuyerDetailsCreditAssessmentSectionStyled = styled.div<{
  theme?: BaseThemeType;
  bottomPadding?: boolean;
}>`
  background: ${props => props.theme.colors.lightBackground};
  border: ${props => props.theme.spaces.space_nano} solid ${props => props.theme.colors.neutral300};
  box-sizing: border-box;
  border-radius: ${props => props.theme.spaces.space_xxxs};
  margin-bottom: ${props => props.theme.spaces.space_xl};
  padding: ${props => props.theme.spaces.space_m} ${props => props.theme.spaces.space_xl};
  padding-bottom: ${props => (props.bottomPadding ? props.theme.spaces.space_xl : '0')};
  &.buyer-added-flow {
    border: none;
    padding-top: 0;
    margin-bottom: 0;
    .data-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const BuyerDetailsCreditAssessmentLabelStyled = styled.div<{ theme?: BaseThemeType }>`
  font-weight: 700;
  font-size: ${props => props.theme.fontSizes.xSmall};
  line-height: ${props => props.theme.spaces.space_m};
  color: ${props => props.theme.colors.neutral700};
`;

export const BuyerDetailsDataStyled = styled.div<{ theme?: BaseThemeType }>`
  margin-top: ${props => props.theme.spaces.space_m};
  display: flex;
  column-gap: 2%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const BuyerDataRowStyled = styled.div<{ theme?: BaseThemeType }>`
  width: 23%;
  margin-bottom: 3%;
  @media only screen and (width < 900px) {
    width: 45%;
  }
  @media only screen and (width < 600px) {
    width: 95%;
  }

  @supports (not (gap: ${props => props.theme.spaces.space_peta})) {
    margin: ${props => props.theme.spaces.space_m} ${props => props.theme.spaces.space_xs};
  }
`;

export const BuyerDetailsCreditAssessmentDataRowLabelStyled = styled.div<{ theme?: BaseThemeType }>`
  font-size: ${props => props.theme.fontSizes.xSmall};
  line-height: ${props => props.theme.spaces.space_m};
  color: ${props => props.theme.colors.neutral500};
  display: flex;
  align-items: center;
`;

export const BuyerACLTableDataRowStyled = styled.div<{ theme?: BaseThemeType }>`
  font-size: ${props => props.theme.fontSizes.xSmall};
  line-height: ${props => props.theme.spaces.space_m};
  color: ${props => props.theme.colors.neutral500};
  display: flex;
  align-items: center;

  @media screen and (width < 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const IdSectionStyled = styled.div`
  @media screen and (width < 600px) {
    width: 100px;
  }

  @media screen and (width >= 600px) {
    width: 180px;
  }

  @media screen and (width >= 900px) {
    width: 400px;
  }

  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
  a {
    color: ${grey[500]};
  }
`;

const creditAssessmentBackgroundColorFn = props => {
  if (props) {
    switch (props.type) {
      case tagType.SUCCESS:
        return props.theme.colors.brightGreen;

      case tagType.WARNING:
        return props.theme.colors.brightOrange;

      case tagType.DANGER:
        return props.theme.colors.brightRed;

      default: {
        return props.theme.colors.brightGreen;
      }
    }
  }
};

export const BuyerDetailsCreditAssessmentDataRowValueStyled = styled.div<{
  theme?: BaseThemeType;
  isBlocked?: boolean;
  type?: string;
  alignValue?: string;
}>`
  font-weight: 500;
  font-size: ${props => props.theme.fontSizes.xSmall};
  line-height: ${props => props.theme.spaces.space_m};
  color: ${props => (props.isBlocked ? red[300] : props.theme.colors.neutral700)};
  margin-top: ${props => props.theme.spaces.space_mini};
  text-align: ${props => props.alignValue || 'initial'};
  ${props =>
    props.type !== '' &&
    `
      ::before {
        display: inline-block;
        content: '';
        background-color: ${creditAssessmentBackgroundColorFn(props)};
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: relative;
        top: -${props.theme.spaces.space_micro};
        margin-right: ${props.theme.spaces.space_xxxs};
      }
    `}
`;
