import styled from '@emotion/styled';
import { BaseThemeType } from 'theme/baseTheme';

export const DateWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  label.MuiFormLabel-filled {
    font-weight: 500;
    font-size: ${props => props.theme.fontSizes.small};
    line-height: ${props => props.theme.spaces.space_22};
    color: ${props => props.theme.colors.neutral600};
    margin-bottom: ${props => props.theme.spaces.space_xxxs};
  }
  label + .MuiInput-formControl {
    margin-top: ${props => props.theme.spaces.space_s};
  }
  path {
    color: ${props => props.theme.colors.neutral600};
  }

  input {
    background: ${props => props.theme.colors.lightBackground};
    height: 22px;

    font-size: ${props => props.theme.fontSizes.xxSmall};
    padding: ${props => props.theme.spaces.space_9} ${props => props.theme.spaces.space_xxs};
    font-weight: 400;
    line-height: ${props => props.theme.spaces.space_22};
    color: ${props => props.theme.colors.neutral700};
  }

  fieldset {
    border-color: ${props => props.theme.colors.neutral300};
    border-radius: ${props => props.theme.spaces.space_mini};
  }
`;
