import styled from '@emotion/styled';

import { grey } from 'theme/colors';

export const FilterMenuStyled = styled.span`
  li {
    color: ${grey[700]};
    min-height: 38px;
  }
  .filter-title {
    color: ${grey[800]};
  }
  .filter-actions {
    justify-content: end;
  }
  .filter-title,
  .filter-actions {
    &:hover {
      background: none;
    }
  }
  svg {
    font-size: 1.2rem;
    position: relative;
    top: 2px;
  }
  & > svg {
    top: 4px;
  }
`;
