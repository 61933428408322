import React, { useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { Box, TextField, Typography, Stack, useMediaQuery, Theme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { TABLE_USE_CASE_SETTLEMENTS } from 'UI/components/table/constants';
import { FETCH_SETTLEMENTS } from 'store/types';
import { DATE_FORMAT } from 'constants/date';
import { MpButton, MpTable, FormattedMessage } from 'UI';
import { changeTableSetting, changeTableConfig } from 'store/actions/tables';
import { DateWrapperStyled } from 'UI/components/dates/style';
import { GHOST } from 'constants/button-types';

import { SETTLEMENTS_DEFUALT_ROWS_PER_PAGE } from 'constants/table-filter-options';

export const CREATED_AT_FROM = 'start_date';
export const CREATED_AT_TO = 'end_date';

const TABLE_HEADERS = [
  { value: 'file_name', label: <FormattedMessage id="SETTLEMENTS.FILE_NAME" /> },
  {
    value: 'date',
    label: <FormattedMessage id="DATE" />,
    minWidth: '120px',
  },
  {
    value: 'total_sales',
    label: <FormattedMessage id="SETTLEMENTS.TOTAL_SALES" />,
    minWidth: '120px',
    align: 'center',
  },
  {
    value: 'total_refunds',
    label: <FormattedMessage id="SETTLEMENTS.TOTAL_REFUNDS" />,
    minWidth: '140px',
    align: 'center',
  },
  {
    value: 'total_fees',
    label: <FormattedMessage id="SETTLEMENTS.TOTAL_FEES" />,
    minWidth: '120px',
    align: 'center',
  },
  {
    value: 'settlement_amount',
    label: <FormattedMessage id="SETTLEMENTS.TOTAL_SETTLED" />,
    minWidth: '130px',
    align: 'center',
  },
  {
    value: 'running_balance',
    label: <FormattedMessage id="SETTLEMENTS.RUNNING_BALANCE" />,
    minWidth: '130px',
    align: 'center',
  },
  { value: 'download', label: '' },
];

export const Settlements = () => {
  const {
    list,
    pagination: { count },
  } = useAppSelector(state => state.settlements);
  const { filterBy } = useAppSelector(state => state.table);
  const { start_date = null, end_date = null } = filterBy;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const dispatch = useAppDispatch();

  const onFilterByFromDate = date => {
    dispatch(changeTableSetting('filterBy', { ...filterBy, [CREATED_AT_FROM]: date }));
  };

  const onFilterByToDate = date => {
    dispatch(changeTableSetting('filterBy', { ...filterBy, [CREATED_AT_TO]: date }));
  };

  const resetFilterByDate = () => {
    dispatch(changeTableSetting('filterBy', {}));
  };

  const isFetched = useRef(false);
  useEffect(() => {
    if (!isFetched.current) {
      isFetched.current = true;
      dispatch(changeTableConfig('useCase', TABLE_USE_CASE_SETTLEMENTS));
      dispatch({ type: FETCH_SETTLEMENTS });
    }
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Typography variant="h1" lineHeight="40px">
          <FormattedMessage id="SETTLEMENTS.SETTLEMENTS" />
        </Typography>
        <Box>
          <DateWrapperStyled>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack
                spacing={2}
                direction={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'end' : 'center'}
              >
                <>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <FormattedMessage id="FROM" />:
                    <DatePicker
                      maxDate={end_date}
                      format={DATE_FORMAT}
                      // @ts-ignore
                      variant="inline"
                      name="settlements-from-date-picker"
                      value={start_date}
                      autoOk
                      mask="__-__-____"
                      onChange={onFilterByFromDate}
                      renderInput={params => (
                        <TextField data-sdet="settlements-from-date-picker" {...params} />
                      )}
                    />
                  </Stack>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <FormattedMessage id="TO" />:
                    <DatePicker
                      minDate={start_date}
                      format={DATE_FORMAT}
                      // @ts-ignore
                      variant="inline"
                      className="settlements-to-date-picker"
                      name="settlements-to-date-picker"
                      value={end_date}
                      autoOk
                      mask="__-__-____"
                      onChange={onFilterByToDate}
                      renderInput={params => (
                        <TextField data-sdet="settlements-to-date-picker" {...params} />
                      )}
                    />
                  </Stack>
                  {(start_date || end_date) && (
                    <MpButton
                      type={GHOST}
                      onClick={resetFilterByDate}
                      additionalStyles={{ minWidth: '115px' }}
                    >
                      <FormattedMessage id="SETTLEMENTS.RESET_FILTER" />
                    </MpButton>
                  )}
                </>
              </Stack>
            </LocalizationProvider>
          </DateWrapperStyled>
        </Box>
      </Box>
      <MpTable
        totalItems={count}
        data={list}
        // @ts-ignore
        headerData={TABLE_HEADERS}
        useCase={TABLE_USE_CASE_SETTLEMENTS}
        defaultPageSize={SETTLEMENTS_DEFUALT_ROWS_PER_PAGE}
      />
    </>
  );
};
