// vendor
import React, { useEffect, useState } from 'react';
import { getData } from 'api';
import { addDays, differenceInDays } from 'date-fns';
import { Box, Typography } from '@mui/material';

// components
import { FormattedMessage } from 'UI';

// icons
import { ArrowForwardRounded, ArrowBackRounded } from '@mui/icons-material';

// constants
import {
  BuyerInfoBoxWrapper,
  BuyerInforBoxDescriptionStyled,
  BuyerInforBoxTitleStyled,
} from './styled';
import { NET_TERM_VALUES } from 'constants/enum.constants';
import { GET_ALL_BUYERS } from 'constants/api-endpoints.constants';

// store
import { useAppSelector } from 'store';

// utils
import { errorMessage } from 'utils';

export const fetchBuyers = (name = '', enqueueSnackbar, sellerId) => {
  const url = GET_ALL_BUYERS(sellerId) + (name ? `?search=${name}` : '');
  return getData(url)
    .then(res => {
      return res.data.data.map(buyer => ({
        ...buyer,
        value: buyer._uuid || buyer.id,
        label: buyer.buyer,
      }));
    })
    .catch(err => {
      errorMessage(err, enqueueSnackbar);
    });
};

export const getRejectedBuyerTitle = (buyer, isBuyerInactive, isBuyerQualificationApproved) => {
  return isBuyerInactive ? (
    <FormattedMessage
      id="TRANSACTIONS.BUYER_NOT_APPROVED"
      context={{ buyer: buyer.buyer, status: buyer.status }}
    />
  ) : !isBuyerQualificationApproved ? (
    <FormattedMessage
      id="TRANSACTIONS.CREDIT_DECISION_NOT_APPROVED"
      context={{ buyer: buyer.buyer }}
    />
  ) : (
    <FormattedMessage id="TRANSACTIONS.PAYMENT_TERMS_NOT_SET" />
  );
};

type BuyerInfoBoxType = {
  title: React.ReactNode;
  description: string;
};

export const BackButtonComponent = () => {
  return (
    <>
      <ArrowBackRounded style={{ fontSize: '18px', marginRight: '12px' }} />
      <FormattedMessage id="BACK" />
    </>
  );
};

export const NextButtonComponent = ({
  id = 'NEXT',
  iconPresent = true,
}: {
  id?: string;
  iconPresent?: boolean;
}) => {
  return (
    <>
      <FormattedMessage id={id} />
      {iconPresent && <ArrowForwardRounded style={{ fontSize: '18px', marginLeft: '12px' }} />}
    </>
  );
};

export const BuyerInfoBox = ({ title, description }: BuyerInfoBoxType) => {
  return (
    <BuyerInfoBoxWrapper data-sdet="company-info">
      <BuyerInforBoxTitleStyled data-sdet="company-title">{title}</BuyerInforBoxTitleStyled>
      <BuyerInforBoxDescriptionStyled data-sdet="company-address">
        {description}
      </BuyerInforBoxDescriptionStyled>
    </BuyerInfoBoxWrapper>
  );
};

export const calculateOutstandingDaysForPayInInstallments = ({
  day,
  netTerms,
  merchantInvoiceDate,
  settlement_frequency,
}) => {
  const netTermDays = NET_TERM_VALUES[netTerms] - settlement_frequency;
  const dueDate = addDays(new Date(merchantInvoiceDate), netTermDays);
  return differenceInDays(dueDate, day);
};

const calculateOutstandingDaysForNetTerm = (day, netTerms, merchantInvoiceDate) => {
  const netTermDays = netTerms ? NET_TERM_VALUES[netTerms] : 0;
  const dueDate = addDays(new Date(merchantInvoiceDate), netTermDays);
  return differenceInDays(dueDate, day);
};

export const useCalculateFee = day => {
  const {
    invoice: {
      step1Values: { netTerms, merchantInvoiceDate },
    },
    settings: {
      dynamicFee: { base_fee = 0.0, fee_per_day = 0.0, minimum_fee = 0.0 },
      buyerFee,
      sellerFee: { settlement_frequency },
    },
  } = useAppSelector(state => state);

  const [fee, setFee] = useState<number>(0.0);

  useEffect(() => {
    if (!day) return;

    const outstandingDays = netTerms.includes('PAY_IN')
      ? calculateOutstandingDaysForPayInInstallments({
          day,
          netTerms,
          merchantInvoiceDate,
          settlement_frequency,
        })
      : calculateOutstandingDaysForNetTerm(day, netTerms, merchantInvoiceDate);

    const buyerFeePercentage = parseFloat(buyerFee[netTerms.toLowerCase()]);

    const sprinqueFeePercentage = base_fee + fee_per_day * outstandingDays;

    const feePercentageComparedWithFloorPricing =
      sprinqueFeePercentage < minimum_fee ? minimum_fee : sprinqueFeePercentage;

    const merchantFeePercentage = feePercentageComparedWithFloorPricing - buyerFeePercentage;

    setFee(merchantFeePercentage);
  }, [day]);

  return fee;
};

const modalStepFontStyles = {
  title: {
    color: '#000339',
    fontFamily: 'Space Grotesk',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '32px',
  },
  step: {
    color: '#000339',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
  },
};

export const ModalStepTitle = ({ title, step }: { title: string; step?: string }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mt={4}>
      <Typography variant="h5" color="textPrimary" sx={modalStepFontStyles.title}>
        <FormattedMessage id={title} />
      </Typography>
      {step ? (
        <Typography variant="body1" color="textSecondary" sx={modalStepFontStyles.step}>
          <FormattedMessage id={step} />
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};
