import styled from '@emotion/styled';
import { BaseThemeType } from 'theme/baseTheme';
import { blue, grey } from 'theme/colors';

export const BuyerInfoBoxWrapper = styled.div<{ theme?: BaseThemeType; type?: any }>`
  width: 100%;
  background: #ffffff;
  border: ${props => props.theme.spaces.space_nano} solid ${blue[400]};
  box-shadow: 0px ${props => props.theme.spaces.space_mini}
    ${props => props.theme.spaces.space_xxxs} rgba(229, 234, 237, 0.5);
  border-radius: ${props => props.theme.spaces.space_mini};
  padding: ${props => props.theme.spaces.space_xs} ${props => props.theme.spaces.space_xs}
    ${props => props.theme.spaces.space_14} ${props => props.theme.spaces.space_xs};
  margin-top: ${props => props.theme.spaces.space_m};
`;

export const BuyerInforBoxTitleStyled = styled.div<{ theme?: BaseThemeType; type?: any }>`
  font-weight: 600;
  font-size: 16px;
  line-height: ${props => props.theme.spaces.space_m};
  color: ${blue[400]};
`;

export const BuyerInforBoxDescriptionStyled = styled.div<{ theme?: BaseThemeType; type?: any }>`
  font-size: 14px;
  line-height: ${props => props.theme.spaces.space_22};
  color: #6266a7;
  margin-top: ${props => props.theme.spaces.space_xxxs};
`;

export const TransactionInfoBoxWrapperStyled = styled.div`
  text-align: center;
  margin-bottom: 100px;
`;

export const TransactionInfoIconWrapperStyled = styled.div<{
  theme?: BaseThemeType;
  type?: any;
  isMarginTopPresent?: boolean;
}>`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background: #f5f8ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${props => props.theme.spaces.space_mega} auto ${props => props.theme.spaces.space_m};
  margin-top: ${props => (props.isMarginTopPresent ? props.theme.spaces.space_mega : 0)};
`;

export const TransactionInfoTitleStyled = styled.div<{ theme?: BaseThemeType; type?: any }>`
  font-size: 24px;
  font-weight: 700;
  line-height: ${props => props.theme.spaces.space_xl};
  letter-spacing: 0em;
  text-align: center;
  color: ${grey[900]};
  margin-bottom: ${props => props.theme.spaces.space_m};
`;

export const TransactionInfoBuyerIdStyled = styled.div<{
  theme?: BaseThemeType;
  type?: any;
  isBiggerFont?: any;
  isMarginPresent?: any;
}>`
  font-size: ${props => (props.isBiggerFont ? '20px' : '16px')};
  font-weight: 400;
  line-height: ${props => (props.isBiggerFont ? '32px' : '24px')};
  text-align: center;
  margin-top: ${props => props.isMarginPresent && props.theme.spaces.space_xl};
  color: ${grey[900]};
`;

export const CalendarStyles = {
  'padding': '8px 0 8px 8px',
  '& .MuiPickersCalendarHeader-root': {
    padding: '0',
    margin: '0',
  },
  '& .MuiDayCalendar-header': {
    'padding': '0 8px 0 0',
    'justifyContent': 'space-between',
    '& .MuiTypography-root': {
      padding: '10px',
      margin: '0',
      color: '#000',
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 600,
    },
  },
  '& .MuiPickersSlideTransition-root': {
    minHeight: '255px',
  },
  '& .MuiDayCalendar-monthContainer': {
    'padding': '0 8px 0 0',
    '& .MuiDayCalendar-weekContainer': {
      'margin': '0',
      'border': '1px solid  #D5D7FE',
      'borderWidth': '0 0px 1px',
      '&:last-child': {
        borderWidth: '0 0px 1px 0px',
      },
      '&:first-child': {
        borderWidth: '1px 0px 1px 0px',
      },
      'justifyContent': 'space-between',
      '& .MuiButtonBase-root': {
        'border': 'inherit',
        'borderWidth': '0 0px 0px 1px',
        '&:last-child': {
          borderWidth: '0 1px 0px 1px',
        },
        'width': '44px',
        'height': '40px',
        'margin': 0,
        'padding': '10px',
        'borderRadius': 0,
        '&.Mui-disabled': {
          'background': '#F7F7FF',
          'opacity': '1',
          '& .custom-date': {
            color: '#B6B8E2',
          },
          '& .fee-percentage': {
            color: '#B6B8E2',
          },
        },
        '&.MuiPickersDay-today': {
          border: 'inherit',
          borderWidth: '0 0px 0px 1px',
          color: 'inherit',
        },
        '&:not(.Mui-disabled)': {
          '&:hover': {
            borderRadius: 0,
          },
          '& .custom-date': {
            color: '#000339',
          },
          '& .fee-percentage': {
            color: '#343AEB',
          },
          '&.Mui-selected': {
            'borderRadius': 0,
            '& .custom-date': {
              color: '#ffffff',
            },
            '& .fee-percentage': {
              color: '#ffffff',
            },
          },
        },
      },
    },
  },
};
