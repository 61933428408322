// vendor
import styled from '@emotion/styled';

// utils
import { BaseThemeType } from 'theme/baseTheme';
import fontSizes from 'constants/font-sizes';
import spaces from 'constants/spacing';
import { grey } from 'theme/colors';

export const AccountCompletionCardWrapper = styled.div<{ theme?: BaseThemeType }>`
  padding: ${spaces.space_m};
  min-height: 212px;
  background: ${props => props.theme.colors.lightBackground};
  border: ${spaces.space_nano} solid ${props => props.theme.colors.neutral300};
  box-sizing: border-box;
  height: 100%;
`;

export const AccountCompletionCardCompanyNameStyled = styled.div<{ theme?: BaseThemeType }>`
  font-size: ${fontSizes.medium};
  line-height: ${spaces.space_xl};
  font-weight: 700;
  margin-top: ${spaces.space_25};
`;

export const AccountCompletionCardCompanyIconStyled = styled.img`
  object-fit: contain;
  height: auto;
  width: 70px;
  border-radius: ${spaces.space_xxxs};
`;

export const AccountCompletionCardDescriptionStyled = styled.div<{ isBold?: boolean }>`
  font-size: ${fontSizes.xSmall};
  line-height: ${spaces.space_m};
  color: ${grey[900]};
  font-weight: ${props => (props.isBold ? '600' : '400')};
  @media screen and (width > 600px) {
    width: 70%;
  }
`;
