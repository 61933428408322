// vendor
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import { Menu, MenuOpen } from '@mui/icons-material';

// assets
// @ts-ignore
import { ReactComponent as Logo } from 'assets/icons/sprinque_white.svg';

// styles
import { MpHeaderWrapperStyled, MpHeaderLinkStyled, MpHeaderLeftSectionStyled } from './styled';

// components
import { MpSidebarMenu } from 'UI';

// constants
import { OVERVIEW, PAYABLES } from 'constants/routes.constants';
import { HeaderData } from 'constants/header-constants';
import { ORG } from 'constants/storage.constants';
import { useAppSelector } from 'store';

export const Header = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [isMobMenuOpened, setIsMobMenuOpened] = useState(true);
  const [selectedPath, setSelectedPath] = useState(() => '/');
  const [isSubMenuOpened, setIsSubMenuOpened] = useState(false);
  const orgData = localStorage.getObject(ORG) || {};
  const {
    user: {
      userProfile: { status: sellerStatus },
    },
    settings: {
      sellerConfig: { is_pending_order_enabled },
    },
  } = useAppSelector(state => state);

  useEffect(() => {
    const activePath = `/${pathname.split('/')[1]}`;
    setSelectedPath(activePath);
    setIsMobMenuOpened(false);
  }, [pathname]);

  return (
    <MpHeaderWrapperStyled className={isMobMenuOpened ? '' : 'mob-menu-closed'}>
      <MpHeaderLeftSectionStyled>
        {/* mobile menu icons */}
        <div className="mob-menu-icons">
          {isMobMenuOpened ? (
            <MenuOpen
              onClick={() => {
                setIsMobMenuOpened(false);
              }}
            />
          ) : (
            <Menu
              onClick={() => {
                setIsMobMenuOpened(true);
              }}
            />
          )}
        </div>
        <Logo
          className="logo"
          onClick={() => history.push(OVERVIEW)}
          style={{ cursor: 'pointer', marginBottom: '20px' }}
        />
        <Box width="100%">
          {HeaderData(is_pending_order_enabled, sellerStatus !== 'ACTIVE')
            .filter(headerLink => {
              if (headerLink.path === PAYABLES && !orgData.buyers?.length) {
                return false;
              }
              return true;
            })
            .map((headerLinks, index) => {
              const isActive = selectedPath === headerLinks.path;

              return (
                <div key={`Mp-header-link-${index}`} data-sdet={`menu-to-${headerLinks.path}`}>
                  <MpHeaderLinkStyled
                    onClick={() => {
                      headerLinks.route ? history.push(headerLinks.path) : false;
                      if (isActive && isSubMenuOpened) return;
                      setSelectedPath(headerLinks.path);
                      setIsSubMenuOpened(false);
                    }}
                    isActive={isActive && !isSubMenuOpened}
                  >
                    {headerLinks.icon}
                    {headerLinks.label}
                    {headerLinks.rightIcon}
                  </MpHeaderLinkStyled>
                  {headerLinks.subMenu?.length && isActive && (
                    <MpSidebarMenu
                      menu={headerLinks.subMenu}
                      onMenuLinkClicked={path => history.push(`${headerLinks.path}/${path}`)}
                      onSubMenuClick={() => setIsSubMenuOpened(true)}
                    />
                  )}
                </div>
              );
            })}
        </Box>
      </MpHeaderLeftSectionStyled>
    </MpHeaderWrapperStyled>
  );
};
