// vendor
import React, { useState } from 'react';
import { format } from 'date-fns';

// components
import { SectionLoader, MpModal } from 'UI';
import { SettlementCalendar } from '../add-invoice/settlement-calendar';
import { SettlementDateUpdateSuccess } from './settlement-date-update-success';

// store
import { useAppDispatch, useAppSelector } from 'store';

// constants
import { CLOSE_SETTLEMENT_DATE_POPUP, RESET_ADD_INVOICE_STATE } from 'store/types';
import { SETTLEMENT_DATE } from 'constants/api-endpoints.constants';
import { DATE_FORMAT_FOR_API } from 'constants/date';

// utils
import { patchData } from 'api';
import { SettlementDateUpdateFailure } from './setllment-date-update-failure';
import { errorMessage } from 'utils';

// enum constant for screen
enum SCREENS {
  DATA_ENTRY = 'DATA_ENTRY',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  INPROGRESS = 'INPROGRESS',
}

export const UpdateSettlementDate = ({ callback }: { callback: () => void }) => {
  const [screen, setScreen] = useState(SCREENS.DATA_ENTRY);
  const [error, setError] = useState('');
  const {
    popup: { settlementDate: settlementDatePopup },
    invoice: {
      step1Values: { invoiceId, settlementDate, settlementFee },
    },
    login: { sellerId },
  } = useAppSelector(state => state);
  const dispatch = useAppDispatch();

  const resetSettlementDate = () => {
    setError('');
    setScreen(SCREENS.INPROGRESS);
    patchData(
      SETTLEMENT_DATE({
        seller_id: sellerId,
        invoice_id: invoiceId,
      }),
      {
        settlement_date: format(settlementDate, DATE_FORMAT_FOR_API),
      }
    )
      .then(() => {
        setScreen(SCREENS.SUCCESS);
        callback?.();
      })
      .catch(error => {
        setError(errorMessage(error));
        setScreen(SCREENS.FAILURE);
      });
  };

  return (
    <MpModal
      title={screen === SCREENS.DATA_ENTRY ? 'ADD_TRANSACTION_STEP_2_TITLE' : ''}
      modalOpen={settlementDatePopup}
      handleClose={() => {
        setScreen(SCREENS.DATA_ENTRY);
        dispatch({ type: CLOSE_SETTLEMENT_DATE_POPUP });
        dispatch({ type: RESET_ADD_INVOICE_STATE });
      }}
    >
      {screen === SCREENS.DATA_ENTRY ? (
        <SettlementCalendar
          onClick={() => {
            resetSettlementDate();
          }}
          changeSettlementDateFlow
        />
      ) : (
        ''
      )}
      {screen === SCREENS.INPROGRESS ? <SectionLoader /> : ''}
      {screen === SCREENS.SUCCESS ? (
        <SettlementDateUpdateSuccess fee={`${settlementFee}%`} settlementDate={settlementDate} />
      ) : (
        ''
      )}
      {screen === SCREENS.FAILURE ? <SettlementDateUpdateFailure errorMessage={error} /> : ''}
    </MpModal>
  );
};
