import React, { ChangeEvent } from 'react';
import { Input } from '@mui/material';

import { CentsStyled } from './cents.styled';

type CentsProps = {
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  defaultValue?: string;
};

export const Cents = ({ handleChange, defaultValue = '00' }: CentsProps) => {
  return (
    <CentsStyled>
      <Input
        onChange={event => {
          if (+event.target.value < 0) event.target.value = '0';
          if (+event.target.value > 99) event.target.value = '99';
          if (event.target.value.length > 2) event.target.value = event.target.value.slice(0, 2);
          handleChange(event);
        }}
        disableUnderline
        id="cents-input"
        type="text"
        // @ts-ignore
        inputmode="numeric"
        placeholder="00"
        step="1"
        value={defaultValue}
        onKeyDown={event => {
          if (event.key === '.' || event.key === ',') {
            event.preventDefault();
          }
        }}
        // avoid pasting wrong data
        onInput={event => {
          // @ts-ignore
          event.target.value = event.target.value.replace(/[^0-9]*/g, '');
        }}
        max={99}
        name="cents"
      />
    </CentsStyled>
  );
};
