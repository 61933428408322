import type from 'constants/tag-constants';
import { Order } from 'store/types/ordersItem';
import { AclListItem } from './available-credit';
import { CreditNote } from 'store/types/invoices';
import { CurrencySymbol } from 'constants/currency';

export const getCreditRiskTextKey = creditRiskScore => {
  switch (true) {
    case creditRiskScore >= 59:
      return 'LOW_RISK';

    case creditRiskScore >= 27:
      return 'MEDIUM_RISK';

    default:
      return 'HIGH_RISK';
  }
};

export const getCreditRiskScoreColor = creditRiskScore => {
  switch (true) {
    case creditRiskScore >= 59:
      return type.SUCCESS;

    case creditRiskScore >= 27:
      return type.WARNING;

    default:
      return type.DANGER;
  }
};

export const getDefaultProbabilityColor = probability => {
  switch (true) {
    case probability <= 0.0135:
      return type.SUCCESS;

    case probability <= 0.04:
      return type.WARNING;

    default:
      return type.DANGER;
  }
};

export const getBondEquivalentRatingColor = rating => {
  switch (rating) {
    case 'A':
    case 'B':
      return type.SUCCESS;

    case 'C':
      return type.WARNING;

    default:
      return type.DANGER;
  }
};

export const getCreditDecisionColor = decision => {
  switch (decision) {
    case 'APPROVED':
      return type.SUCCESS;

    case 'REJECTED':
      return type.DANGER;

    default:
      return type.WARNING;
  }
};

const extendItemsWithCreditNotes = (
  creditNotes: Array<CreditNote>,
  invoiceId,
  fxCurrencySymbol: CurrencySymbol
): Array<AclListItem> => {
  return creditNotes.map(cn => ({
    itemType: 'creditNote',
    id: cn.merchant_credit_note_id,
    status: cn.status,
    fxRate: cn.buyer_credit_currency_exchange_rate || '',
    fxCurrency: cn.currency_code,
    fxCurrencySymbol: cn.currency_symbol || fxCurrencySymbol,
    fxAmount: cn.amount_in_buyer_credit_currency || '',
    amount: String(cn.amount),
    downloadLink: cn.credit_note_file,
    detailsPageLink: `/invoices/details/${invoiceId}`,
  }));
};

export const adaptOrdersAndInvoicesToACL = (orders: Array<Order>): Array<AclListItem> => {
  const res: Array<AclListItem> = [];
  orders.forEach(order => {
    // iterate invoices
    if (order.invoices.length) {
      const activeInvoices = order.invoices.filter(
        ({ status }) => status !== 'PAID' && status !== 'CAPTURE DECLINED'
      );
      activeInvoices.forEach(invoice => {
        // invoice
        const aclItem: AclListItem = {
          itemType: 'invoice',
          id: invoice.merchant_invoice_id,
          status: invoice.status || '',
          fxRate: invoice.buyer_credit_currency_exchange_rate || '',
          fxCurrencySymbol: invoice.currency_symbol,
          fxAmount: invoice.total_amount_in_buyer_credit_currency || '',
          amount: String(invoice.total_amount),
          downloadLink: invoice.invoice_file,
          detailsPageLink: `/invoices/details/${invoice._uuid}`,
        };
        const creditNotes = extendItemsWithCreditNotes(
          invoice.credit_notes,
          invoice._uuid,
          invoice.currency_symbol
        );
        res.push(aclItem, ...creditNotes);
      });
    } else {
      // order
      const aclItem: AclListItem = {
        itemType: 'order',
        id: order.merchant_order_id,
        status: order.status,
        fxRate: order.buyer_credit_currency_exchange_rate,
        fxCurrencySymbol: order.currency_symbol,
        fxAmount: order.auth_amount_in_buyer_credit_currency,
        amount: String(order.net_order_amount),
      };
      res.push(aclItem);
    }
  });

  return res;
};
