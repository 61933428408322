// vendor
import React from 'react';
import { ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@mui/material';
import { useHistory } from 'react-router-dom';

// styles
import { MpDropdownTextWrapperStyled, MpDropdownStyled } from './mp-dropdown-menu.styled';

// assets
import { ExpandMoreRounded, ExpandLessRounded } from '@mui/icons-material';

export type MenuItem = { label: React.ReactNode; action: string; payload?: { path?: string } };

type Props = {
  label: string | React.ReactNode;
  onMenuItemClick?: (...props) => any;
  menuList: Array<MenuItem>;
  /** Is button styled */
  isButton: boolean;
  /** for css button effect */
  withDropdownIcon?: boolean;
  /** Current Parent Node Data */
  data?: Record<string, unknown>;
  /** Left align px */
  menuLeftAlign?: string;
  dataSdet?: string;
};

export const MpDropdownMenu = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory();

  const handleToggle = e => {
    e.stopPropagation();
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event, action, payload) => {
    event.stopPropagation();
    // @ts-ignore
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    // @ts-ignore
    props.onMenuItemClick?.(event, action, {
      ...payload,
      history,
      data: props.data,
    });
    setOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // @ts-ignore
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <MpDropdownStyled menuLeftAlign={props.menuLeftAlign}>
      <div>
        <MpDropdownTextWrapperStyled
          isButton={props.isButton}
          ref={anchorRef}
          onClick={handleToggle}
          isMenuOpen={open}
          data-sdet={props.dataSdet || 'profile-dropdown'}
        >
          <>
            {props.label}
            {props.withDropdownIcon &&
              (open ? (
                <ExpandLessRounded sx={{ marginLeft: '14px' }} />
              ) : (
                <ExpandMoreRounded sx={{ marginLeft: '14px' }} />
              ))}
          </>
        </MpDropdownTextWrapperStyled>

        <Popper
          id="dropdown-menu"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                {/* @ts-ignore */}
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {props.menuList.map(({ label, action, payload }, index) => (
                      <MenuItem
                        onClick={e => handleClose(e, action, payload)}
                        key={`Menu-List-${index}`}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </MpDropdownStyled>
  );
};

MpDropdownMenu.defaultProps = {
  isButton: false,
  data: {},
};
