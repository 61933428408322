import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { TableChart } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

// components
import { FormattedMessage, MpModal, MpTable } from 'UI';
import { EmailPopup } from './email-popup';

// styles
import { SettingsPaymentCollectionWrapperStyled } from 'UI/containers/dashboard/settings/payment-collection/styled';
import { FeesStyled, fontStyles } from './styled';

// utils
import { useAppSelector } from 'store';
import { getData } from 'api';
import { SPRINQUE_INVOICES } from 'constants/api-endpoints.constants';
import { errorMessage } from 'utils';
import { createInvoiceTable, SPRINQUE_INVOICE_TABLE_HEADER } from './adapter';

const initialState = {
  openEmailModal: false,
  selectedInvoice: {
    invoice_number: '',
    invoice_total: '',
    created_at: '',
    file: '',
    _uuid: '',
  },
};

export const Billing = () => {
  const [tableData, setTableData] = useState([]);
  const {
    login: { sellerId },
  } = useAppSelector(state => state);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({ ...initialState });

  const handleCloseEmailPopup = () => {
    setState(initialState);
  };

  const openEmailPopup = (invoice: any) => {
    setState({
      ...state,
      openEmailModal: true,
      selectedInvoice: invoice,
    });
  };

  const getSprinqueInvoices = (sort = 'invoice_date') => {
    getData(`${SPRINQUE_INVOICES(sellerId)}?ordering=${sort}`)
      .then(({ data }) => {
        setTableData(createInvoiceTable(data, openEmailPopup));
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      });
  };

  useEffect(() => {
    getSprinqueInvoices();
  }, []);

  return (
    <FeesStyled>
      <SettingsPaymentCollectionWrapperStyled>
        <Typography variant="h3" sx={fontStyles.title}>
          <TableChart sx={{ fill: 'url(#with-icon-gradient)' }} />
          <FormattedMessage id="SETTINGS.BILLING.TITLE" />
        </Typography>

        <Typography variant="h6" sx={fontStyles.description} mt={3}>
          <FormattedMessage id="SETTINGS.BILLING.DESCRIPTION" />
        </Typography>
        <Box my={4}>
          <MpTable
            data={tableData}
            // @ts-ignore
            headerData={SPRINQUE_INVOICE_TABLE_HEADER}
            requestSort={value => {
              getSprinqueInvoices(value);
            }}
            useCase="billing"
            additionalStyles={{
              td: {
                padding: '11px 14px',
              },
            }}
          />
        </Box>
      </SettingsPaymentCollectionWrapperStyled>
      <MpModal title="" modalOpen={state.openEmailModal} handleClose={handleCloseEmailPopup}>
        <EmailPopup
          invoiceNumber={state.selectedInvoice.invoice_number}
          invoiceId={state.selectedInvoice._uuid}
          handleClose={handleCloseEmailPopup}
        />
      </MpModal>
    </FeesStyled>
  );
};
