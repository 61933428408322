// vendor
import React from 'react';
import { PictureInPicture } from '@mui/icons-material';
import { Box, Card, Grid, Tooltip } from '@mui/material';

// constants
import { INVOICE_IDS } from 'store/types/invoices';

// components
import { FormattedMessage } from 'UI';

// styles
import { BuyerDetailsCreditAssessmentDataRowLabelStyled } from '../buyer-detail-dashboard/company-details/styled';

const getInvoiceIDs = (ids: INVOICE_IDS) => {
  const {
    invoice_id = '',
    merchant_order_id = '',
    transaction_id = '',
    credit_notes_id = [''],
  } = ids;

  if (invoice_id && merchant_order_id && transaction_id) {
    return [
      {
        label: <FormattedMessage id="LABELS.MERCHANT_ORDER_ID" />,
        value: merchant_order_id,
      },
      {
        label: <FormattedMessage id="TRANSACTIONS.SPRINQUE_TRANSACTION_ID" />,
        value: transaction_id,
      },
      {
        label: <FormattedMessage id="TRANSACTIONS.SPRINQUE_INVOICE_ID" />,
        value: invoice_id,
      },

      {
        label: <FormattedMessage id="TRANSACTIONS.MERCHANT_CREDIT_NOTE_ID" />,
        value: credit_notes_id,
      },
    ];
  } else {
    return [];
  }
};

interface Props {
  invoice_id: string;
  merchant_order_id: string;
  transaction_id: string;
  credit_notes_id: Array<string> | null;
  handleCopy: (value: string) => void;
  isMobile: boolean;
}

export const InvoiceIds = ({
  invoice_id,
  merchant_order_id,
  transaction_id,
  credit_notes_id,
  handleCopy,
  isMobile,
}: Props) => {
  return (
    <Grid item xs={12} borderRadius={1}>
      <Card sx={{ p: 3, boxShadow: 'none', borderRadius: 2 }}>
        <Grid container alignItems="center">
          <PictureInPicture sx={{ fill: 'url(#with-icon-gradient)', mr: 1 }} />
          <b>IDs</b>
        </Grid>

        <Grid container sx={{ mb: 1, mt: 3 }}>
          {getInvoiceIDs({ invoice_id, merchant_order_id, transaction_id, credit_notes_id }).map(
            ({ label, value }) => {
              return value ? (
                <Grid
                  item
                  spacing={1}
                  sx={{ mt: 2, mb: 1 }}
                  xs={isMobile ? 12 : 4}
                  key={`transaction-details-id-section-${value}`}
                >
                  <BuyerDetailsCreditAssessmentDataRowLabelStyled>
                    {label}
                  </BuyerDetailsCreditAssessmentDataRowLabelStyled>
                  <Tooltip title={<FormattedMessage id="CLICK_TO_COPY" />} arrow placement="top">
                    {typeof value === 'object' && Array.isArray(value) ? (
                      <>
                        {value.map(idValue => (
                          <Box
                            key={`transaction-details-id-section-${idValue}`}
                            sx={{ cursor: 'pointer' }}
                            mt={1}
                            onClick={() => handleCopy(idValue)}
                          >
                            <b>#{idValue}</b>
                          </Box>
                        ))}
                      </>
                    ) : (
                      <Box
                        display="inline-block"
                        sx={{ cursor: 'pointer' }}
                        mt={1}
                        onClick={() => handleCopy(value)}
                      >
                        <b>#{value}</b>
                      </Box>
                    )}
                  </Tooltip>
                </Grid>
              ) : (
                <></>
              );
            }
          )}
        </Grid>
      </Card>
    </Grid>
  );
};
