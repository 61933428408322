// vendor
import React from 'react';
import { OpenInNew, Download, Receipt } from '@mui/icons-material';
import { Box, Card, Divider, Grid, Tooltip } from '@mui/material';
import { store, useAppSelector } from 'store';

// constants
import { BUYERS_DETAILS } from 'constants/routes.constants';
import { WHITE } from 'theme/colors';
import fontSizes from 'constants/font-sizes';
import spaces from 'constants/spacing';
import type from 'constants/tag-constants';
import { PaymentTerms } from 'constants/enum.constants';
import { Invoice } from 'store/types/invoices';
import { OPEN_SETTLEMENT_DATE_POPUP, SET_DATA_FOR_EDIT_SETTLEMENT_DATE } from 'store/types';
import PaymentStatus from 'constants/payment-status.constants';

// icons
import { EditOutlined } from '@mui/icons-material';

// components
import { FormattedMessage, MpTag } from 'UI';

// utils
import { formatter, formatTimezone } from 'utils';
import { BuyerDetailsCreditAssessmentDataRowLabelStyled } from '../buyer-detail-dashboard/company-details/styled';

const generateInvoiceData = (
  invoice: Invoice,
  is_dynamic_settlements_enabled = false,
  is_default_guarantee_enabled = false
) => {
  const {
    buyer_id,
    buyer_name,
    currency_symbol,
    outstanding_amount,
    credit_notes = [],
    due_date,
    payment_terms,
    total_amount,
    date,
    url,
    additional_file_url,
    settlement_date,
    transaction_status,
    invoice_status,
    invoice_id,
    late_payment_fees_amount,
    dunning_letter_file_url,
    settlement_amounts: { seller_sprinque_fee_percent, sprinque_fee_amount },
  } = invoice || {};
  let totalCreditNotes = 0;

  totalCreditNotes = credit_notes?.reduce?.((acc, item) => acc + item.amount, 0);

  const invoiceData = [
    [
      {
        label: <FormattedMessage id="TXN_DETAILS.BUYER_NAME" />,
        value: (
          <div>
            <span
              data-sdet="link-to-buyer"
              style={{ cursor: 'pointer' }}
              onClick={() => window.open(`/#${BUYERS_DETAILS}/${buyer_id}/details`, '_blank')}
            >
              {buyer_name}
              <OpenInNew style={{ fontSize: '18px', margin: '0 0 -4px 4px' }} />
            </span>
          </div>
        ),
        xs: 4,
      },
    ],

    [
      {
        label: <FormattedMessage id="TXN_DETAILS.OUTSTANDING" />,
        value: currency_symbol + formatter(+outstanding_amount),
        xs: 4,
      },

      {
        label: <FormattedMessage id="LABELS.INVOICE_AMOUNT" />,
        value: currency_symbol + formatter(+total_amount),
        xs: 4,
      },
      {
        label: <FormattedMessage id="TXN_DETAILS.CREDIT_NOTE_AMOUNT" />,
        value: currency_symbol + formatter(totalCreditNotes),
        xs: 4,
      },
    ],
    [
      {
        label: <FormattedMessage id="LABELS.SETTLEMENT_DATE" />,
        value: (
          <>
            {formatTimezone(settlement_date)}
            {is_dynamic_settlements_enabled &&
            transaction_status !== 'SETTLED' &&
            transaction_status !== 'REVERSED' &&
            invoice_status === PaymentStatus.OPEN ? (
              <EditOutlined
                data-sdet="edit-settlement-date"
                sx={{ fontSize: '12px', marginLeft: '15px', cursor: 'pointer' }}
                onClick={e => {
                  e.stopPropagation();
                  store.dispatch({ type: OPEN_SETTLEMENT_DATE_POPUP });
                  store.dispatch({
                    type: SET_DATA_FOR_EDIT_SETTLEMENT_DATE,
                    payload: {
                      merchantInvoiceDate: date,
                      netTerms: payment_terms,
                      settlementDate: new Date(settlement_date).setUTCHours(0, 0, 0, 0),
                      invoiceId: invoice_id,
                    },
                  });
                }}
              />
            ) : (
              <></>
            )}
          </>
        ),
        xs: 4,
      },
      (is_dynamic_settlements_enabled || is_default_guarantee_enabled) && {
        label: <FormattedMessage id="LABELS.SETTLEMENT_FEE" />,
        value: `%${seller_sprinque_fee_percent} - ${
          currency_symbol + formatter(+sprinque_fee_amount)
        }`,
        xs: 4,
      },
    ],
    [
      {
        label: <FormattedMessage id="BUYER.PAYMENT_TERMS" />,
        value: <FormattedMessage id={`LABELS.TERMS.${payment_terms}`} />,
        xs: 4,
      },
      {
        label: <FormattedMessage id="TXN_DETAILS.INVOICE_DATE" />,
        value: formatTimezone(new Date(date)),
        xs: 4,
      },
      {
        label: <FormattedMessage id="TXN_DETAILS.DUE_DATE" />,
        value: (
          <>
            <span>{formatTimezone(new Date(due_date))}</span>
            {payment_terms === PaymentTerms.PAY_IN_ADVANCE && (
              <Tooltip
                title={<FormattedMessage id="TRANSACTION_PIA_TOOLTIP" />}
                placement="bottom"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      color: WHITE,
                      backgroundColor: '#5164A3',
                      fontWeight: 600,
                      fontSize: fontSizes.xxxSmall,
                      lineHeight: spaces.space_s,
                      padding: spaces.space_xs,
                      maxWidth: '200px',
                    },
                  },
                }}
              >
                <span style={{ marginLeft: '10px' }}>
                  <MpTag type={type.HIGHLIGHT} label="PIA" />
                </span>
              </Tooltip>
            )}
          </>
        ),
        xs: 4,
      },
    ],
    [
      {
        label: <FormattedMessage id="TXN_DETAILS.INVOICE_PDF" />,
        value: (
          <>
            <FormattedMessage id="TXN_DETAILS.INVOICE_PDF_NAME" />
            <Download
              cursor="pointer"
              sx={{ fontSize: '18px', margin: '0 0 -4px 4px' }}
              onClick={() => {
                window.open(url, '_blank');
              }}
            />
          </>
        ),
        xs: 4,
      },
      {
        label: <FormattedMessage id="TXN_DETAILS.ADDITIONAL_PDF" />,
        value: additional_file_url ? (
          <>
            <FormattedMessage id="TXN_DETAILS.ADDITIONAL_PDF_NAME" />
            <Download
              cursor="pointer"
              sx={{ fontSize: '18px', margin: '0 0 -4px 4px' }}
              onClick={() => {
                window.open(additional_file_url, '_blank');
              }}
            />
          </>
        ) : (
          '-'
        ),
        xs: 4,
      },
      {
        label: <FormattedMessage id="TXN_DETAILS.CREDIT_NOTE" />,
        value: credit_notes.length
          ? credit_notes.map((cn, index) => {
              return (
                <div key={`invoice-details-credit-note-file-${index}`}>
                  <FormattedMessage
                    id="TXN_DETAILS.CREDIT_NOTE_PDF_NAME"
                    context={{ index: index + 1 }}
                  />
                  <Download
                    cursor="pointer"
                    sx={{ fontSize: '18px', margin: '0 0 -4px 4px' }}
                    onClick={() => {
                      window.open(cn.credit_note_file, '_blank');
                    }}
                  />
                </div>
              );
            })
          : '-',
        xs: 4,
      },
    ],
  ];
  if (+late_payment_fees_amount) {
    invoiceData.splice(2, 0, [
      {
        label: <FormattedMessage id="TXN_DETAILS.LATE_PAYMENT_FEE" />,
        value: currency_symbol + formatter(+late_payment_fees_amount),
        xs: 4,
      },
      {
        label: <FormattedMessage id="TXN_DETAILS.LATE_PAYMENT_COMMUNICATION" />,
        value: (
          <>
            <FormattedMessage id="TXN_DETAILS.LATE_PAYMENT_PDF_NAME" />
            <Download
              cursor="pointer"
              sx={{ fontSize: '18px', margin: '0 0 -4px 4px' }}
              onClick={() => {
                window.open(dunning_letter_file_url, '_blank');
              }}
            />
          </>
        ),
        xs: 4,
      },
    ]);
  }
  return invoiceData;
};

export const InvoiceData = (invoiceProps: Invoice) => {
  const {
    settings: {
      sellerConfig: { is_dynamic_settlements_enabled },
    },
    user: {
      companyProfile: { is_default_guarantee_enabled },
    },
  } = useAppSelector(state => state);
  const invoiceData = generateInvoiceData(
    invoiceProps,
    is_dynamic_settlements_enabled,
    is_default_guarantee_enabled
  );

  return (
    <Grid item xs={12}>
      <Card sx={{ p: 3, boxShadow: 'none', borderRadius: 2 }}>
        <Grid container alignItems="center">
          <Receipt sx={{ fill: 'url(#with-icon-gradient)', mr: 1 }} />
          <b>
            <FormattedMessage id="DETAILS" />
          </b>
        </Grid>

        <Grid>
          {invoiceData.map((row, index) => {
            const isLast = index === invoiceData.length - 1;
            return (
              <>
                <Grid
                  container
                  rowGap={5}
                  sx={{ mb: 3, mt: 4 }}
                  key={`invoice-details-${invoiceProps.buyer_id}-${index}`}
                >
                  {row.map((column, index) => {
                    const { label, value, xs } = column;
                    return (
                      column && (
                        <Grid
                          key={`transaction-id-${value}-${index}`}
                          item
                          spacing={1}
                          xs={12}
                          md={xs}
                        >
                          <BuyerDetailsCreditAssessmentDataRowLabelStyled>
                            {label}
                          </BuyerDetailsCreditAssessmentDataRowLabelStyled>
                          <Box mt={1}>
                            <b>{value}</b>
                          </Box>
                        </Grid>
                      )
                    );
                  })}
                </Grid>
                {!isLast ? <Divider sx={{ border: '1px solid #ECEDF8' }} /> : ''}
              </>
            );
          })}
        </Grid>
      </Card>
    </Grid>
  );
};
