// constants
import * as TYPES from 'store/types';
import { IS_LOGGED_IN_AS, SELLER_ID, USER_ID, SELLERS } from 'constants/storage.constants';
import storageService from 'utils/storageService';
import { SellerAccount } from 'UI/containers/login/select-mcc-account';

type LoginReducerType = {
  isLoggedIn: boolean;
  isSwitchAccountModalOpen: boolean;
  isLoginAs: boolean;
  sellerId: string;
  userId: string;
  sellers: Array<SellerAccount>;
};

const getStorageData = key =>
  localStorage.getItem(key) && localStorage.getItem(key) !== 'undefined'
    ? localStorage.getItem(key)
    : '';

const initialState = {
  isLoggedIn: storageService.getAccessToken() || false,
  isSwitchAccountModalOpen: false,
  isLoginAs: JSON.parse(getStorageData(IS_LOGGED_IN_AS) || 'false') || false,
  sellerId: getStorageData(SELLER_ID) as string,
  userId: getStorageData(USER_ID) as string,
  sellers: JSON.parse(getStorageData(SELLERS) || '[]') as Array<SellerAccount>,
};

const resetAppUserState = () => {
  localStorage.clear();
  storageService.clearToken();
  return {
    ...initialState,
    sellerId: '',
    userId: '',
    sellers: [],
    isLoginAs: false,
    isLoggedIn: false,
  };
};

export const login = (state = initialState, action): LoginReducerType => {
  switch (action.type) {
    case TYPES.LOGGED_IN:
      return { ...state, isLoggedIn: true };

    case TYPES.LOGGED_OUT:
      return resetAppUserState();

    case TYPES.SHOW_SWITCH_ACCOUNT_MODAL:
      return { ...state, isSwitchAccountModalOpen: action.payload };

    case TYPES.SET_SELLER_ID: {
      localStorage.setItem(SELLER_ID, action.payload);
      return { ...state, sellerId: action.payload };
    }

    case TYPES.SET_IS_LOGIN_AS:
      localStorage.setItem(IS_LOGGED_IN_AS, action.payload);
      return {
        ...state,
        isLoginAs: action.payload,
        isSwitchAccountModalOpen: false,
      };

    case TYPES.SET_SELLER_USER_ID: {
      localStorage.setItem(USER_ID, action.payload);
      return { ...state, userId: action.payload };
    }
    case TYPES.REMOVER_SELLER_USER_ID: {
      localStorage.removeItem(USER_ID);
      return { ...state, userId: '' };
    }

    case TYPES.SET_SELLER_USERS: {
      localStorage.setItem(SELLERS, JSON.stringify(action.payload));
      return { ...state, sellers: action.payload };
    }

    case TYPES.RESET: {
      return resetAppUserState();
    }

    default:
      return { ...state };
  }
};
