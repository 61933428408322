import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography, Tooltip, useMediaQuery, Theme } from '@mui/material';
import { useSnackbar } from 'notistack';

// components
import { FormattedMessage } from 'UI';

// styles
import {
  SettingsPaymentCollectionWrapperStyled,
  SettingBlockTitleStyled,
  SettingSubtitleTitleStyled,
} from './styled';

// constants
import { grey } from 'theme/colors';
import spaces from 'constants/spacing';
import fontSizes from 'constants/font-sizes';
import { getCurrencyIcon } from 'constants/currency';

// utils
import { copyToClipboard, errorMessage } from 'utils';
import { getData } from 'api';
import { PAYMENT_COLLECTION, BANK_DETAILS } from 'constants/api-endpoints.constants';
import { getCurrencySymbol } from 'utils/currencies';
import { useAppSelector } from 'store';

interface DisplayValueInterface {
  value: string;
  handleClickToCopy: (value) => void;
}

const FormatPaymentCollectionDataRowValue = ({ value, handleClickToCopy }: DisplayValueInterface) =>
  value ? (
    <Tooltip title={<FormattedMessage id="CLICK_TO_COPY" />} arrow placement="top">
      <Typography
        onClick={() => handleClickToCopy(value)}
        data-sdet={value}
        display="inline-block"
        sx={{
          wordBreak: 'break-word',
          color: grey[900],
          fontWeight: 600,
          fontSize: fontSizes.xSmall,
          marginTop: spaces.space_mini,
          cursor: 'pointer',
        }}
      >
        {value}
      </Typography>
    </Tooltip>
  ) : (
    <span>-</span>
  );

interface DisplayPaymentCollectDataInterface {
  data: Array<{ label: React.ReactNode | string; value: string }>;
}

const DisplayPaymentCollectData = ({ data }: DisplayPaymentCollectDataInterface) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handleClickToCopy = text => {
    copyToClipboard(text);
    enqueueSnackbar(`${text} copied`);
  };

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {data.map(
        ({ value, label }, index) =>
          value && (
            <Grid
              key={`payment-collection-list-${index}`}
              sx={{ marginTop: isMobile ? 0 : spaces.space_22 }}
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
            >
              <Box key={value} sx={{ marginTop: isMobile ? '20px' : 0 }}>
                <Typography sx={{ color: grey[500], fontSize: fontSizes.xSmall }}>
                  {label}
                </Typography>
                <FormatPaymentCollectionDataRowValue
                  handleClickToCopy={handleClickToCopy}
                  value={value}
                />
              </Box>
            </Grid>
          )
      )}
    </Grid>
  );
};

const getPaymentCollectionArr = paymentCollectionRec =>
  Object.keys(paymentCollectionRec).map(key => ({
    label: <FormattedMessage id={`bank_accounts.${key}`} />,
    value: paymentCollectionRec[key],
  }));

interface PaymentCollectionProps {
  isBankDetail?: boolean;
}

export const PaymentCollection = ({ isBankDetail }: PaymentCollectionProps) => {
  const { sellerId } = useAppSelector(state => state.login);
  const [data, setData] = useState<
    Array<{
      currencyCode: string;
      currencySymbol: string;
      fields: Array<{ label: React.ReactNode | string; value: string }>;
    }>
  >([{ currencyCode: '', fields: getPaymentCollectionArr({}), currencySymbol: '' }]);
  const { enqueueSnackbar } = useSnackbar();

  const isFetched = useRef(false);
  useEffect(() => {
    if (!isFetched.current && sellerId) {
      isFetched.current = true;

      getData(isBankDetail ? `${BANK_DETAILS}${sellerId}` : PAYMENT_COLLECTION(sellerId))
        .then(({ data }) => {
          const paymentCollectionArr = data.map(paymentCollectionRecord => {
            return {
              currencySymbol: getCurrencySymbol(paymentCollectionRecord.currency_code),
              currencyCode: paymentCollectionRecord.currency_code,
              fields: getPaymentCollectionArr(paymentCollectionRecord),
            };
          });
          setData(paymentCollectionArr);
        })
        .catch(err => {
          errorMessage(err, enqueueSnackbar);
        });
    }
  }, [sellerId]);

  return (
    <>
      {data.map((record, index) => (
        <SettingsPaymentCollectionWrapperStyled key={`payment-collection-data-${index}`}>
          <Box sx={{ width: { md: '80%', lg: '80%' } }}>
            <SettingBlockTitleStyled>
              {getCurrencyIcon(record.currencyCode)}
              <FormattedMessage
                id={`SETTINGS.${
                  isBankDetail ? 'SETTLMENT_ACCOUNT' : 'PAYMENT_COLLECTION'
                }.BLOCK_TITLE`}
                context={{
                  code: record.currencyCode,
                  symbol: record.currencySymbol,
                }}
              />
            </SettingBlockTitleStyled>
            {!isBankDetail && (
              <SettingSubtitleTitleStyled>
                <FormattedMessage id="SETTINGS.PAYMENT_COLLECTION.DESCRIPTION" />
              </SettingSubtitleTitleStyled>
            )}
            {record.fields.length ? (
              <DisplayPaymentCollectData
                key={`payment-collection-data-${index}`}
                data={record.fields}
              />
            ) : (
              ''
            )}
          </Box>
        </SettingsPaymentCollectionWrapperStyled>
      ))}
    </>
  );
};
