// constants
import { EMAIL_REGEX_CASE_INSENSITIVE, PHONE_NUMBER_REGEX } from 'constants/regex';
import IBANValidator from 'iban';
import { URL_REGEX, EMPTY_STING_REGEX } from 'constants/regex';

export const validators = (values, valuesToValidate) => {
  const errors: Record<string, string> = {};
  if (valuesToValidate.email) {
    if (!values.email) {
      errors.email = 'VALIDATOR.EMAIL_REQUIRED';
    } else if (!EMAIL_REGEX_CASE_INSENSITIVE.test(values.email)) {
      errors.email = 'VALIDATOR.WORK_EMAIL_REQUIRED';
    }
  }

  if (valuesToValidate.merchantCreditNoteId) {
    if (!values.merchantCreditNoteId) {
      errors.merchantCreditNoteId = 'VALIDATOR.MERCHANT_CREDIT_NOTE_ID';
    }
  }
  if (valuesToValidate.merchantOrderId) {
    if (!values.merchantOrderId) {
      errors.merchantOrderId = 'VALIDATOR.MERCHANT_ORDER_ID';
    }
  }

  if (valuesToValidate.merchantInvoiceId) {
    if (!values.merchantInvoiceId) {
      errors.merchantInvoiceId = 'VALIDATOR.MERCHANT_INVOICE_ID';
    }
  }

  if (valuesToValidate.reason) {
    if (!values.reason) {
      errors.reason = 'VALIDATOR.REASON';
    }
  }

  if (valuesToValidate.creditLimitTerms) {
    if (!values.creditLimitTerms) {
      errors.creditLimitTerms = 'VALIDATOR.BUYER_CREDIT_LIMIT_ACKNOWLEDGEMENT';
    }
  }
  if (valuesToValidate.bankStatement) {
    if (!values.bankStatement) {
      errors.bankStatement = 'VALIDATOR.BANK_STATEMENT';
    }
  }

  if (valuesToValidate.merchantCreditNoteFile) {
    if (!values.merchantCreditNoteFile) {
      errors.merchantCreditNoteFile = 'VALIDATOR.MERCHANT_CREDIT_NOTE_FILE';
    }
  }
  if (valuesToValidate.merchantInvoiceFile) {
    if (!values.merchantInvoiceFile) {
      errors.merchantInvoiceFile = 'VALIDATOR.MERCHANT_INVOICE_FILE';
    }
  }
  if (valuesToValidate.invoiceAmount) {
    const [amount, cents = 0] = String(values.invoiceAmount).split('.');
    // @ts-ignore
    if (amount == 0 && cents == 0) {
      errors.invoiceAmount = 'VALIDATOR.AMOUNT_GREATER_THAN_ZERO';
    } else if (!amount && cents == 0) {
      errors.invoiceAmount = 'VALIDATOR.INVOICE_AMOUNT_REQUIRED';
    }
  }

  if (valuesToValidate.orderAmount) {
    const [amount, cents = 0] = String(values.orderAmount).split('.');
    // @ts-ignore
    if (amount == 0 && cents == 0) {
      errors.orderAmount = 'VALIDATOR.AMOUNT_GREATER_THAN_ZERO';
    } else if (!amount && cents == 0) {
      errors.orderAmount = 'VALIDATOR.ORDER_AMOUNT_REQUIRED';
    }
  }

  if (valuesToValidate.creditNoteAmount) {
    const [amount, cents = 0] = String(values.creditNoteAmount).split('.');
    // @ts-ignore
    if (amount == 0 && cents == 0) {
      errors.creditNoteAmount = 'VALIDATOR.AMOUNT_GREATER_THAN_ZERO';
    } else if (!amount && cents == 0) {
      errors.creditNoteAmount = 'VALIDATOR.CREDIT_NOTE_AMOOUNT';
    }
  }

  if (valuesToValidate.creditNoteCreationDate && !values.creditNoteCreationDate) {
    errors.creditNoteCreationDate = 'VALIDATOR.DATE_REQUIRED';
  }

  if (valuesToValidate.merchantInvoiceDate && !values.merchantInvoiceDate) {
    errors.merchantInvoiceDate = 'VALIDATOR.INVOICE_DATE';
  }

  if (
    values.accountsPayableEmail &&
    !EMAIL_REGEX_CASE_INSENSITIVE.test(values.accountsPayableEmail)
  ) {
    errors.accountsPayableEmail = 'VALIDATOR.ACCOUNT_PAYABLE_EMAIL';
  }

  if (valuesToValidate.transaction_id && !values.transaction_id) {
    errors.transaction_id = 'VALIDATOR.TRANSACTION_ID';
  }

  if (
    valuesToValidate.old_password &&
    valuesToValidate.password &&
    valuesToValidate.isPasswordOptional
  ) {
    if (values.old_password) {
      if (!values.password) {
        errors.password = 'VALIDATOR.PASSWORD_REQUIRED';
      } else if (values.password.length < 8) {
        errors.password = 'VALIDATOR.PASSWORD_STRENGTH_ERROR';
      }
    } else if (values.password) {
      if (!values.old_password) {
        errors.old_password = 'VALIDATOR.OLD_PASSWORD_REQUIRED';
      } else if (values.old_password.length < 8) {
        errors.old_password = 'VALIDATOR.PASSWORD_STRENGTH_ERROR';
      }
    }
  }

  if (valuesToValidate.password && !valuesToValidate.isPasswordOptional) {
    if (!values.password) {
      errors.password = 'VALIDATOR.PASSWORD_REQUIRED';
    } else if (values.password.length < 8) {
      errors.password = 'VALIDATOR.PASSWORD_STRENGTH_ERROR';
    }
  }

  if (valuesToValidate.confirm_password) {
    if (!values.confirm_password) {
      errors.confirm_password = 'VALIDATOR.CONFIRM_PASSWORD';
    } else if (values.password !== values.confirm_password) {
      errors.confirm_password = 'VALIDATOR.PASSWORD_MISMATCH';
    }
  }

  if (valuesToValidate.first_name) {
    if (!values.first_name) {
      errors.first_name = 'VALIDATOR.FIRST_NAME_REQUIRED';
    } else if (values.first_name.length > 30) {
      errors.first_name = 'VALIDATOR.FIRST_NAME_MIN_MAX';
    }
  }

  if (valuesToValidate.last_name) {
    if (!values.last_name) {
      errors.last_name = 'VALIDATOR.LAST_NAME_REQUIRED';
    } else if (values.last_name.length > 30) {
      errors.last_name = 'VALIDATOR.LAST_NAME_MIN_MAX';
    }
  }

  if (valuesToValidate.business_name) {
    if (!values.business_name) {
      errors.business_name = 'VALIDATOR.BUSINESS_NAME_REQUIRED';
    }
  }
  // maybe lines below are not needed (using business_name instead) but don't want to break anything
  if (valuesToValidate.business) {
    if (!values.business) {
      errors.business = 'VALIDATOR.BUSINESS_NAME_REQUIRED';
    }
  }

  if (valuesToValidate.acc_name) {
    if (!values.acc_name) {
      errors.acc_name = 'VALIDATOR.ACCOUNT_NAME_REQUIRED';
    }
  }
  if (valuesToValidate.IBAN) {
    if (!values.IBAN) {
      errors.IBAN = 'VALIDATOR.IBAN_REQUIRED';
    } else if (!IBANValidator.isValid(values.IBAN)) {
      errors.IBAN = 'VALIDATOR.VALID_IBAN_MESSAGE';
    }
  }
  if (valuesToValidate.sort_code) {
    if (!values.sort_code) {
      errors.sort_code = 'VALIDATOR.SORT_CODE_REQUIRED';
    }
  }
  if (valuesToValidate.acc_number) {
    if (!values.acc_number) {
      errors.acc_number = 'VALIDATOR.ACCOUNT_NUMBER_REQUIRED';
    }
  }

  if (valuesToValidate.companySize) {
    if (!values.companySize) {
      errors.companySize = 'VALIDATOR.COMPANY_SIZE_REQUIRED';
    }
  }

  if (valuesToValidate.invoice_file) {
    if (!values.invoice_file) {
      errors.invoice_file = 'VALIDATOR.INVOICE_FILE_REQUIRED';
    }
  }

  if (valuesToValidate.credit_note_file) {
    if (!values.credit_note_file) {
      errors.credit_note_file = 'VALIDATOR.CREDIT_NOTE_FILE_REQUIRED';
    }
  }

  if (valuesToValidate.buyer_id) {
    if (!values.buyer_id) {
      errors.buyer_id = 'VALIDATOR.SELECT_BUYER';
    }
  }

  if (valuesToValidate.vatId) {
    if (!values.vatId) {
      errors.vatId = 'VALIDATOR.VAT_ID_REQUIRED';
    }
  }

  if (valuesToValidate.merchant_buyer_id) {
    if (!values.merchant_buyer_id) {
      errors.merchant_buyer_id = 'VALIDATOR.MERCHANT_BUYER_REQUIRED';
    }
  }

  if (valuesToValidate.registration_number) {
    if (!values.registration_number) {
      errors.registration_number = 'VALIDATOR.REG_NUMBER_REQUIRED';
    }
  }

  if (valuesToValidate.price) {
    const newValue = values.price;
    if (newValue === 0 && +values.cents === 0) {
      errors.price = 'VALIDATOR.AMOUNT_GREATER_THAN_ZERO';
    } else if (!newValue && +values.cents === 0) {
      errors.price = 'VALIDATOR.INVOICE_AMOUNT_REQUIRED';
    }
  }

  if (valuesToValidate.date && !values.date) {
    errors.date = 'VALIDATOR.DATE_REQUIRED';
  }

  if (valuesToValidate.dueDate && !values.dueDate) {
    errors.dueDate = 'VALIDATOR.DUE_DATE_REQUIRED';
  }

  if (valuesToValidate.purchase_order_number) {
    if (!values.purchase_order_number) {
      errors.purchase_order_number = 'VALIDATOR.VALID_PO';
    }
  }

  if (valuesToValidate.order_number) {
    if (!values.order_number) {
      errors.order_number = 'VALIDATOR.VALID_ORDER_NUMBER';
    }
  }

  if (valuesToValidate.invoice_number) {
    if (!values.invoice_number) {
      errors.invoice_number = 'VALIDATOR.VALID_INVOICE_NUMBER';
    }
  }

  if (valuesToValidate.credit_note_number) {
    if (!values.credit_note_number) {
      errors.credit_note_number = 'VALIDATOR.VALID_CREDIT_NOTE_NUMBER';
    }
  }

  if (valuesToValidate.address_line1) {
    if (!values.address_line1 || EMPTY_STING_REGEX.test(values.address_line1)) {
      errors.address_line1 = 'VALIDATOR.ADDRESS_REQUIRED';
    } else if (values.address_line1.length > 100) {
      errors.address_line1 = 'VALIDATOR.ADDRESS_MAX_LENGTH';
    }
  }

  if (valuesToValidate.zip_code) {
    if (!values.zip_code || EMPTY_STING_REGEX.test(values.zip_code)) {
      errors.zip_code = 'VALIDATOR.ZIPCODE_REQUIRED';
    }
  }

  if (valuesToValidate.city) {
    if (!values.city || EMPTY_STING_REGEX.test(values.city)) {
      errors.city = 'VALIDATOR.CITY_REQUIRED';
    }
  }

  if (valuesToValidate.country) {
    if (!values.country) {
      errors.country = 'VALIDATOR.COUNTRY_REQUIRED';
    }
  }

  if (valuesToValidate.shipping_address_line1) {
    if (!values.shipping_address_line1 || EMPTY_STING_REGEX.test(values.shipping_address_line1)) {
      errors.shipping_address_line1 = 'VALIDATOR.ADDRESS_REQUIRED';
    } else if (values.shipping_address_line1.length > 100) {
      errors.shipping_address_line1 = 'VALIDATOR.ADDRESS_MAX_LENGTH';
    }
  }

  if (valuesToValidate.shipping_postal_code) {
    if (!values.shipping_postal_code || EMPTY_STING_REGEX.test(values.shipping_postal_code)) {
      errors.shipping_postal_code = 'VALIDATOR.ZIPCODE_REQUIRED';
    }
  }

  if (valuesToValidate.shipping_city) {
    if (!values.shipping_city || EMPTY_STING_REGEX.test(values.shipping_city)) {
      errors.shipping_city = 'VALIDATOR.CITY_REQUIRED';
    }
  }

  if (valuesToValidate.shipping_country) {
    if (!values.shipping_country) {
      errors.shipping_country = 'VALIDATOR.COUNTRY_REQUIRED';
    }
  }

  if (valuesToValidate.phone) {
    if (!values.phone) {
      errors.phone = 'VALIDATOR.PHONE_NUMBER_REQUIRED';
    }

    if (!PHONE_NUMBER_REGEX.test(values.phone)) {
      errors.phone = 'VALIDATOR.VALID_PHONE_NUMBER';
    }
  }

  if (valuesToValidate.linked_invoice) {
    if (!values.linked_invoice) {
      errors.linked_invoice = 'VALIDATOR.CREDIT_NOTE_APPLIED_TO_INOVICE';
    }
  }

  if (valuesToValidate.netTerms) {
    if (!values.netTerms) {
      errors.netTerms = 'VALIDATOR.NET_TERMS_REQUIRED';
    }
  }

  if (values.credit_decision_callback_url) {
    if (!URL_REGEX.test(values.credit_decision_callback_url)) {
      errors.credit_decision_callback_url = 'VALIDATOR.URL';
    }
  }

  if (values.invoice_status_callback_url) {
    if (!URL_REGEX.test(values.invoice_status_callback_url)) {
      errors.invoice_status_callback_url = 'VALIDATOR.URL';
    }
  }

  return errors;
};

export const isValidURL = string => {
  const res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

export const customValidators = (values, valuesToValidate, validationRules = {}) => {
  const errors = {};

  // Helper function to validate a field
  const validateField = fieldName => {
    const rules = validationRules[fieldName];
    const value = values[fieldName];
    if (rules?.required && !value) {
      errors[fieldName] = rules.error;
    } else if (rules?.regex && !rules.regex.test(value)) {
      errors[fieldName] = rules.error;
    } else if (rules.customValidator) {
      const customError = rules.customValidator(value, values);
      if (customError) {
        errors[fieldName] = customError;
      }
    }
  };

  // Iterate over fields to validate
  for (const fieldName in valuesToValidate) {
    validateField(valuesToValidate[fieldName]);
  }

  return errors;
};
