import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { format } from 'date-fns';
import qs from 'query-string';

import { getData } from 'api';
import { convertObjectToQuery } from 'api/helpers';
import {
  GET_ALL_BUYERS,
  INVOICES,
  ORDERS,
  PENDING_ORDERS,
  SETTLEMENTS,
} from 'constants/api-endpoints.constants';
import * as actionTypes from 'store/types';
import * as USE_CASES from 'UI/components/table/constants';
import { setTableIsLoading } from 'store/actions/tables';
import { updateBuyersPageDetails } from '../actions/buyerActions';
import { CREATED_AT_FROM, CREATED_AT_TO } from 'UI/containers/dashboard/settlements';
import { ORDERS_FILTER_STATUS, DEFAULT_ROWS_PER_PAGE } from 'constants/table-filter-options';
import { getCurrencySymbol } from 'utils/currencies';
import { DATE_FORMAT_FOR_API } from 'constants/date';

export const getPageAndRowFromQueryParams = (page_size = DEFAULT_ROWS_PER_PAGE) => {
  const { rowsPerPage, page } = qs.parse(location.hash.split('?')[1]) || {};
  return `page_size=${rowsPerPage || page_size}&page=${page || 1}`;
};

function* fetchPendingOrders(action) {
  const buyerIdFromPayload = action?.payload?.buyerId;
  const { filterBy, search, sortBy } = yield select(state => state.table);
  const { _uuid } = yield select(state => state.buyer.buyerDetails);
  const { sellerId } = yield select(state => state.login);
  const buyerId = _uuid || buyerIdFromPayload;
  const modifiedFilterBy = { ...filterBy };

  const queryObject = { search, ordering: sortBy, ...modifiedFilterBy };

  if (buyerId) queryObject.buyer_id = buyerId;

  const queryParams = convertObjectToQuery(queryObject);
  yield put(setTableIsLoading(true));
  try {
    const res = yield call(
      getData,
      `${PENDING_ORDERS(sellerId)}?${getPageAndRowFromQueryParams() + queryParams}`
    );

    yield put({
      type: actionTypes.UPDATE_PENDING_ORDERS_LIST,
      payload: res.data.data,
    });

    yield put({ type: actionTypes.UPDATE_PAGE_DETAILS, payload: res.data.pagination });
  } finally {
    yield put(setTableIsLoading(false));
  }
}

function* fetchOrders(action) {
  const buyerIdFromPayload = action?.payload?.buyerId;
  const { filterBy, search, sortBy } = yield select(state => state.table);
  const { _uuid } = yield select(state => state.buyer.buyerDetails);
  const { sellerId } = yield select(state => state.login);
  const buyerId = _uuid || buyerIdFromPayload;
  const modifiedFilterBy = { ...filterBy };
  // hack as we can't use merchant_status twice
  if (modifiedFilterBy[ORDERS_FILTER_STATUS]) {
    modifiedFilterBy.status = modifiedFilterBy[ORDERS_FILTER_STATUS];
    delete modifiedFilterBy[ORDERS_FILTER_STATUS];
  }
  const queryObject = { search, sort: sortBy, ...modifiedFilterBy };

  if (buyerId) queryObject.buyer_id = buyerId;

  const queryParams = convertObjectToQuery(queryObject);
  yield put(setTableIsLoading(true));
  try {
    const res = yield call(
      getData,
      `${ORDERS(sellerId)}?${getPageAndRowFromQueryParams() + queryParams}`
    );
    const orders = res.data.data.map(order => ({
      ...order,
      currency_symbol: getCurrencySymbol(order.currency_code),
      invoices: order.invoices.map(invoice => ({
        ...invoice,
        currency_symbol: getCurrencySymbol(invoice.currency_code),
      })),
    }));
    yield put({
      type: actionTypes.UPDATE_ORDERS_LIST,
      payload: orders,
    });
    yield put({ type: actionTypes.UPDATE_PAGE_DETAILS, payload: res.data.pagination });
  } finally {
    yield put(setTableIsLoading(false));
  }
}

function* fetchInvoices(action) {
  const buyerIdFromPayload = action?.payload?.buyerId;
  const { filterBy, search, sortBy } = yield select(state => state.table);
  const { _uuid } = yield select(state => state.buyer.buyerDetails);
  const { sellerId } = yield select(state => state.login);
  const queryObject = { search, ordering: sortBy, ...filterBy };
  const buyerId = _uuid || buyerIdFromPayload;

  if (buyerId) {
    queryObject.buyer_id = buyerId;
  }

  const queryParams = convertObjectToQuery(queryObject);
  yield put(setTableIsLoading(true));
  try {
    const res = yield call(
      getData,
      `${INVOICES(sellerId)}?${getPageAndRowFromQueryParams() + queryParams}`
    );
    const invoices = res.data.data.map(invoice => {
      return {
        ...invoice,
        currency_symbol: getCurrencySymbol(invoice.currency_code),
      };
    });

    yield put({
      type: actionTypes.UPDATE_INVOICE_LIST,
      payload: invoices,
    });
    yield put({ type: actionTypes.UPDATE_PAGE_DETAILS, payload: res.data.pagination });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  } finally {
    yield put(setTableIsLoading(false));
  }
}

function* watchFetchInvoices() {
  yield takeLatest([actionTypes.FETCH_ALL_INVOICES], fetchInvoices);
}

function* watchFetchOrders() {
  yield takeLatest([actionTypes.FETCH_ALL_ORDERS], fetchOrders);
}

function* watchFetchPendingOrders() {
  yield takeLatest([actionTypes.FETCH_ALL_PENDING_ORDERS], fetchPendingOrders);
}

function* fetchBuyers() {
  const { search, sortBy } = yield select(state => state.table);

  const queryParams = convertObjectToQuery({ search, sort: sortBy });
  const { sellerId } = yield select(state => state.login);
  yield put(setTableIsLoading(true));
  try {
    const res = yield call(
      getData,
      `${GET_ALL_BUYERS(sellerId)}?${getPageAndRowFromQueryParams() + queryParams}`
    );

    const buyersData = res.data.data.map(buyer => {
      const {
        credit_qualification: { currency, ...buyerCreditQualification },
        ...restBuyerDetails
      } = buyer;
      const symbol = getCurrencySymbol(currency.code);
      return {
        ...restBuyerDetails,
        credit_qualification: {
          ...buyerCreditQualification,
          currency: {
            ...currency,
            symbol,
          },
        },
      };
    });

    yield put({ type: actionTypes.UPDATE_BUYER_LIST, payload: buyersData });
    yield put(updateBuyersPageDetails(res.data.pagination));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Failed fetching users');
  } finally {
    yield put(setTableIsLoading(false));
  }
}

function* watchFetchBuyers() {
  yield takeLatest([actionTypes.FETCH_BUYERS], fetchBuyers);
}

function* fetchSettlements() {
  const { search, sortBy, filterBy } = yield select(state => state.table);
  const { sellerId } = yield select(state => state.login);

  const {
    pagination: { page_size },
  } = yield select(state => state.settlements);
  const queryObject = { search, sort: sortBy };
  if (filterBy[CREATED_AT_FROM]) {
    const onlyDate = format(new Date(filterBy[CREATED_AT_FROM]), 'MM/dd/yyyy');
    queryObject[CREATED_AT_FROM] = format(new Date(onlyDate), DATE_FORMAT_FOR_API);
  }
  if (filterBy[CREATED_AT_TO]) {
    const onlyDate = format(new Date(filterBy[CREATED_AT_TO]), 'MM/dd/yyyy');
    queryObject[CREATED_AT_TO] = format(new Date(onlyDate), DATE_FORMAT_FOR_API);
  }
  const queryParams = convertObjectToQuery(queryObject);
  yield put(setTableIsLoading(true));
  try {
    const res = yield call(
      getData,
      SETTLEMENTS(sellerId) + '?' + getPageAndRowFromQueryParams(page_size) + queryParams
    );
    const { pagination, data } = res.data;
    const settlementsData = data.map(settlement => ({
      ...settlement,
      currency_symbol: getCurrencySymbol(settlement.currency_code),
    }));

    yield put({
      type: actionTypes.SET_SETTLEMENTS,
      payload: { data: settlementsData, pagination },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Failed fetching settlements');
  } finally {
    yield put(setTableIsLoading(false));
  }
}

function* watchFetchSettlements() {
  yield takeLatest([actionTypes.FETCH_SETTLEMENTS], fetchSettlements);
}

function* workFetchTableData(action) {
  const { useCase } = yield select(state => state.table);
  switch (useCase) {
    case USE_CASES.TABLE_USE_CASE_ORDERS: {
      return yield fetchOrders(action);
    }

    case USE_CASES.TABLE_USE_CASE_PENDING_ORDERS: {
      return yield fetchPendingOrders(action);
    }

    case USE_CASES.TABLE_USE_CASE_INVOICES: {
      return yield fetchInvoices(action);
    }
    case USE_CASES.TABLE_USE_CASE_BUYERS: {
      return yield fetchBuyers();
    }

    case USE_CASES.TABLE_USE_CASE_SETTLEMENTS: {
      return yield fetchSettlements();
    }
  }
}

function* watchChangeTableSettings() {
  yield takeLatest([actionTypes.CHANGE_TABLE_SETTINGS], workFetchTableData);
}

export function* rootSaga() {
  yield all([
    watchFetchInvoices(),
    watchFetchOrders(),
    watchFetchPendingOrders(),
    watchFetchSettlements(),
    watchChangeTableSettings(),
    watchFetchBuyers(),
  ]);
}
