import styled from '@emotion/styled';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { grey, blue, BACKGROUND_SECONDARY, PRIMARY100, WHITE } from 'theme/colors';

import { hexToRGBA } from 'utils/index';
import { BaseThemeType } from 'theme/baseTheme';
const Selection = props => `
  width: 100%;
  border-radius: ${props.theme.spaces.space_mini};
  
  .true__indicator-separator {
    background-color: ${BACKGROUND_SECONDARY};
  }

  .true__control {
    border: ${props.theme.spaces.space_nano} solid
    ${props.theme.colors.neutral300};
    border-radius: ${props.theme.spaces.space_mini};
    height: 40px;
  }

  .true__placeholder {
    font-size: ${props.theme.fontSizes.xxSmall};
    line-height: ${props.theme.spaces.space_22};
    color: ${grey[400]};
  }

  .true__value-container {
    padding-left: ${props.theme.spaces.space_xxxl};
  }

  .true__menu {
    display: ${props.hideNoOptionMessage && 'none'};
    z-index: 2;
    border: 1px solid ${grey[300]};
    box-sizing: border-box;
    box-shadow: 0 ${props.theme.spaces.space_mini} ${props.theme.spaces.space_xxxs}
    ${hexToRGBA(grey[300], 0.5)};
    border-radius: ${props.theme.spaces.space_mini};
  }

  .true__menu-list {
    z-index: 2;
    max-height: 150px;
    padding: ${props.theme.spaces.space_xxxs};
  }

  & .true__single-value,
  & .true__option {
    font-family: ${props.theme.bodyFont};
    font-size: ${props.theme.fontSizes.xxSmall};
    font-weight: 400;
    line-height: ${props.theme.spaces.space_22};
    color: ${grey[700]};
  }

  & .true__option{
    border-radius: ${props.theme.spaces.space_mini};
    margin: ${props.theme.spaces.space_mini} 0;
  }

  .true__loading-indicator * {
    display: none;
  }

  & .true__option--is-focused {
    background-color: ${PRIMARY100};
  }

  & .true__option--is-selected,
  & .true__option--is-selected.true__option--is-focused {
    background-color: ${blue[400]};
    color: ${WHITE};
  }
`;

export const MpDropdownCreatableSearchWithInputStyled = styled(CreatableSelect)`
  ${Selection}
`;

export const MpDropdownSearchWithInputStyled = styled(Select)`
  ${Selection}
`;

export const MpDropDownSearchInputWrapperStyled = styled.div`
  position: relative;
`;

export const MpDropDownSearchInputIconStyled = styled.img<{ theme?: BaseThemeType }>`
  position: absolute;
  z-index: 1;
  left: ${props => props.theme.spaces.space_10};
  top: ${props => props.theme.spaces.space_10};
`;
