// vendors
import 'date-fns';
import React from 'react';
import { Grid, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

// components
import { Label, MpMessage } from 'UI';

// constants
import { DATE_FORMAT } from 'constants/date';
import { ERROR } from 'constants/message-types';

// styles
import { DateWrapperStyled } from './style';

type DateProps = {
  dateConfigs: Array<{
    isDisabled?: boolean;
    label: string;
    isRequired: boolean;
    value: Date;
    onChange: (data: any) => void;
    error: string;
    id: string;
    name: string;
    disableFuture: boolean;
    disableToolbar: boolean;
    disablePast: boolean;
  }>;
  elementSpacing: 3 | 6 | 12;
  autoOk: boolean;
};

export const Dates = ({ dateConfigs, autoOk, elementSpacing }: DateProps) => {
  return (
    <DateWrapperStyled>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container columnSpacing={2}>
          {dateConfigs.map((date, index) => {
            const {
              label,
              isRequired,
              value,
              onChange,
              error,
              id,
              name,
              disableFuture,
              disableToolbar,
              disablePast,
              isDisabled,
            } = date;
            return (
              <Grid item xs={elementSpacing} key={`invoice-date-${id}-${index}`}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {label && <Label isRequired={isRequired}>{label}</Label>}
                  <DatePicker
                    disabled={isDisabled}
                    disableFuture={disableFuture}
                    // @ts-ignore
                    disableToolbar={disableToolbar}
                    disablePast={disablePast}
                    format={DATE_FORMAT}
                    margin="normal"
                    variant="inline"
                    id="invoice-date-picker"
                    name={name}
                    value={
                      Object.prototype.toString.call(date) === '[object Date]'
                        ? value
                        : new Date(value)
                    }
                    autoOk={autoOk}
                    onChange={value => onChange({ target: { name, value: value } })}
                    renderInput={params => <TextField {...params} />}
                  />
                  {error && <MpMessage type={ERROR} message={error} isPaddingTopRequired />}
                </div>
              </Grid>
            );
          })}
        </Grid>
      </LocalizationProvider>
    </DateWrapperStyled>
  );
};

Dates.defaultProps = {
  autoOk: false,
  elementSpacing: 6,
};
