import React from 'react';
import { MAP_LANG_TO_FLAG } from 'constants/lang';

export const CountryFlag = ({ language }: { language: string; isMarginLeftRequired?: boolean }) => (
  <img
    src={`https://flagcdn.com/w20/${MAP_LANG_TO_FLAG[language] || language}.png`}
    srcSet={`https://flagcdn.com/w40/${MAP_LANG_TO_FLAG[language] || language}.png 2x`}
    width="20"
    height="15"
    alt={`country-${language}`}
  />
);
