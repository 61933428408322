import { getCurrencySymbol } from './currencies';
export const withExtendedPaymentTerms = data => {
  const { credit_qualification } = data;
  return {
    ...data,
    credit_qualification: {
      ...credit_qualification,
      currency: {
        ...credit_qualification.currency,
        symbol: getCurrencySymbol(credit_qualification.currency?.code || 'EUR'),
      },
    },
    payment_term: {
      payment_terms: credit_qualification.payment_terms,
      created_at: credit_qualification.created_at,
      credit_limit: Number(credit_qualification.credit_limit_amount),
      currency_code: credit_qualification.currency?.code || 'EUR',
      currency_name: credit_qualification.currency?.name || 'EURO',
      currency_symbol: getCurrencySymbol(credit_qualification.currency?.code || 'EUR'),
    },
  };
};
