// vendor
import React from 'react';

// styles
import { MpButtonStyled } from './styled';

interface Props {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick: (e?: any) => any;
  type?: string;
  /** isFullWidth */
  isFullWidth: boolean;
  variant?: 'contained' | 'outlined';
  //** To override existing styles */
  additionalStyles?: string | object;
}

export const MpButton = (props: Props) => {
  const { children, type, disabled, variant, isFullWidth, additionalStyles = '' } = props;

  const handleClick = event => {
    event.preventDefault();
    props.onClick?.(event);
  };

  return (
    <MpButtonStyled
      {...props}
      onClick={handleClick}
      // @ts-ignore
      type={type}
      disabled={disabled}
      variant={type === 'ghost' ? 'outlined' : variant}
      additionalStyles={additionalStyles}
      isFullWidth={isFullWidth}
    >
      {children}
    </MpButtonStyled>
  );
};

MpButton.defaultProps = {
  variant: 'contained',
  isFullWidth: false,
};
