import { format } from 'date-fns';

import { Address } from 'store/types/buyers';
import { DATE_FORMAT_FOR_API } from 'constants/date';

export const mapUiValuesToAuthPayload = (values, firstCurrencyCode = 'EUR') => {
  const payload = {
    merchant_order_id: values.merchantOrderId,
    auth_amount: Number(values.invoiceAmount || values.orderAmount),
    order_currency: values.code || firstCurrencyCode,
    order_amount: Number(values.invoiceAmount || values.orderAmount),
    shipping_address: {
      address_line1: values.address_line1,
      address_line2: values.address_line2,
      city: values.city,
      zip_code: values.zip_code,
      country_code: values?.country?.code || values.country,
    },
    metadata: values.metadata || {},
    issued_by: values.issuedBy,
  };
  if (values.netTerms) {
    // @ts-ignore
    payload.payment_terms = values.netTerms;
  }

  return payload;
};

type CapturePayload = {
  invoice: Record<string, string | number>;
  shipment: { address: Omit<Address, 'country'> & { country_code: string } };
  metadata?: Record<string, unknown>;
};

export const mapUiValuesToCapturePayload = (
  values,
  fileUrl,
  additional_file_url,
  firstCurrencyCode = 'EUR'
) => {
  const payload: CapturePayload = {
    invoice: {
      merchant_invoice_id: values.merchantInvoiceId,
      url: fileUrl,
      amount: Number(values.invoiceAmount),
      currency: values.code || firstCurrencyCode,
      date: format(new Date(values.merchantInvoiceDate), DATE_FORMAT_FOR_API),
    },
    shipment: {
      address: {
        address_line1: values.address_line1,
        address_line2: values.address_line2,
        city: values.city,
        zip_code: values.zip_code,
        country_code: values.country.code || values.country,
      },
    },
  };
  if (values.metadata) {
    payload.metadata = values.metadata;
  }
  if (additional_file_url) {
    payload.invoice.additional_file_url = additional_file_url;
  }

  if (values.settlement_date) {
    payload.invoice.settlement_date = values.settlement_date;
  }

  return payload;
};
