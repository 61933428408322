// vendor
import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { useAppDispatch } from 'store';

// constants
import { HOME } from 'constants/routes.constants';
import {
  ResetPasswordFormFields,
  valuesToValidateOnResetPasswordPage,
  initialValuesOnResetPasswordPage,
} from 'constants/form-data';
import { PRIMARY } from 'constants/button-types';

// components
import { MpModal, CreateForm, MpIcon, MpButton, FormattedMessage } from 'UI';

// actions
import {
  startLoading,
  stopLoading,
  verifyPasswordToken,
  putPasswordNewPassword,
} from 'store/actions';

// styles
import {
  PasswordResetWrapperStyled,
  PasswordResetLabel,
  PasswordResetEmailSuccessTitleStyled,
  PasswordResetButtonWrapperStyled,
} from './password-reset.styled';

const renderPasswordResetSection = setPassword => {
  return (
    <>
      <PasswordResetLabel>
        <FormattedMessage id="RESET_PASSWORD_PAGE_DESCRIPTION" />
      </PasswordResetLabel>
      <CreateForm
        formFields={ResetPasswordFormFields}
        valuestoValidate={valuesToValidateOnResetPasswordPage}
        initialValue={initialValuesOnResetPasswordPage}
        primaryButtonConfig={{
          label: <FormattedMessage id="SET_PASSWORD" />,
          handleSubmit: setPassword,
        }}
      />
    </>
  );
};

const renderTokenExpiredSection = () => (
  <PasswordResetLabel>
    <FormattedMessage id="RESET_PASSWORD_PAGE_EXPIRE_TOKEN_DESCRIPTION" />,
  </PasswordResetLabel>
);

const renderSuccessPage = backToLogin => {
  return (
    <div style={{ textAlign: 'center' }}>
      <MpIcon name="tick-circle-green" />
      <PasswordResetEmailSuccessTitleStyled>
        <FormattedMessage id="RESET_PASSWORD_EMAIL_SUCCESS_TITLE" />
      </PasswordResetEmailSuccessTitleStyled>
      <PasswordResetButtonWrapperStyled>
        <MpButton onClick={backToLogin} type={PRIMARY}>
          <FormattedMessage id="BACK_TO_LOGIN" />
        </MpButton>
      </PasswordResetButtonWrapperStyled>
    </div>
  );
};

function PasswordReset(props) {
  // eslint-disable-next-line react/prop-types
  const { token } = queryString.parse(location.search || props.location.search);
  const dispatch = useAppDispatch();
  const [TokenExpired, setTokenExpired] = useState(false);
  const [successPasswordPage, setSuccessPasswordPage] = useState(false);

  const isFetched = useRef(false);
  useEffect(() => {
    if (!isFetched.current) {
      isFetched.current = true;
      dispatch(startLoading);
      verifyPasswordToken(token)
        .catch(() => {
          setTokenExpired(true);
        })
        .finally(() => {
          dispatch(stopLoading);
        });
    }
  }, []);

  const closeForgotPasswordModal = () => {
    // eslint-disable-next-line react/prop-types
    props.history.push(HOME);
  };

  const setPassword = values => {
    const { confirm_password, password: new_password } = values;

    putPasswordNewPassword(token, { confirm_password, new_password })
      .then(() => {
        setSuccessPasswordPage(true);
      })
      .catch(() => {
        setTokenExpired(true);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };

  return (
    <MpModal
      title={!successPasswordPage ? 'MODAL_TITLES.CHOOSE_NEW_PASSWORD' : ''}
      modalOpen={true}
      // @ts-ignore
      handleClose={!successPasswordPage && closeForgotPasswordModal}
      maxWidth="sm"
    >
      <PasswordResetWrapperStyled successPasswordPage={successPasswordPage}>
        {TokenExpired
          ? renderTokenExpiredSection()
          : successPasswordPage
          ? renderSuccessPage(closeForgotPasswordModal)
          : renderPasswordResetSection(setPassword)}
      </PasswordResetWrapperStyled>
    </MpModal>
  );
}

export default PasswordReset;
