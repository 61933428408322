// vendor
import React from 'react';

// icons
import {
  BusinessOutlined,
  InsertDriveFileOutlined,
  SupervisedUserCircleOutlined,
  AllInbox,
  ShoppingCartCheckout,
} from '@mui/icons-material';
import { FormattedMessage } from 'i18n/formatted-message';

// constants

import {
  BUYER_DETAILS_SUB_ROUTE,
  BUYER_INVOICES_SUB_ROUTE,
  BUYER_CONTACTS_SUB_ROUTE,
  BUYER_ORDERS_SUB_ROUTE,
  BUYER_PROCESSED_ORDER_SUB_ROUTE,
  BUYER_REDIRECT_CHECKOUT_SUB_ROUTE,
} from 'constants/routes.constants';

export const BUYERS_HEADER = [
  {
    value: 'Buyers',
    label: <FormattedMessage id="LABELS.BUSINESS_NAME" />,
    sort: false,
    sortBy: 'name',
  },
  {
    value: 'Credit limit',
    label: <FormattedMessage id="BUYER.CREDIT_LIMIT" />,
    sort: false,
    sortBy: 'total_invoice',
  },
  {
    value: 'Available credit',
    label: <FormattedMessage id="BUYER.AVAILABLE_CREDIT" />,
    sort: false,
    sortBy: 'paid_invoice',
  },
  {
    value: 'Net term',
    label: <FormattedMessage id="BUYER.PAYMENT_TERMS" />,
    sort: false,
    sortBy: 'due_invoice',
  },
  // { value: 'Status', label: <FormattedMessage id="TRANSACTIONS.STATUS" />, sort: false },
  { value: 'Credit decision', label: <FormattedMessage id="BUYER.CREDIT_DECISION" />, sort: false },
];

export const BUYERS_HEADER_KEYS = {
  'Buyers': 'Buyers',
  'Credit limit': 'Credit limit',
  'Available credit': 'Available credit',
  'Net term': 'Net term',
  'Status': 'Status',
  'Credit decision': 'Credit decision',
};

export const GET_BUYERS_COMPANY_LIST_DEOUNCE_TIMER = 1000;

export const MAX_STEP_COUNTER = 4;
export const INC_STEPPER = 'INC';
export const DEC_STEPPER = 'DEC';

export const BUYER_DETAILS_MENU = (is_pending_order, buyerId) => {
  const menuItems = [
    {
      label: (
        <>
          <BusinessOutlined />
          <FormattedMessage id="MENU.BUYER.COMPANY" />
        </>
      ),
      path: `/buyers/${buyerId}/${BUYER_DETAILS_SUB_ROUTE}`,
      dataSdet: 'Company',
    },
    {
      label: (
        <>
          <AllInbox />
          <FormattedMessage id="MENU.ORDERS" />
        </>
      ),
      path: is_pending_order
        ? `/buyers/${buyerId}/${BUYER_ORDERS_SUB_ROUTE}/${BUYER_PROCESSED_ORDER_SUB_ROUTE}`
        : `/buyers/${buyerId}/${BUYER_ORDERS_SUB_ROUTE}`,
      dataSdet: 'Orders',
    },
    {
      label: (
        <>
          <InsertDriveFileOutlined />
          <FormattedMessage id="MENU.BUYER.INVOICES" />
        </>
      ),
      path: `/buyers/${buyerId}/${BUYER_INVOICES_SUB_ROUTE}`,
      dataSdet: 'Invoices',
    },
    {
      label: (
        <>
          <SupervisedUserCircleOutlined />
          <FormattedMessage id="MENU.BUYER.CONTACTS" />
        </>
      ),
      dataSdet: 'Contacts',
      path: `/buyers/${buyerId}/${BUYER_CONTACTS_SUB_ROUTE}`,
    },
  ];

  if (
    process.env.REACT_APP_CURRENT_ENV !== 'sandbox' &&
    process.env.REACT_APP_CURRENT_ENV !== 'prod'
  ) {
    menuItems.push({
      label: (
        <>
          <ShoppingCartCheckout />
          <span>Redirect checkout</span>
        </>
      ),
      dataSdet: 'redirect_checkout',
      path: `/buyers/${buyerId}/${BUYER_REDIRECT_CHECKOUT_SUB_ROUTE}`,
    });
  }
  return menuItems;
};

export const SOURCE = {
  GOD_MODE: 'GOD MODE',
  MCC: 'MCC',
};
