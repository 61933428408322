// vendor
import styled from '@emotion/styled';
import { TableContainer, TableCell, TableRow } from '@mui/material';

import { grey, WHITE } from 'theme/colors';
import { BaseThemeType } from 'theme/baseTheme';
import spaces from 'constants/spacing';

export const MpTableContainerStyled = styled(TableContainer, {
  shouldForwardProp: prop => prop !== 'withMinHeight',
})<{
  component?: any;
  withMinHeight?: boolean;
  additionalStyles?: any;
}>`
  position: relative;
  min-height: ${props => (props.withMinHeight ? '400px' : 'inherit')};
  background: ${WHITE};
  box-sizing: border-box;
  border: none !important;
  border-radius: 0;
  box-shadow: none;
  th {
    font-weight: bold;
  }
  ${props => props.additionalStyles}
`;

export const TableCellStyled = styled(TableCell, {
  shouldForwardProp: prop => prop !== 'minWidth',
})<{ minWidth?: string }>`
  line-height: ${spaces.space_22};
  font-size: 12px;
  color: ${grey[700]};
  min-width: ${({ minWidth }) => minWidth || 'initial'};
  p {
    font-size: 12px;
  }
`;

const tablelRow = props => `
  background: ${WHITE};
  border-radius: ${props.theme.spaces.space_micro};
`;

export const TabletRowStyled = styled(TableRow, {
  shouldForwardProp: prop => prop !== 'isCursorPointer',
})<{ isCursorPointer?: boolean; type?: string }>`
  ${tablelRow};
  cursor: ${props => props.isCursorPointer && 'pointer'};
  border-bottom: ${spaces.space_nano} solid ${grey[100]};
  td {
    border: none;
  }
`;

export const TabletHeaderRowStyled = styled(TableRow)<{ theme?: BaseThemeType }>`
  ${tablelRow};
`;
