// vendor
import React, { Component } from 'react';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import {
  MenuList,
  ListItemText,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';

// components
import { MpDropdownSelect, MpButton, MpIcon, FormattedMessage, MpInput } from 'UI';
import { CompanyDetails } from './company-details';
import BubbleLoader from 'UI/components/bubble-loader';

// constants
import { GHOST } from 'constants/button-types';
import { GET_BUYERS_COMPANY_LIST_DEOUNCE_TIMER } from 'constants/buyer.constants';
import { blue } from 'theme/colors';

// styles
import {
  AddBuyerPopupButtonWrapperStyled,
  AddBuyerFieldWrapperStyled,
  CustomAddBuyerLabelStyled,
  AddBuyerModalHelperText,
  AddBuyerOptionStyled,
  AddBuyerMenuText,
  MenuItemStyled,
} from './styled';

// actions
import { getBuyersAutoCompleteList } from 'store/actions';
import { SearchedCompany } from 'store/types/buyers';
import { Country } from 'store/types/settings';

// assets
import { RadioButtonUnchecked, CheckCircle } from '@mui/icons-material';

const country = 'country';
const COMPANY = 'company';
const selectOption = 'name_vat_option';
const searchVIA = {
  COMPANY: 'COMPANY',
  VAT_ID: 'VAT_ID',
};
type CompanyCountryFormProps = {
  handleClose: () => any;
  initialValues: {
    country: string;
    company: string;
    selectOption: boolean;
    name_vat_option: string;
    selectedCompany: Record<string, unknown>;
  };
  onSubmit: (values?: any) => any;
  addManually: (values: any) => any;
  addManualButtonLabel: string;
  countries: Array<Country>;
};

type State = {
  companyList: Array<SearchedCompany>;
  isLoading: boolean;
  inputCompanyName: string;
  showOptions: boolean;
};

const disableCompanyInput = ({ country, selectedOption, isSearchByVatAvailable }) => {
  if (!country) {
    return true;
  }

  if (isSearchByVatAvailable && !selectedOption) {
    return true;
  }
};

class AddCompanyForm extends Component<CompanyCountryFormProps, State> {
  state: State = {
    companyList: [],
    isLoading: false,
    inputCompanyName: '',
    showOptions: false,
  };
  selectedCountry = { is_search_by_vat_available: false };

  updateCompanyList = debounce((name, values) => {
    if (name && name.length > 2) {
      this.setState({
        isLoading: true,
        inputCompanyName: name,
      });

      const search_type = values[selectOption] === searchVIA.COMPANY ? 'NAME' : 'VAT_ID';

      getBuyersAutoCompleteList({
        country_code: values.country,
        search_type: this.selectedCountry?.is_search_by_vat_available ? search_type : 'NAME',
        search_term: name,
      })
        .then(({ data }) => {
          const newList = data.businesses.map(company => ({
            ...company,
            label: company.business_name,
            value: company.business_name,
          }));

          this.setState({
            companyList: newList,
            isLoading: false,
            showOptions: true,
          });
        })
        .catch(() => {
          this.setState({
            companyList: [],
            isLoading: false,
            showOptions: true,
          });
        });
    } else {
      this.setState({
        companyList: [],
        showOptions: false,
        inputCompanyName: '',
      });
    }
  }, GET_BUYERS_COMPANY_LIST_DEOUNCE_TIMER);

  handleCompanyChange = (selectedOption, handleChange) => {
    const { country, value = '' } = selectedOption || {};

    if (country) {
      const event = {
        target: {
          name: COMPANY,
          value: selectedOption.business_name,
          //label: selectedOption.business_name,
        },
      };

      handleChange(event);
      handleChange({ target: { name: 'selectedCompany', value: { ...selectedOption } } });
    } else {
      const event = { target: { name: COMPANY, value } };
      handleChange(event);
    }
  };

  valueChangeHandler = ({ handleChange, name, value, label }) => {
    const event = { target: { name, value, label } };
    handleChange(event);
  };

  isDisplayedSearchBy = (selectedCountry = '') => {
    const findSelectedCountry = this.props.countries.find(c => c.value === selectedCountry);
    this.selectedCountry = {
      is_search_by_vat_available: findSelectedCountry?.is_search_by_vat_available || false,
    };
    return findSelectedCountry?.is_search_by_vat_available ? 'block' : 'none';
  };

  render() {
    const { companyList, isLoading, showOptions, inputCompanyName } = this.state;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...this.props.initialValues,
          name_vat_option: this.props.initialValues.name_vat_option || searchVIA.COMPANY,
        }}
        onSubmit={this.props.onSubmit}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => {
          return (
            <form>
              <AddBuyerModalHelperText>
                <FormattedMessage id="ADD_BUYER_POPUP.SELECT_COUNTRY_HELPER" />
              </AddBuyerModalHelperText>
              <div style={{ minHeight: '350px' }}>
                <>
                  <AddBuyerFieldWrapperStyled>
                    <MpDropdownSelect
                      name={country}
                      isClearable={true}
                      label="LABELS.COUNTRY_REGION"
                      isSearchable={true}
                      options={this.props.countries}
                      onChange={selectedOption => {
                        const { value = '', label = '' } = selectedOption || {};
                        this.valueChangeHandler({ handleChange, name: country, value, label });
                        this.valueChangeHandler({
                          handleChange,
                          name: COMPANY,
                          value: '',
                          label: '',
                        });
                        this.valueChangeHandler({
                          handleChange,
                          name: selectOption,
                          value: searchVIA.COMPANY,
                          label: '',
                        });
                        this.setState({
                          companyList: [],
                          showOptions: false,
                        });
                        this.selectedCountry = selectedOption;
                      }}
                      defaultValue={values.country}
                      // @ts-ignore
                      errorMessage={errors.country && touched.country && errors.country}
                    />
                  </AddBuyerFieldWrapperStyled>

                  <Box mt={3} sx={{ display: values.country ? 'block' : 'none' }}>
                    <FormControl
                      sx={{
                        display: this.isDisplayedSearchBy(values.country),
                      }}
                    >
                      <RadioGroup
                        sx={{
                          flexDirection: 'row',
                        }}
                        defaultValue={values[selectOption] || ''}
                        name={selectOption}
                        onClick={event => {
                          this.valueChangeHandler({
                            handleChange,
                            name: COMPANY,
                            value: '',
                            label: '',
                          });
                          this.setState({ inputCompanyName: '', showOptions: false });
                          handleChange(event);
                        }}
                      >
                        <FormControlLabel
                          disabled={!values.country}
                          sx={{ m: 0, ml: '-9px' }}
                          checked={values[selectOption] === searchVIA.COMPANY}
                          control={
                            <Radio icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircle />} />
                          }
                          label={<FormattedMessage id="BUYER.COMPANY_NAME" />}
                          value={searchVIA.COMPANY}
                        />
                        <FormControlLabel
                          disabled={!values.country}
                          sx={{ m: 0 }}
                          checked={values[selectOption] === searchVIA.VAT_ID}
                          control={
                            <Radio icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircle />} />
                          }
                          label={<FormattedMessage id="LABELS.VAT_ID" />}
                          value={searchVIA.VAT_ID}
                        />
                      </RadioGroup>
                    </FormControl>

                    <MpInput
                      onChange={({ target: { value } }) => {
                        handleChange({ target: { name: COMPANY, value } });
                        this.updateCompanyList(value, values);
                      }}
                      name={COMPANY}
                      value={values.company}
                      leftIcon="search"
                      isRequired
                      label={
                        this.selectedCountry?.is_search_by_vat_available
                          ? ''
                          : 'LABELS.BUSINESS_NAME'
                      }
                      placeholder="PLACEHOLDERS.START_TYPING"
                      isDisabled={disableCompanyInput({
                        country: values.country,
                        selectedOption: values[selectOption],
                        isSearchByVatAvailable: this.selectedCountry?.is_search_by_vat_available,
                      })}
                    />

                    {showOptions && (
                      <AddBuyerOptionStyled>
                        <MenuList
                          sx={{
                            padding: 0,
                            maxHeight: '200px',
                            overflow: 'auto',
                          }}
                        >
                          {companyList.map((company, index) => {
                            const { address_line1, address_line2, city, zip_code } =
                              company.address;
                            const isLast = index === companyList.length - 1;

                            return (
                              <MenuItemStyled
                                showCursor
                                isLast={isLast}
                                key={company.business_name + company.registration_number}
                                onClick={() => {
                                  this.setState({ showOptions: false, companyList: [] }, () => {
                                    this.handleCompanyChange(
                                      {
                                        ...company,
                                        label: !this.selectedCountry?.is_search_by_vat_available
                                          ? company.business_name
                                          : values[selectOption] === searchVIA.COMPANY
                                          ? company.business_name
                                          : company.vat_id,
                                        value: company.business_name,
                                      },
                                      handleChange
                                    );
                                  });
                                }}
                              >
                                <ListItemText
                                  style={{ whiteSpace: 'break-spaces', paddingRight: '8px' }}
                                >
                                  <AddBuyerMenuText isMarginPresent isBold>
                                    {company.business_name}
                                  </AddBuyerMenuText>

                                  <AddBuyerMenuText variant="body2" color="text.secondary">
                                    {address_line1}
                                  </AddBuyerMenuText>

                                  <AddBuyerMenuText variant="body2" color="text.secondary">
                                    {[address_line2, city, zip_code].filter(i => i).join(', ')}
                                  </AddBuyerMenuText>
                                </ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                  {company.registration_number}
                                </Typography>
                              </MenuItemStyled>
                            );
                          })}

                          <MenuItemStyled
                            onClick={() =>
                              this.props.addManually({
                                country: values.country,
                                business_name:
                                  values[selectOption] === searchVIA.COMPANY
                                    ? inputCompanyName
                                    : '',
                              })
                            }
                          >
                            <CustomAddBuyerLabelStyled>
                              <div style={{ marginBottom: '8px', fontWeight: 'bold' }}>
                                <FormattedMessage id="COMPANY.NOT_FOUND" />
                              </div>
                              <FormattedMessage id="COMPANY.HAS_NOT_BEEN_FOUND" />
                              <br />
                              <span
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  color: blue[400],
                                }}
                              >
                                <FormattedMessage id="COMPANY.CLICK_HERE" />
                              </span>
                              <FormattedMessage id="COMPANY.ADD_MANUALLY" />
                            </CustomAddBuyerLabelStyled>
                          </MenuItemStyled>
                        </MenuList>
                      </AddBuyerOptionStyled>
                    )}
                  </Box>
                  {isLoading ? (
                    <Box mt={3} textAlign="center">
                      <BubbleLoader />
                    </Box>
                  ) : (
                    ''
                  )}
                  {values.selectedCompany?.address && (
                    <CompanyDetails
                      // @ts-ignore
                      company={values.selectedCompany}
                      clearSelectedCompanyDetails={() => {
                        handleChange({ target: { name: COMPANY, value: '' } });
                        handleChange({ target: { name: 'selectedCompany', value: {} } });
                      }}
                    />
                  )}
                </>
              </div>
              <AddBuyerPopupButtonWrapperStyled>
                <MpButton
                  onClick={handleSubmit}
                  // @ts-ignore
                  disabled={!(values.country && values?.selectedCompany?.business_name)}
                >
                  <FormattedMessage id="NEXT" />
                  <MpIcon name="arrow-right" leftMargin="12px" />
                </MpButton>
                <MpButton type={GHOST} onClick={this.props.handleClose}>
                  <FormattedMessage id="CANCEL" />
                </MpButton>
              </AddBuyerPopupButtonWrapperStyled>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export { AddCompanyForm };
