import React from 'react';
import { FormattedMessage } from 'UI';

export const FallbackComponent = () => {
  return (
    <div>
      <FormattedMessage id="UI_APP_CRASH_ERROR" />
    </div>
  );
};
