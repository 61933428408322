export const WHITE = '#fff';
export const BLACK = '#000';

export const grey = {
  50: '#F0F9F2',
  100: '#ECEDF8',
  200: '#D9DBF2',
  300: '#D9DBF2', // same as 200 as MUI uses it for borders
  400: '#898DC8',
  500: '#6266A7',
  600: '#3C3F7C',
  700: '#282B5D',
  800: '#171945',
  900: '#000339',
};

export const blue = {
  50: '#EAF3FA',
  100: '#D1E5F5',
  200: '#A8CCEB',
  300: '#7AB1E1',
  400: '#343AEB', // Crediblue
  500: '#304483',
  600: '#286295',
  700: '#1F4A70',
  800: '#14324D',
  900: '#10273C',
};

export const green = {
  50: '#E5F5E8',
  100: '#CDFFF9',
  300: '#87FFF1',
  500: '#10E9D7',
};

export const yellow = {
  50: '#FFFAEB',
  100: '#F3E4FF',
  300: '#D5A3FF',
  500: '#A434FD',
};

export const red = {
  50: '#FCEEEE',
  100: '#FFE4EB',
  300: '#FFA8BD',
  500: '#FF4975',
};

export const black = {
  300: '#B6B8E2',
};

export const PRIMARY100 = '#EAF3FA';
export const BACKGROUND_SECONDARY = '#F8F9FA';
