// vendor
import React, { PureComponent } from 'react';

// styles
import { MpLinkStyled } from './mp-link.styled';

type Props = {
  /** Name or jsx to be rendered inside MpLink */
  children: string | React.ReactNode;
  /** Additional css styles */
  additionalStyles?: string;
  /** Click handler */
  onClick?: (a?: any) => any;
  /** Navigate to */
  to?: string;
};

export class MpLink extends PureComponent<Props> {
  render() {
    const { to, onClick, children, additionalStyles } = this.props;
    return (
      <MpLinkStyled to={to} onClick={onClick} additionalStyles={additionalStyles}>
        {children}
      </MpLinkStyled>
    );
  }
}
