import styled from '@emotion/styled';
import { BaseThemeType } from 'theme/baseTheme';
import { blue } from 'theme/colors';
import { Box } from '@mui/material';

export const LoaderWrapper = styled(Box)<{
  theme?: BaseThemeType;
  showCursor?: boolean;
  isLast?: boolean;
}>`
  [id|='loader'] {
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
  }

  #loader-4 span {
    display: inline-block;
    width: 17px;
    height: 17px;
    border-radius: 100%;
    background-color: ${blue[400]};
    margin: 0 4px;
    opacity: 0;
  }
  #loader-4 span:nth-child(1) {
    animation: opacitychange 0.75s ease-in-out infinite;
  }
  #loader-4 span:nth-child(2) {
    animation: opacitychange 0.75s ease-in-out 0.18s infinite;
  }
  #loader-4 span:nth-child(3) {
    animation: opacitychange 0.75s ease-in-out 0.375s infinite;
  }

  #loader-4 span:nth-child(4) {
    animation: opacitychange 0.75s ease-in-out 0.55s infinite;
  }

  @-moz-keyframes opacitychange {
    0%,
    100% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
  }
  @-webkit-keyframes opacitychange {
    0%,
    100% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
  }
  @-o-keyframes opacitychange {
    0%,
    100% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
  }
  @keyframes opacitychange {
    0%,
    100% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
  }
`;
