// vendor
import React from 'react';

// styles
import { MpMessageStyled } from './mp-message.styled';

// components
import { FormattedMessage } from 'UI';

type Props = {
  /** String Message */
  message: string | React.ReactNode;
  /** Type of message */
  type: 'SUCCESS' | 'ERROR' | 'WARNING' | 'INFORMATION';
  /** isPaddingTopRequired */
  isPaddingTopRequired?: boolean;
};

const MpMessage = (props: Props) => {
  const { message, type, isPaddingTopRequired } = props;
  return (
    <MpMessageStyled type={type} isPaddingTopRequired={isPaddingTopRequired}>
      {/* @ts-ignore */}
      <FormattedMessage id={message} />
    </MpMessageStyled>
  );
};

export default MpMessage;
