// vendor
import React, { useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from 'store';

// actions
import { fetchOrdersActionCreator } from 'store/actions/invoice';
import * as HEADERS from 'constants/invoice.constants';
import { TABLE_USE_CASE_ORDERS } from 'UI/components/table/constants';

// components
import { MpTable } from 'UI';
import { adaptOrdersToUI } from './adaptOrdersToUI';

type Props = {
  buyerId: string;
};

export const OrdersTable = ({ buyerId }: Props) => {
  const dispatch = useAppDispatch();
  const {
    transaction: {
      orders,
      page: { count: totalItems },
    },
    user: {
      userProfile: { status: sellerStatus },
    },
  } = useAppSelector(state => state);

  const unsubscribed = useRef(false);

  useEffect(() => {
    if (!unsubscribed.current) {
      unsubscribed.current = true;
      dispatch(fetchOrdersActionCreator(buyerId));
    }
  }, []);

  return (
    <MpTable
      data={adaptOrdersToUI(orders, sellerStatus)}
      // @ts-ignore
      headerData={buyerId ? HEADERS.BUYER_ORDERS_TABLE_HEADER : HEADERS.ORDERS_TABLE_HEADER}
      totalItems={totalItems}
      useCase={TABLE_USE_CASE_ORDERS}
      withMinHeight
    />
  );
};
