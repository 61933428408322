// vendor
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Done } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'store';
import { useSnackbar } from 'notistack';

// components
import { MpButton, MpModal, FormattedMessage } from 'UI';

// constants
import { GHOST } from 'constants/button-types';
import { URL_PREFIX } from 'constants/api-endpoints.constants';
import { postData } from 'api';
import { snackbarConfig, errorMessage } from 'utils';

// actions
import { stopLoading, startLoading } from 'store/actions';

type Props = {
  invoice: {
    _uuid: string;
    invoice_status: string;
  };
  transactionStatus: string;
  onSuccess: () => any;
};

export const checkInvoiceStatusAndId = ({ _uuid, invoice_status }, transactionStatus) => {
  return (
    process.env.REACT_APP_CURRENT_ENV !== 'prod' &&
    process.env.REACT_APP_CURRENT_ENV !== 'staging' &&
    _uuid &&
    invoice_status !== 'PAID' &&
    invoice_status !== 'RETURNED' &&
    (transactionStatus === 'SETTLED' ||
      transactionStatus === 'CAPTURED' ||
      transactionStatus === 'PARTIALLY REVERSED')
  );
};

function MarkAsPaid({ invoice, onSuccess, transactionStatus }: Props) {
  const [marAsPaidInvoicePopupState, setMarAsPaidInvoicePopupState] = useState(false);
  const { _uuid = '', invoice_status = '' } = invoice || {};
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { sellerId } = useAppSelector(state => state.login);

  const handleMarkAsPaidCloseButtonClick = () => {
    setMarAsPaidInvoicePopupState(prevState => {
      return !prevState;
    });
  };

  const markAsPaidClick = () => {
    dispatch(startLoading);
    postData(`${URL_PREFIX}/${sellerId}/invoice/${_uuid}/mark_invoice_paid`, '')
      .then(() => {
        enqueueSnackbar(<FormattedMessage id="INVOICE_PAID" />, snackbarConfig());
        onSuccess();
        setMarAsPaidInvoicePopupState(false);
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };

  return (
    <React.Fragment>
      {checkInvoiceStatusAndId({ _uuid, invoice_status }, transactionStatus) ? (
        <MpButton onClick={handleMarkAsPaidCloseButtonClick}>
          <Done style={{ marginRight: '5px' }} />
          <FormattedMessage id="MARK_AS_PAID" />
        </MpButton>
      ) : (
        ''
      )}
      <MpModal
        title=""
        handleClose={handleMarkAsPaidCloseButtonClick}
        modalOpen={marAsPaidInvoicePopupState}
      >
        <Box sx={{ margin: '100px 0 50px', textAlign: 'center' }}>
          <Typography variant="h3">
            <FormattedMessage id="MARK_AS_PAID_POPUP.TITLE" />
          </Typography>
          <Typography sx={{ marginTop: '30px' }} variant="body2" color="text.secondary">
            <FormattedMessage id="MARK_AS_PAID_POPUP.DESCRIPTION" />
          </Typography>
        </Box>
        <Box
          sx={{
            margin: '100px 0 50px',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          <MpButton onClick={handleMarkAsPaidCloseButtonClick} type={GHOST}>
            <FormattedMessage id="CANCEL" />
          </MpButton>
          <MpButton onClick={markAsPaidClick}>
            <FormattedMessage id="CONFIRM" />
          </MpButton>
        </Box>
      </MpModal>
    </React.Fragment>
  );
}

export { MarkAsPaid };
