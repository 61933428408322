export {
  loginAction,
  logoutAction,
  sendForgotPasswordEmailLink,
  verifyPasswordToken,
  putPasswordNewPassword,
  VerifyEmail,
  resendEmailVerificationLink,
} from 'store/actions/loginActions';

export {
  openBuyerPopup,
  closeBuyerPopup,
  openAddPersonPopup,
  closeAddPersonPopup,
} from 'store/actions/popupActions';

export {
  addBuyer,
  getBuyerBasicProfileDetails,
  deleteBuyersContact,
  addBuyerContact,
  updateBuyerContact,
  //setBuyersTerms,
  getBuyersAutoCompleteList,
  resetBuyerForm,
} from 'store/actions/buyerActions';

export { getAllInvoicesActionCreator, addInvoice } from 'store/actions/invoice';

export {
  updateSellersCompanyProfile,
  fetchTeamsData,
  fetchUserProfile,
  fetchCompanyProfile,
  addContact,
  deleteSellersContact,
  updateContact,
  updateUserProfile,
  userProfilePicUpload,
  updateCompanyProfile,
  companyProfilePicUpload,
  fetchPaymentCollections,
  getMerchantFees,
} from 'store/actions/userActions';

import {
  LOADING_START,
  LOADING_STOP,
  RESET,
  SHOW_SWITCH_ACCOUNT_MODAL,
  SET_IS_LOGIN_AS,
} from 'store/types';

export const startLoading = {
  type: LOADING_START,
};

export const stopLoading = {
  type: LOADING_STOP,
};

export const resetState = {
  type: RESET,
};

export const openSwitchAccountModal = value => {
  return { type: SHOW_SWITCH_ACCOUNT_MODAL, payload: value };
};

export const setIsLoginAs = value => {
  return { type: SET_IS_LOGIN_AS, payload: value };
};
