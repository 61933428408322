export const SUPPORTED_LANGUAGES = [
  { value: 'en', label: 'lang.en' },
  { value: 'es', label: 'lang.es' },
  { value: 'nl', label: 'lang.nl' },
  { value: 'de', label: 'lang.de' },
  { value: 'fr', label: 'lang.fr' },
  { value: 'pl', label: 'lang.pl' },
  { value: 'it', label: 'lang.it' },
  { value: 'pt', label: 'lang.pt' },
];

export type SupportedLanguage = 'en' | 'es' | 'nl' | 'de' | 'fr' | 'pl' | 'it' | 'pt';
