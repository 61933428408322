export { default as axiosConfig } from './interceptor';

// container
import {
  InvoiceTable,
  BuyersTable,
  InvoiceDetails,
  BuyerDetailDashboard,
  BuyerCompanyDetails,
  BuyerContact,
  Settings,
  Overview,
} from 'UI';
import RedirectCheckout from 'UI/containers/redirect-checkout';

// constants
import {
  INVOICES,
  BUYERS,
  SETTINGS,
  INVOICE_DETAILS_ROUTE,
  BUYERS_DASHBOARD_ROUTE,
  BUYERS_DETAILS_ROUTE,
  BUYERS_CONTACTS_ROUTE,
  BUYERS_REDIRECT_CHECKOUT_ROUTE,
} from 'constants/routes.constants';

// Overview route
export const OverviewRoute = { path: '/', component: Overview };

export const InvoiceRoute = { path: INVOICES, component: InvoiceTable };
export const BuyersRoute = { path: BUYERS, component: BuyersTable };
export const SettingsRoute = { path: SETTINGS, component: Settings };

// Invoice
export const InvoiceDetailsRoute = {
  path: INVOICE_DETAILS_ROUTE,
  component: InvoiceDetails,
};

// Buyers
export const BuyersDashboardRoute = {
  path: BUYERS_DASHBOARD_ROUTE,
  component: BuyerDetailDashboard,
};

export const BuyersDetailsRoute = {
  path: BUYERS_DETAILS_ROUTE,
  component: BuyerCompanyDetails,
};

export const BuyersContactsRoute = {
  path: BUYERS_CONTACTS_ROUTE,
  component: BuyerContact,
};

export const BuyersRedirectCheckoutRoute = {
  path: BUYERS_REDIRECT_CHECKOUT_ROUTE,
  component: RedirectCheckout,
};
