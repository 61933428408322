/** Home Routes */

export const HOME = '';

export const LOGIN = `/login`;
export const SIGNUP = `/sign-up`;

export const PASSWORD_RESET = `/home/reset_password/verify`;

export const EMAIL_TOKEN_VERIFY = `/home/verify/email`; // append token at the end

/** Dashboard Routes */
const DASHBOARD = '';
export const OVERVIEW = `${DASHBOARD}/`;

// Invoice Routes
export const INVOICES = `${DASHBOARD}/invoices`;
export const INVOICE_DETAILS = `${INVOICES}/details`;
export const INVOICE_DETAILS_ROUTE = `${INVOICE_DETAILS}/:id`;
export const ORDERS = '/orders';
export const ORDERS_DETAILS_PROCESSED = `${ORDERS}/processed-orders`;
export const ORDERS_DETAILS_PENDING = `${ORDERS}/pending-orders`;
export const MARK_AS_PAID = `${DASHBOARD}/mark-as-paid`;

// Buyers Routes
export const BUYER_DETAILS_SUB_ROUTE = 'details';
export const BUYER_INVOICES_SUB_ROUTE = 'buyer-invoice';
export const BUYER_ORDERS_SUB_ROUTE = 'buyer-order';
export const BUYER_CONTACTS_SUB_ROUTE = 'contacts';
export const BUYER_REDIRECT_CHECKOUT_SUB_ROUTE = 'redirect-checkout';
export const BUYER_PROCESSED_ORDER_SUB_ROUTE = 'processed-orders';
export const BUYER_PENDING_ORDER_SUB_ROUTE = 'pending-orders';

export const BUYERS = `${DASHBOARD}/buyers`;
export const BUYERS_DETAILS = `${BUYERS}`;
export const BUYERS_DASHBOARD_ROUTE = `${BUYERS_DETAILS}/:id`;
export const BUYERS_DETAILS_ROUTE = `${BUYERS_DASHBOARD_ROUTE}/${BUYER_DETAILS_SUB_ROUTE}`;
export const BUYERS_INVOICE_ROUTE = `${BUYERS_DASHBOARD_ROUTE}/${BUYER_INVOICES_SUB_ROUTE}`;
export const BUYERS_ORDERS_ROUTE = `${BUYERS_DASHBOARD_ROUTE}/${BUYER_ORDERS_SUB_ROUTE}`;
export const BUYERS_CONTACTS_ROUTE = `${BUYERS_DASHBOARD_ROUTE}/${BUYER_CONTACTS_SUB_ROUTE}`;
export const BUYERS_REDIRECT_CHECKOUT_ROUTE = `${BUYERS_DASHBOARD_ROUTE}/${BUYER_REDIRECT_CHECKOUT_SUB_ROUTE}`;
export const BUYERS_ORDERS_DETAILS_PROCESSED_ROUTE = `${BUYERS_ORDERS_ROUTE}/${BUYER_PROCESSED_ORDER_SUB_ROUTE}`;
export const BUYERS_ORDERS_DETAILS_PENDING_ROUTE = `${BUYERS_ORDERS_ROUTE}/${BUYER_PENDING_ORDER_SUB_ROUTE}`;

// settlement
export const SETTLEMENTS = `${DASHBOARD}/settlements`;

// settings
export const USER_PROFILE_ROUTE = 'user-profile';
export const COMPANY_ROUTE = 'company';
export const TEAM_ROUTE = 'team';
export const API_KEY_ROUTE = 'api-keys-and-urls';
export const KYC_PROCESS = 'kyc-process';
export const NOTIFICATION_ROUTE = 'notifications';
export const PAYMENT_COLLECTION_ROUTE = 'payment-collection';
export const FEES_ROUTE = 'fees';
export const BILLING_ROUTE = `billing`;
export const SETTINGS = `${DASHBOARD}/settings`;
export const SETTINGS_USER_PROFILE = `${SETTINGS}/${USER_PROFILE_ROUTE}`;
export const SETTINGS_COMPANY = `${SETTINGS}/${COMPANY_ROUTE}`;
export const SETTINGS_IBAN_ID = 'bank-account-hash';
export const SETTINGS_TEAM = `${SETTINGS}/${TEAM_ROUTE}`;
export const SETTINGS_API_KEYS = `${SETTINGS}/${API_KEY_ROUTE}`;
export const SETTING_KYC_PROCESS = `${SETTINGS}/${KYC_PROCESS}`;
export const SETTINGS_EMAILS = `${SETTINGS}/${NOTIFICATION_ROUTE}`;
export const PAYMENT_COLLECTION = `${SETTINGS}/${PAYMENT_COLLECTION_ROUTE}`;
export const SETTINGS_FEES = `${SETTINGS}/${FEES_ROUTE}`;
export const SETTINGS_BILLING = `${SETTINGS}/${BILLING_ROUTE}`;

// payables
export const PAYABLES = `${DASHBOARD}/payables`;
