// vendor
import React from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { ArrowBackRounded } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

// components
import { FormattedMessage, MpButton } from 'UI';
import { BuyerStatus } from 'store/types/buyers';

// utils
import { isTestingOrSandboxEnv } from 'utils';

// constants
import { BUYERS } from 'constants/routes.constants';
import {
  OPEN_UPDATE_PAYMENT_TERMS_MODAL,
  OPEN_UPDATE_CREDIT_LIMIT_MODAL,
  OPEN_APPROVE_REJECT_BUYER_MODAL,
} from 'store/types';

// styles
import { MpBuyerHeaderTitleStyled } from './styled';

// utils
import { useAppSelector } from 'store';
import { GHOST } from 'constants/button-types';

interface DisplayBuyerHeaderNameSectionProp {
  name: string;
}

export const BuyerDetailsHeaderNameSection = ({ name = '' }: DisplayBuyerHeaderNameSectionProp) => {
  const history = useHistory();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Box
      display="flex"
      gap={2}
      alignItems="center"
      flexWrap={isMobile ? 'wrap' : 'nowrap'}
      width="100%"
      position="relative"
    >
      <ArrowBackRounded style={{ cursor: 'pointer' }} onClick={() => history.push(BUYERS)} />
      <MpBuyerHeaderTitleStyled>{name}</MpBuyerHeaderTitleStyled>
    </Box>
  );
};

interface BuyerDetailsHeaderProps {
  onClick: (value) => void;
  isContactsPage: boolean;
  isInvoicePage: boolean;
}

export const BuyerDetailsHeader = ({
  onClick,
  isContactsPage = false,
  isInvoicePage = false,
}: BuyerDetailsHeaderProps) => {
  const {
    buyerDetails: { business_name: name, status },
  } = useAppSelector(state => state.buyer);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mb={isInvoicePage ? 0 : 4}
      minHeight={!isInvoicePage ? '48px' : ''}
    >
      {!isInvoicePage && <BuyerDetailsHeaderNameSection name={name} />}

      {!isInvoicePage && !isContactsPage && (
        <Box display="flex" gap="10px">
          {status === BuyerStatus.ACTIVE && (
            <>
              <MpButton
                type={GHOST}
                onClick={() => onClick(OPEN_UPDATE_CREDIT_LIMIT_MODAL)}
                additionalStyles={{ minWidth: '210px' }}
              >
                <FormattedMessage id="BUYER.ACTIONS.REQUEST_UPDATE_CREDIT_LIMIT" />
              </MpButton>
              <MpButton
                type={GHOST}
                onClick={() => onClick(OPEN_UPDATE_PAYMENT_TERMS_MODAL)}
                additionalStyles={{ minWidth: '210px' }}
              >
                <FormattedMessage id="BUYER.ACTIONS.REQUEST_UPDATE_PAYMENT_TERMS" />
              </MpButton>
            </>
          )}

          {isTestingOrSandboxEnv() && (
            <MpButton
              type={GHOST}
              onClick={() => onClick(OPEN_APPROVE_REJECT_BUYER_MODAL)}
              additionalStyles={{ minWidth: '210px' }}
            >
              <FormattedMessage
                id={`BUYER.ACTIONS.BUYER_APPROVAL_${process.env.REACT_APP_CURRENT_ENV?.toUpperCase()}`}
              />
            </MpButton>
          )}
        </Box>
      )}
    </Box>
  );
};
