// vendor
import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { Grid, Box } from '@mui/material';
import { SyncAlt } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { debounce } from 'lodash';

// components
import { FormattedMessage, MpButton, MpDropdownSelect, MpModal, MpDropdownSearchInput } from 'UI';

// api-requests
import { getData } from 'api';

// constants
import { GOD_MODE_SELLERS } from 'constants/api-endpoints.constants';

// store

import { openSwitchAccountModal, setIsLoginAs } from 'store/actions';

// types
import { UserType } from 'store/types/buyers';

// utils
import { errorMessage, snackbarConfig } from 'utils';

// assets
import { ORIGINAL_SELLER_ID } from 'constants/storage.constants';
import { SET_SELLER_ID, SET_SELLER_USER_ID } from 'store/types';

function refreshPage() {
  setTimeout(() => {
    window.location.reload();
  });
}

export const SwitchAccountModal = () => {
  const { isSwitchAccountModalOpen, isLoginAs, sellerId } = useAppSelector(state => state.login);

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const onModalClose = () => dispatch(openSwitchAccountModal(false));
  const [sellers, setSellers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState<{
    business_name: string;
    seller_id: string;
    status: string;
  }>({ business_name: '', seller_id: '', status: '' });

  const [users, setUsers] = useState<Array<UserType>>([]);
  const [selectedUser, setSelectedUser] = useState<{
    label: string;
    value: string;
    uuid: string;
  }>({
    label: '',
    value: '',
    uuid: '',
  });

  const onActivate = async () => {
    localStorage.setItem(ORIGINAL_SELLER_ID, sellerId);
    const { seller_id } = selectedSeller || {};
    dispatch({
      type: SET_SELLER_ID,
      payload: seller_id,
    });
    dispatch({ type: SET_SELLER_USER_ID, payload: selectedUser.uuid });
    dispatch(setIsLoginAs(true));
    refreshPage();
    enqueueSnackbar(
      <>
        <FormattedMessage id="LOGIN_AS.YOU_ARE_SEEING_MCC_AS" />
        <Box ml={1}>{selectedUser.label}</Box>
      </>,
      snackbarConfig({ vertical: 'top', type: 'success', duration: 5000 })
    );
  };

  const fetchMerchants = (name = '') => {
    setIsLoading(true);
    getData(`${GOD_MODE_SELLERS}?search=${name}`)
      .then(res => {
        setSellers(
          res.data.map(seller => ({
            label: seller.business_name,
            value: seller.business_name,
            ...seller,
          }))
        );
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchMerchants = debounce(value => {
    if (value && typeof value === 'string') {
      fetchMerchants(value);
    }
  }, 1000);

  return (
    <MpModal
      title={!isLoginAs ? 'LOGIN_AS.TITLE' : ''}
      titleIcon={
        !isLoginAs ? <SyncAlt sx={{ fill: 'url(#with-icon-gradient)', mr: 1, mb: -0.5 }} /> : ''
      }
      modalOpen={isSwitchAccountModalOpen}
      handleClose={onModalClose}
      maxWidth="sm"
    >
      <Grid container sx={{ my: 2 }} spacing={4}>
        <Grid item xs={12}>
          <MpDropdownSearchInput
            isLoading={isLoading}
            name="account-switch-merchant"
            label="LOGIN_AS.MERCHANT_NAME"
            options={sellers}
            handleInputChange={searchMerchants}
            handleChange={selectedOption => {
              if (selectedOption?.users) {
                const { business_name, _uuid, users, status } = selectedOption;
                setSelectedSeller({ business_name, seller_id: _uuid, status });
                setUsers(users);
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MpDropdownSelect
            name="account-switch-merchant-user-email"
            label="LOGIN_AS.MERCHANT_USER_EMAIL"
            options={users.map(user => ({
              label: `${user.email} (${user.first_name} ${user.last_name})`,
              value: user.email,
              uuid: user._uuid,
            }))}
            onChange={selectedOption => {
              setSelectedUser(selectedOption);
            }}
            //defaultValue={values.country}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <MpButton onClick={onActivate}>
            <FormattedMessage id="ACTIVATE" />
          </MpButton>
        </Grid>
      </Grid>
    </MpModal>
  );
};
