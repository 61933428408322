// vendor
import React from 'react';
import { formatMoneyAmount } from 'b2b-sprinque-tools';
import { format, compareAsc, addDays, isPast } from 'date-fns';
import { parseSprinqueErrorsToString } from 'b2b-sprinque-tools';

// components
import MpTag from 'UI/components/mp-tag/mp-tag';

// constants
import type from 'constants/tag-constants';
import PaymentStatus from 'constants/payment-status.constants';
import { DATE_FORMAT } from 'constants/date';

export { validators, isValidURL } from './validators';
export { hexToRGBA } from './hexToRgba';
export { sort } from './sort';
export { default as storageService } from './storageService';
import { snackbarConfig } from './snackbar-config';
import { FormattedMessage } from 'i18n/formatted-message';
import { NET_TERM_VALUES } from 'constants/enum.constants';
export { copyToClipboard } from './copy-to-clipboard';
export { snackbarConfig };

export const formatter = (value: string | number, options?): any => {
  const lang = localStorage.getItem('sellerLang') || 'en';
  // @ts-ignore
  return formatMoneyAmount(+value, lang, options) || '';
};

export const isInvoiceOpenOrOverdue = status => {
  return status === PaymentStatus.OPEN || status === PaymentStatus.OVERDUE;
};

export const isInvoiceDue = (merchantInvoiceDate, netTerms) => {
  const netTermDays = netTerms ? NET_TERM_VALUES[netTerms] : 0;
  const dueDate = addDays(new Date(merchantInvoiceDate), netTermDays);
  return isPast(dueDate);
};

export const MAP_BUYER_TYPE_TO_COLOR = {
  ACTIVE: type.WARNING,
  UNDER_REVIEW: type.SUCCESS,
  INACTIVE: type.DISABLED,
  BLOCKED: type.DANGER,
  REJECTED: type.DANGER,
};

export const MAP_TYPE_TO_COLOR = {
  // green
  [PaymentStatus.PAID]: type.SUCCESS,
  [PaymentStatus.AUTHORIZED]: type.SUCCESS,

  // grey
  'REFUNDED': type.DISABLED,
  'RETURNED': type.DISABLED,
  'CANCELED': type.DISABLED,
  'VOIDED': type.DISABLED,
  'PARTIALLY REVERSED': type.DISABLED,
  'UNDER_REVIEW': type.DISABLED,
  'MANUAL_REVIEW': type.DISABLED,
  'PENDING': type.DISABLED,

  // yellow
  'APPROVED': type.WARNING,
  [PaymentStatus.OPEN]: type.WARNING,
  [PaymentStatus.CAPTURED]: type.WARNING,

  // red
  [PaymentStatus.OVERDUE]: type.DANGER,
  'DECLINED': type.DANGER,
  'BLOCKED': type.DANGER,
  'REJECTED': type.DANGER,
  'OVERDUE': type.DANGER,
  'AUTH DECLINED': type.DANGER,

  // blue
  [PaymentStatus.APPLIED]: type.PRIMARY,
  'Settlement date updated': type.PRIMARY,
  'PROCESSED': type.PRIMARY,
};

export const renderTag = status => {
  if (!status) return null;
  return (
    <MpTag
      type={MAP_TYPE_TO_COLOR[status] || type.DISABLED}
      label={<FormattedMessage id={`status.${status}`} />}
    />
  );
};

export const successMessage = (message, snackbar) => {
  if (snackbar) {
    snackbar(message, snackbarConfig());
  }
};

export const errorMessage = (err, snackbar?) => {
  const { error_user_msg = '', ...obj } = err?.response?.data?.errors || {};
  const lang = localStorage.getItem('uiLang') || 'en';
  if (snackbar) {
    snackbar(
      // @ts-ignore
      parseSprinqueErrorsToString(obj, lang) || error_user_msg,
      snackbarConfig({ type: 'error' })
    );
  }
  // @ts-ignore
  return parseSprinqueErrorsToString(obj, lang) || error_user_msg;
};

export const formatAddress = (address, separator = '') => {
  return address.filter(word => word && word !== ' ' && word !== 'null').join(separator);
};

export const formatTimezone = date => {
  const dt = date ? new Date(date) : new Date();
  // Tue Dec 11 2017 19:00:00 GMT-0500 (EST)
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
  // Tue Dec 12 2017 00:00:00 GMT-0500 (EST)
  return format(dtDateOnly, DATE_FORMAT);
};

export const isDateDue = (date: string | Date) => {
  const result = compareAsc(new Date(date), new Date());
  return result === -1;
};

export const numberFormatter = (number, currencySymbol?) => {
  let value = '';
  if (number >= 1000000000) {
    value = Number(number / 1000000000).toFixed(2) + 'B';
  } else if (number >= 1000000) {
    value = Number(number / 1000000).toFixed(2) + 'M';
  } else if (number >= 1000) {
    value = Number(number / 1000).toFixed(2) + 'K';
  } else {
    value = number.toString();
  }
  return currencySymbol ? currencySymbol + value : value;
};

export const isTestingOrSandboxEnv = () => {
  return (
    process.env.REACT_APP_CURRENT_ENV !== 'prod' && process.env.REACT_APP_CURRENT_ENV !== 'staging'
  );
};
