// vendor
import styled from '@emotion/styled';
import { grey } from 'theme/colors';
import { BaseThemeType } from 'theme/baseTheme';

export const TextAreaStyled = styled.textarea<{ theme?: BaseThemeType }>`
  width: 100%;
  resize: none;
  min-height: 62px;

  border-radius: ${props => props.theme.spaces.space_mini};
  border: 1px solid #b6b8e2;
  box-shadow: 0px 1px 3px #f2f2f2;
  background: ${props => props.theme.colors.lightBackground};
  position: relative;
  font-family: ${props => props.theme.bodyFont};
  font-size: ${props => props.theme.fontSizes.xxSmall};
  padding: 20px;
  font-weight: 400;
  line-height: ${props => props.theme.spaces.space_22};
  color: ${grey[900]};

  :focus {
    outline: none;
  }

  ::placeholder {
    font-family: ${props => props.theme.bodyFont};
    font-size: ${props => props.theme.fontSizes.xxSmall};
    font-weight: 400;
    line-height: ${props => props.theme.spaces.space_22};
  }

  :disabled {
    cursor: default;
    background-color: #f5f8ff;
  }
`;
