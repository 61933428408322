import React from 'react';
import { Box, Typography } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'store';

import { FormattedMessage, MpButton } from '../../index';
import { AccountItemStyled, MpLoginSecondaryButtonStyled } from './styled';
import { PRIMARY } from 'constants/button-types';
import { RESET, SET_SELLER_ID } from 'store/types';

export type SellerAccount = {
  business_logo: string;
  business_name: string;
  business_type: string;
  email?: string | null;
  registration_number?: string | null;
  trade_name: string;
  vat_id?: string | null;
  _uuid: string;
};

type SelectMccAccountProps = {
  onSelect?: () => void;
  handleSecondaryButtonClick?: () => void;
  setIsAccountSelector?: (val: boolean) => void;
};

export const SelectMccAccount = ({
  onSelect,
  handleSecondaryButtonClick,
  setIsAccountSelector,
}: SelectMccAccountProps) => {
  const dispatch = useAppDispatch();
  const { sellers, sellerId } = useAppSelector(({ login }) => login);

  return (
    <div>
      {setIsAccountSelector && (
        <ArrowBack
          style={{ cursor: 'pointer' }}
          onClick={() => {
            dispatch({ type: RESET });
            setIsAccountSelector(false);
          }}
        />
      )}
      <Box mb={3} mt={2}>
        <Typography variant="h3">
          <FormattedMessage id="SELECT_MERCHANT_ACCOUNT" />
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          <FormattedMessage id="SELECT_MERCHANT_ACCOUNT_DESCRIPTION" />
        </Typography>
      </Box>

      {sellers.map(({ business_name, _uuid }) => {
        const isSelected = _uuid === sellerId;
        return (
          <AccountItemStyled
            isSelected={isSelected}
            key={business_name}
            onClick={() => {
              if (!isSelected) {
                dispatch({
                  type: SET_SELLER_ID,
                  payload: _uuid,
                });
                onSelect?.();
              }
            }}
          >
            <div>{business_name}</div>
            <ArrowForward />
          </AccountItemStyled>
        );
      })}
      {handleSecondaryButtonClick && (
        <Box mt={3}>
          <MpLoginSecondaryButtonStyled>
            <MpButton type={PRIMARY} onClick={handleSecondaryButtonClick} isFullWidth={true}>
              <FormattedMessage id="CREATE_AN_ACCOUNT" />
            </MpButton>
          </MpLoginSecondaryButtonStyled>
        </Box>
      )}
    </div>
  );
};
