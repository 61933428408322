// loading
export const LOADING_START = 'LOADING_START';
export const LOADING_STOP = 'LOADING_STOP';
// login
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const SHOW_SWITCH_ACCOUNT_MODAL = 'SHOW_SWITCH_ACCOUNT_MODAL';
export const SET_IS_LOGIN_AS = 'SET_IS_LOGIN_AS';
export const SET_SELLER_ID = 'SET_SELLER_ID';
export const SET_SELLER_USER_ID = 'SET_SELLER_USER_ID';
export const REMOVER_SELLER_USER_ID = 'REMOVER_SELLER_USER_ID';
export const SET_SELLER_USERS = 'SET_SELLER_USERS';

// user signup details
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const DELETE_USER_DETAILS = 'DELETE_USER_DETAILS';

// buyer
export const FETCH_BUYERS = 'FETCH_BUYERS';
export const UPDATE_BUYER_LIST = 'UPDATE_BUYER_LIST';
export const UPDATE_BUYERS_PAGE_DETAILS = 'UPDATE_BUYERS_PAGE_DETAILS';

/** Individual Buyer Page Basic Details */
export const UPDATE_SINGLE_BUYER_BASIC_DETAILS = 'UPDATE_SINGLE_BUYER_BASIC_DETAILS';
export const RESET_SINGLE_BUYER_BASIC_DETAILS = 'RESET_SINGLE_BUYER_BASIC_DETAILS';
export const CHANGE_ADD_BUYER_FORM = 'CHANGE_ADD_BUYER_FORM';
export const RESET_BUYER_FORM = 'RESET_BUYER_FORM';

// invoices
export const UPDATE_INVOICE_LIST = 'UPDATE_INVOICE_LIST';
export const UPDATE_PAGE_DETAILS = 'UPDATE_PAGE_DETAILS';
export const FETCH_ALL_INVOICES = 'FETCH_ALL_INVOICES';
export const RESET_ADD_INVOICE_STATE = 'RESET_ADD_INVOICE_STATE';

// orders
export const UPDATE_ORDERS_LIST = 'UPDATE_ORDERS_LIST';
export const FETCH_ALL_ORDERS = 'FETCH_ALL_ORDERS';
export const SET_ORDER_ID_FOR_VOID = 'SET_ORDER_ID_FOR_VOID';
export const SET_BUYER_ID_FOR_ORDER = 'SET_BUYER_ID_FOR_ORDER';
export const CLEANUP_ORDER = 'CLEANUP_ORDER';
export const UPDATE_ORDER_STEP_DATA = 'UPDATE_ORDER_STEP_DATA';
export const FETCH_ALL_PENDING_ORDERS = 'FETCH_ALL_PENDING_ORDERS';
export const UPDATE_PENDING_ORDERS_LIST = 'UPDATE_PENDING_ORDERS_LIST';

// invoices
export const CHANGE_INVOICE_STEP = 'CHANGE_INVOICE_STEP';
export const SET_FILTERED_BUYERS = 'SET_FILTERED_BUYERS';
export const BUYER_COMPANY_SELECTED = 'BUYER_COMPANY_SELECTED';
export const UPDATE_INVOICE_STEP_DATA = 'UPDATE_INVOICE_STEP_DATA';
export const CLEANUP_INVOICE = 'CLEANUP_INVOICE';
export const SET_SETTLEMENT_DATE = 'SET_SETTLEMENT_DATE';
export const SET_SETTLEMENT_FEE = 'SET_SETTLEMENT_FEE';
export const SET_AUTH_TRANSACTIONS = 'SET_AUTH_TRANSACTIONS';
export const UPDATE_MERCHANT_ORDER_ID = 'UPDATE_MERCHANT_ORDER_ID';

// user
export const SELLERS_USER_PROFILE = 'SELLERS_USER_PROFILE';
export const SELLERS_COMPANY_PROFILE = 'SELLERS_COMPANY_PROFILE';
export const UPDATE_SELLER_USERS = 'UPDATE_SELLER_USERS';
export const SET_FINGERPRINT_INFO = 'SET_FINGERPRINT_INFO';

export const RESET = 'RESET';

// Tables
export const CHANGE_TABLE_CONFIG = 'CHANGE_TABLE_CONFIG';
export const CHANGE_TABLE_SETTINGS = 'CHANGE_TABLE_SETTINGS';
export const RESET_TABLE_SETTINGS = 'RESET_TABLE_SETTINGS';
export const SET_TABLE_IS_LOADING = 'SET_TABLE_IS_LOADING';

// Settlements
export const FETCH_SETTLEMENTS = 'FETCH_SETTLEMENTS';
export const SET_SETTLEMENTS = 'SET_SETTLEMENTS';
export const SET_DATA_FOR_EDIT_SETTLEMENT_DATE = 'SET_DATA_FOR_EDIT_SETTLEMENT_DATE';

// modals
export const OPEN_ADD_BUYER_POPUP = 'OPEN_ADD_BUYER_POPUP';
export const CLOSE_ADD_BUYER_POPUP = 'CLOSE_ADD_BUYER_POPUP';
export const OPEN_ADD_BUYER_TEAM_PERSON_POPUP = 'OPEN_ADD_BUYER_TEAM_PERSON_POPUP';
export const CLOSE_ADD_BUYER_TEAM_PERSON_POPUP = 'CLOSE_ADD_BUYER_TEAM_PERSON_POPUP';
export const OPEN_UPDATE_PAYMENT_TERMS_MODAL = 'OPEN_UPDATE_PAYMENT_TERMS_MODAL';
export const OPEN_UPDATE_CREDIT_LIMIT_MODAL = 'OPEN_UPDATE_CREDIT_LIMIT_MODAL';
export const OPEN_APPROVE_REJECT_BUYER_MODAL = 'OPEN_APPROVE_REJECT_BUYER_MODAL';
export const OPEN_DEACTIVATE_BUYER_MODAL = 'OPEN_DEACTIVATE_BUYER_MODAL';
export const OPEN_ADD_INVOICE_POPUP = 'OPEN_ADD_INVOICE_POPUP';
export const CLOSE_ADD_INVOICE_POPUP = 'CLOSE_ADD_INVOICE_POPUP';
export const OPEN_ADD_ORDER_POPUP = 'OPEN_ADD_ORDER_POPUP';
export const CLOSE_ADD_ORDER_POPUP = 'CLOSE_ADD_ORDER_POPUP';
export const OPEN_SETTLEMENT_DATE_POPUP = 'OPEN_SETTLEMENT_DATE_POPUP';
export const CLOSE_SETTLEMENT_DATE_POPUP = 'CLOSE_SETTLEMENT_DATE_POPUP';

// settings
export const FETCHED_COUNTRIES = 'FETCHED_COUNTRIES';
export const UPDATE_COUNTRY_LABEL_BASED_ON_LANG = 'UPDATE_COUNTRY_LABEL_BASED_ON_LANG';
export const SELLER_CONFIG = 'SELLER_CONFIG';
export const PAYMENT_COLLECTIONS = 'PAYMENT_COLLECTIONS';
export const SET_MERCHANT_FEES = 'SET_MERCHANT_FEES';
export const SET_EMAIL_CONFIG = 'SET_EMAIL_CONFIG';

// kyc
export const SET_INITIAL_KYC_FLOW_TYPE = 'SET_INITIAL_KYC_FLOW_TYPE';
export const CHANGE_CURRENT_ACTIVE_STEP = 'CHANGE_CURRENT_ACTIVE_STEP';
export const SET_KYC_COMPANY_DETAILS = 'SET_KYC_COMPANY_DETAILS';
export const SET_KYC_STATUS = 'SET_KYC_STATUS';
export const SET_KYC_DATA = 'SET_KYC_DATA';

// Bank KYC details
export const ADD_EDIT_BANK_ACCOUNT_DETAILS = 'ADD_EDIT_BANK_ACCOUNT_DETAILS';
export const DELETE_BANK_ACCOUNT_DETAILS = 'DELETE_BANK_ACCOUNT_DETAILS';

// LEGAL REP DETAILS
export const ADD_EDIT_LEGAL_REP_DETAILS = 'ADD_EDIT_LEGAL_REP_DETAILS';
export const DELETE_LEGAL_REP_DETAILS = 'DELETE_LEGAL_REP_DETAILS';

// SHAREHOLDER DETAILS
export const SAVE_SHAREHOLDER_DETAILS = 'SAVE_SHAREHOLDER_DETAILS';
export const DELETE_SHAREHOLDER_DETAILS = 'DELETE_SHAREHOLDER_DETAILS';
export const SAVE_SHAREHOLDER_FILE = 'SAVE_SHAREHOLDER_FILE';

// UBO DETAILS
export const SAVE_UBO_DETAILS = 'SAVE_UBO_DETAILS';
export const DELETE_UBO_DETAILS = 'DELETE_UBO_DETAILS';

// FINAL KYC DETAILS SUBMISSION
export const CHANGE_STATUS_TO_UNDER_REVIEW = 'CHANGE_STATUS_TO_UNDER_REVIEW';

// OWNERSHIP DETAILS
export const SAVE_OWNERSHIP_DETAILS = 'SAVE_OWNERSHIP_DETAILS';

// DOCUMENTS
export const SAVE_DOCUMENTS = 'SAVE_DOCUMENTS';
