// vendor
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

// utils
import { getData } from 'api';
import { errorMessage, formatter } from 'utils';
import { getCurrencySymbol } from 'utils/currencies';
import { getCreditDecisionColor } from '../buyer-detail-dashboard/company-details/helpers';

// components
import { FormattedMessage } from 'UI';
import { BuyersCompanyDetailsBlock } from '../buyer-detail-dashboard/company-details';
import BubbleLoader from 'UI/components/bubble-loader';

// constants
import { GET_SINGLE_BUYER } from 'constants/api-endpoints.constants';
import { CreditDecision } from 'store/types/buyers';
import { useAppSelector } from 'store';

type Step4Props = {
  buyerId: string;
};

const STOP_POLLING_TIMEOUT = 10000;
const FETCH_BUYER_DATA_INTERVAL = 2000;

const isBuyerReviewed = data =>
  data?.credit_qualification?.credit_decision !== CreditDecision.PENDING;

// Get buyer data
const useBuyerData = (buyerId, sellerId) => {
  const [state, setState] = useState<{
    data: any;
    isLoading: boolean;
    error: any;
  }>({
    data: null,
    isLoading: true,
    error: null,
  });
  let buyerReviewedState = false;

  const fetchBuyerData = async () => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    try {
      const response = await getData(`${GET_SINGLE_BUYER(sellerId)}/${buyerId}`);
      buyerReviewedState = isBuyerReviewed(response.data);
      setState(prevState => ({ ...prevState, data: response.data, error: null }));
    } catch (err) {
      setState(prevState => ({ ...prevState, data: {}, error: errorMessage(err) }));
    }
  };

  useEffect(() => {
    if (!buyerId) return;

    fetchBuyerData();

    const intervalId = setInterval(() => {
      if (buyerReviewedState) {
        setState(prevState => ({ ...prevState, isLoading: false }));
        clearInterval(intervalId);
      } else {
        fetchBuyerData();
      }
    }, FETCH_BUYER_DATA_INTERVAL);

    const timeoutId = setTimeout(() => {
      setState(prevState => ({ ...prevState, isLoading: false }));
      clearInterval(intervalId);
    }, STOP_POLLING_TIMEOUT);

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, [buyerId]);

  return state;
};

// Buyer verification loading Components
const BuyerVerification = () => (
  <>
    <Box my={4}>
      <Typography variant="h6" gutterBottom>
        <b>
          <FormattedMessage id="BUYER.WE_ARE_VERIFYING_BUYER" />
        </b>
      </Typography>
    </Box>
    <BubbleLoader />
  </>
);

// Display buyer details
const BuyerDetails = ({ data, buyerId }: { data: any; buyerId: any }) => {
  const { credit_qualification } = data || {
    credit_qualification: {
      credit_decision: '',
      eligible_payment_terms: [],
      credit_limit: '',
      available_credit_limit: '',
      currency: {
        code: '',
      },
    },
  };

  const symbol = credit_qualification?.currency?.code
    ? getCurrencySymbol(credit_qualification.currency.code)
    : '';

  return (
    <>
      <Box my={4}>
        <Typography variant="h6" gutterBottom>
          <b>
            <FormattedMessage id="BUYER.REGISTERED" />
          </b>
        </Typography>
      </Box>
      <Box my={2}>
        <FormattedMessage id="TRANSACTIONS.SPRINQUE_BUYER_ID" />: {buyerId}
      </Box>
      <BuyersCompanyDetailsBlock
        label=""
        buyerAddedFlow
        data={[
          {
            label: 'BUYER.CREDIT_DECISION',
            value: credit_qualification.credit_decision,
            color: getCreditDecisionColor(credit_qualification.credit_decision),
            alignValue: 'right',
            alignLabel: 'left',
          },
          {
            label: 'BUYER.PAYMENT_TERMS',
            value: credit_qualification.eligible_payment_terms?.join?.(', '),
            alignValue: 'right',
            alignLabel: 'left',
          },
          {
            label: 'BUYER.CREDIT_LIMIT',
            value: credit_qualification.credit_limit
              ? symbol + formatter(Number(credit_qualification.credit_limit))
              : '',
            alignValue: 'right',
            alignLabel: 'left',
          },
          {
            label: 'BUYER.AVAILABLE_CREDIT',
            value: credit_qualification.available_credit_limit
              ? symbol + formatter(Number(credit_qualification.available_credit_limit))
              : '',
            alignValue: 'right',
            alignLabel: 'left',
          },
        ]}
      />
    </>
  );
};

// Step 4 Component
export const Step4 = ({ buyerId }: Step4Props) => {
  const { sellerId } = useAppSelector(state => state.login);
  const { data, error, isLoading } = useBuyerData(buyerId, sellerId);

  if (isLoading) {
    return (
      <Box textAlign="center" mb={1.2}>
        <BuyerVerification />
      </Box>
    );
  } else if (error) {
    return (
      <Box textAlign="center" mb={1.2}>
        <Typography variant="h6" gutterBottom>
          <b>{error}</b>
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box textAlign="center" mb={1.2}>
        <BuyerDetails data={data} buyerId={buyerId} />
      </Box>
    );
  }
};
