import { useEffect, useState } from 'react';

const initZeda = async ({ email, name }) => {
  (function (k) {
    (function (w, d, s, z, e, o) {
      w[z] = {};
      w[z]._key = k;
      // @ts-ignore
      e = d.createElement(s);
      // @ts-ignore
      e.async = true;
      // @ts-ignore
      e.src = 'https://zeda-widget.s3.ap-south-1.amazonaws.com/dist/widget.bundle.js';
      /**
       * You need to identify your users before the widget can be shown.
       * You can call this method anywhere from your application after the installation script has loaded.
       */

      // @ts-ignore
      e.onload = function () {
        // @ts-ignore
        window.zeda.init({ email, name });
      };

      // @ts-ignore
      o = d.getElementsByTagName(s)[0];
      // @ts-ignore
      o.parentNode.insertBefore(e, o);
    })(window, document, 'script', 'zeda');
  })('H9A2EFV-D174PSQ-Q2RDPJ1-C0ADXKY');
};

// export const getReleaseNotes = async () => {
//   // @ts-ignore
//   const hasNewReleaseNote = await window.zeda.hasNewReleaseNote();
//   return hasNewReleaseNote;
// };

export const useZeda = (email, name) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const loadZeda = async ({ email, name }) => {
    await initZeda({ email, name });
    setIsLoaded(true);
  };

  useEffect(() => {
    if (email && name) {
      loadZeda({ email, name });
    }
  }, [email, name]);

  return [isLoaded];
};
