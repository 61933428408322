import React from 'react';
import { FormattedMessage } from 'UI';
// apis
import { getData, deleteData, postData, putData } from 'api';

// actions
import { startLoading, stopLoading, closeAddPersonPopup } from 'store/actions';

// api constants
import {
  GET_SINGLE_BUYER,
  DELETE_BUYER_CONTACT,
  ADD_BUSINESS_BUYER_CONTACT,
  UPDATE_BUSINESS_BUYER_CONTACT,
  BUYERS_SEARCH,
  ADD_BUYER,
} from 'constants/api-endpoints.constants';

// reducer types
import {
  UPDATE_BUYERS_PAGE_DETAILS,
  UPDATE_SINGLE_BUYER_BASIC_DETAILS,
  CHANGE_ADD_BUYER_FORM,
  RESET_BUYER_FORM,
  FETCH_BUYERS,
} from 'store/types';

// utils
import { snackbarConfig, errorMessage } from 'utils';

// constants
import { withExtendedPaymentTerms } from 'utils/with-extended-payment-terms';

export const updateBuyersPageDetails = pagination => ({
  type: UPDATE_BUYERS_PAGE_DETAILS,
  payload: pagination,
});

export const changeBuyerFormValue = updates => dispatch =>
  dispatch({ type: CHANGE_ADD_BUYER_FORM, payload: updates });

export const resetBuyerForm = () => ({ type: RESET_BUYER_FORM });

export const addBuyer = (data, enqueueSnackbar, cb, sellerId) => dispatch => {
  const {
    isBillingAddressSameAsBilling,
    business_name,
    city,
    phone,
    house_no = '',
    street = '',
    country,
    address_line1,
    address_line2,
    vat_id,
    merchant_buyer_id,
    zip_code,
    registrationNumber,
    website = '',
    first_name,
    last_name,
    email,
    language,
    accountsPayableEmail,
    source = 'MCC',
    metadata,
    shipping_address_line1 = '',
    shipping_address_line2 = '',
    shipping_city = '',
    shipping_postal_code = '',
    shipping_country = '',
    ...rest
  } = data;

  const creditBureauId = rest.credit_bureau_id ? { credit_bureau_id: rest.credit_bureau_id } : {};

  const address = {
    house_no,
    street,
    country: { code: country },
    zip_code: zip_code || rest.zipCode,
    address_line1,
    address_line2,
    city,
  };

  const mapValueForPostRequest: Record<string, any> = {
    business_name,
    source,
    address,
    website,
    metadata,
    email: accountsPayableEmail?.toLowerCase?.() || '',
    merchant_buyer_id,
    vat_id: vat_id || rest.vatId,
    language,
    buyer_users: [
      {
        first_name,
        last_name,
        email: email?.toLowerCase?.() || '',
        phone,
        language,
      },
    ],
    initial_shipping_address: address,
    ...creditBureauId,
  };

  if (!isBillingAddressSameAsBilling) {
    mapValueForPostRequest.initial_shipping_address = {
      address_line1: shipping_address_line1,
      address_line2: shipping_address_line2,
      city: shipping_city,
      zip_code: shipping_postal_code,
      country: {
        code: shipping_country,
      },
    };
  }

  if (registrationNumber || rest.registration_number) {
    mapValueForPostRequest.registration_number = registrationNumber || rest.registration_number;
  }

  dispatch(startLoading);
  return postData(`${ADD_BUYER}${sellerId}/`, mapValueForPostRequest)
    .then(res => {
      enqueueSnackbar(<FormattedMessage id="BUYER_ADDED_SUCCESSFULLY" />, snackbarConfig());
      cb(res.data);
    })
    .catch(err => {
      errorMessage(err, enqueueSnackbar);
    })
    .finally(() => {
      dispatch(stopLoading);
    });
};

export const fetchAllBuyers = () => dispatch => {
  dispatch({ type: FETCH_BUYERS });
};

export const updateSingleBuyer = data => ({
  type: UPDATE_SINGLE_BUYER_BASIC_DETAILS,
  payload: withExtendedPaymentTerms(data),
});

export const getBuyerBasicProfileDetails = (buyerId, sellerId) => dispatch => {
  getData(`${GET_SINGLE_BUYER(sellerId)}/${buyerId}`).then(res => {
    dispatch(updateSingleBuyer(res.data));
  });
};

export const deleteBuyersContact =
  (buyerId, buyerContactId, enqueueSnackbar, sellerId) => dispatch => {
    dispatch(startLoading);
    deleteData(`${DELETE_BUYER_CONTACT(sellerId)}/${buyerContactId}`, {
      business: buyerId,
    })
      .then(() => {
        enqueueSnackbar(<FormattedMessage id="BUYER_USER_DELETE_SUCCESS" />, snackbarConfig());
        getBuyerBasicProfileDetails(buyerId, sellerId)(dispatch);
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };

export const addBuyerContact = (values, enqueueSnackbar, sellerId) => {
  return dispatch => {
    dispatch(startLoading);
    return postData(`${ADD_BUSINESS_BUYER_CONTACT(sellerId)}`, values)
      .then(() => {
        getBuyerBasicProfileDetails(values.business, sellerId)(dispatch);
        dispatch(closeAddPersonPopup);
        enqueueSnackbar(`${values.first_name} add successfully`, snackbarConfig());
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };
};

export const updateBuyerContact = (values, enqueueSnackbar, sellerId) => {
  return dispatch => {
    dispatch(startLoading);
    putData(`${UPDATE_BUSINESS_BUYER_CONTACT(sellerId)}/${values._uuid || values.id}`, values)
      .then(() => {
        getBuyerBasicProfileDetails(values.business, sellerId)(dispatch);
        dispatch(closeAddPersonPopup);
        enqueueSnackbar(`${values.first_name} updated successfully`, snackbarConfig());
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };
};

// export const setBuyersTerms = (values, enqueueSnackbar, sellerId) => {
//   return dispatch => {
//     dispatch(startLoading);
//     return postData(SET_PAYMENT_TERMS, values)
//       .then(() => {
//         dispatch(getBuyerBasicProfileDetails(values.buyer_id, sellerId));
//         dispatch(closeAddPersonPopup);
//         enqueueSnackbar(`Terms are set successfully`, snackbarConfig());
//       })
//       .catch(err => {
//         errorMessage(err, enqueueSnackbar);
//       })
//       .finally(() => {
//         dispatch(stopLoading);
//       });
//   };
// };

export const getBuyersAutoCompleteList = obj => {
  return postData(BUYERS_SEARCH, obj);
};
