import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { MoreVert, FilterAltOutlined, Check } from '@mui/icons-material';
import {
  ClickAwayListener,
  Divider,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Button,
  Stack,
  ListItemText,
  Typography,
} from '@mui/material';

import { FilterMenuStyled } from './filter-menu.styled';
import { FILTER_OPTIONS } from 'constants/table-filter-options';
import { changeTableSetting } from 'store/actions/tables';
import { FormattedMessage } from 'UI';

type FilterMenuProps = {
  filterByField: string;
};

export const FilterMenu = ({ filterByField }: FilterMenuProps) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { filterBy } = useAppSelector(state => state.table);
  // @ts-ignore
  const [filterValues, setFilterValues] = useState(Object.values(filterBy[filterByField] || {}));
  const dispatch = useAppDispatch();

  const handleToggle = e => {
    e.stopPropagation();
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    // @ts-ignore
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // @ts-ignore
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const toggleFilterValue = (event, val) => {
    event.stopPropagation();
    if (filterValues.includes(val)) {
      setFilterValues(filterValues.filter(value => value !== val));
    } else {
      setFilterValues([...filterValues, val]);
    }
  };

  const onApplyFilters = event => {
    event.stopPropagation();
    handleClose(event);
    dispatch(changeTableSetting('filterBy', { ...filterBy, [filterByField]: [...filterValues] }));
  };
  const onCancel = event => {
    event.stopPropagation();
    handleClose(event);
    const nextFilterBy = { ...filterBy };
    delete nextFilterBy[filterByField];
    dispatch(changeTableSetting('filterBy', nextFilterBy));
  };

  useEffect(() => {
    // @ts-ignore
    setFilterValues(Object.values(filterBy[filterByField] || {}));
  }, [filterBy[filterByField]]);

  return (
    <FilterMenuStyled>
      <MoreVert
        ref={anchorRef}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        data-sdet={`more-icon-${filterByField}`}
        onClick={handleToggle}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper sx={{ width: 216 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem className="filter-title">
                    <Stack spacing={1} direction="row">
                      <FilterAltOutlined />{' '}
                      <b>
                        <FormattedMessage id="FILTER_BY" />
                      </b>
                    </Stack>
                  </MenuItem>
                  {FILTER_OPTIONS[filterByField].map(({ label, value }) => (
                    <MenuItem onClick={e => toggleFilterValue(e, value)} key={value}>
                      <ListItemText>{label}</ListItemText>
                      {filterValues.includes(value) && (
                        <Typography variant="body2" color="text.secondary">
                          <Check />
                        </Typography>
                      )}
                    </MenuItem>
                  ))}
                  <Divider />
                  <MenuItem className="filter-actions">
                    <Stack spacing={1} direction="row">
                      <Button color="secondary" variant="outlined" onClick={onCancel}>
                        <FormattedMessage id="RESET" />
                      </Button>
                      <Button color="primary" variant="contained" onClick={onApplyFilters}>
                        <FormattedMessage id="OK" />
                      </Button>
                    </Stack>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </FilterMenuStyled>
  );
};
