// vendor
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';

// components
import { CreateForm, MpButton, MpModal, MpLink, FormattedMessage } from 'UI';
import { WestRounded, MailOutlineRounded } from '@mui/icons-material';

import {
  forgotPasswordFormFields,
  valuesToValidateOnForgotPasswordPage,
} from 'constants/form-data';
import { GHOST } from 'constants/button-types';

// actions
import { sendForgotPasswordEmailLink, startLoading, stopLoading } from 'store/actions';
import { LOGGED_OUT, RESET } from 'store/types';

// utils
import { snackbarConfig, storageService, errorMessage } from 'utils';

// styles
import {
  ForgotPasswordWrapperStyled,
  ForgotPasswordDescriptionStyled,
  ForgotPasswordEmailSuccessTitleStyled,
} from './forgot-password.styled';

type Props = {
  withLogout?: boolean;
};

export const ForgotPassword = ({ withLogout }: Props) => {
  const [state, setState] = useState({
    openForgotPasswordModal: false,
    emailSuccessfullySent: false,
    email: '',
  });

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    userProfile: { email: userEmail },
  } = useAppSelector(state => state.user);

  const verifyEmail = value => {
    if (value) {
      dispatch(startLoading);
      sendForgotPasswordEmailLink({ email: value.toLowerCase() })
        .then(res => {
          enqueueSnackbar(
            res?.data?.message || <FormattedMessage id="FORGOT_PASSWORD_EMAIL_SENT_SUCCESSFULLY" />,
            snackbarConfig()
          );
          setState({ ...state, emailSuccessfullySent: true });
        })
        .catch(err => {
          errorMessage(err, enqueueSnackbar);
        })
        .finally(() => {
          dispatch(stopLoading);
        });
    }
  };

  const saveEmail = ({ email }) => {
    setState({ ...state, email });
    verifyEmail(email);
    if (withLogout) {
      setTimeout(() => {
        storageService.clearToken();
        dispatch({ type: RESET });
        dispatch({ type: LOGGED_OUT });
      }, 3000);
    }
  };

  const handleOpenForgotPasswordModal = e => {
    e.preventDefault();
    setState({ ...state, openForgotPasswordModal: true });
  };

  const closeForgotPasswordModal = () => {
    setState({ ...state, openForgotPasswordModal: false });
  };

  const { openForgotPasswordModal, emailSuccessfullySent, email } = state;
  return (
    <>
      <MpLink onClick={handleOpenForgotPasswordModal}>
        <FormattedMessage id="FORGOT_MY_PASS" />
      </MpLink>
      <MpModal
        title={emailSuccessfullySent ? '' : 'MODAL_TITLES.RESET_PASSWORD'}
        modalOpen={openForgotPasswordModal}
        handleClose={closeForgotPasswordModal}
        maxWidth="sm"
      >
        <ForgotPasswordWrapperStyled emailSuccessfullySent={emailSuccessfullySent}>
          {emailSuccessfullySent ? (
            <WestRounded
              style={{
                cursor: 'pointer',
                fontSize: '20px',
                position: 'absolute',
                top: '-28px',
                left: '10px',
              }}
              onClick={() =>
                setState({
                  ...state,
                  emailSuccessfullySent: false,
                  email: '',
                })
              }
            />
          ) : (
            ''
          )}
          {emailSuccessfullySent ? (
            <>
              <Box
                width={64}
                height={64}
                padding={2}
                sx={{ background: '#F5F8FF' }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                margin="auto"
                borderRadius="50%"
              >
                <MailOutlineRounded
                  name="email-green"
                  style={{ fill: 'url(#with-icon-gradient)', fontSize: '30px' }}
                />
              </Box>
              <ForgotPasswordEmailSuccessTitleStyled>
                <FormattedMessage id="FORGOT_PASSWORD_EMAIL_SUCCESS_TITLE" />
              </ForgotPasswordEmailSuccessTitleStyled>
              <ForgotPasswordDescriptionStyled width="250px" margin="auto">
                <FormattedMessage id="FORGOT_PASSWORD_SUCCESS_DERSRIPTION" />
              </ForgotPasswordDescriptionStyled>
              <Box margin="50px auto 40px">
                <MpButton onClick={() => verifyEmail(email)} type={GHOST}>
                  <FormattedMessage id="SEND_AGAIN" />
                </MpButton>
              </Box>
            </>
          ) : (
            <div>
              <ForgotPasswordDescriptionStyled>
                <FormattedMessage id="FORGOT_PASSWORD_DERSRIPTION" />
              </ForgotPasswordDescriptionStyled>
              <CreateForm
                formFields={forgotPasswordFormFields(userEmail || '')}
                valuestoValidate={valuesToValidateOnForgotPasswordPage}
                initialValue={{
                  email: userEmail || '',
                }}
                primaryButtonConfig={{
                  label: <FormattedMessage id="RESET_PASSWORD" />,
                  handleSubmit: saveEmail,
                  alignToLeft: true,
                }}
              />
            </div>
          )}
        </ForgotPasswordWrapperStyled>
      </MpModal>
    </>
  );
};
