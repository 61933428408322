// vendor
import React from 'react';

// components
import { Box } from '@mui/material';
import { FormattedMessage } from 'i18n/formatted-message';

//utils
import { formatAddress } from 'utils';

// constants
import { Address } from 'store/types/buyers';

// styles
import {
  AccountCompletionCardWrapper,
  AccountCompletionCardCompanyNameStyled,
  AccountCompletionCardCompanyIconStyled,
  AccountCompletionCardDescriptionStyled,
} from './account-completion-card.styled';

interface Props {
  companyName: string;
  icon: string;
  address: Address;
}

export const AccountCompletionCard = ({ icon, companyName, address }: Props) => {
  const {
    country: { name },
    ...rest
  } = address || {
    country: { name: '' },
  };
  const formattedAddress = formatAddress(Object.values({ ...rest, name } || {}), ', ');

  return (
    <AccountCompletionCardWrapper>
      <Box mb="10px">
        {icon && <AccountCompletionCardCompanyIconStyled src={icon} />}
        <AccountCompletionCardCompanyNameStyled>
          {companyName}
        </AccountCompletionCardCompanyNameStyled>
      </Box>

      <AccountCompletionCardDescriptionStyled isBold>
        <FormattedMessage id="BUYER.ADDRESS" />
      </AccountCompletionCardDescriptionStyled>
      <AccountCompletionCardDescriptionStyled>
        {formattedAddress}
      </AccountCompletionCardDescriptionStyled>
    </AccountCompletionCardWrapper>
  );
};
