import styled from '@emotion/styled';
import { BaseThemeType } from 'theme/baseTheme';
import spaces from 'constants/spacing';
import fontSizes from 'constants/font-sizes';
import { grey } from 'theme/colors';

export const PasswordResetWrapperStyled = styled.div<{
  theme?: BaseThemeType;
  successPasswordPage?: boolean;
}>`
  margin: ${spaces.space_xl} 0;
  margin: ${props => props.successPasswordPage && `${spaces.space_exa} 58px`};
  text-align: ${props => !props.successPasswordPage && 'center'};
`;

export const PasswordResetLabel = styled.div<{ theme?: BaseThemeType }>`
  font-size: ${props => props.theme.fontSizes.xSmall};
  line-height: ${spaces.space_m};
  color: ${grey[700]};
  margin-bottom: ${spaces.space_xxxs};
  text-align: left;
`;

export const PasswordResetEmailSuccessTitleStyled = styled.div<{ theme?: BaseThemeType }>`
  font-weight: bold;
  font-size: ${fontSizes.large};
  line-height: ${spaces.space_xl};
  text-align: center;
  color: ${grey[900]};
  margin: ${spaces.space_m} 0px;
`;

export const PasswordResetButtonWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  margin: ${spaces.space_m} auto ${spaces.space_xxxl};
`;
