import { format } from 'date-fns';
// constants
import {
  BUYER_COMPANY_SELECTED,
  CHANGE_INVOICE_STEP,
  SET_FILTERED_BUYERS,
  UPDATE_INVOICE_STEP_DATA,
  CLEANUP_INVOICE,
  RESET,
  SET_SETTLEMENT_DATE,
  SET_SETTLEMENT_FEE,
  SET_DATA_FOR_EDIT_SETTLEMENT_DATE,
  RESET_ADD_INVOICE_STATE,
  UPDATE_MERCHANT_ORDER_ID,
} from 'store/types';
import { Buyer } from './buyer';
import { Address } from '../types/buyers';
import { DATE_FORMAT_FOR_API } from 'constants/date';

type BuyerSelectOption = Buyer & { label: string; value: string };

type AddInvoiceReducerType = {
  step: number;
  invoiceId: string;
  selectedCompany?: BuyerSelectOption;
  filteredBuyers: Array<BuyerSelectOption>;
  step1Values: {
    merchantOrderId: string;
    merchantInvoiceId: string;
    invoiceAmount: string;
    merchantInvoiceDate: string;
    disableFields: {
      merchantOrderId: boolean;
      isCurrencyDropdownDisabled: boolean;
    };
    settlementDate: Date;
    netTerms: string;
    settlementFee: number;
    invoiceId: string;
    transaction_id?: string;
  };
  step2Values: Omit<Address, 'country'> & { country: string };
};
const initialState = {
  step: 0,
  invoiceId: '',
  filteredBuyers: [] as Array<BuyerSelectOption>,
  step1Values: {
    merchantOrderId: '',
    merchantInvoiceId: '',
    invoiceAmount: '',
    merchantInvoiceDate: format(new Date(), DATE_FORMAT_FOR_API),
    disableFields: {
      isCurrencyDropdownDisabled: false,
      merchantOrderId: false,
    },
    settlementDate: null as any,
    netTerms: '',
    settlementFee: null as any,
    invoiceId: '',
  },
  step2Values: {
    address_line1: '',
    zip_code: '',
    city: '',
    country: '',
  },
};

export const addInvoice = (state = initialState, action): AddInvoiceReducerType => {
  switch (action.type) {
    case CHANGE_INVOICE_STEP: {
      return { ...state, step: action.payload };
    }

    case BUYER_COMPANY_SELECTED: {
      return { ...state, selectedCompany: action.payload };
    }
    case UPDATE_INVOICE_STEP_DATA: {
      const { step, data } = action.payload;
      return { ...state, [`step${step}Values`]: data };
    }

    case UPDATE_MERCHANT_ORDER_ID: {
      const { merchantOrderId } = action.payload;
      return { ...state, step1Values: { ...state.step1Values, merchantOrderId } };
    }

    case SET_FILTERED_BUYERS: {
      return { ...state, filteredBuyers: action.payload };
    }
    case CLEANUP_INVOICE: {
      return initialState;
    }

    // To set the settlement date when user selects from add invoice settlements ppoup screen
    case SET_SETTLEMENT_DATE: {
      return {
        ...state,
        step1Values: {
          ...state.step1Values,
          settlementDate: action.payload.settlementDate,
        },
      };
    }

    // Set settlement fee after user edits the settlement date and clicks on save
    case SET_SETTLEMENT_FEE: {
      return {
        ...state,
        step1Values: {
          ...state.step1Values,
          settlementFee: action.payload.settlementFee,
        },
      };
    }

    case SET_DATA_FOR_EDIT_SETTLEMENT_DATE: {
      return {
        ...state,
        step1Values: {
          ...state.step1Values,
          ...action.payload,
        },
      };
    }

    case RESET_ADD_INVOICE_STATE:
    case RESET: {
      return { ...initialState };
    }
    default:
      return { ...state };
  }
};
