// vendor
import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { useSnackbar } from 'notistack';

// actions
import { stopLoading, startLoading } from 'store/actions';

// components
import { CreateForm, EmailVerification, MpIcon, AddCompanyForm, FormattedMessage } from 'UI';

// Formdata
import {
  initialValuesSignupPage,
  signupCompanyDetailsPage,
  valuesToValidateOnSignUpUserDetailsPage,
  businessInformationFieldOnAddSeller,
  valuesToValidateOnBusinessDetailsPage,
} from 'constants/form-data';

// constants
import { SIGNUP_ENDPOINT } from 'constants/api-endpoints.constants';

// utils
import { errorMessage } from 'utils';

import { transformCompanyDetails, transformUserDetails } from './adapter';
import { Box } from '@mui/material';
import { postData } from 'api';

interface Props {
  /** close signup modal */
  handleClose: () => void;
}

export const SignUp = ({ handleClose }: Props) => {
  const [state, setState] = useState({
    serverErrorMessage: '',
    tokenData: {},
    initialValues: initialValuesSignupPage,
    step: 0,
  });

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const countries = useAppSelector(({ settings: { countries } }) => countries);

  const setAccessTokenOnSubmit = values => {
    dispatch(startLoading);
    postData(SIGNUP_ENDPOINT, values)
      .then(res => {
        setState(previousState => ({
          ...previousState,
          step: previousState.step + 1,
          tokenData: res.data,
        }));
      })
      .catch(error => {
        errorMessage(error, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };

  const handleSubmit = values => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { trace_id, ...rest } = values;
    const userDetails = transformUserDetails(rest);
    setState({ ...state, initialValues: { ...rest } });
    setAccessTokenOnSubmit(userDetails);
  };

  const decrementStep = () => {
    setState(previousState => ({
      ...previousState,
      step: previousState.step - 1,
    }));
  };

  const saveCompanyDetails = values => {
    const { country, selectedCompany, ...rest } = values;
    const newValues = transformCompanyDetails(country, selectedCompany, rest);
    setState(previousState => ({
      ...previousState,
      step: previousState.step + 1,
      initialValues: { ...previousState.initialValues, ...newValues },
    }));
  };

  const addSellerManually = values => {
    setState(previousState => ({
      ...state,
      step: previousState.step + 1,
      initialValues: { ...previousState.initialValues, ...values, business: values.business_name },
    }));
  };

  const saveAddressDetails = values => {
    const newValues = {
      ...values,
      business: values.business_name,
      address: `${values.address_line1}, ${
        values.address_line2 ? `${values.address_line2}, ` : ''
      }${values.city}, ${values.zip_code}`,
      country: values.country,
    };

    setState(previousState => ({
      ...previousState,
      step: previousState.step + 1,
      initialValues: { ...previousState.initialValues, ...newValues },
    }));
  };

  const { step, initialValues, serverErrorMessage } = state;
  return (
    <>
      {step === 0 && (
        <AddCompanyForm
          addManually={addSellerManually}
          handleClose={handleClose}
          onSubmit={saveCompanyDetails}
          addManualButtonLabel="COMPANY.MERCHANT"
          countries={countries}
          // @ts-ignore
          initialValues={initialValues}
        />
      )}

      {step === 1 && (
        <CreateForm
          formFields={businessInformationFieldOnAddSeller}
          valuestoValidate={valuesToValidateOnBusinessDetailsPage}
          initialValue={initialValues}
          primaryButtonConfig={{
            label: (
              <>
                <FormattedMessage id="NEXT" />
                <MpIcon name="arrow-right" leftMargin="12px" />
              </>
            ),
            handleSubmit: saveAddressDetails,
            hidePrimaryButtonDefaultTickIcon: true,
          }}
          secondaryButtonConfig={{
            label: (
              <>
                <MpIcon name="arrow-left" rightMargin="12px" />
                <FormattedMessage id="BACK" />
              </>
            ),
            handleCancel: decrementStep,
          }}
          serverErrorMessage={serverErrorMessage}
        />
      )}

      {step === 2 && (
        <CreateForm
          formFields={signupCompanyDetailsPage}
          valuestoValidate={valuesToValidateOnSignUpUserDetailsPage}
          initialValue={initialValues}
          primaryButtonConfig={{
            label: <FormattedMessage id="CREATE_ACCOUNT" />,
            handleSubmit: handleSubmit,
          }}
          secondaryButtonConfig={{
            label: (
              <>
                <MpIcon name="arrow-left" rightMargin="12px" />
                <FormattedMessage id="BACK" />
              </>
            ),
            handleCancel: decrementStep,
          }}
          serverErrorMessage={serverErrorMessage}
        />
      )}
      {step === 3 && (
        <Box my={4}>
          <EmailVerification email={initialValues.email} />
        </Box>
      )}
    </>
  );
};
