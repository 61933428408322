export const convertObjectToQuery = obj => {
  let query = '';
  for (const key in obj) {
    const value = obj[key];
    if (!value) continue;
    if (Array.isArray(value)) {
      if (!value.length) continue;
      query += `&${key}=${value.join(',')}`;
    } else {
      query += `&${key}=${value}`;
    }
  }
  return query;
};
