// vendor
import React, { useEffect, useState, Suspense } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { Box, Grid } from '@mui/material';
import * as Sentry from '@sentry/react';
import { NotificationsNone } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import zipy from 'zipyai';

// components
import {
  MpModal,
  AddBuyerPopup,
  MpSwitch,
  MpDropdownMenu,
  FormattedMessage,
  MpTag,
  OrdersDashboard,
  Loader,
  Header,
  MarkAsPaidInvoiceTable,
  Payables,
} from 'UI';
import { getHeaderDropdownMenuList } from 'UI/components/header/user-dropdown';
import { NoBankDetailsWarningMessage } from 'UI/components/no-bank-details-warning-message';
import { Settlements } from 'UI/containers/dashboard/settlements';
import { SwitchAccountModal } from 'UI/containers/switch-account';
import { UiLangSwitcher } from './ui-lang-switcher';
import { FallbackComponent } from './fallback-component';
import { SelectMccAccount } from 'UI/containers/login/select-mcc-account';

// styles
import {
  MpDashboardWrapperStyled,
  MpDashboardContentWrapperStyled,
  MpDashboardHeaderDropdownTitleStyled,
  MpDashboardHeaderDropdownCSubtitleStyled,
  MpDashboardHeaderDropdownAvatarStyled,
  MpDashboardContentStyled,
} from './dashboard.styled';

// routes
import {
  InvoiceRoute,
  BuyersRoute,
  SettingsRoute,
  InvoiceDetailsRoute,
  BuyersDashboardRoute,
  OverviewRoute,
} from 'routes';

// constants
import {
  ORDERS,
  SETTINGS_USER_PROFILE,
  SETTLEMENTS,
  MARK_AS_PAID,
  PAYABLES,
} from 'constants/routes.constants';
import { ENV_SWITCH, DASHBOARD_HEADER_DROPDOWN_MENU_LIST } from 'constants/header-constants';
import TAG_TYPE from 'constants/tag-constants';
import { ORG, ORIGINAL_SELLER_ID, SELLER_ID } from 'constants/storage.constants';
import { REMOVER_SELLER_USER_ID, SET_SELLER_USERS } from 'store/types';
import { GET_LIST_MERCHANTS } from 'constants/api-endpoints.constants';

// actions
import * as actions from 'store/actions';
import { getData } from 'api';

// utils
import { errorMessage, storageService } from 'utils';
import { useZeda } from 'utils/zeda';

// assets
import Avatar from 'assets/images/dummy-avatar.png';

const showToggleSwitch = (envConfig, onSwitchChange) => {
  return (
    <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
      <MpDashboardHeaderDropdownCSubtitleStyled style={{ marginRight: '10px' }}>
        {envConfig.leftLabel}
      </MpDashboardHeaderDropdownCSubtitleStyled>
      <MpSwitch onChange={onSwitchChange} state={envConfig.isChecked} name="env-toggle" />
      <MpDashboardHeaderDropdownCSubtitleStyled style={{ marginLeft: '10px' }}>
        {envConfig.rightLabel}
      </MpDashboardHeaderDropdownCSubtitleStyled>
    </div>
  );
};

export const Dashboard = () => {
  // hooks
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // state variable
  const [state, setState] = useState({
    newBuyer: '',
    switchAccountProfilePopup: false,
  });

  // redux variables
  const {
    user: {
      userProfile: {
        first_name,
        last_name,
        business_name = '',
        profile_picture_url: avatar,
        email,
      },
      isStaff,
    },
    login: { isLoggedIn, isLoginAs, sellerId, userId, sellers },
    popup: { addBuyerPopup },
  } = useAppSelector(state => state);

  const name = first_name + ' ' + last_name;

  // custom hooks
  const [isZedaLoaded] = useZeda(email, name);
  const orgData = localStorage.getObject(ORG) || {};

  useEffect(() => {
    if (sellerId) {
      dispatch(actions.fetchUserProfile(sellerId, userId));
      dispatch(actions.fetchTeamsData(sellerId));
      dispatch(actions.fetchCompanyProfile(sellerId));
      dispatch(actions.fetchPaymentCollections(sellerId));
      // instead of fetching the buyer fee on fee page, need to get the values on initial load, because of add invoice flow
      dispatch(actions.getMerchantFees(sellerId, enqueueSnackbar));
    }
  }, [sellerId]);

  useEffect(() => {
    if (business_name && email) {
      zipy.identify(email, {
        firstName: first_name,
        lastName: last_name,
        email: email,
        customerName: business_name,
        isStaff,
      });
    }
  }, [business_name, email]);

  useEffect(() => {
    if (isLoggedIn && !sellers.length) {
      getData(GET_LIST_MERCHANTS)
        .then(({ data }) => {
          const organization = data[0];
          const sellers = organization?.sellers || [];
          localStorage.setObject(ORG, organization);
          if (sellers.length) {
            dispatch({ type: SET_SELLER_USERS, payload: organization.sellers });
          } else {
            // eslint-disable-next-line no-console
            console.error('Get list merchant should return data[0].sellers');
          }
        })
        .catch(error => {
          errorMessage(error, enqueueSnackbar);
        });
    }
  }, [isLoggedIn, sellers]);

  const handleCloseBuyerPopup = () => {
    dispatch(actions.closeBuyerPopup);
    dispatch(actions.resetBuyerForm());
  };

  const onDeactivateGodMode = () => {
    const originalSellerId = localStorage.getItem(ORIGINAL_SELLER_ID);
    localStorage.setItem(SELLER_ID, originalSellerId as string);
    dispatch({ type: REMOVER_SELLER_USER_ID });
    dispatch(actions.setIsLoginAs(false));
    // @ts-ignore
    window.location = '/';
  };

  const onHeaderDropdownMenuItemClick = (_, action) => {
    if (action === DASHBOARD_HEADER_DROPDOWN_MENU_LIST.LOGOUT) {
      storageService.clearToken();
      dispatch(actions.resetState);
      dispatch(actions.logoutAction);
    }

    if (action === DASHBOARD_HEADER_DROPDOWN_MENU_LIST.MY_ACCOUNT) {
      history.push(SETTINGS_USER_PROFILE);
    }

    if (action === DASHBOARD_HEADER_DROPDOWN_MENU_LIST.SWITCH_ACCOUNT) {
      setState(prevState => ({
        ...prevState,
        switchAccountProfilePopup: true,
      }));
    }

    if (action === DASHBOARD_HEADER_DROPDOWN_MENU_LIST.GOD_MODE) {
      dispatch(actions.openSwitchAccountModal(true));
    }

    if (action === DASHBOARD_HEADER_DROPDOWN_MENU_LIST.SWITCH_BACK) {
      onDeactivateGodMode();
    }
  };

  const onSwitchChange = () => {
    location.replace(process.env.REACT_APP_TOGGLE_URL + location.hash);
  };

  const handleCloseAccountProfilePopup = () => {
    setState(prevState => ({
      ...prevState,
      switchAccountProfilePopup: false,
    }));
  };

  return (
    <Suspense fallback={<Loader />}>
      <MpDashboardWrapperStyled className="container">
        <MpDashboardContentWrapperStyled>
          <Header />
          <MpDashboardContentStyled>
            <Grid container justifyContent="end" alignItems="center" mb={4}>
              {showToggleSwitch(
                ENV_SWITCH[process.env.REACT_APP_CURRENT_ENV as string],
                onSwitchChange
              )}

              {isZedaLoaded && (
                <NotificationsNone
                  onClick={() =>
                    // @ts-ignore
                    window.zeda.toggleWidget?.()
                  }
                  style={{
                    fill: '#6266A7',
                    marginRight: '20px',
                    cursor: 'pointer',
                  }}
                />
              )}

              <Box mr={3}>
                <UiLangSwitcher />
              </Box>
              {isLoginAs && (
                <Box mr={1}>
                  <MpTag type={TAG_TYPE.WARNING} label={<FormattedMessage id="LOGIN_AS.TITLE" />} />
                </Box>
              )}

              <MpDropdownMenu
                onMenuItemClick={onHeaderDropdownMenuItemClick}
                menuList={getHeaderDropdownMenuList(isStaff, isLoginAs, sellers.length > 1)}
                withDropdownIcon
                label={
                  <>
                    <MpDashboardHeaderDropdownAvatarStyled src={avatar || Avatar} alt="avatar" />
                    <div>
                      <MpDashboardHeaderDropdownTitleStyled>
                        {name}
                      </MpDashboardHeaderDropdownTitleStyled>
                      <MpDashboardHeaderDropdownCSubtitleStyled>
                        {/* @ts-ignore */}
                        {business_name}
                      </MpDashboardHeaderDropdownCSubtitleStyled>
                    </div>
                  </>
                }
                menuLeftAlign="25px"
              />
            </Grid>
            <NoBankDetailsWarningMessage />
            <Box>
              <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
                <Switch>
                  <Route exact {...OverviewRoute} />
                  <Route exact {...InvoiceDetailsRoute} />
                  {process.env.REACT_APP_CURRENT_ENV !== 'prod' &&
                    process.env.REACT_APP_CURRENT_ENV !== 'staging' &&
                    isStaff && (
                      <Route exact path={MARK_AS_PAID} render={() => <MarkAsPaidInvoiceTable />} />
                    )}
                  <Route exact {...InvoiceRoute} />
                  {orgData.buyers?.length > 0 && (
                    <Route exact path={PAYABLES} component={Payables} />
                  )}
                  <Route path={ORDERS} component={OrdersDashboard} />
                  <Route {...BuyersDashboardRoute} />
                  <Route exact {...BuyersRoute} />
                  <Route {...SettingsRoute} />
                  <Route exact path={SETTLEMENTS} component={Settlements} />
                </Switch>
              </Sentry.ErrorBoundary>
            </Box>
          </MpDashboardContentStyled>
        </MpDashboardContentWrapperStyled>
      </MpDashboardWrapperStyled>

      <MpModal modalOpen={addBuyerPopup} handleClose={handleCloseBuyerPopup}>
        <AddBuyerPopup handleClose={handleCloseBuyerPopup} />
      </MpModal>

      <SwitchAccountModal />

      <MpModal
        title=""
        modalOpen={state.switchAccountProfilePopup}
        handleClose={handleCloseAccountProfilePopup}
      >
        <Box mb={5}>
          <SelectMccAccount
            onSelect={() => {
              handleCloseAccountProfilePopup();
              window.location.reload();
            }}
          />
        </Box>
      </MpModal>
    </Suspense>
  );
};
