import { PaymentTerms } from 'constants/enum.constants';
import { SupportedLanguage } from 'constants/supported-languages';
import { Buyer } from 'store/reducers/buyer';

export enum BuyerStatus {
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
  'UNDER REVIEW' = 'UNDER REVIEW',
  'REJECTED' = 'REJECTED',
}

type UserRole = 'ADMIN' | 'MEMBER';

export type UserType = {
  email: string;
  first_name: string;
  last_name: string;
  id: number;
  _uuid?: string;
  language?: SupportedLanguage;
  user_designation?: string;
  role: UserRole;
  user_type: string;
  phone?: string;
  date_of_birth?: string;
  thumbnail?: string;
  email_otp_validated?: boolean;
};

export type NewBuyerForm = {
  step: number;
  business_name: string;
  merchant_buyer_id: string;
  registration_number: string;
  accountsPayableEmail: string;
  first_name: string;
  last_name: string;
  language: string;
  role: UserRole;
  phone: string;
  email: string;
  address_line1: string;
  address_line2: string;
  zip_code: string;
  city: string;
  country: string;
  isBillingAddressSameAsBilling: boolean;
  shipping_address_line1?: string;
  shipping_postal_code?: string;
  shipping_city?: string;
  shipping_country?: string;
};

export enum CreditDecision {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  MANUAL_REVIEW = 'MANUAL_REVIEW',
}

export type CreditQualification = {
  company_credit_score?: string;
  probability_of_default: string;
  bond_equivalent_rating: string;
  rec_credit_limit: string;
  est_insured_limit: string;
  insurable: string;
  available_credit_limit?: string;
  business_status?: keyof typeof BuyerStatus;
  created_at?: Date;
  credit_decision?: keyof typeof CreditDecision;
  credit_limit?: string;
  currency: { name: string; code: string; symbol: string };
  noble_credit_decision?: 'string';
  payment_terms?: keyof typeof PaymentTerms;
  eligible_payment_terms: Array<keyof typeof PaymentTerms>;
};

export type Address = {
  address_line1: string;
  address_line2?: string;
  city: string;
  country: { name: string; code: string; flag_icon_url?: string; isd_code?: string };
  zip_code: string;
};

export type UpdateRequest = {
  date: string;
  type: string;
  status: string;
  merchant_reason: string;
  sprinque_reason: string;
  old_value: string | Array<string> | number;
  new_value: string | Array<string> | number;
};

export type BuyerDetails = Buyer & {
  isVerified: boolean;
  name: string;
  status: keyof typeof BuyerStatus;
  credit_qualification: CreditQualification;
  payment_term: {
    created_at: string;
    credit_limit: string;
    currency_code: string;
    currency_name: string;
    currency_symbol: string;
    payment_terms: string;
  };
  companyDetails: Record<string, unknown>;
  people: Array<UserType>;
  address: Address;
  language: SupportedLanguage;
  id?: number;
  _uuid?: string;
  update_requests: Array<UpdateRequest>;
};

export type SearchedCompany = {
  address: Address;
  city: string;
  post_code: string;
  province: string;
  simple_value: string;
  street: string;
  business_name: string;
  country: string;
  registration_number: string;
  vat_id: string;
};
