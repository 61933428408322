// vendor
import React from 'react';

import { useHistory } from 'react-router-dom';

// components
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
// constants
import { grey } from 'theme/colors';
import { USEFULL_RESOURCE } from './constants';

// styles
import {
  UsefulResourcesCardStyled,
  UsefulResourcesCardStyledLabelIconStyled,
  UsefulResourcesCardStyledLabelStyled,
  UsefulResourcesCardDataStyled,
  UsefulResourcesCardDataRowStyled,
  UsefulResourcesCardDataRowLabelStyled,
} from './useful-resources-card.styled';

// components
import { FormattedMessage } from 'UI';

export const UsefulResourcesCard = () => {
  const history = useHistory();

  return (
    <UsefulResourcesCardStyled>
      <UsefulResourcesCardStyledLabelIconStyled>
        <ClassOutlinedIcon />
        <UsefulResourcesCardStyledLabelStyled>
          <FormattedMessage id="OVERVIEW.RESOURCE_TITLE" />
        </UsefulResourcesCardStyledLabelStyled>
      </UsefulResourcesCardStyledLabelIconStyled>
      <UsefulResourcesCardDataStyled>
        {USEFULL_RESOURCE.map((row, index) => {
          const isLast = USEFULL_RESOURCE.length - 1 === index;
          return (
            <UsefulResourcesCardDataRowStyled
              key={`useful-resources-card-row-${index}`}
              data-sdet={row.redirectionLink}
              isLast={isLast}
              onClick={() => history.push(row.redirectionLink)}
            >
              <UsefulResourcesCardDataRowLabelStyled>
                <FormattedMessage id={row.label} />
              </UsefulResourcesCardDataRowLabelStyled>
              <ArrowForwardIosIcon sx={{ fontSize: '18px', color: grey[700] }} />
            </UsefulResourcesCardDataRowStyled>
          );
        })}
      </UsefulResourcesCardDataStyled>
    </UsefulResourcesCardStyled>
  );
};
