import React from 'react';

import { FormattedMessage } from 'UI';
import { DASHBOARD_HEADER_DROPDOWN_MENU_LIST } from 'constants/header-constants';
import { MenuItem } from '../mp-dropdown-menu/mp-dropdown-menu';

export const getHeaderDropdownMenuList = (
  isStaff = false,
  isLoginAs = false,
  hasMultipleMerchants = false
) => {
  const list: Array<MenuItem> = [
    {
      label: <FormattedMessage id="MY_ACCOUNT" />,
      action: DASHBOARD_HEADER_DROPDOWN_MENU_LIST.MY_ACCOUNT,
      payload: { path: '/' },
    },
  ];

  if (hasMultipleMerchants) {
    list.splice(1, 0, {
      label: <FormattedMessage id="SWITCH_ACCOUNT" />,
      action: DASHBOARD_HEADER_DROPDOWN_MENU_LIST.SWITCH_ACCOUNT,
    });
  }

  if (isStaff && !isLoginAs) {
    list.splice(2, 0, {
      label: <FormattedMessage id="GOD_MODE" />,
      action: DASHBOARD_HEADER_DROPDOWN_MENU_LIST.GOD_MODE,
    });
  }

  if (isLoginAs) {
    list.splice(2, 0, {
      label: <FormattedMessage id="SWITCH_BACK" />,
      action: DASHBOARD_HEADER_DROPDOWN_MENU_LIST.SWITCH_BACK,
    });
  }

  list.push({
    label: <FormattedMessage id="LOGOUT" />,
    action: DASHBOARD_HEADER_DROPDOWN_MENU_LIST.LOGOUT,
    payload: { path: '/' },
  });

  return list;
};
