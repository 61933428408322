// vendor
import styled from '@emotion/styled';

// utils
import { hexToRGBA } from 'utils';
import { blue } from 'theme/colors';
import { BaseThemeType } from 'theme/baseTheme';

export const MpDropdownTextWrapperStyled = styled.div<{
  theme?: BaseThemeType;
  isMenuOpen?: boolean;
  isButton?: boolean;
}>`
  background: ${props => props.theme.colors.lightBackground};

  font-weight: 500;
  font-size: ${props => props.theme.fontSizes.xxSmall};
  line-height: ${props => props.theme.spaces.space_22};
  display: flex;
  align-items: center;
  color: ${props =>
    props.isMenuOpen ? props.theme.colors.primary200 : props.theme.colors.neutral700};
  ${props =>
    props.isButton &&
    `
      margin: 0 ${props.theme.spaces.space_xxxs};
      padding: ${props.theme.spaces.space_9} ${props.theme.spaces.space_xs};
      border: ${props.theme.spaces.space_micro} solid ${blue[400]};
      box-sizing: border-box;
      border-radius: ${props.theme.spaces.space_mini};
      height: 40px;
      :hover {
        background: ${props.theme.colors.lightBackground};
        box-shadow: none;
        border: ${props.theme.spaces.space_micro} solid ${blue[400]};
      }
    `}
`;

export const MpDropdownStyled = styled.div<{
  theme?: BaseThemeType;
  menuLeftAlign?: string;
}>`
  cursor: pointer;
  .MuiPaper-root {
    filter: drop-shadow(
      0 ${props => props.theme.spaces.space_xxxs} ${props => props.theme.spaces.space_xs}
        ${props => hexToRGBA(props.theme.colors.neutral900, 0.1)}
    );
    border-radius: ${props => props.theme.spaces.space_xxs};
    margin-top: ${props => props.theme.spaces.space_15};
  }

  .MuiList-root {
    padding: ${props => props.theme.spaces.space_xxxs};
  }

  .MuiMenuItem-root {
    padding: ${props => props.theme.spaces.space_9} ${props => props.theme.spaces.space_xxs};
    background: ${props => props.theme.colors.lightBackground};
    border-radius: ${props => props.theme.spaces.space_mini};
    margin: ${props => props.theme.spaces.space_mini} 0;

    color: ${props => props.theme.colors.neutral600};
    font-size: ${props => props.theme.fontSizes.xxSmall};
    font-weight: 400;
    line-height: ${props => props.theme.spaces.space_22};
  }

  .MuiMenuItem-root:hover {
    background: #f5f8ff;
  }

  #dropdown-menu {
    z-index: 1;
    left: ${props => (props.menuLeftAlign ? `${props.menuLeftAlign} !important` : 'initial')};
  }
`;
