//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useAppSelector, useAppDispatch } from 'store';
import { useLocation } from 'react-router-dom';

// actions
import { startLoading, stopLoading, getBuyerBasicProfileDetails } from 'store/actions';
import { changeStepAction, cleanupInvoice } from 'store/actions/invoices';
import { cleanupOrder, updateOrderStepData } from 'store/actions/orders';
import { fetchOrdersActionCreator, fetchPendingOrdersActionCreator } from 'store/actions/invoice';

// components
import { FormattedMessage, MpModal, CreateForm } from 'UI';
import { Step3Captured } from './step-3-captured';
import { NextButtonComponent, BackButtonComponent } from './helpers';

// constants
import {
  addressDetailsFormFields,
  valuesToValidateOnAddInvoiceShippingForm,
  addOrderFormField,
  valuesToValidateOnAddOrdersForm,
} from 'constants/form-data';
import { AUTH } from 'constants/api-endpoints.constants';
import { RESET_SINGLE_BUYER_BASIC_DETAILS, CLOSE_ADD_ORDER_POPUP } from 'store/types';
import { INVOICES, ORDERS } from 'constants/routes.constants';

// apis and helper functions
import { postData } from 'api';
import { errorMessage } from 'utils';

import { mapUiValuesToAuthPayload } from './adapters';
import { SearchCompany } from './search-company';
import { convertFingerprintDataToSprinquePayload } from 'b2b-sprinque-tools';

const titleMapper = step => {
  const titles = [
    'ADD_TRANSACTION_POPUP_STEP_INITIAL_TITLE',
    'ORDER_STEP_1_TITLE',
    'ADD_TRANSACTION_STEP_3_TITLE',
  ];
  return titles[step];
};

type Props = {
  buyerId: string;
};

export const AddOrder = ({ buyerId = '' }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const {
    user: { fingerprint },
    settings: {
      currencyList,
      sellerConfig: { is_pending_order_enabled },
    },
    buyer: {
      buyerDetails: {
        _uuid,
        address,
        credit_qualification: { payment_terms = '', eligible_payment_terms = [] },
      },
      issuedBy,
    },
    invoice: { step },
    order: { orderPopupValues, orderCurrency },
    popup: { addOrderPopup },
    login: { sellerId },
  } = useAppSelector(state => state);

  // locale state
  const [orderId, setOrderId] = useState('');

  const isOrdersInvoicePage = pathname.includes(INVOICES) || pathname.includes(ORDERS);

  const changeStepperState = nextStep => {
    dispatch(changeStepAction(nextStep));
  };

  useEffect(() => {
    // on buyers page start from invoice details
    if (addOrderPopup) {
      changeStepperState(buyerId ? 1 : 0);
      if (buyerId) {
        dispatch(getBuyerBasicProfileDetails(buyerId, sellerId));
      }
    }
  }, [buyerId, addOrderPopup]);

  const closeModal = () => {
    dispatch(cleanupInvoice());
    dispatch(cleanupOrder());
    dispatch({ type: CLOSE_ADD_ORDER_POPUP });
  };

  const authCall = (buyerUuid, authData) => {
    return postData(AUTH(sellerId, buyerUuid), authData);
  };

  const onOrderSubmit = values => {
    const metadata = fingerprint
      ? { merchant: convertFingerprintDataToSprinquePayload(fingerprint) }
      : {};
    const currencyLabel = orderCurrency || currencyList[0].label;
    const combinedValues = { ...orderPopupValues, ...values, issuedBy, metadata };

    const authData = mapUiValuesToAuthPayload(combinedValues, currencyLabel);
    dispatch(startLoading);

    authCall(_uuid, authData)
      .then(res => {
        if (!buyerId || isOrdersInvoicePage) {
          dispatch({ type: RESET_SINGLE_BUYER_BASIC_DETAILS });
        }
        changeStepperState(3);
        setOrderId(res.data?.order_id);
        dispatch(fetchOrdersActionCreator(isOrdersInvoicePage ? '' : buyerId));
        if (is_pending_order_enabled) {
          dispatch(fetchPendingOrdersActionCreator(isOrdersInvoicePage ? '' : buyerId));
        }
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };

  const submitInvoiceDetails = values => {
    changeStepperState(step + 1);
    dispatch(updateOrderStepData({ ...values }));
  };

  const submitShippingAddress = values => {
    onOrderSubmit(values, true);
  };

  return (
    <MpModal
      title={titleMapper(step)}
      modalOpen={addOrderPopup}
      handleClose={closeModal}
      maxWidth="sm"
    >
      {/* Search business */}
      {step === 0 && <SearchCompany changeStepperState={changeStepperState} />}

      {/* Invoice info */}
      {step === 1 && (
        <CreateForm
          secondaryButtonConfig={{
            label: buyerId ? <FormattedMessage id="CANCEL" /> : <BackButtonComponent />,
            handleCancel: () => (buyerId ? closeModal() : changeStepperState(step - 1)),
          }}
          primaryButtonConfig={{
            hidePrimaryButtonDefaultTickIcon: true,
            label: <NextButtonComponent />,
            handleSubmit: submitInvoiceDetails,
          }}
          formFields={addOrderFormField(eligible_payment_terms || [])}
          valuestoValidate={valuesToValidateOnAddOrdersForm}
          initialValue={{
            ...orderPopupValues,
            netTerms: orderPopupValues.netTerms || payment_terms,
          }}
        />
      )}
      {/* Shipping to another address */}
      {step === 2 && (
        <CreateForm
          secondaryButtonConfig={{
            label: <BackButtonComponent />,
            handleCancel: () => changeStepperState(step - 1),
          }}
          primaryButtonConfig={{
            hidePrimaryButtonDefaultTickIcon: true,
            label: <NextButtonComponent />,
            handleSubmit: submitShippingAddress,
          }}
          formFields={addressDetailsFormFields}
          valuestoValidate={valuesToValidateOnAddInvoiceShippingForm}
          initialValue={{
            ...address,
            country: address.country?.code,
          }}
        />
      )}
      {/* Success screen */}
      {step === 3 && <Step3Captured id={orderId} />}
    </MpModal>
  );
};
