// vendor
import styled from '@emotion/styled';

// utils
import { hexToRGBA } from 'utils';
import { BaseThemeType } from 'theme/baseTheme';

export const EmailVerifyWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  min-height: 352px;
  width: 560px;
  border-radius: ${props => props.theme.spaces.space_xxxs};
  padding: ${props => props.theme.spaces.space_exa};
  background: ${props => props.theme.colors.lightBackground};
  box-shadow: 0px ${props => props.theme.spaces.space_mini}
    ${props => props.theme.spaces.space_xxxs}
    ${props => hexToRGBA(props.theme.colors.neutral300, 0.5)};
  margin: auto;
  text-align: center;
`;

export const EmailVerifyTitleStyled = styled.div<{ theme?: BaseThemeType }>`
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.large};
  line-height: ${props => props.theme.spaces.space_xl};
  text-align: center;
  color: ${props => props.theme.colors.neutral900};
  margin: ${props => props.theme.spaces.space_m} 0px;
`;
