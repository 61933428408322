// constants
import {
  UPDATE_INVOICE_LIST,
  UPDATE_PAGE_DETAILS,
  RESET,
  UPDATE_ORDERS_LIST,
  UPDATE_PENDING_ORDERS_LIST,
} from 'store/types';
import { Invoice, OrdersItem } from '../types/ordersItem';

type InvoiceReducerType = {
  page: {
    count: number;
  };
  orders: Array<OrdersItem>;
  invoices: Array<Invoice>;
  pending_orders: Array<OrdersItem>;
};

const initialState: InvoiceReducerType = {
  page: {
    count: 0,
  },
  orders: [],
  pending_orders: [],
  invoices: [] as Array<Invoice>,
};

export const invoice = (state = initialState, action): InvoiceReducerType => {
  switch (action.type) {
    case UPDATE_PENDING_ORDERS_LIST: {
      return {
        ...state,
        pending_orders: action.payload,
      };
    }

    case UPDATE_ORDERS_LIST: {
      return {
        ...state,
        orders: action.payload,
      };
    }
    case UPDATE_INVOICE_LIST: {
      return {
        ...state,
        invoices: action.payload,
      };
    }

    case UPDATE_PAGE_DETAILS:
      return { ...state, page: action.payload };

    case RESET: {
      return initialState;
    }
    default:
      return { ...state };
  }
};
