import React from 'react';
import { Box, Typography } from '@mui/material';
import { ShoppingBagOutlined } from '@mui/icons-material';

// components
import { FormattedMessage, MpTable } from 'UI';

// constants
import { TABLE_USE_CASE_FEES } from 'UI/components/table/constants';

// store
import { useAppSelector } from 'store';

// styles
import { SettingsPaymentCollectionWrapperStyled } from 'UI/containers/dashboard/settings/payment-collection/styled';
import { FeesStyled, fontStyles } from '../styled';

// utils
import { useGetPrice } from './useGetPrice';

export const BuyerFee = () => {
  const {
    settings: { buyerFee },
  } = useAppSelector(state => state);
  const { tableHeader, tableData } = useGetPrice(buyerFee, 'SETTINGS.FEES.BUYER.TABLE_LABEL');

  return (
    <FeesStyled>
      <SettingsPaymentCollectionWrapperStyled>
        <Typography variant="h3" sx={fontStyles.title}>
          <ShoppingBagOutlined sx={{ fill: 'url(#with-icon-gradient)' }} />
          <FormattedMessage id="SETTINGS.FEES.BUYER.TITLE" />
        </Typography>

        <Typography variant="h6" sx={fontStyles.description} mt={3}>
          <FormattedMessage id="SETTINGS.FEES.BUYER.DESCRIPTION" />
        </Typography>

        <Box mt={3}>
          <MpTable data={tableData} headerData={tableHeader} useCase={TABLE_USE_CASE_FEES} />
        </Box>
      </SettingsPaymentCollectionWrapperStyled>
    </FeesStyled>
  );
};
