// vendor
import styled from '@emotion/styled';

// constants
import { ERROR, SUCCESS } from 'constants/message-types';
import { BaseThemeType } from 'theme/baseTheme';

const getColor = type => {
  switch (type) {
    case ERROR:
      return '#ff0033';

    case SUCCESS:
      return '#155724';

    default:
      return '#000';
  }
};

export const MpMessageStyled = styled.div<{
  theme?: BaseThemeType;
  type?: string;
  isPaddingTopRequired?: boolean;
}>`
  color: ${props => getColor(props.type)};

  font-size: ${props => props.theme.fontSizes.xxxSmall};
  padding-top: ${props => props.isPaddingTopRequired && props.theme.spaces.space_5};
`;
