// vendor
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useAppDispatch } from 'store';
import { useSnackbar } from 'notistack';

// assets
import Sucess from 'assets/icons/tick-circle-green.svg';

// constants
import { LOGIN } from 'constants/routes.constants';

// components
import { MpIcon, MpButton, FormattedMessage } from 'UI';

// styles
import { EmailVerifyWrapperStyled, EmailVerifyTitleStyled } from './email-verify.styled';

// actions
import { VerifyEmail, stopLoading, startLoading } from 'store/actions';
import { PRIMARY } from 'constants/button-types';

// utils
import { errorMessage } from 'utils';

type Props = {
  location: object;
  history: Record<string, unknown>;
};

function EmailVerify(props: Props) {
  // @ts-ignore
  const { token } = queryString.parse(location.search || props.location.search);
  const [message, setMessage] = useState('EMAIL_VERIFY_INPROGRESS_TITLE');
  const [isMailVerifiedSuccessfully, setMailVerificationStatus] = useState(false);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isInitialized = React.useRef(false);

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true;
      if (token) {
        dispatch(startLoading);

        VerifyEmail(token)
          .then(() => {
            setMailVerificationStatus(true);
            setMessage('EMAIL_VERIFY_SUCESS_TITLE');
          })
          .catch(err => {
            errorMessage(err, enqueueSnackbar);
          })
          .finally(() => {
            dispatch(stopLoading);
          });
      } else {
        setMessage('EMAIL_VERIFY_TOKEN_NOT_VALID');
      }
    }
  }, []);

  const continueToSprinque = () => {
    // @ts-ignore
    props.history.push(LOGIN);
  };

  return (
    <EmailVerifyWrapperStyled>
      {isMailVerifiedSuccessfully ? (
        <>
          <img src={Sucess} alt="success" />
          <EmailVerifyTitleStyled>
            <FormattedMessage id={message} />
          </EmailVerifyTitleStyled>
          <MpButton type={PRIMARY} onClick={continueToSprinque}>
            <FormattedMessage id="CONTINUE_TO_SPRINQUE" />
          </MpButton>
        </>
      ) : (
        <>
          {!token && <MpIcon name="error-failed" />}
          <EmailVerifyTitleStyled>
            <FormattedMessage id={message} />
          </EmailVerifyTitleStyled>
        </>
      )}
    </EmailVerifyWrapperStyled>
  );
}

export default EmailVerify;
