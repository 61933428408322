import React from 'react';
import { Download } from '@mui/icons-material';
import { format } from 'date-fns';

// utils
import { formatter } from 'utils';

import { DATE_FORMAT } from 'constants/date';

export const convertSettlementsToUI = data =>
  data.map(item => {
    const {
      settlement_amount = 0,
      total_fees = 0,
      total_refunds = 0,
      total_sales = 0,
      total_vat = 0,
      created_at,
      report_date,
      currency_symbol,
      running_balance,
      settlement_reports,
    } = item || {};

    const date = report_date || created_at;
    const putMinusToStart = value => value.replace(`${currency_symbol}-`, `-${currency_symbol}`);

    return {
      ...item,
      key: item.id,
      settlement_amount: putMinusToStart(currency_symbol + formatter(+settlement_amount)),
      total_fees: putMinusToStart(currency_symbol + formatter(+total_fees)),
      total_refunds: putMinusToStart(currency_symbol + formatter(+total_refunds)),
      total_sales: putMinusToStart(currency_symbol + formatter(+total_sales)),
      total_vat: putMinusToStart(currency_symbol + formatter(+total_vat)),
      date: format(new Date(date), DATE_FORMAT),
      file_name: settlement_reports[0].file_name,
      running_balance: putMinusToStart(currency_symbol + formatter(+running_balance)),
      download: (
        <Download
          sx={{ fill: 'url(#with-icon-gradient)' }}
          style={{ cursor: 'pointer' }}
          onClick={() => window.open(settlement_reports[0].report_file, 'blank')}
        />
      ),
    };
  });
