import { lazy } from 'react';

// components
export { MpButton } from './components/mp-button';
export { default as MpInput } from './components/mp-input/mp-input';
export { default as MpMessage } from './components/mp-message/mp-message';
export { MpLink } from './components/mp-link/mp-link';
export { Label } from './components/label';
export { MpTable } from './components/table';
export { MpModal } from './components/mp-modal';
export { default as MpDropdownSelect } from './components/mp-dropdown-select/mp-dropdown-select';
export { MpDropdownMenu } from './components/mp-dropdown-menu/mp-dropdown-menu';
export { default as MpTag } from './components/mp-tag/mp-tag';
export { default as MpDropdownSearchInput } from './components/mp-dropdown-search-input/mp-dropdown-search-input';
export { MpTimeline } from './components/mp-timeline/mp-timeline';
export { default as MpSwitch } from './components/mp-switch/mp-switch';
export { default as MpSidebarMenu } from './components/mp-sidebar-menu/mp-sidebar-menu';
export { default as MpIcon } from 'UI/components/icon/icon';
export { default as ContactPerson } from './components/contact-person/contact-person';
export { default as CreateForm } from './components/create-form/create-form';
export { default as MpDropzone } from './components/mp-dropzone/mp-dropzone';
export { InputCurrencyWithDropdown } from 'UI/components/input-currency-with-dropdown/input-currency-with-dropdown';
export { default as Loader, SectionLoader } from './components/loader/loader';
export { default as PasswordStrengthMeter } from './components/password-strength-meter/password-strength-meter';
export { EmailVerification } from './components/email-verification';
export { AccountCompletionCard } from './components/account-completion-card/account-completion-card';
export { UsefulResourcesCard } from './components/useful-resources-card/useful-resources-card';
export { PhoneNumberInput } from 'UI/components/phone-number-input';
export { default as RadioButtonsGroup } from 'UI/components/radio-button';
export { Dates } from 'UI/components/dates';
export { AddCompanyForm } from 'UI/components/add-company';
export { TextArea } from 'UI/components/text-area';
export { ElipsesTextWithTooltip } from 'UI/components/elipses-text-with-tooltip';
export { ErrorPage } from 'UI/components/error-page';
export { ScrollToTop } from 'UI/components/scroll-to-top';
export { Header } from 'UI/components/header';

// containers
export { Login } from './containers/login';
export { SignUp } from './containers/signup';
export { ForgotPassword } from './containers/forgot-password/forgot-password';
export { default as PasswordReset } from './containers/password-reset/password-reset';
export { default as EmailVerify } from './containers/email-verify/email-verify';
export { Refund } from './containers/dashboard/refund-popup';

// dashboard container
export { BuyersTable } from './containers/dashboard/buyers-table';
export { InvoiceTable } from './containers/dashboard/invoice-table';
export { Settings } from './containers/dashboard/settings';

// Overview containers
export const Overview = lazy(() => import('./containers/overview/overview'));

// invoice containers
export { InvoiceDetails } from './containers/dashboard/invoice-details';

// order
export { OrdersDashboard } from './containers/dashboard/orders-table';

// buyers containers
export { AddBuyerPopup } from './containers/dashboard/add-buyer';
export { BuyerDetailDashboard } from './containers/dashboard/buyer-detail-dashboard';
export { BuyerCompanyDetails } from './containers/dashboard/buyer-detail-dashboard/company-details';
export { BuyerContact } from './containers/dashboard/buyer-detail-dashboard/contacts';

// settings
export const SettingsUserProfile = lazy(
  () => import('./containers/dashboard/settings/settings-user-profile/settings-user-profile')
);

export const SettingsCompany = lazy(
  () => import('./containers/dashboard/settings/company/settings-company')
);

export const SettingsTeam = lazy(
  () => import('./containers/dashboard/settings/team/settings-team')
);

export const ApiKeys = lazy(() => import('./containers/dashboard/settings/api-keys-urls'));

export const KycProcess = lazy(() => import('./containers/dashboard/settings/kyc-process'));

export const MarkAsPaidInvoiceTable = lazy(() => import('./containers/dashboard/mark-as-paid'));

export const Payables = lazy(() => import('./containers/dashboard/payables-table'));
// i18n
export { FormattedMessage } from 'i18n/formatted-message';
