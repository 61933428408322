import React, { useEffect, useState } from 'react';
import { getData } from 'api';
import { BANK_DETAILS } from 'constants/api-endpoints.constants';

// components
import { MpIcon, FormattedMessage } from 'UI';

// style
import { NoBankDetailsWarningMessageWrapperStyled } from './index.styled';
import { useAppSelector } from '../../../store';

function NoBankDetailsWarningMessage() {
  const { sellerId } = useAppSelector(state => state.login);
  const [showWarningMessage, hideDisableWaringMessage] = useState(true);
  const [accountDetails, setAccountDetails] = useState(() => []);

  useEffect(() => {
    if (sellerId) {
      getData(`${BANK_DETAILS}${sellerId}`)
        .then(({ data }) => {
          setAccountDetails(data);
        })
        .catch(({ message }) => {
          // eslint-disable-next-line no-console
          console.error('Bank details fetching failed with ', message);
        });
    }
  }, [sellerId]);

  const hideWarningMessage = () => {
    hideDisableWaringMessage(false);
  };
  return !accountDetails.length && showWarningMessage ? (
    <NoBankDetailsWarningMessageWrapperStyled>
      <FormattedMessage id="EMPTY_IBAN_MESSAGE" />
      <MpIcon name="close" onClick={hideWarningMessage} />
    </NoBankDetailsWarningMessageWrapperStyled>
  ) : (
    <></>
  );
}

export { NoBankDetailsWarningMessage };
