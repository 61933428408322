import {
  BUYER_COMPANY_SELECTED,
  CHANGE_INVOICE_STEP,
  SET_FILTERED_BUYERS,
  UPDATE_INVOICE_STEP_DATA,
  CLEANUP_INVOICE,
} from '../types';

export const changeStepAction = step => ({ type: CHANGE_INVOICE_STEP, payload: step });

export const setFilteredBuyers = buyers => ({ type: SET_FILTERED_BUYERS, payload: buyers });

export const cleanupInvoice = () => ({ type: CLEANUP_INVOICE });

export const updateInvoiceStepData = (step, data) => ({
  type: UPDATE_INVOICE_STEP_DATA,
  payload: { step, data },
});

export const onBuyerCompanySelect = buyerOption => ({
  type: BUYER_COMPANY_SELECTED,
  payload: buyerOption,
});
