import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'i18n/formatted-message';
import { convertPricesToTableData, tableOption } from './adapters';
import { useAppSelector } from 'store';
export const useGetPrice = (
  prices: Record<string, string>,
  tableColumnLabel = 'SETTINGS.FEES.SELLER.TABLE_LABEL'
) => {
  const [tableHeader, setTableHeader] = useState<Array<tableOption>>([]);
  const [tableData, setTableData] = useState([]);
  const {
    user: {
      companyProfile: { eligible_payment_terms },
    },
  } = useAppSelector(state => state);

  useEffect(() => {
    // Convert the obj array to lowercase
    const lowercaseObj = eligible_payment_terms?.map(key => key.toLowerCase()) || [];

    // Filter the prices object using lowercase keys from obj array
    const filteredPrices = Object.fromEntries(
      Object.entries(prices).filter(([key]) => lowercaseObj.includes(key))
    );

    const netTermsOptions = Object.keys(filteredPrices).map(item => {
      return {
        label: `${item.slice(0, 3).toUpperCase()} ${item.slice(3)}`,
        value: item,
      };
    });

    setTableHeader([
      { value: 'type', label: <FormattedMessage id="SET_TERMS_PAYMENT_LABEL" /> },
      ...netTermsOptions,
    ]);
    setTableData(
      // @ts-ignore
      convertPricesToTableData(prices, tableColumnLabel)
    );
  }, [Object.keys(prices).length, eligible_payment_terms]);

  return { tableHeader, tableData };
};
