// vendor
import React, { useEffect, useRef } from 'react';
import { useAppDispatch } from 'store';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

// utils
import { ScrollToTop } from 'UI';

// constants
import { packageJson } from 'package.alias';

// components
import { Loader } from 'UI';
import { fetchCountry } from 'api';
import { AuthRoutes } from './pages/home/authRoutes';
import { grey } from './theme/colors';
import { SET_FINGERPRINT_INFO } from './store/types';

function App() {
  const dispatch = useAppDispatch();
  const { data } = useVisitorData({ extendedResult: true }, { immediate: true });

  const isFetched = useRef(false);
  useEffect(() => {
    if (!isFetched.current) {
      isFetched.current = true;
      const fi = document.getElementById('favicon') as HTMLElement;

      window.onfocus = function () {
        fi.setAttribute('href', 'favicon.ico');
      };

      window.onblur = function () {
        fi.setAttribute('href', 'favicon-white.ico');
      };
      dispatch(fetchCountry());
    }
  }, []);
  // Fingerprint
  useEffect(() => {
    if (data?.visitorId) {
      dispatch({ type: SET_FINGERPRINT_INFO, payload: data });
    }
  }, [data?.visitorId]);

  return (
    <>
      <Loader />
      <div className="app">
        <ScrollToTop />
        <AuthRoutes />
        <small
          style={{ position: 'fixed', left: '10px', bottom: '10px', zIndex: 3, color: grey[300] }}
        >
          v.{packageJson.version}
        </small>
        {/* code to add gradient to the icons */}
        <svg width={0} height={0}>
          <linearGradient id="with-icon-gradient" x1={1} y1={0} x2={1} y2={1}>
            <stop offset={0} stopColor="#01A1FB" />
            <stop offset={1} stopColor="#932BE5" />
          </linearGradient>
        </svg>
        {/* # code to add gradient to the icons */}

        {/* # code to add gradient to the graphs */}
        <svg width={0} height={0}>
          <linearGradient id="invoices-graph-gradient" x1={1} y1={0} x2={1} y2={1}>
            <stop stopColor="#FF4975" offset="0" />
            <stop stopColor="#932BE5" offset="100" />
          </linearGradient>
        </svg>
      </div>
    </>
  );
}
export default App;
