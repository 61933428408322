import * as TYPES from '../types';

export const resetTableSettings = () => ({ type: TYPES.RESET_TABLE_SETTINGS });

export const changeTableConfig = (key, value) => ({
  type: TYPES.CHANGE_TABLE_CONFIG,
  payload: { key, value },
});

export const changeTableSetting = (key, value) => ({
  type: TYPES.CHANGE_TABLE_SETTINGS,
  payload: { key, value },
});

export const setTableIsLoading = value => ({
  type: TYPES.SET_TABLE_IS_LOADING,
  payload: value,
});
