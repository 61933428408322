import styled from '@emotion/styled';
import { yellow } from 'theme/colors';
import { BaseThemeType } from 'theme/baseTheme';

export const NoBankDetailsWarningMessageWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  padding: ${props => props.theme.spaces.space_15};
  margin-bottom: ${props => props.theme.spaces.space_xl};
  border: ${props => props.theme.spaces.space_nano} solid ${yellow[100]};
  border-radius: ${props => props.theme.spaces.space_mini};
  background-color: ${yellow[50]};
  display: flex;
  justify-content: space-between;
`;
