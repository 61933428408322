import styled from '@emotion/styled';

import { grey, WHITE } from 'theme/colors';
import { BaseThemeType } from 'theme/baseTheme';

export const MpSidebarMenuLabelStyled = styled.div<{ theme?: BaseThemeType; isActive?: boolean }>`
  font-size: ${props => props.theme.fontSizes.xxSmall};
  font-weight: 400;
  line-height: ${props => props.theme.spaces.space_22};
  min-height: 42px;
  padding: 4px 14px;
  color: ${WHITE};
  background: ${props => (props.isActive ? '#242151' : 'none')};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const SubMenuLabelStyled = styled.div<{
  theme?: BaseThemeType;
  isActive?: boolean;
  withoutMargin?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: ${props => (props.withoutMargin ? '0' : '0 20px 20px 0')};
  padding: 8px 10px;
  transition: 0.3s;
  color: ${grey[700]};
  background: ${WHITE};
  border-bottom: ${props => (props.isActive ? '2px solid ' + grey[900] : 'none')};
  svg {
    font-size: 14px;
    margin-right: 6px;
  }
`;
