import React from 'react';

// vendor
import { chunk } from 'lodash';
import { format } from 'date-fns';

// constants
import { BuyersUserDesgination, SellersUserDesgination } from 'constants/enum.constants';
import { FALLBACK_CURRENCY_ITEM } from 'constants/currency';
import { SUPPORTED_LANGUAGES } from './supported-languages';
import { FormattedMessage } from 'i18n/formatted-message';
import { DATE_FORMAT_FOR_API } from './date';
import { getCurrencySymbol } from 'utils/currencies';
import { formatter } from 'utils';

export const formTypes = {
  dropdown: 'dropdown',
  phone: 'phone',
  radio: 'radio',
  file: 'file',
  date: 'date',
  freeText: 'freetext',
  textArea: 'textArea',
  amount: 'amount',
  country: 'country', // to display country dropdown
};

const email = {
  isRequired: false,
  label: 'LABELS.EMAIL_ADDRESS',
  placeholder: 'PLACEHOLDERS.ENTER_EMAIL',
  type: 'email',
  name: 'email',
};

const password = {
  isRequired: false,
  label: 'LABELS.PASSWORD',
  placeholder: 'PLACEHOLDERS.ENTER_PASSWORD',
  type: 'password',
  name: 'password',
  enablePasswordStrengthChecker: true,
};

const confirm_password = {
  isRequired: false,
  label: 'LABELS.CONFIRM_PASSWORD',
  placeholder: 'PLACEHOLDERS.CONFIRM_PASSWORD',
  type: 'password',
  name: 'confirm_password',
};

const first_name = {
  label: 'LABELS.FIRST_NAME',
  placeholder: 'PLACEHOLDERS.FIRST_NAME',
  type: 'text',
  name: 'first_name',
};

const last_name = {
  label: 'LABELS.LAST_NAME',
  placeholder: 'PLACEHOLDERS.LAST_NAME',
  type: 'text',
  name: 'last_name',
};

export const GENDER_OPTIONS = [
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
  {
    label: 'Prefer not to say',
    value: 'PREFER_NOT_TO_SAY',
  },
];

const user_designation = {
  label: 'LABELS.ROLE',
  placeholder: 'PLACEHOLDERS.USER_DESIGNATION',
  type: formTypes.dropdown,
  options: BuyersUserDesgination,
  name: 'user_designation',
};

const phoneNumber = {
  label: 'LABELS.PHONE_NUMBER',
  placeholder: 'PLACEHOLDERS.OPTIONAL',
  type: formTypes.phone,
  name: 'phone',
  country: 'nl',
  //regions: 'europe',
  countryCodeEditable: true,
};

const vatId = {
  label: 'LABELS.VAT_ID',
  placeholder: 'PLACEHOLDERS.VAT_ID',
  type: 'text',
  name: 'vatId',
};

const business = {
  label: 'LABELS.BUSINESS_NAME',
  placeholder: 'PLACEHOLDERS.BUSINESS_NAME',
  type: 'text',
  name: 'business',
};

const country = {
  type: formTypes.dropdown,
  name: 'country',
  label: 'LABELS.COUNTRY',
  isSearchable: true,
};

const preferred_language = {
  label: 'LABELS.PREFERRED_LANGUAGE',
  placeholder: 'PLACEHOLDERS.PREFERRED_LANGUAGE',
  type: formTypes.dropdown,
  name: 'language',
  options: SUPPORTED_LANGUAGES,
  defaultValue: { value: 'en', label: 'lang.en' },
};

const companyType = {
  label: 'LABELS.LEGAL_FORM',
  placeholder: 'PLACEHOLDERS.SELECT',
  type: formTypes.dropdown,
  name: 'legal_form',
  options: [
    { value: 'Sole Proprietorship', label: 'LABELS.LEGAL_FORM_TYPES.SOLE_PROPRIETORSHIP' },
    { value: 'Private Company', label: 'LABELS.LEGAL_FORM_TYPES.PRIVATE_COMPANY' },
    { value: 'Partnership', label: 'LABELS.LEGAL_FORM_TYPES.PARTNERSHIP' },
    { value: 'Association', label: 'LABELS.LEGAL_FORM_TYPES.ASSOCIATION' },
    { value: 'Corporation', label: 'LABELS.LEGAL_FORM_TYPES.CORPORATION' },
    { value: 'Foundation', label: 'LABELS.LEGAL_FORM_TYPES.FOUNDATION' },
  ],
};

const industryType = {
  label: 'LABELS.INDUSTRY_TYPE',
  placeholder: 'PLACEHOLDERS.SELECT',
  type: formTypes.dropdown,
  name: 'business_type',
  isSearchable: true,
  options: [
    { value: 'Accounting', label: 'LABELS.INDUSTRY_TYPES.ACCOUNTING' },
    {
      value: 'Administration & Office Support',
      label: 'LABELS.INDUSTRY_TYPES.ADMINISTRATION_OFFICE_SUPPORT',
    },
    { value: 'Advertising, Arts & Media', label: 'LABELS.INDUSTRY_TYPES.ADVERTISING_ARTS_MEDIA' },
    {
      value: 'Banking & Financial Services',
      label: 'LABELS.INDUSTRY_TYPES.BANKING_FINANCIAL_SERVICES',
    },
    {
      value: 'Community Services & Development',
      label: 'LABELS.INDUSTRY_TYPES.COMMUNITY_SERVICES_DEVELOPMENT',
    },
    { value: 'Consulting & Strategy', label: 'LABELS.INDUSTRY_TYPES.CONSULTING_STRATEGY' },
    { value: 'Design & Architechture', label: 'LABELS.INDUSTRY_TYPES.DESIGN_ARCHITECHTURE' },
    { value: 'Education & Training', label: 'LABELS.INDUSTRY_TYPES.EDUCATION_TRAINING' },
    { value: 'Engineering', label: 'LABELS.INDUSTRY_TYPES.ENGINEERING' },
    {
      value: 'Farming, Animals & Conservation',
      label: 'LABELS.INDUSTRY_TYPES.FARMING_ANIMALS_CONSERVATION',
    },
    { value: 'Government & Defence', label: 'LABELS.INDUSTRY_TYPES.GOVERNMENT_DEFENCE' },
    { value: 'Healthcare & Medical', label: 'LABELS.INDUSTRY_TYPES.HEALTHCARE_MEDICAL' },
    { value: 'Hospitality & Tourism', label: 'LABELS.INDUSTRY_TYPES.HOSPITALITY_TOURISM' },
    {
      value: 'Human Resources & Recruitment',
      label: 'LABELS.INDUSTRY_TYPES.HUMAN_RESOURCES_RECRUITMENT',
    },
    {
      value: 'Information & Communication Technology',
      label: 'LABELS.INDUSTRY_TYPES.INFORMATION_COMMUNICATION_TECHNOLOGY',
    },
    {
      value: 'Insurance & Superannuation',
      label: 'LABELS.INDUSTRY_TYPES.INSURANCE_SUPERANNUATION',
    },
    { value: 'Legal', label: 'LABELS.INDUSTRY_TYPES.LEGAL' },
    {
      value: 'Manufacturing, Transport & Logistics',
      label: 'LABELS.INDUSTRY_TYPES.MANUFACTURING_TRANSPORT_LOGISTICS',
    },
    {
      value: 'Marketing & Communications',
      label: 'LABELS.INDUSTRY_TYPES.MARKETING_COMMUNICATIONS',
    },
    { value: 'Mining, Resources & Energy', label: 'LABELS.INDUSTRY_TYPES.MINING_RESOURCES_ENERGY' },
    { value: 'Real Estate & Property', label: 'LABELS.INDUSTRY_TYPES.REAL_ESTATE_PROPERTY' },
    {
      value: 'Retail & Consumer Products',
      label: 'LABELS.INDUSTRY_TYPES.RETAIL_CONSUMER_PRODUCTS',
    },
    { value: 'Sales', label: 'LABELS.INDUSTRY_TYPES.SALES' },
    { value: 'Science & Technology', label: 'LABELS.INDUSTRY_TYPES.SCIENCE_TECHNOLOGY' },
    { value: 'Sport & Recreation', label: 'LABELS.INDUSTRY_TYPES.SPORT_RECREATION' },
    { value: 'Trades & Services', label: 'LABELS.INDUSTRY_TYPES.TRADES_SERVICES' },
  ],
};

export const registerationNumber = {
  label: 'LABELS.REGISTRATION_NUMBER',
  placeholder: 'PLACEHOLDERS.REGISTRATION_NUMBER',
  type: 'text',
  name: 'registration_number',
};

const merchantBuyerId = {
  label: 'LABELS.MERCHANT_BUYER_ID',
  placeholder: 'LABELS.MERCHANT_BUYER_ID',
  type: 'text',
  name: 'merchant_buyer_id',
};

const address_line1 = {
  label: 'LABELS.ADDRESS_LINE_1',
  placeholder: 'PLACEHOLDERS.ADDRESS_LINE_1',
  type: 'text',
  name: 'address_line1',
  width: '50',
};

const address_line2 = {
  label: 'LABELS.ADDRESS_LINE_2',
  placeholder: 'PLACEHOLDERS.ADDRESS_LINE_2',
  type: 'text',
  name: 'address_line2',
  width: '50',
};

const zipCode = {
  label: 'LABELS.ZIPCODE',
  placeholder: 'PLACEHOLDERS.ZIPCODE',
  type: 'text',
  name: 'zip_code',
  width: '25',
};

const IBAN = {
  label: 'LABELS.IBAN',
  placeholder: 'PLACEHOLDERS.IBAN',
  type: 'text',
  name: 'IBAN',
};
const city = { label: 'LABELS.CITY', placeholder: 'PLACEHOLDERS.CITY', type: 'text', name: 'city' };

/** Login Page Details */
export const valuesToValidateOnLoginPage = {
  email: email.name,
};

export const initialValuesLoginPage = {
  email: '',
  password: '',
};

export const loginPageFields = [
  { ...email, label: 'LABELS.COMPANY_EMAIL' },
  {
    ...password,
    showForgotPasswordLink: true,
  },
];

const initialValuesAddressFormFields = {
  address_line1: '',
  address_line2: '',
  zip_code: '',
  city: '',
  country: '',
};

export const initialValuesSignupPage = {
  preferred_language: 'en',
  first_name: '',
  business: '',
  last_name: '',
  email: '',
  password: '',
  confirm_password: '',
  ...initialValuesAddressFormFields,
};

const signupPageFields = [
  { ...first_name, isRequired: true },
  { ...last_name, isRequired: true },
  { ...preferred_language, isRequired: true },
];

const signupPageCredentialFields = [
  { ...email, isRequired: true },
  { ...password, isRequired: true },
  { ...confirm_password, isRequired: true },
];
/** Business Details Page */

const address = [
  {
    ...address_line1,
    placeholder: 'PLACEHOLDERS.REQUIRED',
    isRequired: true,
    width: '100%',
  },
  {},
  {
    ...address_line2,
    placeholder: 'PLACEHOLDERS.OPTIONAL',
    isRequired: false,
    width: '100%',
  },
  {},
  { ...city, placeholder: 'PLACEHOLDERS.REQUIRED', isRequired: true, width: '50' },
  { ...zipCode, placeholder: 'PLACEHOLDERS.REQUIRED', isRequired: true, width: '50' },
  { ...country, label: 'LABELS.COUNTRY_REGION', isRequired: true, width: '50' },
];

export const valuesToValidateOnBusinessDetailsPage = {
  business: business.name,
  country: country.name,
  address_line1: address_line1.name,
  zip_code: zipCode.name,
  city: city.name,
};

export const userProfileFormFields = [
  {
    fields: chunk(
      [
        { ...first_name, isRequired: true },
        { ...last_name, isRequired: true },
        preferred_language,
        { ...email, isRequired: true },
      ],
      2
    ),
  },
];

export const userProfilePasswordFields = [
  {
    fields: chunk(
      [
        {
          ...password,
          placeholder: 'PLACEHOLDERS.OPTIONAL',
          label: 'LABELS.OLD_PASSWORD',
          name: 'old_password',
          enablePasswordStrengthChecker: false,
          showForgotPasswordLink: true,
        },
        {
          ...password,
          placeholder: 'PLACEHOLDERS.OPTIONAL',
          label: 'LABELS.NEW_PASSWORD',
        },
      ],
      2
    ),
  },
];

export const signupCompanyDetailsPage = [
  { fields: chunk(signupPageFields, 2) },
  { label: 'LABELS.ACCOUNT_INFO', fields: chunk(signupPageCredentialFields, 1) },
];

export const forgotPasswordFormFields = defaultEmailValue => [
  { fields: [[{ ...email, labelBold: true, isDisabled: defaultEmailValue ? true : false }]] },
];

export const valuesToValidateOnForgotPasswordPage = {
  email: email.name,
};

export const ResetPasswordFormFields = [
  {
    fields: [
      [{ ...password, label: 'LABELS.NEW_PASSWORD' }],
      [{ ...confirm_password, label: 'LABELS.CONFIRM_NEW_PASSWORD' }],
    ],
  },
];
export const valuesToValidateOnResetPasswordPage = {
  password: password.name,
  confirm_password: confirm_password.name,
};
export const initialValuesOnResetPasswordPage = {
  password: '',
  confirm_password: '',
};

export const BusinessDetailFormFields = [
  {
    fields: chunk(
      [
        {
          ...business,
          label: 'LABELS.BUSINESS_LEGAL_NAME',
          placeholder: 'PLACEHOLDERS.ENTER_NAME',
          isRequired: true,
          isDisabled: true,
        },
        companyType,
        phoneNumber,
        email,
        { ...registerationNumber, isDisabled: true },
        { ...vatId, isDisabled: true },
        { ...industryType, width: '50' },
        { ...preferred_language, width: '50' },
      ],
      2
    ),
  },
];

export const BusinessDetailsAddressFormFields = [
  {
    fields: chunk(
      [
        {
          ...address_line1,
          placeholder: 'PLACEHOLDERS.REQUIRED',
          isRequired: true,
        },

        {
          ...address_line2,
          placeholder: 'PLACEHOLDERS.OPTIONAL',
          isRequired: false,
        },

        { ...city, placeholder: 'PLACEHOLDERS.REQUIRED', isRequired: true, width: '50' },
        { ...zipCode, placeholder: 'PLACEHOLDERS.REQUIRED', isRequired: true, width: '50' },
        {
          ...country,
          label: 'LABELS.COUNTRY_REGION',
          isRequired: true,
          width: '50',
          isDisabled: true,
        },
      ],
      2
    ),
  },
];

const accountsPayableEmail = {
  label: 'LABELS.BILLING_ACOUNTS_PAYABLE_EMAIL',
  placeholder: 'PLACEHOLDERS.OPTIONAL',
  type: 'text',
  name: 'accountsPayableEmail',
};

export const businessInformationFieldOnAddBuyer = (isRegNumberRequired: boolean) => [
  {
    label: 'ADD_BUYER_POPUP.BUSINESS_INFO_HELPER',
    labelStyleConfig: {
      marginTop: '24px',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
    },

    fields: chunk(
      [
        {
          ...business,
          name: 'business_name',
          placeholder: 'PLACEHOLDERS.REQUIRED',
          isRequired: true,
        },
        {},
        {
          ...registerationNumber,
          placeholder: isRegNumberRequired ? 'PLACEHOLDERS.REQUIRED' : 'PLACEHOLDERS.OPTIONAL',
          isRequired: isRegNumberRequired,
        },
        {},
        { ...merchantBuyerId, placeholder: 'PLACEHOLDERS.REQUIRED', isRequired: true },
        {},
        accountsPayableEmail,
      ],
      2
    ),
  },
];

export const businessInformationFieldOnAddSeller = [
  {
    fields: chunk(
      [
        {
          ...business,
          name: 'business_name',
          placeholder: 'PLACEHOLDERS.REQUIRED',
          isRequired: true,
        },
        {},
        { ...registerationNumber, placeholder: 'PLACEHOLDERS.OPTIONAL' },
        {},
      ],
      2
    ),
  },
  {
    label: 'LABELS.ADDRESS_INFORMATION',
    fields: chunk(address, 2),
  },
];

export const valuesToValidateOnAddBuyerBusinessInformationField = (
  isRegNumberRequired: boolean
) => ({
  merchant_buyer_id: merchantBuyerId.name,
  business_name: business.name,
  ...(isRegNumberRequired && { registration_number: registerationNumber.name }),
});

export const addressDetailsFormFields = [
  {
    fields: chunk(address, 2),
  },
];

export const addressDetailsOnAddBuyerPopupFormFields = [
  {
    label: 'ADD_BUYER_POPUP.ADDRESS_DETAILS_HELPER',
    labelStyleConfig: {
      marginTop: '24px',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      color: '#000339',
    },
    fields: chunk(
      [
        ...address.filter(obj => Object.keys(obj).length).map(obj => ({ ...obj, width: '100' })),
        { ...preferred_language, width: '100' },
        {
          type: formTypes.radio,
          row: true,
          radioButtonData: [
            {
              label: 'LABELS.DIFF_SHIP_ADDRESS_RADIO_BUTTON_BUYER_ONBOARDING',
            },
          ],
          name: 'isBillingAddressSameAsBilling',
        },
      ],
      1
    ),
  },
];

export const valuesToValidateOnAddressDetails = {
  address_line1: address_line1.name,
  zip_code: zipCode.name,
  city: city.name,
};

export const shippingAddressOnBuyerOnboardingFlow = [
  {
    fields: chunk(
      [
        {
          ...address_line1,
          name: 'shipping_address_line1',
          placeholder: 'PLACEHOLDERS.REQUIRED',
          isRequired: true,
          width: '100%',
        },
        {
          ...address_line2,
          name: 'shipping_address_line2',
          placeholder: 'PLACEHOLDERS.OPTIONAL',
          isRequired: false,
          width: '100%',
        },
        {
          ...city,
          name: 'shipping_city',
          placeholder: 'PLACEHOLDERS.REQUIRED',
          isRequired: true,
          width: '100',
        },
        {
          ...zipCode,
          name: 'shipping_postal_code',
          placeholder: 'PLACEHOLDERS.REQUIRED',
          isRequired: true,
          width: '100',
        },
        {
          ...country,
          name: 'shipping_country',
          label: 'LABELS.COUNTRY_REGION',
          isRequired: true,
          width: '100',
          type: formTypes.country,
        },
      ],
      1
    ),
  },
];

export const valuesToValidateOnShippingAddressDetails = {
  shipping_address_line1: 'shipping_address_line1',
  shipping_postal_code: 'shipping_postal_code',
  shipping_city: 'shipping_city',
  shipping_country: 'shipping_country',
};

const businessEmailAddress = {
  label: 'LABELS.WORK_EMAIL',
  placeholder: 'PLACEHOLDERS.WORK_EMAIL',
  type: 'text',
  name: 'email',
};

export const valuesToValidateOnUserProfilePage = {
  email: email.name,
  first_name: first_name.name,
  last_name: last_name.name,
  preferred_language: preferred_language.name,
  password: password.name,
  isPasswordOptional: true,
  old_password: 'old_password',
};

export const valuesToValidateOnSignUpUserDetailsPage = {
  first_name: first_name.name,
  last_name: last_name.name,
  preferred_language: preferred_language.name,
  email: email.name,
  password: password.name,
  confirm_password: confirm_password.name,
};

// settings team add member page

export const SettingsTeamAddMemberFormFields = [
  {
    fields: chunk(
      [
        { ...first_name, isRequired: true },
        { ...last_name, isRequired: true },
        { ...user_designation, options: SellersUserDesgination, isRequired: true },
        {},
        preferred_language,
        {},
        { ...businessEmailAddress, isRequired: true },
        {},
        { ...phoneNumber, placeholder: 'PLACEHOLDERS.OPTIONAL' },
      ],
      2
    ),
  },
];

export const initialValuesOnSettingsTeamAddMemberPage = {
  first_name: '',
  last_name: '',
  user_designation: '',
  phone: '',
  email: '',
};

export const valuesToValidateOnSettingsTeamAddMemberPage = {
  first_name: first_name.name,
  last_name: last_name.name,
  user_designation: user_designation.name,
  email: email.name,
};

// Buyers Dashboard Add Person Page

export const buyersDashboardAddPersonFormFields = [
  {
    fields: chunk(
      [
        { ...first_name, isRequired: true },
        { ...last_name, isRequired: true },
        preferred_language,
        {},
        { ...businessEmailAddress, isRequired: true },
        {},
        { ...phoneNumber, placeholder: 'PLACEHOLDERS.OPTIONAL' },
        {},
      ],
      2
    ),
  },
];

export const valuesToValidateOnBuyersDashboardAddPersonPopup = {
  first_name: first_name.name,
  last_name: last_name.name,
  email: email.name,
};

// Buyers Dashboard Add Buyer

export const addBuyerFormFields = selectedCountry => [
  {
    label: 'ADD_BUYER_POPUP.CONTACT_PAGE_HELPER',
    labelStyleConfig: {
      marginTop: '24px',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
    },
    fields: chunk(
      [
        { ...first_name, isRequired: true },
        {},
        { ...last_name, isRequired: true },
        {},
        { ...phoneNumber, country: selectedCountry || 'nl' },
        {},
        { ...businessEmailAddress, isRequired: true, placeholder: 'User email' },
      ],
      2
    ),
  },
];

export const valuesToValidateOnAddBuyerFormFields = {
  first_name: first_name.name,
  last_name: last_name.name,
  email: email.name,
};

/** Refund popup fields */
const merchantCreditNoteId = {
  isRequired: true,
  label: 'LABELS.MERCHANT_CREDIT_NOTE_ID',
  placeholder: 'PLACEHOLDERS.REQUIRED',
  type: 'text',
  name: 'merchantCreditNoteId',
};

const merchantCreditNoteFile = {
  label: 'LABELS.UPLOAD_CREDIT_NOTE',
  isRequired: true,
  placeholder: 'PLACEHOLDERS.UPLOAD_FILE',
  name: 'merchantCreditNoteFile',
  type: formTypes.file,
};

const creditNoteCreationDate = {
  label: 'LABELS.CREDIT_NOTE_DATE',
  isRequired: true,
  id: 'credit-note-date-picker',
  name: 'creditNoteCreationDate',
  disableFuture: true,
  disableToolbar: true,
  type: formTypes.date,
};

export const creditNoteAmount = {
  showCents: true,
  options: [FALLBACK_CURRENCY_ITEM],
  name: 'creditNoteAmount',
  currency_name: 'currencyName',
  currency_code: 'code',
  currency_symbol: 'symbol',
  label: 'LABELS.CREDIT_NOTE_AMOUNT',
  type: formTypes.amount,
  isRequired: true,
};

export const refundPopupFormFields = defaultCurrencyCode => [
  {
    fields: chunk(
      [
        merchantCreditNoteId,
        merchantCreditNoteFile,
        { ...creditNoteAmount, defaultCurrencyCode },
        creditNoteCreationDate,
      ],
      1
    ),
  },
];

const bankStatement = {
  label: 'LABELS.UPLOAD_BANK_STATEMENT',
  isRequired: true,
  placeholder: 'PLACEHOLDERS.UPLOAD_FILE',
  name: 'bankStatement',
  type: formTypes.file,
};

export const changeIBANPopupFormFields = [
  {
    fields: chunk(
      [{ ...IBAN, isRequired: true, placeholder: 'PLACEHOLDERS.REQUIRED' }, bankStatement],
      1
    ),
  },
];

export const initialValuesOnIBANPopupFormFields = {
  IBAN: '',
  bankStatement: '',
};

export const valuesToValidateOnChangeIBANPopupFormFields = {
  IBAN: IBAN.name,
  bankStatement: bankStatement.name,
};

export const valuesToValidateOnRefundPopupForm = {
  merchantCreditNoteId: merchantCreditNoteId.name,
  merchantCreditNoteFile: merchantCreditNoteFile.name,
  creditNoteAmount: creditNoteAmount.name,
  creditNoteCreationDate: creditNoteCreationDate.name,
};
export const initialValuesOnRefundPopupFormFields = {
  merchantCreditNoteId: '',
  merchantCreditNoteFile: '',
  creditNoteAmount: '',
  creditNoteCreationDate: format(new Date(), DATE_FORMAT_FOR_API),
};

/** Add invoice form fields */
const merchantOrderId = {
  isRequired: true,
  label: 'LABELS.MERCHANT_ORDER_ID',
  placeholder: 'PLACEHOLDERS.REQUIRED',
  type: 'text',
  name: 'merchantOrderId',
};

const merchantInvoiceId = {
  isRequired: true,
  label: 'LABELS.MERCHANT_INVOICE_ID',
  placeholder: 'PLACEHOLDERS.REQUIRED',
  type: 'text',
  name: 'merchantInvoiceId',
};

const merchantInvoiceFile = {
  label: 'LABELS.UPLOAD_INVOICE',
  isRequired: true,
  placeholder: 'PLACEHOLDERS.UPLOAD_INVOICE_FILE',
  name: 'merchantInvoiceFile',
  type: formTypes.file,
};

const merchantInvoiceDate = {
  label: 'LABELS.INVOICE_DATE',
  isRequired: true,
  id: 'credit-note-date-picker',
  name: 'merchantInvoiceDate',
  disableFuture: true,
  disableToolbar: true,
  type: formTypes.date,
};

const invoiceAmount = {
  showCents: true,
  type: formTypes.amount,
  options: [FALLBACK_CURRENCY_ITEM],
  name: 'invoiceAmount',
  currency_name: 'currencyName',
  currency_code: 'code',
  currency_symbol: 'symbol',
  label: 'LABELS.INVOICE_AMOUNT',
  isRequired: true,
};

const NET_TERMS_OPTIONS = [
  { value: 'NET7', label: 'LABELS.TERMS.NET7' },
  { value: 'NET15', label: 'LABELS.TERMS.NET15' },
  { value: 'NET30', label: 'LABELS.TERMS.NET30' },
  { value: 'NET45', label: 'LABELS.TERMS.NET45' },
  { value: 'NET60', label: 'LABELS.TERMS.NET60' },
  { value: 'NET90', label: 'LABELS.TERMS.NET90' },
  { value: 'PAY_IN_3', label: 'LABELS.TERMS.PAY_IN_3' },
  { value: 'PAY_IN_6', label: 'LABELS.TERMS.PAY_IN_6' },
  { value: 'PAY_IN_9', label: 'LABELS.TERMS.PAY_IN_9' },
  { value: 'PAY_IN_12', label: 'LABELS.TERMS.PAY_IN_12' },
];

export const CREDIT_DECISION_OPTIONS = [
  {
    value: 'APPROVED',
    label: 'status.APPROVED',
  },
  {
    value: 'REJECTED',
    label: 'status.REJECTED',
  },
];

const netTerms = {
  type: formTypes.dropdown,
  name: 'netTerms',
  label: 'LABELS.SELECT_NEW_PAYMENT_TERMS',
  isSearchable: true,
  isRequired: true,
  options: NET_TERMS_OPTIONS,
};

const PAYMENT_TERM_OPTIONS = [
  ...NET_TERMS_OPTIONS,
  { value: 'PAY_IN_ADVANCE', label: 'LABELS.TERMS.PAY_IN_ADVANCE' },
];

export const addOrderFormField = eligiblePaymentTerms => [
  {
    fields: chunk(
      [
        merchantOrderId,
        {
          ...invoiceAmount,
          name: 'orderAmount',
          label: 'LABELS.ORDER_AMOUNT',
          isRequired: true,
        },
        eligiblePaymentTerms?.length && {
          ...netTerms,
          label: 'BUYER.PAYMENT_TERMS',
          options: PAYMENT_TERM_OPTIONS.filter(({ value }) => eligiblePaymentTerms.includes(value)),
        },
      ],
      1
    ),
  },
];

export const addInvoiceFormField = (
  eligiblePaymentTerms,
  disableFields = { merchantOrderId: false, isCurrencyDropdownDisabled: false },
  defaultCurrencyCode = '',
  authTransactions,
  getOrderDetails?: (e) => void
) => [
  {
    fields: chunk(
      [
        {
          ...merchantOrderId,
          isDisabled: disableFields?.merchantOrderId || false,
          handleBlur: getOrderDetails,
        },

        merchantInvoiceId,
        merchantInvoiceDate,
        {
          ...invoiceAmount,
          defaultCurrencyCode,
          isCurrencyDropdownDisabled:
            disableFields?.isCurrencyDropdownDisabled || authTransactions?.length,
        },
        !authTransactions?.length &&
          eligiblePaymentTerms?.length && {
            ...netTerms,
            label: 'BUYER.PAYMENT_TERMS',
            options: PAYMENT_TERM_OPTIONS.filter(({ value }) =>
              eligiblePaymentTerms.includes(value)
            ),
          },
        authTransactions?.length && {
          type: formTypes.dropdown,
          name: 'transaction_id',
          label: 'LABELS.SELECT_TRANSACTION',
          isRequired: true,
          options: authTransactions.map(
            ({ _uuid, auth_amount, currency_code, created_at, payment_terms }) => ({
              label: `${payment_terms} - ${getCurrencySymbol(currency_code)}${formatter(
                +auth_amount
              )} - ${format(new Date(created_at), 'dd/MM/yyyy')}`,
              value: _uuid,
            })
          ),
          handleBlur: getOrderDetails,
        },
        merchantInvoiceFile,
        {
          label: 'LABELS.ADDITIONAL_PDF',
          isRequired: false,
          placeholder: 'PLACEHOLDERS.UPLOAD_INVOICE_FILE',
          name: 'additionalFileUrl',
          type: formTypes.file,
        },
      ],
      1
    ),
  },
];

export const valuesToValidateOnAddInvoiceForm = {
  merchantOrderId: merchantOrderId.name,
  merchantInvoiceId: merchantInvoiceId.name,
  invoiceAmount: invoiceAmount.name,
  merchantInvoiceFile: merchantInvoiceFile.name,
  merchantInvoiceDate: merchantInvoiceDate.name,
};

export const valuesToValidateOnAddOrdersForm = {
  merchantOrderId: merchantOrderId.name,
  orderAmount: 'orderAmount',
  netTerms: netTerms.name,
};

export const valuesToValidateOnAddInvoiceShippingForm = {
  address_line1: address_line1.name,
  zip_code: zipCode.name,
  city: city.name,
  country: country.name,
};

const reason = {
  isRequired: true,
  label: 'LABELS.REASON',
  placeholder: 'PLACEHOLDERS.REQUIRED',
  type: formTypes.textArea,
  name: 'reason',
};

export const updateCreditLimitFormFields = defaultCurrencyCode => [
  {
    fields: chunk(
      [
        {
          isCurrencyDropdownDisabled: true,
          ...invoiceAmount,
          label: 'LABELS.NEW_CREDIT_LIMIT',
          isRequired: true,
          placeholder: 'PLACEHOLDERS.REQUIRED',
          defaultCurrencyCode,
        },
        reason,
        {
          label: 'LABELS.SUPPORTING_DOCUMENT',
          tooltip: 'LABELS.SUPPORTING_DOCUMENT_TOOLTIP',
          isRequired: false,
          placeholder: 'PLACEHOLDERS.UPLOAD_INVOICE_FILE',
          name: 'merchantInvoiceFile',
          type: formTypes.file,
        },
        {
          isSquared: true,
          type: formTypes.radio,
          row: true,
          radioButtonData: [
            {
              label: 'LABELS.BUYER_CREDIT_LIMIT_CHECKBOX',
            },
          ],
          name: 'creditLimitTerms',
          alignTop: true,
        },
      ],
      1
    ),
  },
];
export const initialValuesUpdateCreditLimitFormFields = {
  reason: '',
  invoiceAmount: '',
  creditLimitTerms: false,
};

export const valuesToValidateOnUpdateCreditLimitFormFields = {
  reason: reason.name,
  invoiceAmount: invoiceAmount.name,
  creditLimitTerms: 'creditLimitTerms',
};

export const updatePaymentTernsFormFields = [
  {
    fields: chunk([{ ...netTerms, isMulti: true }, reason], 1),
  },
];

export const initialValuesUpdatePaymentTernsFormFields = ({
  netTerms = [],
}: {
  netTerms: Array<string>;
}) => {
  const netTermsArray = PAYMENT_TERM_OPTIONS.filter(({ value }) => {
    if (netTerms && netTerms.includes(value)) {
      return true;
    }
    return false;
  }).map(({ value, label }) => {
    return { value, label: <FormattedMessage id={label} /> };
  });
  return { reason: '', netTerms: netTermsArray || '' };
};

export const valuesToValidateOnUpdatePaymentTernsFormFields = {
  reason: reason.name,
  netTerms: netTerms.name,
};

// Credit decision callback urls

export const credit_decision_callback_url = {
  className: 'credit-decision-webhook',
  label: 'LABELS.CREDIT_DECISION_URL',
  placeholder: 'PLACEHOLDERS.CALLBACK_URL',
  type: 'text',
  name: 'credit_decision_callback_url',
};

export const invoice_status_callback_url = {
  className: 'invoice-status-callback-webhook',
  label: 'LABELS.INVOICE_STATUS_URL',
  placeholder: 'PLACEHOLDERS.CALLBACK_URL',
  type: 'text',
  name: 'invoice_status_callback_url',
};

export const pending_authorize_callback_url = {
  className: 'pending-authorize-callback-url',
  label: 'LABELS.PENDING_ORDER_URL',
  placeholder: 'PLACEHOLDERS.CALLBACK_URL',
  type: 'text',
  name: 'pending_authorize_callback_url',
};
