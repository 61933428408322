import React from 'react';

import { formatter, formatTimezone, renderTag } from 'utils';
import { OrdersItem } from 'store/types/ordersItem';
import { ElipsesTextWithTooltip } from 'UI/components/elipses-text-with-tooltip';
import { OrderTableActions, VOID_FOR } from './order-table-actions';
import { getCurrencySymbol } from 'utils/currencies';
import { AUTHORIZED } from 'constants/invoice.constants';

export const adaptOrdersToUI = (orders: Array<OrdersItem>, sellerStatus: string) => {
  return orders.map(order => {
    const {
      buyer_id,
      buyer_name,
      date,
      id,
      merchant_order_id,
      net_order_amount,
      order_id,
      status,
      invoices,
      currency_code = 'EUR',
      currency_symbol,
      transactions,
    } = order || {};

    const isInactive = status !== AUTHORIZED;
    const isCaptured = invoices.length;

    const authTransactions = transactions.filter(transaction => transaction.status === AUTHORIZED);

    return {
      id,
      order_id,
      merchant_order_id,
      inactive: isInactive,
      buyerName: (
        <ElipsesTextWithTooltip title={buyer_name} maxWidth="150px">
          {buyer_name}
        </ElipsesTextWithTooltip>
      ),
      order_amount: (
        <span
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {currency_symbol + formatter(+net_order_amount)}
        </span>
      ),
      created_at: formatTimezone(date),
      order_status: <span data-sdet={`${merchant_order_id}-status`}>{renderTag(status)}</span>,
      actions: (
        <OrderTableActions
          displayVoid={sellerStatus === 'ACTIVE' && (isInactive || isCaptured ? false : true)}
          displayAddInvoice={sellerStatus === 'ACTIVE' && status === AUTHORIZED}
          orderDetails={{
            orderId: order_id,
            merchantOrderId: merchant_order_id,
            buyerId: buyer_id,
            orderCurrency: currency_code,
            authTransactions,
          }}
        />
      ),
    };
  });
};

export const adaptPendingOrdersToUI = (orders: Array<OrdersItem>) => {
  return orders.map(order => {
    const {
      id,
      order_id,
      buyer_name,
      payment_terms,
      merchant_order_id,
      date,
      status,
      currency_code,
      auth_amount,
    } = order || {};

    return {
      id,
      order_id,
      merchant_order_id,
      buyerName: (
        <ElipsesTextWithTooltip title={buyer_name} maxWidth="150px">
          {buyer_name}
        </ElipsesTextWithTooltip>
      ),
      payment_terms,
      auth_amount: (
        <span
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {getCurrencySymbol(currency_code) + formatter(+auth_amount)}
        </span>
      ),
      date: formatTimezone(date),
      status: <span data-sdet={`pending-${merchant_order_id}-status`}>{renderTag(status)}</span>,
      actions: (
        <OrderTableActions
          displayVoid={status === 'PENDING' ? true : false}
          displayAddInvoice={false}
          orderDetails={{
            orderId: order_id,
            voidFor: VOID_FOR.PENDING_ORDER,
          }}
        />
      ),
    };
  });
};
