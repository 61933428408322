// vendor
import React from 'react';
import { TimelineContent } from '@mui/lab';
import { format, parseISO } from 'date-fns';
import { Typography } from '@mui/material';

// constants
import { UpdateRequest } from 'store/types/buyers';

// utils
import { renderTag } from 'utils';

// components
import { FormattedMessage, useTranslation } from 'i18n/formatted-message';
import { DATE_FORMAT } from 'constants/date';

const getIdBasedOnStatus = status => {
  if (status === 'APPROVED') {
    return 'BUYER.UPDATE_REQUEST.SPRINQUE_APPROVED_REASON';
  } else if (status === 'REJECTED') {
    return 'BUYER.UPDATE_REQUEST.SPRINQUE_REJECTED_REASON';
  } else {
    return status;
  }
};

type Props = UpdateRequest & {
  currencySymbol: string;
};

export const CustomTimelineContent = ({
  date,
  type,
  status,
  merchant_reason,
  sprinque_reason,
  old_value,
  new_value,
  currencySymbol,
}: Props) => {
  const LABELS = useTranslation('LABELS.TERMS', { returnObjects: true });
  const oldValue = Array.isArray(old_value)
    ? old_value.map(res => LABELS[res] || res).join(', ')
    : `${(type === 'credit_limit' ? currencySymbol : '') + old_value}`;
  const newValue = Array.isArray(new_value)
    ? new_value.map(res => LABELS[res] || res).join(', ')
    : `${(type === 'credit_limit' ? currencySymbol : '') + new_value}`;

  return (
    <div>
      <TimelineContent display="flex" gap={2}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '24px',
          }}
        >
          {date ? format(parseISO(date), DATE_FORMAT) : ''}
        </Typography>
        |
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
          }}
        >
          <FormattedMessage
            id={
              type === 'credit_limit'
                ? 'BUYER.UPDATE_REQUEST.UPDATE_CREDIT_LIMIT_TITLE'
                : 'BUYER.UPDATE_REQUEST.UPDATE_PAYMENT_TERMS_TITLE'
            }
            context={{
              oldValue,
              newValue,
            }}
          />
        </Typography>
        |<div>{renderTag(status)}</div>
      </TimelineContent>
      <TimelineContent>
        {merchant_reason ? (
          <Typography mb={1}>
            <FormattedMessage
              id="BUYER.UPDATE_REQUEST.MERCHANT_REASON"
              context={{
                reason: merchant_reason,
              }}
            />
          </Typography>
        ) : (
          ''
        )}
        {sprinque_reason ? (
          <Typography mt={1}>
            <FormattedMessage
              id={getIdBasedOnStatus(status)}
              context={{ reason: sprinque_reason }}
            />
          </Typography>
        ) : (
          ''
        )}
      </TimelineContent>
    </div>
  );
};
