import React, { useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { addDays, format, isWeekend } from 'date-fns';
import { Box, Typography } from '@mui/material';

// store
import { useAppDispatch, useAppSelector } from 'store';

// styles
import { CalendarStyles } from './styled';

// components
import { MpButton, Label, MpMessage } from 'UI';
import { BackButtonComponent, NextButtonComponent } from './helpers';
import { FormattedMessage } from 'i18n/formatted-message';
import { CalculateFee } from './calculate-settlement-fee';

// constants
import { GHOST } from 'constants/button-types';
import { ERROR } from 'constants/message-types';
import { DATE_FORMAT } from 'constants/date';
import { SET_SETTLEMENT_DATE, SET_SETTLEMENT_FEE } from 'store/types';
import { useCalculateFee } from './helpers';
import { isInvoiceDue } from 'utils';

const styles = {
  description: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
  },
};

interface SettlementCalendarProps {
  onClick: (e: any) => void;
  changeSettlementDateFlow?: boolean;
}

export const SettlementCalendar = (props: SettlementCalendarProps) => {
  const { onClick, changeSettlementDateFlow } = props;
  const {
    invoice: {
      step,
      step1Values: { settlementDate, netTerms, merchantInvoiceDate },
    },
    settings: {
      sellerFee: { settlement_frequency },
    },
  } = useAppSelector(state => state);
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const today = new Date().setUTCHours(0, 0, 0, 0);
  const defaultSettlementDay = addDays(today, Number(settlement_frequency || 0));

  const fee = useCalculateFee(settlementDate ? new Date(settlementDate) : defaultSettlementDay);
  const invoiceDue = isInvoiceDue(merchantInvoiceDate, netTerms);

  const slotProps = {
    layout: {
      sx: CalendarStyles,
    },
    day: {
      sx: { fontSize: '12px' },
    } as any,
    textField: {
      sx: {
        width: '100%',
      },
      InputProps: {
        placeholder: 'DD-MM-YYYY',
        disableUnderline: true,
        value: settlementDate
          ? format(settlementDate, DATE_FORMAT)
          : format(defaultSettlementDay, DATE_FORMAT),
        sx: {
          ...styles.description,
          'paddingRight': '12px',
          'width': '100%',
          '.MuiInputBase-input': {
            padding: '9px 0 9px 12px',
          },
        },
      },
    },
  };

  useEffect(() => {
    if (invoiceDue) {
      dispatch({
        type: SET_SETTLEMENT_DATE,
        payload: {
          settlementDate: null,
        },
      });
      return;
    } else if (!settlementDate) {
      dispatch({
        type: SET_SETTLEMENT_DATE,
        payload: {
          settlementDate: defaultSettlementDay,
        },
      });
    }
  }, [settlementDate, invoiceDue]);

  return (
    <Box>
      {invoiceDue ? (
        <Typography sx={styles.description} mt={5} mb={5}>
          <FormattedMessage id="DYNAMIC_SETTLEMENTS_POPUP.ALREADY_OVERDUE_INVOICE" />
        </Typography>
      ) : (
        <>
          <Typography sx={styles.description} mt={2} mb={3}>
            <FormattedMessage id="DYNAMIC_SETTLEMENTS_POPUP.DESCRIPTION" />
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Label>LABELS.SETTLEMENT_DATE</Label>
            <DatePicker
              className="dynamic-settlements-calendar"
              views={['month', 'day']}
              value={settlementDate}
              slots={{
                day: CalculateFee,
              }}
              slotProps={slotProps}
            />
            {errorMessage && (
              <MpMessage type={ERROR} message={errorMessage} isPaddingTopRequired={true} />
            )}
          </LocalizationProvider>
          <Typography mt={2} sx={{ ...styles.description, color: '#6266A7' }}>
            <FormattedMessage
              id="DYNAMIC_SETTLEMENTS_POPUP.HELPER_TEXT"
              context={{ fee: parseFloat(fee.toFixed(3)) }}
            />
          </Typography>
        </>
      )}

      {isWeekend(new Date()) && (
        <MpMessage
          type={ERROR}
          message="DYNAMIC_SETTLEMENTS_POPUP.UPDATE_SETTLEMENT_DATE.FAILURE.WEEKEND_DATE"
          isPaddingTopRequired={true}
        />
      )}
      <Box
        display="flex"
        justifyContent={!changeSettlementDateFlow ? 'space-between' : 'right'}
        mt={5}
        mb={4}
        textAlign="right"
      >
        {!changeSettlementDateFlow && (
          <MpButton onClick={() => onClick(step - 1)} type={GHOST}>
            <BackButtonComponent />
          </MpButton>
        )}

        <MpButton
          disabled={isWeekend(new Date())}
          onClick={() => {
            if (!invoiceDue && !settlementDate) {
              setErrorMessage('SETTINGS.FEES.DYNAMIC_PRICING.DATE_NOT_SET_ERROR');
            } else {
              dispatch({
                type: SET_SETTLEMENT_FEE,
                payload: {
                  settlementFee: parseFloat(fee.toFixed(3)),
                },
              });
              setErrorMessage('');
              onClick(step + 1);
            }
          }}
        >
          <NextButtonComponent
            id={changeSettlementDateFlow ? 'CONFIRM' : 'NEXT'}
            iconPresent={changeSettlementDateFlow ? false : true}
          />
        </MpButton>
      </Box>
    </Box>
  );
};
