import styled from '@emotion/styled';
import { grey } from 'theme/colors';
import { BaseThemeType } from 'theme/baseTheme';

export const RefundCreditNoteCreatedBoxWrapperStyled = styled.div`
  text-align: center;
  margin-bottom: 100px;
`;

export const RefundCreditNoteCreatedIconWrapperStyled = styled.div<{ theme?: BaseThemeType }>`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background: #cdfff9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${props => props.theme.spaces.space_mega} auto ${props => props.theme.spaces.space_m};
`;

export const RefundCreditNoteCreatedTitleStyled = styled.div<{ theme?: BaseThemeType }>`
  font-size: ${props => props.theme.fontSizes.large};
  font-weight: 700;
  line-height: ${props => props.theme.spaces.space_xl};
  text-align: center;
  color: ${grey[900]};
`;

export const RefundCreditNoteCreatedBuyerIdStyled = styled.div<{
  theme?: BaseThemeType;
  isDarkerFont?: boolean;
  isMarginPresent?: boolean;
}>`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: ${props => (props.isDarkerFont ? '700' : '400')};
  line-height: ${props => props.theme.spaces.space_xl};
  text-align: center;
  margin-top: ${props => props.isMarginPresent && '24px'};
  color: #282b5d;
`;
