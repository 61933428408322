import { postData } from 'api';
import { FILE_UPLOAD } from 'constants/api-endpoints.constants';

export const uploadFile = async (file: File, index: number | string = 0, name = '') => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await postData(FILE_UPLOAD, formData, {
      'Content-Type': 'multipart/form-data',
    });
    return { index, fileLocation: response.data.url, name, file_id: response.data.file_id };
  } catch (error) {
    return { index, fileLocation: '', name, error };
  }
};

function isJSON(content) {
  try {
    JSON.parse(content);
    return true;
  } catch (error) {
    return false;
  }
}

export const checkFileTypeIsBinary = (file, callback) => {
  if (file) {
    const reader = new FileReader();

    reader.onload = function (e) {
      // @ts-ignore
      const content = e.target.result;
      const isJSONFile = isJSON(content);
      callback(isJSONFile);
    };

    reader.onerror = function () {
      callback(false);
    };

    try {
      reader.readAsText(file);
    } catch {
      callback(true);
    }
  }
};
