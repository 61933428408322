import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { FormattedMessage } from 'i18n/formatted-message';
import { useAppSelector } from 'store';
import { SettingsPaymentCollectionWrapperStyled } from 'UI/containers/dashboard/settings/payment-collection/styled';
import { FeesStyled, fontStyles } from '../styled';
import { PublishedWithChangesOutlined } from '@mui/icons-material';

export const DynamicFee = () => {
  const {
    base_fee = '0.00',
    fee_per_day = '0.00',
    minimum_fee = '0.00',
  } = useAppSelector(({ settings }) => settings.dynamicFee);

  return (
    <FeesStyled>
      <SettingsPaymentCollectionWrapperStyled>
        <Box>
          <Typography variant="h3" sx={fontStyles.title}>
            <PublishedWithChangesOutlined sx={{ fill: 'url(#with-icon-gradient)' }} />
            <FormattedMessage id="SETTINGS.FEES.DYNAMIC_PRICING.TITLE" />
          </Typography>
          <Typography variant="h6" sx={fontStyles.description} mt={3}>
            <FormattedMessage id="SETTINGS.FEES.DYNAMIC_PRICING.DESCRIPTION" />
          </Typography>

          <Box width="22rem" mt={3}>
            <Box display="flex" padding="12px 0 11px">
              <Typography variant="h6" width="100%" sx={fontStyles.label}>
                <FormattedMessage id="SETTINGS.FEES.DYNAMIC_PRICING.BASE_FEE" />
              </Typography>
              <Typography variant="h6" width="100%" sx={fontStyles.value}>
                {base_fee}%
              </Typography>
            </Box>
            <Divider
              sx={{
                border: '1px solid #D9DBF2',
              }}
            />

            <Box display="flex" padding="12px 0 11px">
              <Typography variant="h6" width="100%" sx={fontStyles.label}>
                <FormattedMessage id="SETTINGS.FEES.DYNAMIC_PRICING.FEE_PER_DAY" />
              </Typography>
              <Typography variant="h6" width="100%" sx={fontStyles.value}>
                {fee_per_day}%
              </Typography>
            </Box>

            <Divider
              sx={{
                borderColor: '#D9DBF2',
              }}
            />
            <Box display="flex" padding="12px 0 11px">
              <Typography variant="h6" width="100%" sx={fontStyles.label}>
                <FormattedMessage id="SETTINGS.FEES.DYNAMIC_PRICING.MINIMUM_FEE" />
              </Typography>
              <Typography variant="h6" width="100%" sx={fontStyles.value}>
                {minimum_fee}%
              </Typography>
            </Box>
            <Divider
              sx={{
                borderColor: '#D9DBF2',
              }}
            />
          </Box>

          <Box mt={3}>
            <Typography variant="h6" sx={fontStyles.description}>
              <FormattedMessage id="SETTINGS.FEES.DYNAMIC_PRICING.HIGHLIGHT" />
            </Typography>
          </Box>
        </Box>
      </SettingsPaymentCollectionWrapperStyled>
    </FeesStyled>
  );
};
