// vendor
import React from 'react';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { Box, Typography } from '@mui/material';
import { getDate, addDays, isPast, isBefore, isAfter, isWeekend } from 'date-fns';

// constants
import { NET_TERM_VALUES } from 'constants/enum.constants';
import { useAppDispatch, useAppSelector } from 'store';
import { SET_SETTLEMENT_DATE } from 'store/types';

// utils
import { useCalculateFee } from './helpers';

export const CalculateFee = (props: PickersDayProps<Date>) => {
  const { day, outsideCurrentMonth, ...other } = props;
  const {
    invoice: {
      step1Values: { netTerms, merchantInvoiceDate },
    },
    settings: {
      sellerFee: { settlement_frequency },
    },
  } = useAppSelector(state => state);
  const dispatch = useAppDispatch();

  const netTermDays = netTerms
    ? netTerms.includes('PAY_IN')
      ? NET_TERM_VALUES[netTerms] - parseInt(settlement_frequency)
      : NET_TERM_VALUES[netTerms]
    : 0;

  const dueDate = addDays(new Date(merchantInvoiceDate), netTermDays);

  const feesToBeDisplayed = useCalculateFee(day);

  let isDisabled = false;

  if (isPast(day) || isAfter(day, dueDate) || isBefore(dueDate, day) || isWeekend(day)) {
    isDisabled = true;
  } else {
    isDisabled = false;
  }

  const isSelected = day > new Date() || day < dueDate;

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={false}
      day={day}
      disabled={isDisabled || outsideCurrentMonth}
      data-sdet={getDate(day)}
      onClick={() => {
        dispatch({
          type: SET_SETTLEMENT_DATE,
          payload: {
            settlementDate: day,
          },
        });
      }}
    >
      <Box textAlign="center">
        <Typography className="custom-date" sx={{ color: 'inherit' }}>
          {getDate(day)}
        </Typography>

        <Typography
          className="fee-percentage"
          sx={{
            textAlign: 'center',
            leadingTrim: 'both',
            textEdge: 'cap',
            fontSize: '8px',
            fontWeight: '400',
          }}
        >
          {!isDisabled && isSelected ? feesToBeDisplayed.toFixed(3) + '%' : '-'}
        </Typography>
      </Box>
    </PickersDay>
  );
};
