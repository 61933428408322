import styled from '@emotion/styled';
import { Accordion, AccordionSummary, Box } from '@mui/material';
import { BaseThemeType } from 'theme/baseTheme';
import spacing from 'constants/spacing';
import { grey, WHITE } from 'theme/colors';

export const HistoryTableTitleStyled = styled.div<{ theme?: BaseThemeType }>`
  font-family: ${props => props.theme.bodyFont};
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: 600;
  line-height: ${props => props.theme.spaces.space_xl};
  display: flex;
  gap: ${spacing.space_xs};
  align-items: center;
`;

export const HistoryTableDateStyled = styled.span`
  font-weight: 700;
`;

export const HistoryTableTimeStyled = styled.span<{ theme?: BaseThemeType }>`
  font-weight: 700;
  line-height: ${props => props.theme.spaces.space_s};
  font-size: ${props => props.theme.fontSizes.xxxSmall};
  margin-left: ${spacing.space_5};
`;

export const TimelineCustomAccordionSummary = styled(AccordionSummary)`
  padding: 0;
  min-height: 0;
  &.Mui-expanded {
    min-height: 0;
  }
  & .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
    margin: 0;
  }
`;

export const TimelineCustomAccordion = styled(Accordion)<{ isExpanded?: boolean }>`
  box-sizing: border-box;
  padding: ${spacing.space_m} ${spacing.space_m}
    ${props => (props.isExpanded ? 0 : spacing.space_m)};

  gap: ${spacing.space_m};
  background: ${WHITE};
  border: ${spacing.space_nano} solid ${grey[300]};
  border-radius: ${spacing.space_xxxs} !important;
  box-shadow: none;
  overflow-x: scroll;
`;

export const CustomStatusBox = styled(Box)`
  display: flex;
  align-items: center;
  span {
    font-size: 10px;
    line-height: ${spacing.space_14};
  }
`;
