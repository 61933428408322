import React from 'react';
import { Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

// icons
import { EmailOutlined } from '@mui/icons-material';

// components
import { MpButton } from 'UI';
import { FormattedMessage } from 'i18n/formatted-message';

// styles
import { fontStyles } from './styled';

// constants
import { SETTINGS_EMAILS } from 'constants/routes.constants';

export const NoEmailFound = ({
  invoiceNumber,
  handleClose,
}: {
  invoiceNumber: string;
  handleClose: () => void;
}) => {
  const history = useHistory();

  const gotoNotifications = () => {
    history.push(SETTINGS_EMAILS);
    handleClose();
  };
  return (
    <Box textAlign="center" mt={4}>
      <Box
        display="inline-flex"
        alignItems="center"
        component="span"
        p="18px"
        borderRadius="50%"
        sx={{
          background: '#F7F7FF',
        }}
      >
        <EmailOutlined />
      </Box>
      <Typography mt={4} sx={{ ...fontStyles.title, fontFamily: 'Space Grotesk' }}>
        <FormattedMessage
          id="SETTINGS.BILLING.EMAIL_POPUP.TITLE"
          context={{ invoice_number: invoiceNumber }}
        />
      </Typography>
      <Typography mt={2} mb={5} sx={fontStyles.description}>
        <FormattedMessage id="SETTINGS.BILLING.EMAIL_POPUP.NO_SETTLEMENT_EMAIL_FOUND" />
      </Typography>
      <Box mt={7}>
        <MpButton onClick={gotoNotifications}>
          <FormattedMessage id="GO_TO_NOTIFICATIONS" />
        </MpButton>
      </Box>
    </Box>
  );
};
