import React from 'react';
import * as Sentry from '@sentry/react';

// api
import { getData, postData, putData, deleteData, fetchSellerSettings } from 'api';

// actions
import { startLoading, stopLoading } from 'store/actions';

// constants
import {
  SELLERS_USER_PROFILE,
  SELLERS_COMPANY_PROFILE,
  UPDATE_SELLER_USERS,
  PAYMENT_COLLECTIONS,
  SET_MERCHANT_FEES,
} from 'store/types';
import {
  GET_SELLERS_USER_PROFILE_DETAILS,
  SELLER_USER,
  GET_SELLERS_COMPANY_DETAILS,
  BUSINESS_SELLER,
  PAYMENT_COLLECTION,
  GET_SELLERS_USER_PROFILE_DETAILS_FOR_GOD_MODE,
  SELLER_PRICING,
} from 'constants/api-endpoints.constants';

// utils
import { snackbarConfig, errorMessage } from 'utils';

// components
import { FormattedMessage } from 'i18n/formatted-message';

export const updateSellersCompanyProfile = { type: SELLERS_COMPANY_PROFILE };

export const fetchCompanyProfile = sellerId => {
  return dispatch => {
    dispatch(startLoading);
    getData(`${GET_SELLERS_COMPANY_DETAILS}${sellerId}`)
      .then(res => {
        dispatch({ ...updateSellersCompanyProfile, payload: res.data[0] });
        dispatch(fetchSellerSettings(sellerId));
        Sentry.configureScope(scope => {
          scope.setTag('merchant', res.data[0].business_name);
        });
        // @ts-ignore
        if (window.FS) {
          // @ts-ignore
          window.FS.setUserVars({
            merchant: res.data[0].business_name,
          });
        }
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };
};

export const fetchTeamsData = sellerId => {
  return dispatch => {
    dispatch(startLoading);
    getData(SELLER_USER(sellerId))
      .then(res => {
        dispatch({ type: UPDATE_SELLER_USERS, payload: res.data });
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };
};

export const fetchPaymentCollections = sellerId => {
  return dispatch => {
    dispatch(startLoading);
    getData(PAYMENT_COLLECTION(sellerId))
      .then(({ data }) => {
        dispatch({ type: PAYMENT_COLLECTIONS, payload: data });
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };
};

export const fetchUserProfile = (sellerId, userId = '') => {
  return dispatch => {
    dispatch(startLoading);
    getData(
      userId
        ? GET_SELLERS_USER_PROFILE_DETAILS_FOR_GOD_MODE(sellerId, userId)
        : GET_SELLERS_USER_PROFILE_DETAILS(sellerId)
    )
      .then(({ data }) => {
        dispatch({ type: SELLERS_USER_PROFILE, payload: data });
        Sentry.configureScope(scope => {
          scope.setTag('userEmail', data.email);
        });
        // @ts-ignore
        if (window.FS) {
          // @ts-ignore
          window.FS.setUserVars({
            userEmail: data.email,
            isStaff_bool: data.is_staff,
          });
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };
};

export const addContact = (values, enqueueSnackbar, closeTeamMemberModalPopup, sellerId) => {
  return dispatch => {
    dispatch(startLoading);
    return postData(SELLER_USER(sellerId), values)
      .then(() => {
        if (enqueueSnackbar) {
          enqueueSnackbar(
            <FormattedMessage id="SELLER_TEAM_MEMBER_ADD_SUCCESS" />,
            snackbarConfig()
          );
        }
        closeTeamMemberModalPopup();
        dispatch(fetchTeamsData(sellerId));
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };
};

export const updateContact = (values, enqueueSnackbar, closeTeamMemberModalPopup, sellerId) => {
  return dispatch => {
    dispatch(startLoading);
    const userId = values['_uuid'] || values.id;
    return putData(`${SELLER_USER(sellerId)}${userId}/`, values)
      .then(() => {
        if (enqueueSnackbar) {
          enqueueSnackbar(
            <FormattedMessage id="SELLER_TEAM_MEMBER_UPDATE_SUCCESS" />,
            snackbarConfig()
          );
        }
        closeTeamMemberModalPopup();
        dispatch(fetchTeamsData(sellerId));
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };
};

export const updateUserProfile = (values, snackbar, sellerId, userId = '') => {
  return dispatch => {
    dispatch(startLoading);
    return putData(
      userId
        ? GET_SELLERS_USER_PROFILE_DETAILS_FOR_GOD_MODE(sellerId, userId)
        : GET_SELLERS_USER_PROFILE_DETAILS(sellerId),
      values
    )
      .then(res => {
        dispatch({ type: SELLERS_USER_PROFILE, payload: res.data.data });
        if (snackbar) {
          snackbar(<FormattedMessage id="SELLER_PROFILE_UPDATE_SUCCESS" />, snackbarConfig());
        }
      })
      .catch(err => {
        errorMessage(err, snackbar);
      })
      .finally(dispatch(stopLoading));
  };
};

export const updateCompanyProfile = (values, enqueueSnackbar, sellerId) => {
  return dispatch => {
    dispatch(startLoading);
    return putData(`${GET_SELLERS_COMPANY_DETAILS}${sellerId}`, values)
      .then(() => {
        if (enqueueSnackbar) {
          enqueueSnackbar(
            <FormattedMessage id="SELLER_COMPANY_PROFILE_UPDATE_SUCCESS" />,
            snackbarConfig()
          );
        }
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(fetchCompanyProfile(sellerId));
      });
  };
};

export const deleteSellersContact = (dispatch, userId, enqueueSnackbar, sellerId) => {
  return deleteData(`${SELLER_USER(sellerId)}${userId}/`)
    .then(() => {
      if (enqueueSnackbar) {
        enqueueSnackbar(<FormattedMessage id="SELLER_USER_DELETE_SUCCESS" />, snackbarConfig());
      }
      dispatch(fetchTeamsData(sellerId));
    })
    .catch(err => {
      errorMessage(err, enqueueSnackbar);
    });
};

export const userProfilePicUpload = (file, enqueueSnackbar, sellerId, userId) => {
  return dispatch => {
    dispatch(startLoading);
    const formdata = new FormData();
    formdata.append('profile_picture_url', file, file.name || '');
    return postData(`${BUSINESS_SELLER}${sellerId}/profile_picture`, formdata, {
      'Content-Type': 'multipart/form-data',
    })
      .then(() => {
        enqueueSnackbar(
          <FormattedMessage id="SELLER_USER_PROFILE_PICTURE_UPLOAD_SUCCESS" />,
          snackbarConfig()
        );
        dispatch(fetchUserProfile(sellerId, userId));
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(dispatch(stopLoading));
  };
};

export const companyProfilePicUpload = (file, enqueueSnackbar, sellerId) => {
  return dispatch => {
    dispatch(startLoading);
    const formdata = new FormData();
    formdata.append('business_logo', file, file.name || '');

    return postData(`${BUSINESS_SELLER}${sellerId}/business_logo`, formdata, {
      'Content-Type': 'multipart/form-data',
    })
      .then(res => {
        enqueueSnackbar(
          <FormattedMessage id="SELLER_USER_COMPANY_PICTURE_UPLOAD_SUCCESS" />,
          snackbarConfig()
        );
        dispatch({ ...updateSellersCompanyProfile, payload: res.data });
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopLoading);
      });
  };
};

export const getMerchantFees = (sellerId, enqueueSnackbar) => {
  return dispatch => {
    dispatch(startLoading);
    return getData(`${SELLER_PRICING}${sellerId}`)
      .then(({ data: { buyer_pricing_fee_percent, seller_pricing_fee_percent } }) => {
        const { base_fee, fee_per_day, minimum_fee, ...sellerPricing } = seller_pricing_fee_percent;

        dispatch({
          type: SET_MERCHANT_FEES,
          payload: {
            dynamicFee: {
              base_fee: parseFloat(base_fee),
              fee_per_day: parseFloat(fee_per_day),
              minimum_fee: parseFloat(minimum_fee),
            },
            sellerFee: {
              ...sellerPricing,
            },
            buyerFee: {
              ...buyer_pricing_fee_percent,
            },
          },
        });
      })
      .catch(err => {
        errorMessage(err, enqueueSnackbar);
      });
  };
};
