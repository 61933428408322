import React from 'react';
import { LoaderWrapper } from './styled';

function BubbleLoader() {
  return (
    <LoaderWrapper>
      <div id="loader-4">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </LoaderWrapper>
  );
}

export default BubbleLoader;
