import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, TextField, MenuItem, Button } from '@mui/material';
import { useAppSelector } from 'store';
import axios from 'axios';

import { InitRequestPayload } from './types';
import { errorMessage } from 'utils';
import { useSnackbar } from 'notistack';

const initRequestPayload: InitRequestPayload = {
  user: {
    email: 'user@example.com',
    first_name: 'string',
    last_name: 'string',
    phone_number: 'string',
    language_code: 'en',
  },
  order: {
    amount: '151.60',
    currency_code: 'EUR',
    id: `order-${new Date().getTime()}`,
    payment_terms: 'NET7',
    shipping_address: {
      address_line1: 'string',
      address_line2: 'string',
      city: 'string',
      zip_code: 'string',
      country_code: 'st',
    },
  },
  business: {
    name: 'string',
    country_code: 'st',
    id: 'string',
    billing_email: 'user@example.com',
  },
  urls: {
    success: 'https://codepen.io/warrendunlop/pen/YmVKzm',
    failure: 'https://codepen.io/ziteboard/pen/vVyZBa',
    back_to_webshop: window.location.href,
  },
};

const RedirectCheckout = () => {
  const [requestPayload, setRequestPayload] = useState(initRequestPayload);
  const [apiKey, setApiKey] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const { sellerId } = useAppSelector(state => state.login);
  const {
    buyerDetails: {
      buyer_users,
      credit_qualification: { eligible_payment_terms },
      address,
      business_name,
      merchant_buyer_id,
      email: billingEmail,
    },
  } = useAppSelector(state => state.buyer);
  const { currencyList } = useAppSelector(state => state.settings);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const nextPayload: InitRequestPayload = {
      ...requestPayload,
      user: {
        email: buyer_users[0]?.email,
        first_name: buyer_users[0]?.first_name,
        last_name: buyer_users[0]?.last_name,
        phone_number: buyer_users[0]?.phone || '+351913888000',
        language_code: buyer_users[0]?.language || 'en',
      },
      order: {
        ...requestPayload.order,
        shipping_address: {
          address_line1: address.address_line1,
          city: address.city,
          zip_code: address.zip_code,
          country_code: address.country?.code,
        },
      },
      business: {
        name: business_name,
        country_code: address.country?.code,
        id: merchant_buyer_id,
      },
    };
    if (address.address_line2) {
      nextPayload.order.shipping_address.address_line2 = address.address_line2;
    }
    if (billingEmail) {
      nextPayload.business.billing_email = billingEmail;
    }

    setRequestPayload(nextPayload);
  }, [buyer_users, address]);

  const handleInputChange = (field: string, value: string) => {
    setRequestPayload(prevState => ({
      ...prevState,
      order: {
        ...prevState.order,
        [field]: value,
      },
    }));
  };

  const isButtonDisabled = !apiKey || !requestPayload.order.amount || !requestPayload.order.id;

  const handleRequestLink = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ACCESS_URL}/api/v1/redirect/${sellerId}/init/`,
        requestPayload,
        {
          headers: {
            'X-API-Key-ID': apiKey,
          },
        }
      );
      const link = process.env.REACT_APP_CURRENT_ENV === 'local' ? response.data.redirect_url.replace('https://checkout-testing.sprinque.com/', 'http://localhost:3000/') : response.data.redirect_url;
      setRedirectUrl(link);
    } catch (error) {
      errorMessage(error, enqueueSnackbar);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Typography variant="h3" gutterBottom>
        Let&apos;s build payload for the init request
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="API Key"
            variant="outlined"
            fullWidth
            value={apiKey}
            onChange={e => setApiKey(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Seller ID"
            variant="outlined"
            fullWidth
            value={sellerId}
            disabled
            margin="normal"
          />

          <Box sx={{ height: 32 }} />
          <Typography variant="h4" gutterBottom>
            Order details
          </Typography>

          <TextField
            label="Order Amount"
            variant="outlined"
            fullWidth
            value={requestPayload.order.amount}
            onChange={e => handleInputChange('amount', e.target.value)}
            margin="normal"
          />
          <TextField
            label="Currency Code"
            variant="outlined"
            fullWidth
            select
            value={requestPayload.order.currency_code}
            onChange={e => handleInputChange('currency_code', e.target.value)}
            margin="normal"
          >
            {currencyList.map(currency => (
              <MenuItem key={currency.name} value={currency.name}>
                {currency.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Order ID"
            variant="outlined"
            fullWidth
            value={requestPayload.order.id}
            onChange={e => handleInputChange('id', e.target.value)}
            margin="normal"
          />
          <TextField
            label="Payment Terms"
            variant="outlined"
            fullWidth
            select
            value={requestPayload.order.payment_terms}
            onChange={e => handleInputChange('payment_terms', e.target.value)}
            margin="normal"
          >
            {eligible_payment_terms.map(paymentTerm => (
              <MenuItem key={paymentTerm} value={paymentTerm}>
                {paymentTerm}
              </MenuItem>
            ))}
          </TextField>

          <Button
            variant="contained"
            color="primary"
            onClick={handleRequestLink}
            disabled={isButtonDisabled}
            sx={{ marginTop: 2 }}
          >
            Request Link
          </Button>
        </Grid>
        <Grid item xs={6}>
          <pre
            style={{
              border: '1px solid #343AEB',
              borderRadius: '6px',
              padding: '16px',
              whiteSpace: 'pre-wrap',
              overflowWrap: 'anywhere',
            }}
          >
            {JSON.stringify(requestPayload, null, 4)}
          </pre>
        </Grid>
      </Grid>

      {redirectUrl && (
        <Box sx={{ marginTop: 2 }}>
          <Typography
            variant="body1"
            style={{
              whiteSpace: 'pre-wrap',
              overflowWrap: 'anywhere',
              background: '#f3f1f1',
              padding: '10px',
              margin: '20px 0',
            }}
          >
            Redirect URL:{' '}
            <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
              {redirectUrl}
            </a>
          </Typography>
          <Button variant="outlined" onClick={() => navigator.clipboard.writeText(redirectUrl)}>
            Copy to Clipboard
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default RedirectCheckout;
