// vendor
import React from 'react';
import { useAppSelector } from 'store';
import { Switch, Route } from 'react-router-dom';
import { Box } from '@mui/material';

// components
import { OrdersTable } from './orders-table';
import { Header } from './header';
import { SubMenu } from 'UI/components/mp-sidebar-menu/sub-menu';
import { ConfirmOrderVoid } from './confirm-void-order';

// constants
import { ORDER_SUB_MENU } from 'constants/invoice.constants';
import {
  ORDERS_DETAILS_PENDING,
  ORDERS_DETAILS_PROCESSED,
  BUYERS_ORDERS_DETAILS_PROCESSED_ROUTE,
  BUYERS_ORDERS_DETAILS_PENDING_ROUTE,
} from 'constants/routes.constants';
import { PendingOrdersTable } from './pending-table';

type Props = {
  buyerId: string;
  /** Buyer status */
  status?: string;
  /** Buyer name */
  name?: string;
};

export const OrdersDashboard = ({ status, buyerId, name }: Props) => {
  const {
    settings: {
      sellerConfig: { is_pending_order_enabled },
    },
  } = useAppSelector(state => state);

  return (
    <div style={{ position: 'relative' }}>
      <Header buyerId={buyerId} status={status || ''} name={name || ''} />

      {is_pending_order_enabled ? (
        <Box borderBottom="2px solid #D9DBF2" mb={3}>
          <SubMenu menu={ORDER_SUB_MENU(buyerId)} withoutMargin />
        </Box>
      ) : (
        <OrdersTable buyerId={buyerId} />
      )}

      {is_pending_order_enabled ? (
        <Box width="100%">
          <Switch>
            <Route
              path={buyerId ? BUYERS_ORDERS_DETAILS_PROCESSED_ROUTE : ORDERS_DETAILS_PROCESSED}
              render={() => <OrdersTable buyerId={buyerId} />}
            />
            <Route
              path={buyerId ? BUYERS_ORDERS_DETAILS_PENDING_ROUTE : ORDERS_DETAILS_PENDING}
              render={() => <PendingOrdersTable buyerId={buyerId} />}
            />
          </Switch>
        </Box>
      ) : (
        <></>
      )}

      <ConfirmOrderVoid />
    </div>
  );
};
